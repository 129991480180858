<template>
    <div class="frame">
        <iframe :src="url" class="embed" frameborder="0" scrolling="no"/>
    </div>
</template>

<script>
export default {
  name: 'EmbedBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    data() {
        return {
            url: ""
        }
    },
    mounted() {
        const content = this.block.saveContent;
        if(content) {
            const url = content.replace(/<[^>]*>?/gm, '').replace("https://vimeo.com", "https://player.vimeo.com/video");
            this.url = url.replace(/\/(?=[^\/]*$)/, "?h=");
        }
    }
}

</script>

<style lang="scss" scoped>
iframe {
    width: 100%;
    height: 100%;
}

.frame {
    width: 100%;
    height: 750px;
    overflow: hidden;
    background-color: #000;
    margin-bottom: 20px;
}

</style>