<template>
  <div class="rpc-header">
    <header :class="isMobile() || isiPad() ? 'header mobile' : 'header'">
      <div
        :class="
          isMobile()
            ? 'header-top header-top-mobile'
            : isiPad()
            ? 'header-top header-top-iPad'
            : 'header-top header-top'
        "
      >
        <div class="header-left">
          <img
            v-responsive="['hidden-all', 'lg', 'xl']"
            alt="Sal logo"
            :src="details.salLogo"
            class="sal-logo"
            @click="reload"
          />
          <img
            v-responsive="['hidden-all', 'md']"
            alt="Sal logo"
            :src="details.salLogo"
            class="sal-logo-iPad"
            @click="reload"
          />
          <img
            v-responsive="['hidden-all', 'sm', 'xs']"
            alt="Sal logo"
            :src="details.salLogo"
            class="m-sal-logo"
            @click="reload"
          />
          <p v-responsive="['hidden-all', 'md', 'sm', 'xs']" class="m-title">
            {{ details.appTitle }}
          </p>
          <p v-responsive="['hidden-all', 'lg', 'xl']" class="title">
            {{ details.appTitle }}
          </p>
          <!-- <p class="title">Retail Partner Connect</p> -->
        </div>
        <div :class="isMobile() ? 'm-header-right' : 'header-right'">
          <b-input-group class="rpc-input-group" v-if="false">
            <b-form-input placeholder="SEARCH" class="rpc-input input-search" />
            <img class="search" src="../assets/images/search.svg" alt="Sal" />
          </b-input-group>
          <b-dropdown
            id="dropdown-1"
            no-caret
            class="rpc-input store-dd"
            v-if="false"
          >
            <template #button-content>
              Stores
              <img
                class="dd-icon"
                src="../assets/images/dropdown_blue.svg"
                alt="Sal"
              />
            </template>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="flavour-2a"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
          <img
            class="sal-icon notification"
            src="../assets/NotificationDot.svg"
            alt="Sal"
            v-if="false"
          />
            <mAllSearch
                v-responsive="['hidden-all', 'sm', 'xs']"
                class="m-search"
            />
            <mAllSearch
                v-responsive="['hidden-all', 'md']"
                class="t-search"
            />
            <AllSearch
                v-responsive="['hidden-all', 'lg', 'xl']"
                class="search-icon"
            />
          <ProfileIcon
            v-responsive="['hidden-all', 'lg', 'xl']"
            class="profile-icon"
            :profileicon="details.profileIcon"
          />

          <img
            class="m-hamburg hamburg"
            v-responsive="['hidden-all', 'sm', 'xs']"
            :src="details.hamburgmenuIcon"
            :getMenuTitle="extractMenuTitle"
            v-b-toggle.sidebar-backdrop
            @click="menuClicked()"
          />

          <img
            class="t-hamburg hamburg"
            v-responsive="['hidden-all', 'md']"
            :src="details.hamburgmenuIcon"
            :getMenuTitle="extractMenuTitle"
            v-b-toggle.sidebar-backdrop
            @click="menuClicked()"
          />
          <!-- <transition name="slide-fade">
            <nav-bar v-if="showNavbar">hello</nav-bar>
          </transition> -->
          <b-sidebar
            id="sidebar-backdrop"
            backdrop
            shadow
            no-header-close
            right
            v-if="showNavbar"
            v-responsive="['hidden-all', 'md', 'xs', 'sm']"
          >
            <nav-bar></nav-bar>
          </b-sidebar>
        </div>
      </div>
      <b-row
        class="cl-section"
        v-responsive="['hidden-all', 'lg', 'xl']"
        @mouseleave="showCommonLinksSection(false)"
      >
        <b-row no-gutters class="cl-headers">
          <div class="common-links" @mouseover="showCommonLinksSection(true)">
            <span
              class="common"
              :class="visible ? null : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'"
              aria-controls="collapse-4"
            >
              {{ details.commonLinksTitle }}
              <img class="arrow" :src="details.commonLnksIcon" alt="Sal" />
            </span>
          </div>
          <div
            :class="
              clSelectedBtn === btnTitles.allapps
                ? 'cl-common-btn-selected'
                : 'cl-common-btn'
            "
            v-show="visible"
            @click="showAllApplications()"
          >
            {{ btnTitles.allapps }}
          </div>
          <div
            :class="
              clSelectedBtn === btnTitles.fav
                ? 'cl-common-btn-selected'
                : 'cl-common-btn'
            "
            v-show="visible"
            @click="showFavsSection()"
          >
            {{ btnTitles.fav }}
          </div>
          <div
            :class="
              clSelectedBtn === btnTitles.recent
                ? 'cl-common-btn-selected'
                : 'cl-common-btn'
            "
            v-show="visible"
            @click="showRecentlyVisitedSection()"
          >
            {{ btnTitles.recent }}
          </div>
        </b-row>
        <b-collapse
          id="collapse-4"
          v-model="visible"
          class="mt-2 common-links-body"
        >
          <div class="spinner" v-if="isLoading">
            <b-spinner></b-spinner>
            <div>Loading...</div>
          </div>
          <b-card
            class="cl-card"
            rows="3"
            @mouseleave="showCommonLinksSection(false)"
          >
            <b-row class="cl-card-body">
              <b-col class="col-xs-4 mr-4">
                <b-list-group class="list-group" v-show="selected.length > 0">
                  <b-list-group-item
                    class="cl-li-item"
                    v-for="(cLink, index) in selected"
                    :key="index"
                  >
                    <a v-if="showFavIcon">
                      <img
                        class="favorite"
                        :src="getFavImage(cLink.favEnbld)"
                        alt="Sal"
                        @click="favIconClicked(cLink)"
                      />
                    </a>
                    <a
                      class="appname"
                      :href="cLink.appLink"
                      target="_blank"
                      @click="applinkClicked(cLink)"
                      >{{ cLink.appName }}
                    </a>
                  </b-list-group-item>
                </b-list-group>
                <h5 v-show="selected.length === 0">
                  {{ noFavorites }}
                </h5>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-row>
    </header>
  </div>
</template>

<script>
import ProfileIcon from "./ProfileIcon.vue";
import AllSearch from "./module/search/AllSearch.vue";
import mAllSearch from "./module/search/mAllSearch.vue";
import { COMMON_LINKS_QUERY } from "../queries/gqlQueries.js";
import { mapActions, mapGetters } from "vuex";
import Utils from "../utils/Utils";
import store from "../store/index";
import NavBar from "./mobile/Navbar.vue";
const userInfo = store.getters.profileGetter;

export default {
  name: "Header",
  components: {
    ProfileIcon,
    NavBar,
    AllSearch,
    mAllSearch,
  },
  props: {
    pageDetails: Object,
  },
  data() {
    return {
      middleSize: ["hidden-all", "lg", "xl"],
      contentUrl: "https://salextranet.wpengine.com/",
      menuLabel: "Dashboard",
      componentName: "Dashboard",
      visible: false,
      showFavoriteBtnClick: false,
      showRecentBtnClick: false,
      clSelectedBtn: Utils.Strings.AllApps,
      btnTitles: {
        fav: Utils.Strings.Favs,
        allapps: Utils.Strings.AllApps,
        recent: Utils.Strings.RecentVstd,
        cmnLinks: Utils.Strings.commonLinks,
      },
      isLoading: false,
      selected: [],
      showFavIcon: true,
      showNavbar: false,
      noFavorites: Utils.Strings.noFavorites,
      showMobileSearch: false,
    };
  },
  created() {
    this.getAdditionalLinksData();
  },
  computed: {
    ...mapGetters([
      "getCommonLinks",
      "getMenuList",
      "getAdditionalLinks",
      "profileGetter",
      "userName",
      "getHistory",
      "getFavorites",
      "getFavStatus",
      "getFavErrCode",
    ]),
    ...mapGetters("menuModule", ["commonLinks", "visibleCommonLinks"]),
    details() {
      return {
        appTitle: this.pageDetails.appTitle,
        salLogo: this.pageDetails.salLogo,
        profileIcon: this.pageDetails.profileIcon,
        commonLinksTitle: this.pageDetails.commonLinksTitle,
        commonLnksIcon: this.pageDetails.commonLnksIcon,
        hamburgmenuIcon: this.pageDetails.hamburgermenuIcon,
      };
    },
    getUserName() {
      if (userInfo.length > 0) {
        return userInfo[0].profile.mail;
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "fetchCommonLinks",
      "fetchMenu",
      "fetchSubMenu",
      "fetchHistoryList",
      "saveHistory",
      "fetchFavoritesList",
      "addFavorites",
      "deleteFavorite",
    ]),
    showMobileInput() {
        this.showMobileSearch = true;
        document.body.style.overflow = "hidden";
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    menuClicked() {
      this.showNavbar = true;
    },
    async getAdditionalLinksData() {
      this.isLoading = true;
      const request = {
        environment: Utils.Strings.environment,
        userName: this.getUserName,
      };
      await this.fetchMenu(request).then(() => {
        let threads = 0;
        this.getMenuList.forEach((menu) => {
          const req = {
            environment: Utils.Strings.environment,
            businessType: menu.salmenu,
            userName: this.getUserName,
          };
          threads = threads + 1;

          this.fetchSubMenu(req).then((res) => {
            threads = threads - 1;
            if (threads === 0) {
              this.fetchCommonLinks(COMMON_LINKS_QUERY).then(() => {
                // this.loadCommonLinks = this.getCommonLinks;
                this.fetchFavoritesList(this.getUserName) //.then((res) => {});
              });
            }
          });
        });
      });
      this.isLoading = false;
    },
    showCommonLinksSection(value) {
      this.visible = value;
      this.showFavIcon = true;
      if (this.showRecentBtnClick) {
        this.showFavIcon = false;
        this.fetchHistoryList(this.userName).then(() => {
          this.selected = this.getHistory;
        });
      } else if (this.showFavoriteBtnClick) {
        this.selected = this.getFavorites;
      } else {
        this.selected = this.getCommonLinks;
      }
      this.$emit("commonlinkspresent", value);
    },
    showAllApplications() {
      this.showFavoriteBtnClick = false;
      this.showRecentBtnClick = false;
      this.showFavIcon = true;
      this.selected = this.getCommonLinks;
      this.clSelectedBtn = Utils.Strings.AllApps;
    },
    showFavsSection() {
      this.fetchFavoritesList(this.userName);
      this.showFavIcon = true;
      this.selected = this.getFavorites;
      this.showFavoriteBtnClick = true;
      this.showRecentBtnClick = false;
      this.clSelectedBtn = Utils.Strings.Favs;
      // this.$store.commit(
      //   "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
      //   this.getFavorites,
      //   { root: true }
      // );
    },
    showRecentlyVisitedSection() {
      this.showFavoriteBtnClick = false;
      this.showRecentBtnClick = true;
      this.showFavIcon = false;
      this.clSelectedBtn = Utils.Strings.RecentVstd;
      this.fetchHistoryList(this.userName).then(() => {
        this.selected = this.getHistory;
      });
    },
    favIconClicked(cLink) {
      this.$store.commit("menuModule/MENU_LOADING_UPDATE", true, {
        root: true,
      });
      if (cLink.favEnbld) {
        const request = {
          userName: this.userName,
          label: cLink.appName,
          breadcrumb: "",
          url: cLink.appLink,
        };
        this.deleteFavorite(request).finally(() => {
          this.fetchFavoritesList(this.userName)
            .then(() => {
              if (this.clSelectedBtn === this.btnTitles.fav) {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.getFavorites,
                  { root: true }
                );
                // this.loadCommonLinks = this.getFavorites;
              } else {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.commonLinks,
                  { root: true }
                );
              }
              this.$store.commit("updateFavStatus", {
                appName: cLink.appName,
                appCategory: null,
                favStatus: false,
              });
            })
            .finally(() => {
              this.$store.commit("menuModule/MENU_LOADING_UPDATE", false, {
                root: true,
              });
            });
        });
      } else {
        const request = {
          userName: this.userName,
          environment: Utils.Strings.environment,
          userFavorites: [
            {
              menuLabel: cLink.appName,
              url: cLink.appLink,
              breadcrumb: "",
              addWrapper: "",
            },
          ],
        };
        this.addFavorites(request)
          .then(() => {
            this.fetchFavoritesList(this.userName).then(() => {
              if (this.clSelectedBtn === this.btnTitles.fav) {
                this.$store.commit(
                  "menuModule/FAVORITE_MENU_LINKS_LOADED",
                  this.getFavorites,
                  { root: true }
                );
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.getFavorites,
                  { root: true }
                );
              } else {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.commonLinks,
                  { root: true }
                );
              }
            });
            this.$store.commit("updateFavStatus", {
              appName: cLink.appName,
              appCategory: null,
              favStatus: true,
            });
          })
          .finally(() => {
            this.$store.commit("menuModule/MENU_LOADING_UPDATE", false, {
              root: true,
            });
          });
      }
      console.log("*** getcommonlinks ***", this.getCommonLinks);
    },
    getFavImage(status) {
      return status ? Utils.Images.favEnable : Utils.Images.favDisable;
    },
    extractUrl(url) {
      this.contentUrl = url;
    },
    extractMenuTitle(menu) {
      //this.contentUrl = url;
      this.menuLabel = menu;
      this.componentName = menu;
    },
    reload() {
      this.menuLabel = "Dashboard";
      this.componentName = "Dashboard";
      this.$emit("reloadPage", this.menuLabel);
      this.$root.$emit("disabledBackground");
      this.$root.$emit("one", this.menuLabel);
    },
    // removeTags(str) {
    //   if (str === null || str === "") return false;
    //   else str = str.toString();
    //   return str.replace(/(<([^>]+)>)/gi, "");
    // },
    // async fetchCommonLinksData() {
    //   this.fetchCommonLinks(COMMON_LINKS_QUERY);
    // gQLCaller(COMMON_LINKS_QUERY).then((result) => {
    //   this.commonLinks = result.allCommonLinks.nodes;
    // });
    //},
    applinkClicked(appDetails) {
      const params = {
        menuLabel: appDetails.appName,
        url: appDetails.appLink,
        breadcrumb: "",
        addWrapper: false,
      };
      const request = {
        userName: this.userName,
        environment: Utils.Strings.environment,
        userHistory: [params],
      };
      this.saveHistory(request);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/_shared.scss";

.rpc-header {
  .mobile {
    margin-top: 5px !important;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 37px;

    .header-top {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 120px;
    }
    .header-top-mobile {
      height: 60px !important;
    }
    .header-top-iPad {
      height: 120px !important;
    }
    .header-left {
      width: 70%;
      display: flex;
      justify-content: left;
      align-items: center;

      .m-sal-logo {
        width: 45px;
        height: 45px;
        cursor: pointer;
        margin-left: 15px;
      }

      .sal-logo-iPad {
        width: 80px;
        height: 80px;
        cursor: pointer;
        margin-left: 25px;
      }

      .sal-logo {
        width: 120px;
        height: 120px;
        cursor: pointer;
        margin-left: 75px;
      }

      .m-title {
        font-family: "Isidora-Bold";
        padding-left: 5px;
        width: fit-content;
        word-wrap: normal;
        font-size: 4vw;
        color: $salBlue;
        font-weight: 700;
        margin-bottom: 0rem;
        display: -webkit-box;
        line-clamp: 1;
        text-align: left;
      }

      .title {
        font-family: "Isidora-Bold";
        padding-left: 20px;
        width: fit-content;
        word-wrap: normal;
        font-size: 3vw;
        color: $salBlue;
        font-weight: 700;
        margin-top: 15px;
        display: -webkit-box;
        line-clamp: 1;
        text-align: left;
      }
    }
    .m-header-right {
      width: 30%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
    }
    .header-right {
      width: 30%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin: auto 85px auto 0px;
      .rpc-input-group {
        width: 200px;
      }
      .sal-icon {
        width: 60p;
        height: 60px;
        margin: auto 20px !important;
      }
      .sal-icon:hover {
        cursor: pointer;
      }
      .rpc-input {
        font-family: "Isidora Sans";
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        width: 200px;
        height: 60px;
        color: $salBlue !important;
        border: 1px solid $salBlue !important;
        border-radius: 30px;
        text-align: start;
        margin-right: 10px;

        .dd-icon {
          padding-left: 80px;
        }
      }
      .store-dd {
        margin-left: 20px;
        .custom-control-input {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
      .search {
        z-index: 1000;
        align-self: center;
      }

      .input-group:not(.has-validation)
        > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
      .input-group:not(.has-validation)
        > .dropdown-toggle:nth-last-child(n + 3),
      .input-group:not(.has-validation)
        > .form-floating:not(:last-child)
        > .form-control,
      .input-group:not(.has-validation)
        > .form-floating:not(:last-child)
        > .form-select {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
      .input-group
        > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
      .input-group > .form-floating:not(:first-child) > .form-control,
      .input-group > .form-floating:not(:first-child) > .form-select {
        margin-left: -30px;
      }
      .input-search ::placeholder {
        color: $salLightGrey !important;
      }
      .btn.dropdown-toggle.btn-secondary {
        background-color: transparent !important;
        color: $salBlue !important;
        border: none;
        border-radius: 30px;
      }
    }

    .cl-li-item {
      min-width: 250px;
      color: $salBlueCommonlink !important;
      border: none !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .appname {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: normal;
      }
    }
    .cl-li-item > a {
      text-decoration: none;
      font-family: "Isidora Sans";
      font-weight: 500;
      font-size: 18px;
      .favorite {
        margin-right: 5px;
      }

      .favorite:hover {
        cursor: pointer;
      }
    }

    .cl-section {
      width: 100%;
      margin-top: 10px;

      .cl-headers {
        font-family: "Isidora Sans";
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;

        .cl-common-btn {
          color: $salBlue;
          background-color: white;
          width: fit-content;
          margin-left: 15px;
          max-width: 170px;
          height: 39px;
          border: 0.5px solid $salBlue;
          border-radius: 10px;
          padding: 5px 10px 10px;
          cursor: pointer;
          font-family: "Isidora Sans";
          font-weight: 600;
        }

        .cl-common-btn-selected {
          color: white;
          background-color: $salBlue;
          width: fit-content;
          margin-left: 15px;
          max-width: 170px;
          height: 39px;
          border: 0.5px solid $salBlue;
          border-radius: 10px;
          padding: 5px 10px 10px;
          cursor: pointer;
          font-family: "Isidora Sans";
          font-weight: 600;
        }
      }

      .common-links {
        background-color: $salYellow;
        width: 352px;
        height: 56px;
        border-radius: 0 20px 20px 0px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-right: 30px;
        padding: 15px 0 10px 35px !important;
        margin: 10px 0;

        .common {
          color: $salBlue;
          font-family: "Isidora-SemiBold";
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          align-self: center;
          cursor: pointer;
        }
        .arrow {
          align-self: center;
          width: 22px;
          height: 22px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .common-links-body {
        width: 100%;
        font-size: 14px !important;
        background-color: $salGrey;
        margin-bottom: 15px;

        .spinner {
          position: absolute;
          width: 200px;
          height: 80px;
          left: 50%;
          top: 50%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1001;
          color: black;
          border-radius: 10px;

          div {
            margin-left: 5px;
            font-family: "Isidora Sans";
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
        }
        .cl-card {
          font-family: "Isidora Sans";
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          width: 100%;
          border: none !important;
          background-color: transparent;
        }
        .cl-card-body {
          margin-left: 35px;
          margin-bottom: 0;

          .list-group {
            display: inline-grid !important;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-column-gap: 1em;
            grid-auto-flow: row;
            width: 95%; 
            height: 100%;
          }

          .list-group-item {
            background-color: transparent !important;
          }
        }
      }
    }

    .common-links-collapsed {
      margin-top: 1px;
      background-color: red;
    }
  }
}

.profile-icon {
    display: inline-block;
    float: right;

}

.search-icon {
    display: inline-block;
    float: right;
    margin-right: 300px;
    z-index: 1001;
}

.m-search {
    display: inline-block;
    float: right;
    top: 0;
    margin-right: 0px;
    margin-top: 5px;
    .search-img {
        height: 25px;
        width: 25px;
        float: right;
    }
    .back-icon {
        height: 25px;
        width: 25px;
        margin-left: 15px;
        margin-top: 40px;
    }
    .input-icon {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 20px;
    }
    .search-input {
        width: 85vw;
        height: 35px;
    }
    z-index: 1001;
}

.t-search {
    display: inline-block;
    float: right;
    margin-right: 5px;
    top: 0;
    margin-top: 5px;
    .search-img {
        width: 40px;
        height: 40px;
        float: right;
    }
    .back-icon {
        height: 30px;
        width: 30px;
        margin-left: 10px;
        margin-top: 40px;
    }
    .input-icon {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 40px;
    }
    .search-input {
        width: 90vw;
        height: 35px;
    }
    z-index: 1001;
}

.m-hamburg {
  width: 30px;
  height: 30px;
}
.t-hamburg {
  width: 45px;
  height: 45px;
}
.hamburg:hover {
  cursor: pointer;
}
#sidebar-backdrop {
  margin-top: 20px;
}
.b-sidebar > .b-sidebar-header {
  background-color: $navBlue;
  padding: 5px 1rem !important;
}
.b-sidebar > .b-sidebar-body {
  background-color: white;
}
// @media (min-width: 970px) {
//   .content-body {
//     max-width: 100%;
//   }
//   .rpc-header .header .title {
//     font-size: 38px !important;
//   }
//   .rpc-header .header .cl-section .common-links-body .cl-card {
//     max-height: 350px;
//   }
// }

// @media screen and (min-width: $medium) and (max-width: $xtralg) {
//   .rpc-header .header .title {
//     font-size: 38px;
//   }
// }
</style>
