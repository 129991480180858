<template>
    <div class="container">
        <h2>{{ message }}</h2>
    </div>
</template>
<script>

export default {
        props: {
            result: {
                type: Number,
                required: true
            },
        },
        computed: {
             message: function () {
                if (this.result==1){
                    return this.result + " result found"
                }
                if (this.result>0){
                    return this.result + " results found"
                }
                return "No results found"                  
             }
            }
}
</script>


<style lang="scss" scoped>
h2 {
    float: left;
    font-family: "Isidora-Bold";
    font-size: 32px; 
    color: #EE3124;
    padding-top: 15px;
}
</style>