import * as saveActions from './actions';
import savedGetters from './getters';
import saveMutations from './mutations';

export const savedStore = {
    state: {
        savedContent: {},
        savedPage: {},
    },
    actions: {
        ...saveActions,
    },
    mutations: {
        ...saveMutations,
    },
    getters: {
        ...savedGetters,
    }
}