<template>
  <div :class="this.isMobile() ? 'mweb-cards' : this.isiPad() ? 'iweb-cards' : 'web-cards' ">
    <div class="web-content">
      <div class="web-header">
        <div
          class="hover-overlay"
          @mouseover="show = webinarInfo.headline"
          @mouseleave="show = ''"
        >
          <div
            class="hover-background"
            v-if="show == webinarInfo.headline"
            @click="watchNow(webinarInfo.webinarLink)"
          >
            <img
              src="../../../assets/images/play_button.svg"
              v-if="show == webinarInfo.headline"
              class="play-btn"
              alt="Watch Now"
            />
          </div>
        </div>
        <img
          class="thumbnail"
          v-bind:src="webinarInfo.thumbnailImage.mediaItemUrl"
        />
      </div>
      <span class="web-header">
        <h1>{{ webinarInfo.headline }}</h1>
        <h2>{{ formatRawDate(webinarInfo.postedDate) }}</h2>
      </span>
      <span class="web-body">
        <p>{{ webinarInfo.cardSummary }}</p>
      </span>
      <div class="web-footer">
        <a
          :href="webinarInfo.webinarLink"
          class="watch-link"
          target="_blank"
          @click="watchNow(webinarInfo.webinarLink)"
        >
          <img
            src="../../../assets/WatchMore.svg"
            class="watch-btn"
            alt="Watch Now"
          />
        </a>
        <img
          src="../../../assets/PDF.svg"
          class="pdf-btn"
          v-if="webinarInfo.pDFAttachment"
          @click="pdfRead(webinarInfo.pDFAttachment.mediaItemUrl)"
        />

        <a
          target="_blank"
          v-if="this.getSaveProfile"
          @click="saveThisItem(webinarInfo.databaseId)"
          class="save-btn"
        >
          <img
            :src="
              isSaved(webinarInfo.databaseId)
                ? require('../../../assets/SaveFilled.svg')
                : require('../../../assets/Save.svg')
            "
          />
        </a>

      </div>
    </div>
  </div>
</template>
<script>
import { convertDate } from "../../../utils/Dates.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    webinarInfo: Object,
  },
  data() {
    return {
      date: "",
      show: false,
    };
  },
  computed: {
    ...mapGetters(["getSaveProfile"]),
  },
  methods: {
    ...mapActions(["updateSaveProfile"]),
    isSaved: function () {
        if (this.getSaveProfile.userPosts.length > 0) {
            for (let i = 0; i < this.getSaveProfile.userPosts.length; i++) {
                if (this.getSaveProfile.userPosts[i].postId == this.webinarInfo.databaseId) {
                    return true;
                }
            }
        }
        return false;
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    saveThisItem(id) {
        this.updateSaveProfile(id);
    },
    determinePdfFile(attchmt) {
      return attchmt === null ? false : true;
    },
    formatRawDate(date) {
      return convertDate(date);
    },
    pdfRead(post) {
      window.open(post, "_blank");
    },
    watchNow(link) {
      window.open(link, "_blank");
    },
    getPostedDate(pDate) {
      const cDate = new Date(pDate);
      const month = cDate.toLocaleString("en-US", { month: "long" });
      return month + " " + cDate.getDate() + ", " + cDate.getFullYear();
    },
    removeHtmlTags(str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    },
    openLinkInNewWindow(link) {
      window.open(link);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/webinar-cards.scss";

.hover-background {
  display: flex;
  justify-content: center;
  align-items: center;
  .play-btn {
    width: 70px;
    height: 70px;
  }
}
</style>
