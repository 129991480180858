import { filterMenu } from "../../utils/MenuFilter.js";

export default {
  saveMenu(state, data) {
    state.additionalLinks = [];
    state.menuList = data;
  },
  saveSubMenu(state, data) {
    let applications = [];
    const onj = data.res.map((submenu) => {
      const second = submenu.salMenuChildren.map((secondEle) => {
        if (typeof secondEle.salmenu === "object") {
          const secCheckFav = state.favoritesData.filter(
            (app) => app.appName === secondEle.salmenu.label
          );
          const secObj = {
            label: secondEle.salmenu.label,
            appStartingUrl: secondEle.salmenu.appStartingUrl,
            isFav: secCheckFav.length > 0 ? true : false,
          };
          applications.push({ appdetails: secObj });
        }
        if (
          secondEle.salMenuChildren != null &&
          secondEle.salMenuChildren.length > 0
        ) {
          const trird = secondEle.salMenuChildren.map((thirdEle) => {
            if (typeof thirdEle.salmenu === "object") {
              const thrdCheckFav = state.favoritesData.filter(
                (app) => app.appName === thirdEle.salmenu.label
              );

              const thrdObj = {
                label: thirdEle.salmenu.label,
                appStartingUrl: thirdEle.salmenu.appStartingUrl,
                isFav: thrdCheckFav.length > 0 ? true : false,
              };
              applications.push({ appdetails: thrdObj });
            }

            if (
              thirdEle.salMenuChildren != null &&
              thirdEle.salMenuChildren.length > 0
            ) {
              const forth = thirdEle.salMenuChildren.map((forthEle) => {
                if (typeof forthEle.salmenu === "object") {
                  const frthCheckFav = state.favoritesData.filter(
                    (app) => app.appName === forthEle.salmenu.label
                  );

                  const frthObj = {
                    label: forthEle.salmenu.label,
                    appStartingUrl: forthEle.salmenu.appStartingUrl,
                    isFav: frthCheckFav.length > 0 ? true : false,
                  };
                  applications.push({ appdetails: frthObj });
                }
              });
            }
          });
        }
      });
    });

    filterMenu(applications).then((res) => {
      const addlLinkObj = [
        {
          category: data.appCategory,
          appList: res,
        },
      ];

      if (!state.additionalLinks.some( addlLink => 
          addlLink.category === addlLinkObj[0].category
      ) && addlLinkObj[0].appList.length > 0) {
          state.additionalLinks = [...state.additionalLinks, ...addlLinkObj];
      }
    });
  },
  resetState(state) {
    state.additionalLinks = [];
    state.menuList = [];
  },
  updateFavStatus(state, app) {
    const existingApps = state.additionalLinks;
    if (app.appCategory === null) {
      existingApps.forEach((data) => {
        data.appList.forEach((appDetails) => {
          if (appDetails.label === app.appName) {
            appDetails.isFav = app.favStatus;
          }
        });
      });
    } else {
      const filteredCatgoryObj = existingApps.filter(
        (link) => link.category === app.appCategory
      );
      filteredCatgoryObj[0].appList.forEach((appDetails) => {
        if (appDetails.label === app.appName) {
          appDetails.isFav = app.favStatus;
        }
      });

      existingApps.forEach((list) => {
        if (list.category === app.appCategory) {
          list = filteredCatgoryObj[0];
        }
      });
      state.additionalLinks = existingApps;
    }
  },
};
