import axios from 'axios';
import { CallApimWithGql } from "../../api/GraphqlAPI";

export const getSearch = async ({ commit }, payload ) => {
    await CallApimWithGql(payload)
        .then((response) => {
            commit('MUTATE_SEARCH_RESULTS', response);
            commit('MUTATE_SEARCH_PARAMS', payload.vars);
        })
        .catch((err) => {
            console.log("API failed - ", err);
        });
}