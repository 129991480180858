<template>
    <div class="outer">
        <div class="headings">
            <div class="heading-dash" v-responsive="['hidden-all', 'lg', 'xl']">
                <h1>Search</h1>
                <h1 class="red-dot">.</h1>
            </div>
            <div class="heading-dash" v-responsive="['hidden-all', 'md']">
                <h2>Search</h2>
                <h2 class="red-dot">.</h2>
            </div>
            <div
            class="heading-dash mobile"
            v-responsive="['hidden-all', 'sm', 'xs']"
            >
                <h3>Search</h3>
                <h3 class="red-dot">.</h3>
            </div>
        </div>
        <div class="breadcrumbs">
            <h1 @click="breadcrumbNav()">Retail Partner Connect </h1>
            <h2> / </h2>
            <h2>{{searchParams}}</h2>
        </div>
        <div class="results-found">
            <h1>{{resultCount}} Results Found</h1>
            <h1 v-if="empty">!</h1>
            <hr v-if="!empty"/>
        </div>
        <div class="no-results" v-if="empty">
            <h1 @click="breadcrumbNav()">< Go Back to Homepage</h1>
        </div>
        <div class="results"  v-for="result in searchResults" :key="result.key">
            <div v-if="result.key != 'FAQs' && result.key != 'WeeklyCheckoutArchives' && result.key != 'Recalls'" @click="navigationHandling(result.key.replace(/([a-z])([A-Z])/g, '$1 $2'), item)" v-for="item in result.nodes">
                <h3>{{result.key.replace(/([a-z])([A-Z])/g, '$1 $2')}}</h3>
                <h2>{{item.headline}}</h2>
                <h2 v-if="result.key.includes('RetailResources')">{{item.pDFTitle}}</h2>
                <h2 v-if="result.key.includes('FAQ')">{{item.question}}</h2>
                <hr/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SEARCH_QUERY } from '../../../queries/gqlQueries';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            searchResults: [],
            searchParams: "",
            empty: true,
            resultCount: 0,
        }
    },
    computed: {
        ...mapGetters(['getSearchResult', 'getSearchParams']),
        getResult() {
            return this.getSearchResult;
        },
    },
    methods: {
        ...mapActions(['getSearch']),
        emptyCheck(){
            this.empty = true;
            Object.entries(this.searchResults).forEach(key => {
                if(key[1].nodes.length > 0){
                    this.empty = false;
                    key[1].key = key[0];
                }
            });
        },
        navigationHandling(key, item) {
            if(key == "Important News And Updates") {
                this.$router.push({ name: 'Important News and Updates', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Dot Comm Archives") {
                this.$router.push({ name: 'Dot Comm Archives', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Webinars") {
                console.log(item.story);
                this.$router.push({ name: 'Webinars', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Sharing Corner") {
                this.$router.push({ name: 'Sharing Corner', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Retail Resources") {
                this.$router.push({ name: 'Retail Resources', params:{ item: item }});
            }
        },
        countResults() {
            this.resultCount = 0;
            Object.entries(this.searchResults).forEach(key => {
                if(key[1].key != 'FAQs' && key[1].key != 'WeeklyCheckoutArchives' && key[1].key != 'Recalls') {
                    key[1].nodes.forEach(node => {
                        if(node) {
                            this.resultCount++;
                        }
                    });
                }
            });
            if (this.resultCount == 0) {
                this.resultCount = "No";
            }
        },
        breadcrumbNav() {
            this.$emit('navHome', "Dashboard");
        },
        handleSearch() {
            let searchValue = document.querySelector('.search-box').value
            if(searchValue) {
                let payload =
                {
                    query: SEARCH_QUERY,
                    vars: {
                        searchTerm: searchValue
                    }
                }
                this.getSearch(payload).then(() => {
                    this.searchResults = this.getSearchResult;
                    this.searchParams = searchValue;
                    this.emptyCheck();
                    this.countResults();
                });
            }
        },
    },
    mounted() {
        this.searchResults = this.getSearchResult;
        this.searchParams = this.getSearchParams;
        this.emptyCheck();
        this.countResults();
        document.querySelector('.search-box').addEventListener('keyup', (e) => {
            if (e.keyCode === 13) {
                this.handleSearch();
            }
        })
    },
    watch: {
        getResult() {
            this.searchResults = this.getSearchResult;
            this.searchParams = this.getSearchParams;
            this.emptyCheck();
            this.countResults();
        }

    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.heading-dash {
    text-align: left;
    margin-left: 70px;
    margin-top: 60px;
    margin-bottom: 10px;
    max-height: 45px;
    display: flex;
    flex-direction: row;
    color: #14377c;
    h1 {
      font-family: "Isidora-Bold";
      font-size: 48px;
    }
}
.heading-dash.mobile {
  margin-left: 25px;
  margin-top: 0px;
}

.heading-dash .red-dot {
  color: #ee3124;
}

.search-div {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
}

.search-box {
    position: relative;
    font-family: "Isidora-SemiBold";
    font-weight: 600px;
    color: $salBlue;
    line-height: 24px;
    font-size: 20px;
    border: 2px solid $salBlue;
    border-radius: 30px;
    width: 50vw;
    height: 60px;
    padding-left: 20px;
    padding-right: 60px;
    box-sizing: border-box;
    background: url(../../../assets/images/search.svg) no-repeat right;
    background-size: 25px;
    background-position-x: calc(100% - 30px);

}

.search-box:focus {
    outline: none;
    border: 2px solid $salBlue;
    color: $salBlue;
}

input[type="text"]::placeholder {
    color: #7387B2;
    line-height: 24px;
    padding-right: 20px;
}


.no-results {
    margin-left: 70px;
    margin-top: 10px;
    margin-bottom: 500px;
    h1 {
        color: $navBlue;
        font-family: "Isidora-Bold";
        font-size: 32px;
        text-align: left;
        cursor: pointer;
    }
}

.results {
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 0px;
    margin-top: 0px;
    cursor: pointer;

    h2 {
        color: $salBlue;
        font-family: "Isidora-Bold";
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 15px;
        text-align: left;
    }
    h3 {
        color: $navBlue;
        font-family: "Isidora Sans";
        font-weight: 800;
        font-size: 20px;
        margin-top: 10px;
        text-align: left;
    }
}

.breadcrumbs {
    text-align: left;
    padding-left: 70px;
    padding-top: 0px;
    padding-bottom: 47px;
    h1 {
        display: inline-block;
        color: $navBlue;
        font-family: "Isidora-Bold";
        font-size: 24px;
        cursor: pointer;
        padding-right: 5px;
    }
    h2 {
        display: inline-block;
        color: $salLightGrey;
        font-family: "Isidora-Bold";
        font-size: 24px;
        padding-left: 5px;
    }
}

.results-found {
    margin-left: 70px;
    margin-right: 70px;
    text-align: left;


    h1 {
        color: $salRed;
        font-family: "Isidora-Bold";
        font-size: 32px;
        line-height: 38px;
        margin-top: 0px;
        text-align: left;
        display: inline-block;
    }
}

hr {
    color: $salBlue;
    background-color: $salBlue;
    border: none;
    height: 1px;
    opacity: 1;
}

</style>