<template>
  <div clas="Main-Container">
    <div :class="grayedOut ? 'greyout' : ''"></div>
    <div class="rpc">
      <div :class="isMobile ? 'm-sal-head' : 'sal-head'">
        <Header
          @commonlinkspresent="getCommonLinksShowStatus"
          :currentCompnt="componentName"
          v-on:reloadPage="reload"
          :pageDetails="pageDetails"
          v-if="
            !isMobile() ||
            (isMobile() && componentName === 'Dashboard') ||
            isiPad()
          "
        />
        <SalNavBar
          v-responsive="['hidden-all', 'lg', 'xl']"
          :class="visible ? 'common-links-collapsed' : null"
          v-on:getMenuTitle="extractMenuTitle"
          @getComponent="getAdditionalComponent"
          ref="salNavBar"
          :pageDetails="pageDetails"
        />
      </div>
      <div
        :class="
          !showCommonLinks ? 'sal-content' : 'sal-content common-links-show'
        "
      >
        <div class="content">
          <ContentWrapper
            :pageTitle="pageName"
            :componentName="compName"
            :customUrl="customUrl"
            @getCurrentComponent="updatedComponent"
            :pageDetails="pageDetails"
            v-on:reloadPage="reload"
          />
        </div>
        <Footer
          v-if="
            !isMobile() ||
            (isMobile() && componentName === 'Dashboard') ||
            isiPad()
          "
          :pageDetails="pageDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SalNavBar from "../components/SalNavBar.vue";
import MnavBar from "../components/mobile/Navbar.vue";
import ContentWrapper from "../components/ContentWrapper.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import {
  COMMON_LINKS_QUERY,
  HOME_PAGE_DETAILS,
} from "../queries/gqlQueries.js";
import { COUNT_QUERY } from "../queries/countQueries.js";
import { gQLCaller } from "../queries/gqlCaller.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    SalNavBar,
    ContentWrapper,
    MnavBar,
  },
  props: {
    pageTitle: {
      type: String,
      default: "Dashboard",
    },
    componentName: {
      type: String,
      default: "Dashboard",
    },
    customUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contentUrl: "https://salextranet.wpengine.com/",
      menuLabel: "",
      visible: false,
      commonLinks: "",
      showCommonLinks: false,
      counts: [],
      compName: this.componentName,
      pageName: this.pageTitle,
      grayedOut: false,
      pageDetails: {},
    };
  },
  created() {
    this.getCommonLinks();
    this.getAllPostCounts();
    this.getPagedetails();
  },
  mounted() {
    if(this.getSaveProfile === undefined || this.getSaveProfile.length === 0) {
        this.postProfile();
    }
    if(this.$route.path == "/search-results") {
        this.compName = "AllResults";
        this.pageName = "Search Results";
    }
    this.$root.$on("GrayedOut", (flag) => {
      this.grayedOut = flag;
    });
    this.fetchLearningCart();
    console.log("getHomePageDetails----", this.getHomePageDetails);
  },
  computed: {
    ...mapGetters([
      "getProfileInfo",
      "getSaveProfile",
      "getSavedGetter",
      "getHomePageDetails",
    ]),
  },
  watch: {
    pageName(newVal) {
      console.log("-----pageName watching---", newVal);
      console.log("componentName watching---", newVal);
    },
    compName(newVal) {
      console.log("-----compName watching---", newVal);
      console.log("componentName watching---", this.componentName);
    },
  },
  methods: {
    ...mapActions([
      "getProfile",
      "createSaveProfile",
      "getSaved",
      "viewSaveProfile",
      'fetchLearningCart',
      "fetchHomePageDetails",
    ]),
    isPortrait() {
      return window.innerHeight > window.innerWidth;
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );

      return isTablet || this.isIpadPro();
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 1536 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    scrollTop() {
      document.querySelector(".sal-content").scrollTo(0, 0);
    },
    async getPagedetails() {
      this.fetchHomePageDetails(HOME_PAGE_DETAILS).then((result) => {
        this.pageDetails = {
          appTitle: this.getHomePageDetails.applicationTitle,
          salLogo: this.getHomePageDetails.sallogo.mediaItemUrl,
          commonLinksTitle: this.getHomePageDetails.commonlinksTitle,
          commonLnksIcon: this.getHomePageDetails.commonlinksicon.mediaItemUrl,
          additionalLinksIcon: this.getHomePageDetails.additionallinksicon.mediaItemUrl,
          carouselHeader: this.getHomePageDetails.carouselheader,
          poerBItitle: this.getHomePageDetails.powerBITitle,
          profileIcon: this.getHomePageDetails.profileiconplaceholder.mediaItemUrl,
          facebookIcon: this.getHomePageDetails.facebookicon.mediaItemUrl,
          facebookUrl: this.getHomePageDetails.facebookurl,
          twitterIcon: this.getHomePageDetails.twittericon.mediaItemUrl,
          twitterUrl: this.getHomePageDetails.twitterurl,
          linkedinIcon: this.getHomePageDetails.linkedinicon.mediaItemUrl,
          linkedinUrl: this.getHomePageDetails.linkedinurl,
          tiktokIcon: this.getHomePageDetails.tiktokicon.mediaItemUrl,
          tiktokUrl: this.getHomePageDetails.tiktokurl,
          instagramIcon: this.getHomePageDetails.instagramicon.mediaItemUrl,
          instagramUrl: this.getHomePageDetails.instagramurl,
          yotubeIcon: this.getHomePageDetails.youtubeicon.mediaItemUrl,
          youtubeuUrl: this.getHomePageDetails.youtubeurl,
          dropdownIcon: this.getHomePageDetails.dropdownicon.mediaItemUrl,
          gethelpIcon: this.getHomePageDetails.gethelpicon.mediaItemUrl,
          gethelpUrl: this.getHomePageDetails.gethelpurl,
          hamburgermenuIcon: this.getHomePageDetails.hamburgmenuicon.mediaItemUrl,
          privacyPolicy: this.getHomePageDetails.privacyPolicy,
          termsOfUse: this.getHomePageDetails.termsOfUse,
          coronaResources: this.getHomePageDetails.coronaResources,
          contactUs: this.getHomePageDetails.contactUs,
          ownaSal: this.getHomePageDetails.ownaSal,
        };
      });
    },

    postProfile() {
        this.getProfile().then(() => {
            this.viewSaveProfile(this.getProfileInfo.profile.id)
        });
    },
    getAllPostCounts() {
      gQLCaller(COUNT_QUERY, "").then((result) => {
        for (let i = 0; i < Object.keys(result).length; i++) {
          this.counts.push({
            name: Object.keys(result)[i],
            count: Object.values(result)[i].nodes[0].count,
          });
        }
        this.$store.commit("ADD_COUNT_DATA", this.counts);
      });
    },
    getCommonLinksShowStatus(value) {
      this.showCommonLinks = value;
    },
    getAdditionalComponent(value) {
      this.compName = value;
    },
    updatedComponent(value) {},
    extractUrl(url) {
      this.contentUrl = url;
    },
    extractMenuTitle(menu) {
      this.compName = menu;
    },
    reload() {
      this.$router.push("/");
      this.compName = "Dashboard";
      this.pageName = "Dashboard";
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    async getCommonLinks() {
      gQLCaller(COMMON_LINKS_QUERY).then((result) => {
        this.commonLinks = result.allCommonLinks.nodes;
      });
    },
  },
  watch: {
    componentName: function (newVal, oldVal) {
      this.compName = newVal;
    },
    pageTitle: function (newVal, oldVal) {
      this.pageName = newVal;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/_shared.scss";

.Main-Container {
  overflow-y: auto;
  .rpc {
    .sal-head {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1000;
    }

    .m-sal-head {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: row !important;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1000;
    }
    .sal-content {
      position: absolute;
      top: 300px;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 900;
    }
    .common-links-show {
      top: 540px;
    }
    .row {
      --bs-gutter-x: 0 !important;
    }
    .content {
      position: relative;
      display: flex;
      height: auto;
      width: 100%;
    }
    .content-sidebar {
      position: relative;
      display: flex;
      width: 20%;
      flex-direction: column;
      height: 78vh;
    }
    .content-dashboard {
      position: relative;
      display: flex;
      width: 80%;
      background-color: $salYellow;
      flex-direction: column;
    }
  }
}
</style>
