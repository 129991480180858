import * as wpUserActions from "./actions";
import profileMutations from "./mutations";
import profileGetters from "./getters";

export const saveProfileStore = {
    state: {
        wpProfile: [],
        userName: "",
        profileData: [],
    },
    actions: {
        ...wpUserActions,
    },
    mutations: {
        ...profileMutations,
    },
    getters: {
        ...profileGetters,
    }
}