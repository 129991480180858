<template>
  <div class="rpc-m-navbar">
    <div class="profile">
      <img class="user-icon" :src="getProfileIcon()" alt="Sal" />
      <div class="info">
        <p>{{ getUserName }}</p>
        <p @click="viewProfileClicked()">View Profile</p>
      </div>
    </div>
    <div class="dark-strip"></div>
    <div class="nav-accordion" role="tablist">
      <b-card
        no-body
        class="menu-card"
        v-for="(menu, key, index) in getNavMenu"
        :key="index"
      >
        <b-row header-tag="header" class="menu-header" role="tab">
          <div
            v-b-toggle="'accordion' + index"
            class="menu-btn"
          >
            <p>{{ key }}</p>
            <img
              class="arrow"
              v-if="!menu.selected"
              :src="getIconForDropdown()"
              alt="Sal"
            />
            <img
              class="arrow"
              v-if="menu.selected"
              :src="getIconForDropdownNew()"
              alt="Sal"
            />
          </div>
        </b-row>
        <b-collapse
          :id="'accordion' + index"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-list-group-item
              class="link-item"
              v-for="(mItem, index) in menu"
              :key="index"
              @click="onSelectedSubMenu(mItem)"
            >
              {{ mItem.menuItem }}
            </b-list-group-item>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div class="common-links" @click="commonlinksClicked()">Common Links</div>
    <div class="gethelp-nav">
      <img
        class="link-icon"
        :src="getAdditonalLinkIcon()"
        alt="chevron"
        @click="gotoAdditionalLinks"
      />
      <img
        class="link-icon"
        :src="getHelpIcon()"
        alt="chevron"
        @click="gotoGetHelp"
      />
    </div>
  </div>
</template>
<script>
import Utils from "../../utils/Utils";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      commonLinks: [],
    };
  },
  computed: {
    ...mapGetters(["getNavMenu", "getProfileInfo"]),
    getUserName() {
      return this.getProfileInfo === undefined
        ? "Sal User"
        : this.getProfileInfo.profile.displayName;
    },
  },
  methods: {
    ...mapActions(["fetchCustomPost"]),
    getProfileIcon() {
      if (this.getProfileInfo.profileImg !== null) {
        return this.getProfileInfo.profileImg;
      }
      return Utils.Images.profile_placeholder;
    },
    getIconForDropdown() {
      return Utils.Images.polygon_down;
    },
    getIconForDropdownNew() {
      return Utils.Images.polygon_up;
    },
    getHelpIcon() {
      return Utils.Images.m_gethelp;
    },
    getAdditonalLinkIcon() {
      return Utils.Images.m_add_link;
    },
    viewProfileClicked() {
      this.$router.push("/profile");
    },
    gotoAdditionalLinks() {
      this.$emit("getComponent", "AdditionalLinks");
      this.$router.push("/links");
    },
    gotoGetHelp() {
      this.$emit("getComponent", "GetHelp");
      this.$router.push("help");
    },
    onSelectedSubMenu(selected) {
          if("/" + selected.menuItem.replace(/\s/g, "-").toLowerCase() === this.$route.path) {
              return;
          }
          if(selected.postLink) {
              if(selected.menuItem.toLowerCase().includes('calendar')) {
                  this.$router.push({
                      name: "Mobile Operational Calendar",
                      params: {
                          componentName: 'mOperationalCalendar',
                          pageTitle: selected.menuItem,
                          url: selected.postLink
                      }
                  });
                  return;
              }
              if(this.currentRouteName === 'CustomPost') {
                  this.$router.replace({
                      name: "CustomPost",
                      params: {
                          path: selected.menuItem.replace(/\s/g, "-").toLowerCase(),
                          componentName: "mCustomPost",
                          pageTitle: selected.menuItem,
                          customUrl: selected.postLink
                      },
                  });
                  return;
              } else {
                  this.fetchCustomPost(selected.postLink).then(() => {
                      this.$router.push({
                          name: "CustomPost",
                          params: {
                              path: selected.menuItem.replace(/\s/g, "-").toLowerCase(),
                              componentName: "mCustomPost",
                              pageTitle: selected.menuItem,
                              customUrl: selected.postLink
                          },
                      });
                  });
              }
          } else {
              this.$router.push(selected.menuItem.replace(/\s/g, "-").toLowerCase());
          }
    },
    commonlinksClicked() {
      this.$router.push("/common-links");
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/_shared.scss";

.rpc-m-navbar {
  display: flex;
  flex-direction: column;
  position: relative;

  .profile {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 13px;
    .user-icon {
      height: 30px;
      widows: 30px;
      border-radius: 50%;
    }

    .info {
      width: fit-content;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 9px;

      p:nth-child(1) {
        font-family: "Isidora-Bold";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: $salBlue;
        height: 50%;
        margin-bottom: 0rem;
        text-align: left;
        margin-top: 5px;
      }
      p:nth-child(2):hover {
        cursor: pointer;
      }
      p:nth-child(2) {
        font-family: "Isidora Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: $salBlue;
        height: 50%;
        margin-bottom: 5px;
        text-decoration: underline;
        text-align: left;
      }
    }
  }
  .dark-strip {
    height: 8px;
    background-color: $salBlue;
  }
  .nav-accordion {
    display: flex;
    flex-direction: column !important;
    max-height: fit-content;
    border-radius: 20px;
    background-color: white;

    .collapsed > .when-open,
    .not-collapsed > .when-closed {
      display: none;
    }

    .menu-card {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid lightgray;

      .menu-header {
        border-radius: 0;

        .menu-btn {
          border: none;
          background-color: transparent;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 95%;
          margin-left: 13px;
          height: 38px;

          p {
            color: $navBlue;
            font-family: "Isidora-Bold";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 0px;
          }

          .arrow {
            width: 19px;
            height: 19px;
            margin-right: 12px;
          }
        }
      }

      .card-body {
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        margin: 0px auto;
        position: relative;
        display: flex;
        flex-direction: column !important;
        columns: 2;
        flex-wrap: wrap;
        padding: 0 14px;
      }

      .list-group-item {
        padding: 5px 5px 0px 0px;
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
        font-family: "Isidora-SemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        color: #14377d;
      }
      .list-group-item:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .list-group-item.active {
        cursor: pointer;
        color: red;
        text-decoration: underline;
      }
    }
  }
  .common-links:hover {
    cursor: pointer;
  }
  .common-links {
    display: flex;
    align-items: center;
    font-family: "Isidora-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $salBlue;
    text-decoration: underline;
    padding-left: 13px;
    height: 36px;
    border-bottom: 1px solid lightgray;
  }
  .gethelp-nav {
    padding-right: 50px;
    padding-left: 20px;
    margin-right: 10px;
    position: fixed;
    bottom: 42px;
    width: 100%;
    height: 72px;
    border-top: 1px solid lightgray;
    display: flex;
    align-items: center;

    .link-icon:hover {
      cursor: pointer;
    }
    .link-icon {
      margin-right: 20px;
    }

    .gethelp {
      :hover {
        cursor: pointer;
      }
      width: auto !important;
      height: 40px !important;
      text-align: center !important;
      border-radius: 20px;
      color: $salBlue !important;
      font-weight: bold;
      padding: 0 5px;
      padding-bottom: 3px;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
</style>
