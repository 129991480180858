<template>
  <div class="outerDiv">
    <div class="readMoreWrapper">
      <read-more
        class="ReadContent"
        @close="closeReadMore()"
        v-if="showRead"
        @readMore="readMore"
        :readMoreObj="readMoreObj"
        :breadcrumb="`News and Updates`"
      />
    </div>
    <div class="pageWrap" v-if="!showRead">
      <div
        :class="isiPad() ? 'iheading' : 'heading'"
        v-responsive="['hidden-all', 'md', 'lg', 'xl']"
      >
        <h1>Important News and Updates</h1>
        <h2>.</h2>
        <a @click="navToSaved()" :class="isiPad() ? 'isaved' : 'saved'">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
          class="back-btn"
          :src="getBackBtnImage()"
          alt="Back"
          @click="backBtnClicked()"
        />
        <span class="main_title">Important News and Updates</span>
        <span class="main_fullstop"></span>
        <a @click="navToSaved()" class="msaved">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="msearch" v-responsive="['hidden-all', 'xs', 'sm']">
        <card-search :key="reloadSearch" v-on:emitSearchInput="searchCards" />
      </div>
      <card-search
        :key="reloadSearch"
        v-on:emitSearchInput="searchCards"
        v-responsive="['hidden-all', 'md', 'xl', 'lg']"
      />
      <div
        class="leftDiv"
        v-responsive="['hidden-all', 'xl', 'lg']"
        v-if="!isIpadPro()"
      >
        <sort-filter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        />
        <div
          v-responsive="['hidden-all', 'lg', 'xl']"
          class="clearFilters"
          @click="clearAllSelection()"
        >
          <p>Clear All Selection</p>
        </div>
      </div>
      <div class="m_sortfilter" v-responsive="['hidden-all', 'md', 'sm', 'xs']">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>
      <div class="m_sortfilter" v-if="isIpadPro()">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>
      <div :class="isMobile() || isiPad() ? 'rightDiv mobile' : 'rightDiv'">
        <b-row>
          <search-results
            v-if="searchString.length > 0"
            :result="cardItemsFiltered.length"
          />
        </b-row>
        <b-row
          v-if="currentdisplayisready"
          cols="4"
          :class="isMobile() || isiPad() ? 'mcard' : 'cards-row'"
        >
          <b-col
            v-for="item in currentdisplay"
            :key="item.name"
            :class="
              isMobile() ? 'mcards-col' : isiPad() ? 'icards-col' : 'cards-col'
            "
          >
            <div class="card-comp">
              <card-news-updates
                class="leftPad"
                @readMore="readMore"
                v-if="currentdisplayisready"
                :postedDate="item.postedDate"
                :headline="item.headline"
                :cardSummary="item.cardSummary"
                :attachments="getAttachments(item)"
                :story="item.story"
                :isUrgent="item.urgentPost"
                :id="item.databaseId"
              />
            </div>
          </b-col>
        </b-row>
        <div id="pagination" v-if="currentdisplayisready">
          <pager
            ref="pager"
            :key="reloadPager"
            v-on:emitCurrentDisplayIdx="updateCurrentDisplay"
            :buttoncount="buttoncount"
            :cardsperpagecount="cardsperpagecount"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReadMore from "../cards/ReadMore.vue";
import SortFilter from "../../SortFilter.vue";
import MSortFilter from "../../mobile/MSortFilter.vue";
import CardSearch from "../search/CardSearch.vue";
import SearchResults from "../search/SearchResults.vue";
import Pager from "../../pagination/Pager.vue";
import CardNewsUpdates from "../cards/NewsUpdates.vue";
import { NEWS_QUERY } from "../../../queries/gqlQueries.js";
import { gQLCaller } from "../../../queries/gqlCaller";
import SortFilterCommon from "../../../utils/SortFilterCommon.js";
import Search from "../../../utils/Search.js";
import { mapGetters, mapMutations } from "vuex";
import Utils from "../../../utils/Utils";

export default {
  components: {
    SortFilter,
    MSortFilter,
    Pager,
    CardNewsUpdates,
    ReadMore,
    CardSearch,
    SearchResults,
  },
  props: {
    pageTitle: String,
    compProps: String,
  },
  data() {
    return {
      showRead: false,
      reloadPager: 0,
      reloadFilters: 0,
      reloadSearch: 0,
      cardsperpagecount: this.getPageCount(),
      currentdisplayidx: 0,
      currentdisplay: [],
      currentdisplayisready: false,
      buttoncount: 0,
      currentPage: 1,
      filters: [],
      sorts: [],
      sortDefault: "",
      userSelections: Object,
      cardItemsFiltered: [],
      cardItems: [],
      readMoreObj: Object,
      searchString: "",
      previousFilters: [],
      page: "NewsUpdates",
      windowHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    setTimeout(() => {
        if (this.$route.params.headline) {
            this.scrollToSearch(this.$route.params.headline, this.$route.params.story);
        }
    }, 1500);
  },
  methods: {
      ...mapMutations(['mutateSortNewsUpdates','mutateFilterNewsUpdates']),
    scrollToSearch(headline, story) {
        let card = this.cardItems.find((card) => card.headline == headline && card.story == story);
        if (card) {
            let cardIndex = this.cardItems.indexOf(card);
            if(cardIndex <= this.cardsperpagecount - 1) {
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIndex].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".card-comp")[cardIndex].offsetTop, behavior: 'smooth' });
                    return;
                });
            } else {
                let page = 0;
                if(cardIndex == this.cardsperpagecount) {
                    page = Math.ceil(cardIndex / this.cardsperpagecount) + 1;
                } else {
                    page = Math.ceil(cardIndex / this.cardsperpagecount)
                }
                this.$refs.pager.findSearch(page - 1);
                let cardIdx = cardIndex % this.cardsperpagecount;
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIdx].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".card-comp")[cardIdx].offsetTop, behavior: 'smooth' });
                });
            }
        }
    },
    getPageCount() {
      return this.isMobile() || this.isiPad() ? 6 : 9;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet || this.isIpadPro();
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 1536 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    setState(clearAll) {
      const doResetState = clearAll || false;
      if (doResetState || this.getSortNewsUpdates == "") {
        this.$store.commit("mutateSortNewsUpdates", this.sortDefault);
        this.$store.commit("mutateFilterNewsUpdates");
      }
    },
    getBackBtnImage() {
      return Utils.Images.back;
    },
    backBtnClicked() {
      this.$router.back();
    },
    closeReadMore() {
      this.showRead = false;
      setTimeout(() => {
        this.$refs.sortFilter.refresh(this.previousFilters);
      }, 0);
      this.reloadFilters++;
    },

    navToSaved() {
      this.$router.push({ name: "Saved" });
    },

    readMore(readMore) {
      this.readMoreObj = readMore;
      this.showRead = true;
    },

    getAttachments(item) {
      if (item.postAttachments === null) return "null";

      return item.postAttachments;
    },

    reloadPagination() {
      this.reloadPager++;
    },

    clearAllSelection() {
      this.searchString = "";
      this.setState(true);
      this.reloadFilters++;
      this.reloadSearch++;
      this.updateSettings();
    },

    updateCurrentDisplay(newidx) {
      this.currentdisplayidx = newidx;
      if (this.currentdisplaycontentFiltered.length > 0) {
        this.currentdisplay = this.currentdisplaycontentFiltered;
      } else {
        this.currentdisplay = this.currentdisplaycontent;
      }
    },

    searchCards(value) {
      if (value.length == 0) {
        this.clearAllSelection();
      } else {
        this.searchString = value;
        this.updateSettings();
      }
    },

    updateSettings() {
      this.currentdisplayisready = false;
      let SortedFilteredCards = [];
      SortedFilteredCards = SortFilterCommon.sortCards(
        this.cardItems,
        this.getSortNewsUpdates
      );

      if (this.getFilterNewsUpdates.length > 0) {
        SortedFilteredCards = SortFilterCommon.filterCards(
          SortedFilteredCards,
          this.getSortNewsUpdates,
          this.getFilterNewsUpdates
        );
      }
      if (this.searchString.length > 0) {
        this.cardItemsFiltered = Search.posts(
          this.searchString,
          SortedFilteredCards
        );
      } else {
        this.cardItemsFiltered = SortedFilteredCards;
      }
      this.currentdisplayidx = 0;
      this.buttoncount = Math.ceil(
        this.cardItemsFiltered.length / this.cardsperpagecount
      );
      this.currentdisplay = this.currentdisplaycontentFiltered;
      this.currentdisplayisready = true;
      this.reloadPagination();
    },

    init() {
      this.setFilters();
      this.setSorts();
      this.setState();
      this.buttoncount = Math.ceil(
        this.cardItems.length / this.cardsperpagecount
      );
      this.currentdisplay = this.currentdisplaycontent;
      this.currentdisplayisready = true;
      this.reloadPagination();
      this.reloadFilters++;
    },

    async getData() {
      gQLCaller(NEWS_QUERY).then((result) => {
        this.cardItems =
          result.newsFeedTypes.nodes[0].children.nodes[0].importantNewsAndUpdates.nodes.sort(
            function custom_sort(a, b) {
              return (
                new Date(b.postedDate).getTime() -
                new Date(a.postedDate).getTime()
              );
            }
          );
        this.cardItems.forEach((item) => {
          if (item.urgentUntil) {
            if (new Date(item.urgentUntil) < new Date()) {
              item.urgentPost = false;
            }
          }
        });
        this.cardItems.sort(function (a, b) {
          return b.urgentPost - a.urgentPost;
        });
        this.init();
      });
    },

    setFilters() {
      this.filters = [];
      let filterdata = [];

      this.cardItems.filter((item) => {
        for (let i = 0; i < item.Filter.nodes.length; i++) {
          let f = item.Filter.nodes[i].name;

          if (!filterdata.includes(f)) {
            filterdata.push(f);
          }
        }
      });

      this.filters = filterdata;
    },

    setSorts() {
      this.sorts = [];
      this.sorts.push("Latest Posts"); //,'Most Shared','Most Saved')
      this.sortDefault = this.sorts[0];
    },
  },

  computed: {
    ...mapGetters(["getSortNewsUpdates", "getFilterNewsUpdates"]),
    currentdisplayrange() {
      return (
        parseInt(this.currentdisplayidx) + parseInt(this.cardsperpagecount)
      );
    },
    currentdisplaycontent() {
      return this.cardItems.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
    currentdisplaycontentFiltered() {
      return this.cardItemsFiltered.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
  },

  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.mobile_heading{
    display:flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;
    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 5px;
        margin-top: 8px;
        border-radius: 2px;
    }
}
.msearch {
  margin-top: 40px;
}
.ReadContent {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
}
.card-comp {
  width: 100%;
}
.readMoreWrapper {
  top: 0px;
  left: 0;
}
.m_sortfilter {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  z-index: 100;
  margin-left: -7px;
}
.heading {
  text-align: left;
  padding-left: 70px;
  padding-top: 50px;
}
.iheading {
  text-align: left;
  padding-left: 20px;
}
.heading h1 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #14377c;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.iheading h1 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 43px;
  color: #14377c;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.iheading h2 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.heading h2 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.clearFilters {
    padding-left: 70px;
    width: 300px;
    font-family: "Isidora Sans";
    text-align: left;
    color: deepskyblue;
    font-size: 18px;
    text-decoration: underline;
}
.clearFilters:hover {
  cursor: pointer;
}
p:hover {
  color: rgb(23, 23, 179);
  font-weight: 500;
}
.pageSelected {
  background-color: rgb(21, 11, 167);
}
.mobile {
  float: none !important;
  width: 100% !important;
  margin-top: 15px;
}
.rightDiv {
  color: #000;
  height: 100%;
  min-height: 500px;
  width: 75%;
  float: right;
}
.leftDiv {
  color: #000;
  height: 1000px;
  width: 25%;
  float: left;
}
.pageWrap {
  margin-left: 2px;
}
h1 {
  font-size: 2.5em;
  text-align: left;
  color: darkblue;
}
.mcards-col {
  width: 372px !important;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.icards-col {
  width: 50%;
  padding-bottom: 15px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 51%;
  }
}
.cards-col {
  padding-top: 20px;
  width: 375px;
  margin-right: 20px;
}
.mcard {
  width: 100%;
  margin: 0 auto;
}
.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 55px;
  width: 100%;
}
.saved {
  float: right;
  margin-right: 75px;
  margin-top: 12px;
  cursor: pointer;
}
.isaved {
  float: right;
  margin-right: 86px;
  margin-top: 15px;
  cursor: pointer;
  margin-left: auto;
}
.msaved {
  margin-top: 6px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}
.sharing-mobile-red-news {
  height: 291px !important;
}
.sharing-mobile-news {
  height: 291px !important;
}
.highlight {
    border: 2px solid orange;
    box-shadow: 0 0 10px orange;
    animation: boxanimate 1s 3;
}

@keyframes boxanimate {
  50% {box-shadow: 10px 10px 27px -12px rgba(0,0,0,0.75);}
}

</style>