<template>
  <div class="outerDiv">
    <div class="pageWrap">
      <div
        :class="isiPad() ? 'iheading' : 'heading'"
        v-responsive="['hidden-all', 'md', 'lg', 'xl']"
      >
        <h1>Webinars</h1>
        <h2>.</h2>

        <a @click="navToSaved()" :class="isiPad() ? 'isaved' : 'saved'">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
          class="back-btn"
          :src="getBackBtnImage()"
          alt="Back"
          @click="backBtnClicked()"
        />
        <span class="main_title">Webinars</span>
        <span class="main_fullstop"></span>
        <a @click="navToSaved()" class="msaved">
          <img
            src="../../../assets/SaveFilled.svg"
            style="width: 14px; height: 20px"
          />
        </a>
      </div>
      <div class="msearch" v-responsive="['hidden-all', 'xs', 'sm']">
        <card-search
          :key="reloadSearch"
          v-on:emitSearchInput="searchCards"
        />
      </div>
      <card-search
        :key="reloadSearch"
        v-on:emitSearchInput="searchCards"
        v-responsive="['hidden-all', 'md', 'xl', 'lg']"
      />
      <div class="leftDiv" v-responsive="['hidden-all', 'xl', 'lg']" v-if="!isIpadPro()">
        <div class="sort">
          <sort-filter
            :key="reloadFilters"
            v-on:emitUserSettings="updateSettings"
            :sortOptions="sorts"
            :filterOptions="filters"
            :sortDefault="sortDefault"
            :page="page"
          />
          <div
            v-responsive="['hidden-all', 'lg', 'xl']"
            class="clearFilters clearFilterButtonStyle"
            @click="clearAllSelection()"
          >
            <p>Clear All Selection</p>
          </div>
        </div>
      </div>
      <div class="m_sortfilter" v-responsive="['hidden-all', 'md', 'sm', 'xs']">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>
      <div class="m_sortfilter" v-if="isIpadPro()">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>
      <div
        :class="isMobile() || isiPad() ? 'rightDiv mobile' : 'rightDiv'"
        v-if="currentdisplayisready"
      >
        <b-row>
          <search-results
            v-if="searchString.length > 0"
            :result="cardItemsFiltered.length"
          />
        </b-row>
        <b-row cols="4" :class="isMobile() || isiPad() ? 'mcard' : 'cards-row'">
          <b-col
            v-for="item in currentdisplay"
            :key="item.databaseId"
            :class="
              isMobile() ? 'mcards-col' : isiPad() ? 'icards-col' : 'cards-col'
            "
          >
            <div class="card-comp">
              <card-webinar class="leftPad" :webPosts="item" />
            </div>
          </b-col>
        </b-row>
        <div id="pagination" v-if="currentdisplayisready" class="pagination">
          <pager
            ref="pager"
            :key="reloadPager"
            v-on:emitCurrentDisplayIdx="updateCurrentDisplay"
            :buttoncount="buttoncount"
            :cardsperpagecount="cardsperpagecount"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReadMore from "../cards/ReadMore.vue";
import SortFilter from "../../SortFilter.vue";
import Pager from "../../pagination/Pager.vue";
import CardWebinar from "../cards/CardWebinar.vue";
import { NEWS_WEBINAR_QUERY } from "../../../queries/gqlQueries.js";
import { gQLCaller } from "../../../queries/gqlCaller";
import Search from "../../../utils/Search.js";
import CardSearch from "../search/CardSearch.vue";
import SearchResults from "../search/SearchResults.vue";
import SortFilterCommon from "../../../utils/SortFilterCommon.js";
import { mapGetters, mapMutations } from "vuex";
import MSortFilter from "../../mobile/MSortFilter.vue";
import Utils from "../../../utils/Utils";
export default {
  components: {
    SortFilter,
    Pager,
    CardWebinar,
    ReadMore,
    CardSearch,
    SearchResults,
    MSortFilter,
  },
  props: {
    pageTitle: String,
    compProps: String,
  },
  data() {
    return {
      showReadMore: false,
      reloadPager: 0,
      reloadFilters: 0,
      reloadSearch: 0,
      cardsperpagecount: 6,
      currentdisplayidx: 0,
      currentdisplay: [],
      currentdisplayisready: false,
      buttoncount: 0,
      currentPage: 1,
      filters: [],
      sorts: [],
      sortDefault: "",
      userSelections: Object,
      cardItemsFiltered: [],
      cardItems: [],
      readMoreObj: Object,
      searchString: "",
      page: "Webinars",
    };
  },
  mounted() {
    setTimeout(() => {
        if (this.$route.params.headline) {
            this.scrollToSearch(this.$route.params.headline, this.$route.params.story);
        }
    }, 1500);
  },
  methods: {
    ...mapMutations(['mutateSortWebinars','mutateFilterWebinars']),
    scrollToSearch(headline, story) {
        let card = this.cardItems.find((card) => card.headline == headline && card.story == story);
        if (card) {
            let cardIndex = this.cardItems.indexOf(card);
            if(cardIndex <= this.cardsperpagecount - 1) {
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIndex].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".card-comp")[cardIndex].offsetTop, behavior: 'smooth' });
                    return;
                });
            } else {
                let page = 0;
                if(cardIndex == this.cardsperpagecount) {
                    page = Math.ceil(cardIndex / this.cardsperpagecount) + 1;
                } else {
                    page = Math.ceil(cardIndex / this.cardsperpagecount)
                }
                this.$refs.pager.findSearch(page - 1);
                let cardIdx = cardIndex % this.cardsperpagecount;
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIdx].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".card-comp")[cardIdx].offsetTop, behavior: 'smooth' });
                });
            }
        }
    },
    setState(clearAll) {
      const doResetState = clearAll || false;
      if (doResetState || this.getSortWebinars == "") {
        this.$store.commit("mutateSortWebinars", this.sortDefault);
        this.$store.commit("mutateFilterWebinars");
      }
    },
    getBackBtnImage() {
      return Utils.Images.back;
    },
    backBtnClicked() {
      this.$router.back();
    },
    navToSaved() {
      this.$router.push({ name: "Saved" });
    },

    reloadPagination() {
      this.reloadPager++;
    },

    clearAllSelection() {
      this.searchString = "";
      this.setState(true);
      this.reloadFilters++;
      this.reloadSearch++;
      this.updateSettings();
    },

    updateCurrentDisplay(newidx) {
      this.currentdisplayidx = newidx;
      if (this.currentdisplaycontentFiltered.length > 0) {
        this.currentdisplay = this.currentdisplaycontentFiltered;
      } else {
        this.currentdisplay = this.currentdisplaycontent;
      }
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet || this.isIpadPro();
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    searchCards(value) {
      if (value.length == 0) {
        this.clearAllSelection();
      } else {
        this.searchString = value;
        this.updateSettings();
      }
    },

    updateSettings() {
      this.currentdisplayisready = false;
      let SortedFilteredCards = [];
      SortedFilteredCards = SortFilterCommon.sortCards(
        this.cardItems,
        this.getSortWebinars
      );

      if (this.getFilterWebinars.length > 0) {
        SortedFilteredCards = SortFilterCommon.filterCards(
          SortedFilteredCards,
          this.getSortWebinars,
          this.getFilterWebinars
        );
      }
      if (this.searchString.length > 0) {
        this.cardItemsFiltered = Search.posts(
          this.searchString,
          SortedFilteredCards
        );
      } else {
        this.cardItemsFiltered = SortedFilteredCards;
      }

      this.currentdisplayidx = 0;
      this.buttoncount = Math.ceil(
        this.cardItemsFiltered.length / this.cardsperpagecount
      );
      this.currentdisplay = this.currentdisplaycontentFiltered;
      this.currentdisplayisready = true;
      this.reloadPagination();
    },

    init() {
      this.setFilters();
      this.setSorts();
      this.setState();
      this.buttoncount = Math.ceil(
        this.cardItems.length / this.cardsperpagecount
      );
      this.currentdisplay = this.currentdisplaycontent;
      this.currentdisplayisready = true;
      this.reloadPagination();
      this.reloadFilters++;
    },

    async getData() {
      gQLCaller(NEWS_WEBINAR_QUERY).then((result) => {
        this.cardItems =
          result.newsFeedTypes.nodes[0].children.nodes[0].webinars.nodes;
        this.init();
      });
    },

    setFilters() {
      let filterdata = [];
      this.cardItems.filter((item) => {
        for (let i = 0; i < item.Filter.nodes.length; i++) {
          let f = item.Filter.nodes[i].name;

          if (!filterdata.includes(f)) {
            filterdata.push(f);
          }
        }
      });

      this.filters = filterdata;
    },
    setSorts() {
      this.sorts = [];
      this.sorts.push("Latest Posts"); //,'second option','third option')
      this.sortDefault = this.sorts[0];
    },
  },
  computed: {
    ...mapGetters(["getSortWebinars", "getFilterWebinars"]),
    currentdisplayrange() {
      return (
        parseInt(this.currentdisplayidx) + parseInt(this.cardsperpagecount)
      );
    },
    currentdisplaycontent() {
      return this.cardItems.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
    currentdisplaycontentFiltered() {
      return this.cardItemsFiltered.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.m_sortfilter {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  z-index: 2000;
  margin-left: -7px;
}
.card-comp {
  width: 100%;
}
.msearch {
  margin-top: 40px;
}
.mobile_heading {
  display: flex;
  flex-direction: row;
  height: 40px;
  left: 0px;
  background: #ffffff;
  border-bottom: 2px solid #e3e3e3;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  .back-btn {
    width: 36px;
    height: 18px;
  }
  .main_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #14377c;
  }
  .main_fullstop {
    width: 4px;
    height: 4px;
    background: #ee3124;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 2px;
  }
}
.mcard {
  width: 100%;
  margin: 0 auto;
}
.outerDiv {
  color: #fff;
  margin: 0px auto;
  padding: 5px;
}
.msaved {
  margin-top: 6px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 16px;
}
.leftDiv {
  color: #000;
  min-height: 500px;
  width: 25%;
  float: left;
}

.rightDiv {
  color: #000;
  height: 100%;
  min-height: 500px;
  width: 75%;
  float: right;
  padding-top: 0px;
}
.mobile {
  float: none !important;
  width: 100% !important;
  margin-top: 12px;
}

.heading {
  padding-left: 60px;
  text-align: left;
  padding-top: 50px;
}
.iheading {
  padding-left: 27px;
  text-align: left;
}

.heading h1 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #14377c;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.iheading h1 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 42px;
  color: #14377c;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.iheading h2 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.heading h2 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.clearFilterButtonStyle:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(24%) sepia(37%) saturate(6249%)
    hue-rotate(351deg) brightness(86%) contrast(97%);
}

.clearFilters {
    width: 300px;
    color:deepskyblue;
    font-size: 18px;
    text-decoration: underline;
    text-align: left;
    padding-left: 70px;
    font-family: 'Isidora Sans';
    font-weight: 500;
}

.clearFilters:hover {
  cursor: pointer;
}

.pageSelected {
  background-color: rgb(21, 11, 167);
}

.cards-col {
  padding-top: 20px;
  width: 375px;
  margin-right: 30px;
}
.mcards-col {
  width: 372px !important;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  margin: 0 auto;
}
.icards-col {
  width: 50%;
  padding-bottom: 15px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 51%;
  }
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 45px;
  width: 100%;
}

.pagination {
  display: flex;
  float: bottom;
  bottom: 0;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pageWrap {
  width: 100%;
  height: 100%;
}
.isaved {
  float: right;
  margin-right: 85px;
  margin-top: 12px;
  cursor: pointer;
}
.saved {
  float: right;
  margin-right: 75px;
  margin-top: 12px;
  cursor: pointer;
}
.highlight {
    border-radius: 12px;
    animation: boxanimate 1s 3;
}
@keyframes boxanimate {
  50% {    box-shadow: 0 0 10px orange;}
}
</style>
