<template>
  <div :class="isUrgent ? 'mcard red' : 'mcard'">
    <div class="mcard-header">
      <h4>{{ formatRawDate }}</h4>
      <div class="mcard-icons">
        <img
          v-show="hasAttachments"
          src="../../assets/Vector.svg"
          alt="fav"
          class="card-icon icon"
        />
        <img
          v-show="isRecent"
          src="../../assets/flag.png"
          alt="fav"
          class="mcard-icon icon"
        />
        <img v-show="isUrgent" src="../../assets/red_star.png" alt="fav" />
      </div>
    </div>
    <div class="mcard-body">
      <h1>{{ headline }}</h1>
      <p>{{ cardSummary | truncate(80) }}</p>
      <div class="docs">
        <div
          :class="attachments.length == 1 ? 'min-item item' : 'item'"
          v-for="(item, index) in attachments.slice(-4)"
          :key="index"
        >
          <img class="pdf-icon" src="../../assets/PDF_button.svg" alt="" />
          <a target="_blank" :href="item.mediaItemUrl">
            {{ item.title }}
            <!--| truncate(25) }} -->
          </a>
        </div>
      </div>
      <div class="attachments-text">{{ attachments.length }} Attachments</div>
    </div>
    <div class="mcard-footer">
      <a target="_blank" @click="doReadMore()">
        <img src="../../assets/ReadMore.svg" alt="fav" class="read-more-btn" />
      </a>

      <a
        target="_blank"
        v-if="this.getSaveProfile"
        @click="saveThisItem(id)"
        class="save-btn"
      >
        <img
          :src="
            isSaved
              ? require('../../assets/SaveFilled.svg')
              : require('../../assets/Save.svg')
          "
        />
      </a>
    </div>
  </div>
</template>
<script>
import { convertDate } from "@/utils/Dates.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dateObj: new Date(),
      output: "",
      currentDate: "",
      // attachmentsObj: Object
    };
  },
  name: "CardDotComm",
  props: {
    postedDate: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    cardSummary: {
      type: String,
      required: true,
    },
    attachments: {
      type: [Array, String],
      required: true,
    },
    story: {
      type: String,
      required: true,
    },
    isUrgent: {
      type: Boolean,
      required: true,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
  },
  computed: {
    ...mapGetters(["getSavedGetter", "getDisplaySaved", "getSaveProfile"]),
    displaySaved: function () {
      return this.getDisplaySaved;
    },
    isRecent: function () {
      return this.determineIfRecent();
    },
    isUrgentPost: function () {
      if (this.isUrgent) return true;
    },
    formatRawDate: function () {
      return convertDate(this.postedDate);
    },
    hasAttachments: function () {
      if (this.attachments == "null") {
        this.attachmentsObj = [];
        return false;
      }
      this.attachmentsObj = this.attachments;
      return true;
    },
    isSaved: function () {
        if (this.getSaveProfile.userPosts.length > 0) {
            for (let i = 0; i < this.getSaveProfile.userPosts.length; i++) {
                if (this.getSaveProfile.userPosts[i].postId == this.id) {
                    return true;
                }
            }
        }
        return false;
    },
  },
  methods: {
  ...mapActions(["updateSaveProfile"]),
    saveThisItem(id) {
      this.updateSaveProfile(id);
    },
    doReadMore() {
      window.scrollTo(0, 350);
      this.$emit("readMore", {
        headline: this.headline,
        cardSummary: this.cardSummary,
        attachments: this.attachments,
        story: this.story,
      });
    },
    determineIfRecent() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      const current = new Date(cYear, cMonth, cDay);

      const posted = new Date(this.postedDate);
      let difference = current.getTime() - posted.getTime();
      let TotalDaysDiff = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDaysDiff < 80;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mcard.scss";
</style>
