<template>
  <div>
    <b-navbar class="rpc-navbar" type="dark">
      <b-navbar-nav>
        <b-nav-item-dropdown
          no-caret
          disabled
          v-for="(navItem, key, index) in getNavMenu"
          :key="index"
          :class="menuItems.includes(key) ? 'activateNewsFeed' : ''"
        >
          <template #button-content>
            {{ key }}
            <img class="nav-dd-icon" :src="getIcons.dropdownIcon" alt="Sal" />
          </template>
          <b-dropdown-item
            class="rpc-dd-item"
            @mouseenter="hover = true"
            @mouseleave="hover = false"
            v-for="(submenuItem, index) in navItem"
            :key="index"
            @click="onSelectedSubMenu(submenuItem, key)"
          >
            {{ submenuItem.menuItem }}
            <b-dropdown-divider />
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <div class="gethelp-nav">
        <img
          class="link-icon"
          :src="getIcons.addLinksIcon"
          alt="chevron"
          @click="gotoAdditionalLinks"
        />
        <img
          class="link-icon"
          src="../assets/images/gethelp.svg"
          alt="chevron"
          @click="gotoGetHelp"
        />
      </div>
    </b-navbar>
  </div>
</template>
<script>
import { NAVIGATION_QUERY } from "../queries/gqlQueries.js";
import CustomPost from "./module/custom/CustomPost.vue";
import Main from "./Main.vue";
import { gQLCaller } from "../queries/gqlCaller.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    pageDetails: Object,
  },
  data() {
    return {
      title: null,
      submenu: "",
      activateNewsFeed: false,
      activateSupportServices: false,
      activateCalendar: false,
      activateDistribution: false,
      activateTrainingHub: false,
      menuItems: [],
      commonLinks: [],
    };
  },
  mounted() {
    this.$root.$on("disabledBackground", () => {
      this.menuItems = [];
      localStorage.setItem("key", "");
    }),
      this.mountNavigation();
    this.setNavMenu();
    if (this.$route.name === "CustomPost") {
      this.$emit("getComponent", "CustomPost");
      if (this.$route.path === "/saved") {
        this.$emit("getComponent", "Saved");
      }
    }
  },
  computed: {
    ...mapGetters(["getNavMenu", "getProfileInfo"]),
    getIcons() {
      return {
        addLinksIcon: this.pageDetails.additionalLinksIcon,
        dropdownIcon: this.pageDetails.dropdownIcon,
        gethelpIcon: this.pageDetails.gethelpIcon,
        gethelpUrl: this.pageDetails.gethelpUrl,
      };
    },
  },
  methods: {
    ...mapActions(["fetchNav", "fetchCustomPost"]),
    mountNavigation() {
      if (this.getNavMenu.length === 0) {
        this.fetchNav(NAVIGATION_QUERY);
      }
    },
    setNavMenu() {
      this.menuItems.push(localStorage.getItem("key"));
    },
    verifySelection(key) {
      this.menuItems = [];
      this.menuItems.push(key);
      localStorage.setItem("key", key);
    },
    onSelectedSubMenu(selected, key) {
      this.verifySelection(key);
      if (
        "/" + selected.menuItem.replace(/\s/g, "-").toLowerCase() ===
        this.$route.path
      ) {
        return;
      }
      if (selected.postLink) {
        if (selected.menuItem.toLowerCase().includes("calendar")) {
          this.$router.push({
            name: "Operational Calendar",
            params: {
              url: selected.postLink,
            },
          });
          return;
        }
        if (this.currentRouteName === "CustomPost") {
          this.$router.replace({
            name: "CustomPost",
            params: {
              path: selected.menuItem.replace(/\s/g, "-").toLowerCase(),
              componentName: "CustomPost",
              pageTitle: selected.menuItem,
              customUrl: selected.postLink,
            },
          });
          return;
        } else {
          this.fetchCustomPost(selected.postLink).then(() => {
            this.$router.push({
              name: "CustomPost",
              params: {
                path: selected.menuItem.replace(/\s/g, "-").toLowerCase(),
                componentName: "CustomPost",
                pageTitle: selected.menuItem,
                customUrl: selected.postLink,
              },
            });
          });
        }
      } else {
        this.$router.push(selected.menuItem.replace(/\s/g, "-").toLowerCase());
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    gotoAdditionalLinks() {
      this.$emit("getComponent", "AdditionalLinks");
      this.$router.push("/links");
    },
    gotoGetHelp() {
      if(this.getIcons.gethelpUrl.startsWith('/')) 
        this.$router.push(this.getIcons.gethelpUrl)


      if(this.getIcons.gethelpUrl.startsWith('http')) {
        window.open(
          this.getIcons.gethelpUrl,
          '_blank' 
        );
      } 
    },
  },
};
</script>
<style lang="scss">
@import "../assets/_shared.scss";

.rpc-navbar {
  background-color: $navBlue;
  height: 56px;
  padding-left: 60px !important;

  .navitemdropdown {
    background-color: $salBlue;
    border-radius: 25px;
    padding-top: 2px;
  }
  .nav-default {
    color: red;
  }

  .navbar-dark.navbar-nav.nav-link.disabled,
  .nav-link.dropdown-toggle.disabled.dropdown-toggle-no-caret {
    color: white !important;
    cursor: pointer !important;
    font-size: 24px;
    font-weight: 600;
    font-family: "Isidora-SemiBold" !important;
    line-height: 23px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    // padding-top: 5px;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0px;
  }
  .dropdown {
    height: 36px;
  }
  .activateNewsFeed {
    background-color: $salBlue;
    border-radius: 18px;
    line-height: 36px;
  }

  .inactivateNewsFeed {
    background-color: red;
    border-radius: 18px;
    line-height: 36px;
  }

  .nav-dd-icon {
    width: 15px;
    height: 15px;
    // padding-left: 5px;
  }
  .nav-link {
    :hover,
    :focus {
      color: white;
    }
    font-family: "Isidora" !important;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: white;
    width: fit-content !important;
    text-align: left !important;
    margin-left: 15px;
    margin-right: 15px;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    background-color: $salBlue !important;
    color: white;
    margin-left: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 0 0 16px 16px;
  }

  .dropdown-item {
    font-family: "Isidora Sans";
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    background-color: $salBlue !important;
    margin-bottom: 2px;
  }

  .dropdown-item:hover {
    background-color: $salBlue !important;
  }

  .dropdown {
    .dropdown-item {
      color: white;
      height: 35px;
    }
  }

  .rpc-dd-item {
    .dropdown-divider {
      border-top: 1px solid white;
      margin: 10px -17px;
    }
  }

  .rpc-dd-item:last-child {
    .dropdown-divider {
      border: none;
    }
  }
  .gethelp-nav {
    padding-right: 50px;
    margin-left: auto;
    margin-right: 10px;

    .link-icon:hover {
      cursor: pointer;
    }
    .link-icon {
      margin-right: 20px;
    }

    .gethelp {
      :hover {
        cursor: pointer;
      }
      width: auto !important;
      height: 40px !important;
      text-align: center !important;
      border-radius: 20px;
      color: $salBlue !important;
      font-weight: bold;
      padding: 0 5px;
      padding-bottom: 3px;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
.active {
  background-color: $salBlue;
}
</style>
