<template>
    <div v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'CoverBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    mounted() {
        this.handleWrap();
    },
    methods: {
        handleWrap() {
            if (this.block.saveContent.includes('img')) {
                setTimeout(() => {
                    const img = this.$el.querySelector('.wp-block-cover__image-background');
                    const span = this.$el.querySelector('.wp-block-cover__background');
                    span.appendChild(img);
                }, 10);
            } else {
                this.$el.querySelector('.wp-block-cover__background').classList.add('solid-block');
            }
        }
    },
    watch: {
        '$route'() {
            this.handleWrap();
        }
    }
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

::v-deep .wp-block-cover {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;

    p {
        font-family: "Isidora-SemiBold";
        color: $salWhiteSmoke;
        text-align: left;
        font-weight: 500;
        font-size:  4vw;
        text-align: center;
    }
    .wp-block-cover__inner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .wp-block-cover__background {
        background-color: rgba(0,0,0,1);
        border-radius: 10px;
        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 50%;
        }
    }
    .solid-block {
        background-color: $salBlue;
        border-radius: 10px;
        width: 100%;
        height: 300px;
    }
}
</style>