<template>
    <div class="outerDiv">
        <div class="heading">
            <h1>{{pageTitle}}</h1>
            <h2>.</h2>
        </div>
        <div class="comps" v-for="block in blocks">
             <Block :block="block"/>
        </div>
    </div>
</template>

<script>
import Block from './Block.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        Block
    },
    data() {
        return {
            pageTitle: "",
            blocks: [],
        }
    },
    methods: {
        getBlocks() {
            this.blocks = this.getCustomPost.blocks;
        }
    },
    computed: {
        ...mapGetters(['getCustomPost'])
    },
    mounted() {
        this.pageTitle = this.getCustomPost.title;
        this.getBlocks();
    },
    watch: {
        '$route.params': {
            handler: function () {
                this.getBlocks();
            },
            deep: true
        },
        getCustomPost: {
            handler: function () {
                this.pageTitle = this.getCustomPost.title;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

outerDiv {
    height: 100%;
    width: 100%;
}

.heading {
    text-align: left;
    padding-left: 70px;
    padding-top: 50px;
    padding-bottom: 20px;
}

.heading h1 {
    display: inline;
    font-family: "Isidora-Bold";
    font-size: 48px;
    color: #14377c;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.heading h2 {
    display: inline;
    font-family: "Isidora-Bold";
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.comps {
    padding-left: 70px;
    padding-right: 70px;
    overflow: hidden;
}
</style>
