import axios from 'axios'
import Utils from '@/utils/Utils'
  
const headers = {
        "Access-Control-Allow-Origin": "http://localhost:8080",
      };
export const AdditionalLinksMenu = (data) =>
    axios.post(Utils.URI.menu, data, headers)


export const AdditionalLinksSubMenu = (data) =>
    axios.post(Utils.URI.submenu, data, headers)