import { getYear, getMonth } from '@/utils/Dates.js'
export default {
    sortCards,
    filterCards
  };

   
function sortCards (items, userSelectedSort){

        if(userSelectedSort=="") {
          return items
        }

        let sortedCards = []
        if(isDotCommPage(userSelectedSort)) {
            // The Dot Comm page uses this sort logic...bc of Year values in the Sort 
            for (let i=0; i<items.length; i++){
                if(getYear(items[i].postedDate) == userSelectedSort ) {  
                    if(!sortedCards.includes(items[i])) {
                        sortedCards.push(items[i])
                    }
                }
            } 
        } else {
            // All other pages use this sort logic (ie News/updates, Dot Comm, Webinars, Sharing Corner. )
            switch(userSelectedSort) {
                case 'Latest Posts':
                    for (let i=0; i<items.length; i++){
                        sortedCards.push(items[i])
                    }
                    break;
                case 'Most Shared':
                    /*******************    not implemented yet     ***************/ 
                    break;
                case 'Most Saved':
                    /*******************    not implemented yet     ***************/ 
                    break; 
                default: 
            }
            
        }
        return sortedCards // these cards match the sort selected 

    }

    function filterCards (sortedCards, userSelectedSort, userSelectedFilters){

        let SortedFilteredCards = []
        if(isDotCommPage(userSelectedSort)) { 
            if(userSelectedFilters.length > 0) {
              for (let i=0; i<sortedCards.length; i++){
                for (let f=0; f<userSelectedFilters.length; f++) {
                  if(getMonth(sortedCards[i].postedDate) == userSelectedFilters[f] ) {      
                    if(!SortedFilteredCards.includes(sortedCards[i])) {
                      SortedFilteredCards.push(sortedCards[i])
                    }
                  }
                }
              }
            }
      } else {
      
        switch(userSelectedSort) {
                      case 'Latest Posts':
                          if(userSelectedFilters.length > 0) {
                            for (let i=0; i<sortedCards.length; i++){
                              for (let f=0; f<userSelectedFilters.length; f++) {
      
                                let filterNodes = sortedCards[i].Filter.nodes
                                 if (filterNodes.length > 0) {
                                    for(let idx = 0; idx < filterNodes.length; idx++) {
                                      if(filterNodes[idx].name == userSelectedFilters[f] ) {      
                                        if(!SortedFilteredCards.includes(sortedCards[i])) {
                                          SortedFilteredCards.push(sortedCards[i])
                                        }
                                      }
                                    }
                                }
                              }
                            }
                          }
                          break;
                      case 'Most Shared':
                          /*******************    not implemented yet     ***************/ 
                          break;
                      case 'Most Saved':
                          /*******************    not implemented yet     ***************/ 
                          break; 
                      default: 
                  }
      
      }
        return SortedFilteredCards // these cards match the sort selected + the filters selected 

    }


const isDotCommPage = function (userSelectedValue) {
    if (parseInt(userSelectedValue)) {
        // is a YYYY date value 
        // is a DotComm page 
        return true; 
    }
    // all other pages. 
    return false;
  }