<template>
  <div class="rpc-m-commonlinks">
    <div class="header">
      <img
        class="back-btn"
        :src="getBackBtnImage()"
        alt="Back"
        @click="backBtnClicked()"
      />
      <p>Common Links <span>.</span></p>
    </div>
    <b-row no-gutters class="cl-headers">
      <div
        :class="
          clSelectedBtn === btnTitles.allapps
            ? 'cl-common-btn-selected'
            : 'cl-common-btn'
        "
        @click="showAllApplications()"
      >
        {{ btnTitles.allapps }}
      </div>
      <div
        :class="
          clSelectedBtn === btnTitles.fav
            ? 'cl-common-btn-selected fav'
            : 'cl-common-btn fav'
        "
        @click="showFavsSection()"
      >
        {{ btnTitles.fav }}
      </div>
      <div
        :class="
          clSelectedBtn === btnTitles.recent
            ? 'cl-common-btn-selected'
            : 'cl-common-btn'
        "
        @click="showRecentlyVisitedSection()"
      >
        {{ btnTitles.recent }}
      </div>
    </b-row>
    <div class="common-links-body">
      <div class="spinner" v-if="isLoading">
        <b-spinner></b-spinner>
        <div>Loading...</div>
      </div>
      <b-row class="list-group" v-show="selected.length > 0">
        <li class="cl-li-item" v-for="(cLink, index) in selected" :key="index">
          <a v-if="showFavIcon">
            <img
              class="favorite"
              :src="getFavImage(cLink.favEnbld)"
              alt="Sal"
              @click="favIconClicked(cLink)"
            />
          </a>
          <a
            class="appname"
            :href="cLink.appLink"
            target="_blank"
            @click="applinkClicked(cLink)"
            >{{ cLink.appName }}
          </a>
        </li>
      </b-row>
      <h5 v-show="selected.length === 0">
        {{ noFavorites }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "../../utils/Utils";
import Header from "../Header.vue";
import store from "../../store/index";
const userInfo = store.getters.profileGetter;

export default {
  name: "CommonLinks",
  components: { Header },
  data() {
    return {
      middleSize: ["hidden-all", "lg", "xl"],
      visible: false,
      showFavoriteBtnClick: false,
      showRecentBtnClick: false,
      clSelectedBtn: Utils.Strings.AllApps,
      btnTitles: {
        fav: Utils.Strings.Favs,
        allapps: Utils.Strings.AllApps,
        recent: Utils.Strings.RecentVstd,
        cmnLinks: Utils.Strings.commonLinks,
      },
      selected: [],
      showFavIcon: true,
      noFavorites: Utils.Strings.noFavorites,
    };
  },
  created() {
    this.showAllApplications();
  },
  computed: {
    ...mapGetters([
      "getCommonLinks",
      "getProfileInfo",
      "getHistory",
      "getFavorites",
      "getFavStatus",
      "getFavErrCode",
    ]),
    ...mapGetters("menuModule", [
      "isLoading",
      "commonLinks",
      "visibleCommonLinks",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchHistoryList",
      "saveHistory",
      "fetchFavoritesList",
      "addFavorites",
      "deleteFavorite",
    ]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getUserName() {
      return this.getProfileInfo === undefined
        ? ""
        : this.getProfileInfo.profile.displayName;
    },
    getBackBtnImage() {
      return Utils.Images.back;
    },
    backBtnClicked() {
      this.$router.back();
    },
    getUserEmail() {
      console.log("getProfileInfo ---", this.getProfileInfo);
      return this.getProfileInfo === undefined
        ? ""
        : this.getProfileInfo.profile.mail;
    },
    showAllApplications() {
      this.showFavoriteBtnClick = false;
      this.showRecentBtnClick = false;
      this.showFavIcon = true;
      this.selected = this.getCommonLinks;
      this.clSelectedBtn = Utils.Strings.AllApps;
    },
    showFavsSection() {
      this.fetchFavoritesList(this.getUserEmail());
      this.showFavIcon = true;
      this.selected = this.getFavorites;
      this.showFavoriteBtnClick = true;
      this.showRecentBtnClick = false;
      this.clSelectedBtn = Utils.Strings.Favs;
    },
    showRecentlyVisitedSection() {
      this.showFavoriteBtnClick = false;
      this.showRecentBtnClick = true;
      this.showFavIcon = false;
      this.clSelectedBtn = Utils.Strings.RecentVstd;
      this.fetchHistoryList(this.getUserEmail()).then(() => {
        this.selected = this.getHistory;
      });
    },
    favIconClicked(cLink) {
      this.$store.commit("menuModule/MENU_LOADING_UPDATE", true, {
        root: true,
      });
      if (cLink.favEnbld) {
        const request = {
          userName: this.getUserName(),
          label: cLink.appName,
          breadcrumb: "",
          url: cLink.appLink,
        };
        this.deleteFavorite(request).finally(() => {
          this.fetchFavoritesList(this.getUserName())
            .then(() => {
              if (this.clSelectedBtn === this.btnTitles.fav) {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.getFavorites,
                  { root: true }
                );
              } else {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.commonLinks,
                  { root: true }
                );
              }
              this.$store.commit("updateFavStatus", {
                appName: cLink.appName,
                appCategory: null,
                favStatus: false,
              });
            })
            .finally(() => {
              this.$store.commit("menuModule/MENU_LOADING_UPDATE", false, {
                root: true,
              });
            });
        });
      } else {
        const request = {
          userName: this.getUserName(),
          environment: Utils.Strings.environment,
          userFavorites: [
            {
              menuLabel: cLink.appName,
              url: cLink.appLink,
              breadcrumb: "",
              addWrapper: "",
            },
          ],
        };
        this.addFavorites(request)
          .then(() => {
            this.fetchFavoritesList(this.getUserName()).then(() => {
              if (this.clSelectedBtn === this.btnTitles.fav) {
                this.$store.commit(
                  "menuModule/FAVORITE_MENU_LINKS_LOADED",
                  this.getFavorites,
                  { root: true }
                );
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.getFavorites,
                  { root: true }
                );
              } else {
                this.$store.commit(
                  "menuModule/VISIBLE_COMMON_LINKS_CHANGED",
                  this.commonLinks,
                  { root: true }
                );
              }
            });
            this.$store.commit("updateFavStatus", {
              appName: cLink.appName,
              appCategory: null,
              favStatus: true,
            });
          })
          .finally(() => {
            this.$store.commit("menuModule/MENU_LOADING_UPDATE", false, {
              root: true,
            });
          });
      }
    },
    getFavImage(status) {
      return status ? Utils.Images.favEnable : Utils.Images.favDisable;
    },
    applinkClicked(appDetails) {
      const params = {
        menuLabel: appDetails.appName,
        url: appDetails.appLink,
        breadcrumb: "",
        addWrapper: false,
      };
      const request = {
        userName: this.getUserName(),
        environment: Utils.Strings.environment,
        userHistory: [params],
      };
      this.saveHistory(request);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/_shared.scss";

.rpc-m-commonlinks {
  width: 100%;
  margin-top: 10px;

  .header {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 15px;

    img:hover {
      cursor: pointer;
    }

    p {
      font-family: "Isidora-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $salBlue;
      margin-left: 10px;
      padding-top: 3px;
      margin-bottom: 0rem;
    }

    span {
      color: red;
      font-size: 20px;
    }
  }

  .cl-headers {
    font-family: "Isidora Sans";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    background-color: $salLightBlue;
    padding: 0px 29px !important;

    .cl-common-btn {
      padding: 5px;
      color: $salBlue;
      background-color: white;
      width: fit-content;
      height: 27px;
      max-width: 170px;
      border: 0.5px solid $salBlue;
      border-radius: 5px;
      cursor: pointer;
      font-family: "Isidora-Bold";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 10px 1px;
      .fav {
        margin-left: 2%;
        margin-right: 2%;
      }
    }

    .cl-common-btn-selected {
      padding: 5px;
      margin: 10px 1px;
      display: flex;
      align-items: center;
      color: white;
      background-color: $salBlue;
      width: fit-content;
      max-width: 170px;
      height: 27px;
      border: 0.5px solid $salBlue;
      border-radius: 5px;
      cursor: pointer;
      font-family: "Isidora-Bold";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
    .fav {
      margin-left: 2%;
      margin-right: 2%;
    }
  }
  .common-links-body {
    width: 100%;
    font-size: 14px !important;
    background-color: white;
    padding-bottom: 5px;

    h5 {
      padding-top: 50px;
    }

    .spinner {
      position: absolute;
      width: 200px;
      height: 80px;
      left: 50%;
      top: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1001;
      color: black;
      border-radius: 10px;
    }

    .list-group {
      background-color: white;
      padding-left: 15px;

      .cl-li-item {
        width: 100%;
        height: 36px;
        color: $salBlueCommonlink !important;
        border: none !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .appname {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: normal;
        }

        a {
          text-decoration: none;
          font-family: "Isidora";
          font-weight: 600;
          font-size: 12px;
          .favorite {
            margin-right: 5px;
          }

          .favorite:hover {
            cursor: pointer;
          }
        }
      }
    }

    .list-group-item {
      background-color: transparent !important;
    }
  }

  .common-links-collapsed {
    margin-top: 1px;
    background-color: red;
  }
}
</style>
