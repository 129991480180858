import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import Utils from "@/utils/Utils";



const state = {
    selectedButton: Utils.Strings.AllApps,
    menuLoading: false,
    visibleCommonLinks: [],
    commonLinks: [],
    favoriteLinks: [],
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
