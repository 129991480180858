<template>
    <div :class="isMobile() ? 'mweb-cards':isIpadPro() ? 'iproweb-cards': isiPad() ? 'iweb-cards' :'web-cards'">
        <div class="web-content">
            <div class="web-header">
                <div class="hover-overlay" @mouseover="show = webPosts.headline" @mouseleave="show = ''">
                    <div class="hover-background" v-if="show == webPosts.headline" @click="watchNow(webPosts.webinarLink)">
                        <img src="../../../assets/images/play_button.svg" v-if="show == webPosts.headline"  class="play-btn" alt="Watch Now"/>
                    </div>
                </div>
                <img class="thumbnail" v-bind:src="webPosts.thumbnailImage.mediaItemUrl"/>
            </div>
                <span class="web-header">
                    <h1> {{ webPosts.headline }} </h1>
                    <h2> {{ formatRawDate(webPosts.postedDate) }} </h2>
                </span>
                <span class="web-body">
                    <p>{{webPosts.cardSummary}}</p>
                </span>
                <div class="web-footer">
                    <a :href="webPosts.webinarLink" class="watch-link" target="_blank" @click="watchNow(webPosts.webinarLink)">
                        <img src="../../../assets/WatchMore.svg" class="watch-btn" alt="Watch Now"/>
                    </a>
                    <img src="../../../assets/PDF.svg" class="pdf-btn" v-if="webPosts.pDFAttachment" @click="pdfRead(webPosts.pDFAttachment.mediaItemUrl)"/>

                    <a target="_blank" v-if="this.getSaveProfile" @click="saveThisItem(webPosts.databaseId)" class="save-btn">
                        <img
                            :src="isSaved ? require('../../../assets/SaveFilled.svg') : require('../../../assets/Save.svg')"
                        />
                    </a>

                </div>
            </div>
        </div>
 
</template>
<script>
import {convertDate} from '../../../utils/Dates.js'
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            dateObj: new Date(),
            output: "", 
            currentDate: "",
            show: '',
        }
    },
    name: 'CardWebinar',
    props: {
        webPosts: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapGetters(['getSaveProfile']),
        isRecent: function () {
            return this.determineIfRecent(); 
        },
        showPDFButton: function () {
          return true; 
        },
        isSaved: function () {
            if (this.getSaveProfile.userPosts.length > 0) {
                for (let i = 0; i < this.getSaveProfile.userPosts.length; i++) {
                    if (this.getSaveProfile.userPosts[i].postId == this.webPosts.databaseId) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    methods: {
        ...mapActions(["updateSaveProfile"]),
        saveThisItem(id) {
            this.updateSaveProfile(id);
        },
        formatRawDate(date) {
            return convertDate(date);
        },
        pdfRead(post) {
            window.open(post, "_blank");
        },
        watchNow(link) {
            window.open(link, "_blank");
        },
        determineIfRecent() {
            let currentDate = new Date();
            let cDay = currentDate.getDate();
            let cMonth = currentDate.getMonth() + 1;
            let cYear = currentDate.getFullYear();
            const current = new Date(cYear, cMonth, cDay);
            const posted = new Date(this.postedDate); 
            let difference = current.getTime() - posted.getTime();
            let TotalDaysDiff = Math.ceil(difference / (1000 * 3600 * 24));
            return TotalDaysDiff < 80
        },
        isMobile() {
            return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
        isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
        isiPad() {
            const userAgent = navigator.userAgent.toLowerCase();
            const isTablet =
                /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
                userAgent
                );
            return isTablet;
        },
        
    }
}

</script>

<style lang="scss" scoped>

@import "@/styles/webinar-cards.scss";
.hover-background {
  display: flex;
  justify-content: center;
  align-items: center;
  .play-btn {
    width: 70px;
    height: 70px;
  }
}

</style> 