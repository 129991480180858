<template>
    <div class="outerDiv">
        <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
            <img
                class="back-btn"
                :src="getBackBtnImage()"
                alt="Back"
                @click="backBtnClicked()"
            />
            <span class="main_title">{{pageTitle}}</span>
            <span class="main_fullstop"></span>
        </div>
        <div class="comps" v-for="block in blocks">
             <Block :block="block"/>
        </div>
    </div>
</template>

<script>
import Block from './Block.vue'
import { mapGetters } from 'vuex';
import Utils from "../../../utils/Utils";

export default {
    components: {
        Block
    },
    data() {
        return {
            pageTitle: "",
            blocks: [],
        }
    },
    methods: {
        getBlocks() {
            this.blocks = this.getCustomPost.blocks;
        },
        getBackBtnImage() {
          return Utils.Images.back;
        },
        backBtnClicked() {
          this.$router.back();
        },
    },
    computed: {
        ...mapGetters(['getCustomPost'])
    },
    mounted() {
        this.pageTitle = this.getCustomPost.title;
        this.getBlocks();
    },
    watch: {
        '$route.params': {
            handler: function () {
                this.getBlocks();
            },
            deep: true
        },
        getCustomPost: {
            handler: function () {
                this.pageTitle = this.getCustomPost.title;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

outerDiv {
    height: 100%;
    width: 100%;
}

.mobile_heading {
    display: flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;

    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 5px;
        margin-top: 8px;
        border-radius: 2px;
    }
}

.comps {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    overflow: hidden;
}

.separator {
    width: 100%;
}
</style>
