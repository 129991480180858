<template>
  <div class="outerDiv">
      <div :class="isMobile() ? 'heading-m' : 'heading'">
      <h1>{{ readMoreObj.headline }}</h1>
      <h2>.</h2>
    </div>
      <ul :class="isMobile() ? 'breadcrumb-m' : 'breadcrumb'">
      <li @click="goHome"><a>Home</a></li>
      <li v-if="!dashboard" @click="close">
        <a>{{ breadcrumb }}</a>
      </li>
      <li class="currentPostCrumb">{{ readMoreObj.headline }}</li>
    </ul>
    <div class="pageContent" :v-if="haveAtts()">
      <div class="pdf-container">
        <div>
          <b-row cols="4">
            <b-col
              class="pdf-cols"
              v-for="(attachment, index) in attachments"
              :key="index"
            >
              <div :class="isMobile() ? 'pdfs-m' : 'pdfs'" @click="openPdf(attachment.mediaItemUrl)">
                <img src="../../../assets/PDF_button.svg" alt="" />
                <p>{{ attachment.title }}</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
      <div :class="isMobile() ? 'post-content-m' : 'post-content'">
      <p v-html="readMoreObj.story"></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      attachments: "",
    };
  },
  props: {
    readMoreObj: {
      type: Object,
      required: true,
    },
    breadcrumb: {
      type: String,
      required: true,
    },
    dashboard: {
        type: Boolean,
        required: false,
    },
  },
  beforeDestroy() {
    this.attachments = null;
  },
    methods: {
        isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
        close() {
            this.$emit('close');
        },
        openPdf(mediaItemUrl) {
            window.open(mediaItemUrl);
        },
        goHome() {
            if(this.breadcrumb == "Dashboard"){
                this.$emit('close');
                document.querySelector('.sal-content').scrollTo(0, 0);
            } else {
                this.$router.push("/");
                document.querySelector('.sal-content').scrollTo(0, 0);
            }
            this.$root.$emit('disabledBackground');
        },
        getAttachments() {
          const { attachments } = this.readMoreObj;
          this.attachments = attachments;
          if (attachments === null) {
            return [];
          }
          return attachments;
        },
        haveAtts() {
          if (
            this.getAttachments() !== "null" &&
            this.getAttachments().length > 0
          ) {
            this.attachments = this.getAttachments();
            return true;
          } else {
            this.attachments = [];
            return false;
          }
        },
    },
  computed: {},
};
</script>

<style lang="scss" scoped>
.heading {
  text-align: left;
  padding-left: 3.5%;
  padding-right: 3%;
}

.heading h1 {
  display: inline;
  font-size: 48px;
  font-family: "Isidora-Bold";
  color: #14377c;
}

.heading h2 {
  display: inline;
  font-size: 48px;
  font-family: "Isidora-Bold";
  color: #ee3124;
}
.heading-m {
  text-align: left;
  padding-left: 4%;
  padding-right: 5%;
}

.heading-m h1 {
  display: inline;
  font-size: 32px;
  font-family: "Isidora-Bold";
  color: #14377c;
}

.heading-m h2 {
  display: inline;
  font-size: 32px;
  font-family: "Isidora-Bold";
  color: #ee3124;
}

.currentPostCrumb {
  color: #5c5c5c;
}

ul.breadcrumb {
  font-family: "Isidora-SemiBold";
  padding: 10px 16px;
  list-style: none;
  background-color: white;
  padding-left: 3.5%;
  padding-right: 3%;
}
ul.breadcrumb-m {
  font-family: "Isidora-SemiBold";
  padding: 10px 16px;
  list-style: none;
  background-color: white;
  margin-left: 1%;
  margin-right: 10%;
  text-align: left;
}
ul.breadcrumb li {
  display: inline;
  font-size: 28px;
}
ul.breadcrumb-m li {
  display: inline;
  font-size: 18px;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb-m li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a {
  color: #00afd7;
  text-decoration: none;
}
ul.breadcrumb-m li a {
  color: #00afd7;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #01447e;
  cursor: pointer;
}
ul.breadcrumb-m li a:hover {
  color: #01447e;
  cursor: pointer;
}

.pageContent::v-deep p {
  color: #5c5c5c;
}

.pdfs:hover {
  background-color: #14377c;

  p {
    color: white;
  }
}

.post-content p {
  font-family: "Isidora Sans Medium";
  font-size: 18px;
  color: #5c5c5c;
  margin-left: 70px;
  margin-right: 55px;
  margin-top: 20px;
  text-align: left;
  word-wrap: break-word;
}
.post-content-m p {
  font-family: "Isidora Sans Medium";
  font-size: 14px;
  color: #5c5c5c;
  margin-left: 3%;
  margin-right: 10%;
  margin-top: 20px;
  text-align: left;
  word-wrap: break-word;
}

.post-content::v-deep p {
  color: #5c5c5c;
}
.post-content-m::v-deep p {
  color: #5c5c5c;
}

.pdfs {
    display: relative;
    min-height: 88px;
    margin: 0 auto;
    margin-left: 70px;
    height: 88px;
    width: 90%;
    border-radius: 10px;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.pdfs-m {
    display: block;
    margin: 0 auto;
    margin-left: 10px;
    height: 100%;
    width: 90%;
    border-radius: 10px;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.pdf-cols {
     margin-right: 25px;
     margin-bottom: 25px;
     width: 375px;
}

.pdfs img {
  display: inline;
  vertical-align: middle;
  float: left;
  width: 20%;
  height: 60px;
  margin-left: 10px;
  margin-top: 13px;
}

.pdfs-m img {
  display: inline;
  vertical-align: middle;
  float: left;
  width: 20%;
  height: 40%;
  margin-top: 10%;
}

.pdfs p {
  display: inline-block;
  float: left;
  text-align: left;
  line-height: 88px;
  max-height: 88px;
  overflow: hidden;
  width: 75%;
  color: #14377c;
  font-family: "Isidora-SemiBold";
  font-size: 20px;
}
.pdfs-m p {
  margin-top: 10%; 
  white-space: nowrap;
  vertical-align: middle;
  padding-top: 2%;
  display: inline-block;
  float: left;
  text-align: left;
  line-height: 28px;
  min-height: 55px;
  max-height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  color: #14377c;
  font-family: "Isidora-SemiBold";
  font-size: 20px;
}


.single-img {
  display: block;
  margin-left: 70px;
  max-height: 500px;
  max-width: 500px;
  align-self: center;
  border: 2px solid #14377d;
  border-radius: 10px;
}

.image-container {
  padding: 10px;
  height: auto;
  max-width: 775px;
  margin-left: 5%;
}

</style>
