import axios from "axios";
import Utils from "@/utils/Utils";

export const fetchFavorites = (userName, headers) =>
  axios.get(Utils.URI.appProfile + Utils.API.fetchFavorite + userName, headers);

export const addFavorite = (data, headers) =>
  axios.post(Utils.URI.appProfile + Utils.API.addFavorite, data, headers);

export const deleteFavorites = (data, headers) =>
  axios.post(Utils.URI.appProfile + Utils.API.deleteFavorite, data, headers);
