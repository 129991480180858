export const convertDate = (date_string) => {
    let date = new Date(date_string)
    return `
        ${['January', 'February', 'March', 'April', 'May','June','July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]}  
        ${date.getDate()},
        ${date.getFullYear()}
    `;
  }

  export const getMonth = (date_string) => {
    let date = new Date(date_string)
    return `${['January', 'February', 'March', 'April', 'May','June','July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]}`;
  }

  export const getYear = (date_string) => {
    let date = new Date(date_string)
    return `${date.getFullYear()}`;
  }
