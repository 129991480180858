var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isiPad()
      ? _vm.isUrgent
        ? 'post-cards-red sharing-iPad-red'
        : 'post-cards sharing-iPad'
      : _vm.isMobile()
      ? _vm.isUrgent
        ? 'post-cards-red sharing-mobile-red-news'
        : 'post-cards sharing-mobile-news'
      : _vm.isUrgent
      ? 'post-cards-red sharing-red'
      : 'post-cards sharing'},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(_vm._s(_vm.formatRawDate))]),_c('div',{staticClass:"card-icons"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAttachments),expression:"hasAttachments"}],staticClass:"card-icon icon savecard",attrs:{"src":_vm.getIcons.savecardIcon,"alt":"fav"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRecent),expression:"isRecent"}],staticClass:"card-icon icon flag",attrs:{"src":_vm.getIcons.flagIcon,"alt":"fav"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUrgent),expression:"isUrgent"}],staticClass:"card-icon icon redstar",attrs:{"src":_vm.getIcons.redstarIcon,"alt":"fav"}})])]),_c('div',{staticClass:"card-body news"},[_c('span',{class:_vm.isMobile() ? 'mheadline' : 'headline'},[_vm._v(_vm._s(_vm.headline))]),_c('p',{class:_vm.isMobile() ? 'msummary' : 'summary'},[_vm._v(_vm._s(_vm.cardSummary))])]),_c('div',{staticClass:"footer"},[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.doReadMore()}}},[_c('img',{staticClass:"read-more-btn",attrs:{"src":_vm.getIcons.readmoreIcon,"alt":"fav"}})]),(this.getSaveProfile)?_c('a',{staticClass:"save-btn",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.saveThisItem(_vm.id)}}},[_c('img',{attrs:{"src":_vm.isSaved
              ? _vm.getIcons.savecardfilledIcon
              : _vm.getIcons.savecardoutlineIcon}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }