<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "assets/_shared.scss";
@font-face {
  font-family: "Isidora";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Isidora-Regular.otf"),
    url(./assets/fonts/Isidora/Isidora-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Isidora-Sans-Regular.otf"),
    url(./assets/fonts/IsidoraSans/Isidora-Sans-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Isidora-Bold";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Isidora-Bold.otf"),
    url(./assets/fonts/Isidora/Isidora-Bold.otf) format("opentype");
}
@font-face {
  font-family: 'Isidora-SemiBold';
  src: url('./assets/fonts/Isidora/Isidora-SemiBold.otf');
}
@font-face {
  font-family: 'Isidora Sans Medium';
  src: url('./assets/fonts/IsidoraSans/Isidora Sans Medium.otf');
}

#app {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  background-color: white;
}
</style>
