import store from '../../store/index';

const ADD_PROFILE_DATA = (state, payload) => {
    state.profileData = payload
    state.userName = payload.profile.mail.toLowerCase();
    store.dispatch("menuModule/getAdditionalLinksData", payload);
}

const ADD_WP_PROFILE = (state, payload) => {
    state.wpProfile = payload;
}

const ADD_SAVES = (state, payload) => {
    state.wpProfile.userPosts = payload;
}

export default { ADD_PROFILE_DATA, ADD_WP_PROFILE, ADD_SAVES }