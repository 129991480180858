<template>
  <div>
    <div
      class="custom_sort_card"
      v-if="showSort"
      v-clickoutside="closeEventSort"
    >
      <div class="msort_header" style="display: flex; flex-direction: rows">
        <span :class=" isIpadPro() ? 'ipro_s_title_sortby' : isiPad() ? 'is_title_sortby':'s_title_sortby'"> SORT BY </span>
        <span :class=" isIpadPro() ? 'ipros_title_clearall' : isiPad() ? 'is_title_clearall':'s_title_clearall'" @click="clearall()">
          Clear All Selection
        </span>
      </div>
      <div
        class="msort_body"
        :class="isiPad() ? 'msort_body ipad_sort' : 'msort_body'"
      >
        <ul
          v-for="sort in sortOptions"
          :key="sort"
          :class="sortSelected.includes(sort) ? 'sort-selected' : 'ul'"
        >
          <img
            class="larger"
            :src="getRadioImage(sortSelected.includes(sort))"
            alt="Sal"
            @click="sortInputSelected(sort)"
            name="sort"
            :value="sort"
          />
          {{
            sort
          }}
        </ul>
      </div>
    </div>
    <div
      class="custom_filter_card"
      v-if="showFilter"
      v-clickoutside="closeEventFilter"
    >
      <div class="mfilter_header" style="display: flex; flex-direction: rows">
        <span :class="isIpadPro()? 'ipro_s_title' : isiPad() ? 'is_title' : 's_title'"> FILTER BY </span>
        <span :class=" isIpadPro() ? 'ipros_title_clearall' : isiPad() ? 'is_title_clearall':'s_title_clearall'" @click="clearall()">
          Clear All Selection
        </span>
      </div>
      <div
        class="mfilter_body"
        :class="isiPad() ? 'mfilter_body ipad' : 'mfilter_body'"
      >
        <ul
          v-for="filter in filterOptions"
          :key="filter"
          :class="filterSelected.includes(filter) ? 'sort-selected' : 'ul'"
        >
          <img
            class="larger"
            :src="getCheckboxImage(isSelected(filter))"
            alt="Sal"
            @click="filterInputSelected(filter)"
            name="filterCB"
            :value="filter"
          />
          {{
            filter
          }}
        </ul>
      </div>
      <div class="mfilter_footer" @click="applyClicked()">
        <span class="mfooter_title"> APPLY </span>
      </div>
    </div>
    <div class="m_sort_container">
      <div class="m_sort" @click.stop="enableSortFilter('sort')">
        <span :class=" isIpadPro() ? 'iprotitle' : isiPad() ? 'ititle':'title'" >Sort</span>
      </div>
      <div class="m_filter" @click.stop="enableSortFilter('filter')">
        <span :class=" isIpadPro() ? 'iprotitle' : isiPad() ? 'ititle':'title'">Filter</span>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from "../../utils/Utils";
import { mapGetters } from "vuex";

export default {
  directives: {
    clickoutside: {
      bind: function (el, binding, vnode) {
        window.event = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", window.event);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", window.event);
      },
    },
  },
  props: {
    sortOptions: {
      type: Array,
      required: true,
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    sortDefault: {
      type: [String,Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showFilter: false,
      showSort: false,
      userFiltered: [],
      userSorted: [],
      selected: "first",
      filterSelected: [],
      sortSelected: [],
      applyClick: false,
    };
  },
  methods: {
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    applyClicked() {
      this.showFilter = false;
      this.applyClick = true;
      this.$root.$emit('GrayedOut',false);
    },
    clearall() {
      this.$parent.clearAllSelection();
      this.applyClick = false;
      this.$root.$emit('GrayedOut',false);
    },
    closeEventSort() {
      this.showFilter = false;
      this.showSort = false;
      this.applyClick = false;
      this.$root.$emit('GrayedOut',false);
    },
    closeEventFilter() {
      this.showFilter = false;
      this.showSort = false;
      this.applyClick = false;
      this.$parent.clearAllSelection();
      this.$root.$emit('GrayedOut',false);
    },
    enableSortFilter(str) {
      if (str === "sort") {
        this.showSort = !this.showSort;
        this.showFilter = false;
      } else if (str === "filter") {
        this.showFilter = !this.showFilter;
        this.showSort = false;
      }
      this.$root.$emit('GrayedOut',true);
    },
    isSelected(filter) {
      for (let i = 0; i < this.filterSelected.length; i++) {
        if (this.filterSelected[i] == filter) {
          return true;
        }
      }
      return false;
    },

    refresh(filter) {
      this.emitSettings();
    },
    emitSettings() {
      this.$emit(
        "emitUserSettings",
        `{"Sort":` +
          JSON.stringify(this.sortSelected) +
          `,"Filter":` +
          JSON.stringify(this.filterSelected) +
          `}`
      );
    },
    sortInputSelected(sort) {
      if (this.sortSelected.length > 0) {
        this.sortSelected = [];
        this.sortSelected.push(sort);
      } else {
        this.sortSelected.push(sort);
      }
      this.$store.commit("mutateSort" + this.page, this.sortSelected[0]);
      window.scrollTo(0, 0);
      this.emitSettings();
    },
    filterInputSelected(filter) {
      if (!this.filterSelected.includes(filter)) {
        this.filterSelected.push(filter);
      } else {
        const remove = this.filterSelected.indexOf(filter);
        this.filterSelected.splice(remove, 1);
      }
      if (this.filterSelected.length == 0) {
        this.$store.commit("mutateFilter" + this.page);
      } else {
        this.$store.commit("mutateFilter" + this.page, this.filterSelected);
      }
      this.userFiltered = this.filterSelected;
      this.emitSettings();
      window.scrollTo(0, 350);
    },
    getCheckboxImage(status) {
      return status
        ? Utils.Images.filterCheckboxSelected
        : Utils.Images.filterCheckboxEmpty;
    },
    getRadioImage(status) {
      return status
        ? Utils.Images.sortRadioButtonSelected
        : Utils.Images.sortRadioButtonEmpty;
    },
  },
  computed: {
    ...mapGetters([
      "getSortNewsUpdates",
      "getFilterNewsUpdates",
      "getSortDotComm",
      "getFilterDotComm",
      "getSortWebinars",
      "getFilterWebinars",
      "getSortSharingCorner",
      "getFilterSharingCorner",
    ]),
  },
  created() {
    this.sortSelected = [];
    this.filterSelected = [];

    switch (this.page) {
      case "NewsUpdates":
        this.sortSelected.push(this.getSortNewsUpdates);

        for (let i = 0; i < this.getFilterNewsUpdates.length; i++) {
          this.filterSelected.push(this.getFilterNewsUpdates[i]);
        }
        if (this.filterSelected.length > 0) {
          this.emitSettings();
        }
        break;
      case "DotComm":
        this.sortSelected.push(this.getSortDotComm);
        for (let i = 0; i < this.getFilterDotComm.length; i++) {
          this.filterSelected.push(this.getFilterDotComm[i]);
        }
        if (this.filterSelected.length > 0) {
          this.emitSettings();
        }

        break;
      case "Webinars":
        this.sortSelected.push(this.getSortWebinars);
        for (let i = 0; i < this.getFilterWebinars.length; i++) {
          this.filterSelected.push(this.getFilterWebinars[i]);
        }
        if (this.filterSelected.length > 0) {
          this.emitSettings();
        }

        break;
      case "SharingCorner":
        this.sortSelected.push(this.getSortSharingCorner);
        for (let i = 0; i < this.getFilterSharingCorner.length; i++) {
          this.filterSelected.push(this.getFilterSharingCorner[i]);
        }
        if (this.filterSelected.length > 0) {
          this.emitSettings();
        }

        break;
    }
  },
};
</script>
<style>
.mfooter_title {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
}
.sort-selected {
  font-family: "Isidora-SemiBold";
  font-size: 20px;
  text-decoration: none;
  background-color: white;
  list-style-position: outside;
  list-style-type: square;
  color: #14377c;
}
.larger {
  width: 36px;
  height: 36px;
  border-radius: 40px;
}
.m_sort_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}
.msort_body {
  display: flex;
}
.ipad_sort {
  padding-left: 90px;
}
.mfilter_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
}
.ipad {
  padding-left: 104px;
}
.ul {
  font-family: "Isidora-SemiBold";
  font-size: 20px;
  text-decoration: none;
  background-color: white;
  list-style-position: outside;
  list-style-type: square;
  color: rgba(115, 135, 178, 0.55);
}
.mfilter_header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width:100%;
  max-width:100%;
  margin-left: 15px;
  border-bottom: 1px solid #b2bdd5;
  height: 50px;
  max-height: 85px;
  padding-top: 16px;
  padding-bottom: 10px;
}
.mfilter_footer {
  display: flex;
  align-items: center;
  background: #ee3124;
  height: 70px;
}
.msort_header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width:100%;
  max-width:100%;
  margin-left: 15px;
  border-bottom: 1px solid #b2bdd5;
  height: 40px;
  max-height: 45px;
}
@keyframes pulse {
  from {
    bottom: -600px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes sort {
  from {
    bottom: -240px;
  }
  to {
    bottom: 0px;
  }
}
.custom_filter_card {
  animation: pulse 1s;
  bottom: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 0px 0px;
  height: auto;
  max-height:600px;
  overflow-y: scroll;
}
.custom_sort_card {
  animation: sort 1s;
  bottom: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 0px 0px;
  height: 232px;
  overflow-y: scroll;
  .sort_title {
    display: flex;
    flex-direction: rows;
  }
}
.s_title_clearall {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #00afd7;
  text-decoration: underline;
  margin-right:-8px;
}
.is_title_clearall {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #00afd7;
  text-decoration: underline;
  margin-right:-8px;
}
.ipros_title_clearall {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #00afd7;
  text-decoration: underline;
  margin-right:-8px;
}
.is_title_sortby {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ee3124;
  margin-left:-17px;
}
.ipro_s_title_sortby {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ee3124;
  margin-left:-95px;
}
.s_title_sortby {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ee3124;
  margin-left:-23px;
}
.is_title {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: #ee3124;
  margin-left:-9px;
}
.ipro_s_title {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: #ee3124;
  margin-left:-77px;
}
.s_title {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ee3124;
  margin-left:-23px;
}
.title {
  font-family: "Isidora";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ee3124;
}
.ititle {
  font-family: "Isidora";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #ee3124;
}
.iprotitle {
  font-family: "Isidora";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #ee3124;
}
.m_sort {
  flex-basis: 50%;
  align-items: center;
  background: #ffffff;
  border-right: 1px solid #ee3124;
}
.m_filter {
  flex-basis: 50%;
  align-items: center;
  background: #ffffff;
}
</style>
