<template>
    <p v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'ParagraphBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

p {
    font-family: "Isidora Sans";
    color: $salLightGrey;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

::v-deep a {
    color: #14377C;
    text-decoration: underline;
}


</style>