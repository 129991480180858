<template>
  <div class="poc-container">
    <b-row class="header"> Points of Contact </b-row>
    <b-row class="body-content">
      <b-col class="poc-card">
        <b-col class="pic-container">
          <img
            class="pic"
            :src="getImageName(pocList[0].contactImage)"
            alt="Sal"
          />
        </b-col>
        <b-col class="details">
          <p class="name">{{ pocList[0].contactName }}</p>
          <p class="role">{{ pocList[0].contactTitle }}</p>
          <p class="contact-number">{{ pocList[0].contactPhone }}</p>
        </b-col>
      </b-col>
      <b-col class="poc-card" v-if="pocList.length > 1">
        <b-col class="pic-container">
          <img
            class="pic"
            :src="getImageName(pocList[1].contactImage)"
            alt="Sal"
          />
        </b-col>
        <b-col class="details">
          <p class="name">{{ pocList[1].contactName }}</p>
          <p class="role">{{ pocList[1].contactTitle }}</p>
          <p class="contact-number">{{ pocList[1].contactPhone }}</p>
        </b-col>
      </b-col>
      <b-col class="poc-card" v-if="pocList.length > 2">
        <b-col class="pic-container">
          <img
            class="pic"
            :src="getImageName(pocList[2].contactImage)"
            alt="Sal"
          />
        </b-col>
        <b-col class="details">
          <p class="name">{{ pocList[2].contactName }}</p>
          <p class="role">{{ pocList[2].contactTitle }}</p>
          <p class="contact-number">{{ pocList[2].contactPhone }}</p>
        </b-col>
      </b-col>
      <b-col :class="isMobile() ? 'mobile-qn' : isiPad() ? 'tablet-qn' : 'qn-section'">
        <p>Questions?</p>
        <p>Write an email to</p>
        <p>{{ qnEmail }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    pocList: Array,
    qnEmail: String,
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    getImageName(media) {
      return media.mediaItemUrl === null ? "" : media.mediaItemUrl;
    },
    isMobile() {
        return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet =
            /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
        );
        return isTablet;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";
.poc-container {
  background-color: white;

  .header {
    font-family: "Isidora-Bold";
    font-size: 32px;
    font-weight: 700;
    color: $salBlue;
  }
  .body-content {
    margin-left: -22px;
  }

  .poc-card {
    display: flex;
    flex-direction: row;

    .pic-container {
      margin-right: 10px;
      max-width: 120px;
      display: flex;
      justify-content: center;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        width: 100%;
        text-align: left;
      }

      .name {
        color: $salBlue;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        margin: 5px auto;
        font-family: "Isidora";
      }

      .role {
        color: $navBlue;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin: 5px auto;
        font-family: "Isidora Sans";
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .contact-number {
        font-family: "Isidora Sans";
        color: $salBlue;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    p {
      text-align: left;
    }

    .pic {
      width: 120px;
      height: 120px;
      align-self: center;
      border-radius: 10px;
      border: $salBlueBorder;
    }
  }

  .qn-section {
    border: 1px solid $salBlue;
    border-radius: 10px;
    text-align: center;
    margin: 10px auto;
    max-width: 25%;

    p:nth-child(1) {
      color: $salBlue;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
      font-family: "Isidora Sans";
    }

    p:nth-child(2) {
      color: $salBlue;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      font-family: "Isidora Sans";
    }

    p:nth-child(3) {
      color: $navBlue;
      font-size: 20px;
      font-weight: 600;
      font-family: "Isidora Sans";
    }
  }

    .mobile-qn {
      border: 1px solid $salBlue;
      border-radius: 10px;
      text-align: center;
      margin: 20px 12px;

      p:nth-child(1) {
        color: $salBlue;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        font-family: "Isidora Sans";
      }

      p:nth-child(2) {
        color: $salBlue;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        font-family: "Isidora Sans";
      }

      p:nth-child(3) {
        color: $navBlue;
        font-size: 20px;
        font-weight: 600;
        font-family: "Isidora Sans";
      }
    }

    .tablet-qn {
          border: 1px solid $salBlue;
          border-radius: 10px;
          text-align: center;
          margin: 20px 12px;
          width: 100%;

          p:nth-child(1) {
            color: $salBlue;
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
            font-family: "Isidora Sans";
          }

          p:nth-child(2) {
            color: $salBlue;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            font-family: "Isidora Sans";
          }

          p:nth-child(3) {
            color: $navBlue;
            font-size: 20px;
            font-weight: 600;
            font-family: "Isidora Sans";
          }
        }
}
</style>
