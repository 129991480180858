import * as searchActions from './actions';
import searchGetters from './getters';
import searchMutations from './mutations';

export const searchStore = {
    state: {
        searchResults: [],
        searchParams: "",
    },
    actions: {
        ...searchActions,
    },
    mutations: {
        ...searchMutations,
    },
    getters: {
        ...searchGetters,
    }
}