import * as additionalLinksActions from "../additionallinks/actions";
import additionalLinksGetters from "../additionallinks/getters";
import additionalLinksMutations from "../additionallinks/mutations";

import * as recentlyUsedActions from "../recentlyused/actions";
import recentlyUsedGetters from "../recentlyused/getters";
import recentlyUsedMutations from "../recentlyused/mutations";

import * as wpActions from "../wordpresscontent/actions";
import wpGetters from "../wordpresscontent/getters";
import wpMutations from "../wordpresscontent/mutations";
import { fetchMenu, fetchSubMenu } from "../additionallinks/actions";
import { fetchCommonLinks } from "../wordpresscontent/actions";

import * as favActions from "../fav/actions";
import favGetters from "../fav/getters";
import favMutations from "../fav/mutations";

const state = {
  menuList: [],
  navMenu: [],
  additionalLinks: [],
  webinars: [],
  topics: [],
  commonLinks: [],
  marketingPageInfo: [],
  hrPageInfo: [],
  pocList: "",
  hrPocList: "",
  commonPOCList: "",
  weeklyCheckoutArchives: "",
  dResources: "",
  historyData: [],
  historyErrCode: "",
  historyStaus: "",
  favoritesData: [],
  favErrCode: "",
  favStatus: "",
  retailRes: [],
  learningCart: "",
  savedContent: {},
  customPost: [],
  routeParam: null,
  homePageDetails: "",
};
const actions = {
  ...additionalLinksActions,
  ...wpActions,
  ...recentlyUsedActions,
  ...favActions,
  fetchMenu,
  fetchCommonLinks,
};
const getters = {
  ...additionalLinksGetters,
  ...wpGetters,
  ...recentlyUsedGetters,
  ...favGetters,
  commonLinks: (state) => state.commonLinks,
};
const mutations = {
  ...additionalLinksMutations,
  ...wpMutations,
  ...recentlyUsedMutations,
  ...favMutations,
  COMMON_LINKS_UPDATED: (state, links) => (state.commonLinks = links),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
