import Utils from "@/utils/Utils";



export default {
    getSelectedButton: state => state.selectedButton,
    isLoading: state => state.menuLoading,
    visibleCommonLinks: state => state.visibleCommonLinks,
    commonLinks: state => state.commonLinks,
    favLinks: state => state.favoriteLinks,
}