<template>
  <div style="treeOuter">
    <div
      v-for="(item, index) in pdfContent"
      :key="item.title"
      @click="
        toggle(item.title);
        toggleIcon(item);
        changeActive(index);
      "
    >
    <div :class="isMobile() ? 'm-parent' : isiPad() ? 't-parent' : ''">
      <div class="parentStyle" :class="{ clicked: isClicked(index) }">
            <h1>{{ item.icon }}</h1>
            <h2>{{ item.title }}</h2>
      </div>
    </div>
      <div v-show="false" :id="item.title">
        <div
          :id="child.pDFTitle"
          v-for="child in item.children"
          :key="child.pDFTitle"
          class="childStyle"
        >
          <div :class="isMobile() ? 'm-child' : isiPad() ? 't-child' : 'childSpan' " @click="openPdf(child.pDFUrl)">
            <img :src="getIcons.pdfIcon" alt="PDF" />
            <h1>{{ child.pDFTitle }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  props: {
    pdfContent: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPdf: "",
      isActive: [],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["getHomePageDetails"]),
    getIcons() {
      return {
        pdfIcon: this.getHomePageDetails.savecardicon.mediaItemUrl,
      };
    },
  },
  methods: {
      isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
      },
      isiPad() {
          const userAgent = navigator.userAgent.toLowerCase();
          const isTablet =
          /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
              userAgent
          );
          return isTablet;
      },
    handleSearchNav(item, fullItem, index) {
      this.toggle(item.category.nodes[0].name);
      this.toggleIcon(fullItem);
      this.changeActive(index);
      this.scrollToSearch(item);
    },
    scrollToSearch(item) {
      this.$nextTick(() => {
        let el = document.getElementById(item.pDFTitle);
        window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
        el.getElementsByTagName("h1")[0].classList.add("highlight");
      });
    },
    toggle(idname) {
      if (document.getElementById(idname).style.display == "none") {
        document.getElementById(idname).style.display = "inline";
      } else {
        document.getElementById(idname).style.display = "none";
      }
    },
    toggleIcon(t) {
      if (t.icon == " + ") {
        t.icon = " - ";
      } else {
        t.icon = " + ";
      }
    },
    isClicked(index) {
      return this.isActive[index];
    },
    changeActive(index) {
      Vue.set(this.isActive, index, !this.isActive[index]);
    },
    openPdf(selected) {
      this.selectedPdf = selected;
      window.open(this.selectedPdf);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_shared.scss";

@font-face {
  font-family: "Isidora-Bold";
  src: url("../assets/fonts/Isidora/Isidora-Bold.otf");
}

@font-face {
  font-family: "Isidora-SemiBold";
  src: url("../assets/fonts/Isidora/Isidora-SemiBold.otf");
}

@font-face {
  font-family: "Isidora Sans Medium";
  src: url("../assets/fonts/IsidoraSans/Isidora Sans Medium.otf");
}

.parentStyle {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    color: #14377c;
    border-bottom: 1px solid rgba($color: #14377d, $alpha: 0.5);

    h1 {
        font-family: Isidora Sans Medium;
        font-size: 36px;
        margin-bottom: 15px;
    }

    h2 {
        font-family: Isidora-SemiBold;
        font-size: 24px;
        margin-left: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
        text-align: left;
    }

    .parentStyle:hover h1 {
        color: #ee3124;
    }

    .parentStyle:hover h2 {
        color: #ee3124;
    }

    .clicked h1,
    .clicked h2 {
      color: #ee3124;
    }
}

.m-parent {
    h1 {
        font-family: Isidora Sans Medium;
        font-size: 28px;
        margin-bottom: 15px;
    }

    h2 {
        font-family: Isidora-SemiBold;
        font-size: 24px;
        margin-left: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
        text-align: left;
    }

    .clicked h1,
    .clicked h2 {
      color: #ee3124;
    }
}

.t-parent {
    h1 {
        font-family: Isidora Sans Medium;
        font-size: 32px;
        margin-bottom: 15px;
    }

    h2 {
        font-family: Isidora-SemiBold;
        font-size: 28px;
        margin-left: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
        text-align: left;
    }

    .clicked h1,
    .clicked h2 {
      color: #ee3124;
    }
}

.childStyle {
  margin-left: 50px;
  cursor: pointer;
  border-bottom: 1px solid rgba($color: #14377d, $alpha: 0.5);
}

.childSpan {
    display: flex;
    align-items: center;

    h1 {
      text-align: left;
      font-family: Isidora-SemiBold;
      font-size: 24px;
      color: #14377c;
      text-decoration: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .childSpan:hover h1 {
      color: #ee3124;
    }
}

.m-child {
    display: flex;
    align-items: center;

    h1 {
      text-align: left;
      font-family: Isidora-SemiBold;
      font-size: 18px;
      color: #14377c;
      text-decoration: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .childSpan:hover h1 {
      color: #ee3124;
    }
}

.t-child {
    display: flex;
    align-items: center;

    h1 {
      text-align: left;
      font-family: Isidora-SemiBold;
      font-size: 22px;
      color: #14377c;
      text-decoration: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .childSpan:hover h1 {
      color: #ee3124;
    }
}

.childStyle img {
  margin-right: 10px;
  vertical-align: middle;
}

.highlight {
  animation: highlight 1s 3;
}

@keyframes highlight {
  0% {
    color: $salBlue;
  }
  50% {
    color: $salRed;
  }
  100% {
    color: $salBlue;
  }
}
</style>
