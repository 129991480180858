<template>
  <div class="outerDiv">
    <div class="readMoreWrapper">
      <SharingCornerRead
        class="ReadContent"
        v-if="showRead"
        :post="selectedPost"
        @close="closeReadMore()"
      />
    </div>
    <div class="pageWrap" v-if="!showRead">
      <div
        :class="isiPad() ? 'heading heading-iPad' : 'heading heading-desktop '"
        v-responsive="['hidden-all', 'md', 'lg', 'xl']"
      >
        <h1>Sharing Corner</h1>
        <h2>.</h2>

        <a @click="navToSaved()" class="saved">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
          class="back-btn"
          :src="getBackBtnImage()"
          alt="Back"
          @click="backBtnClicked()"
        />
        <span class="main_title">Sharing Corner</span>
        <span class="main_fullstop"></span>
        <a @click="navToSaved()" class="saved-mobile">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="msearch" v-if="isMobile()">
        <card-search
          :key="reloadSearch"
          v-on:emitSearchInput="searchCards"
          v-responsive="['hidden-all', 'xs', 'sm']"
        />
      </div>
      <card-search
        :key="reloadSearch"
        v-on:emitSearchInput="searchCards"
        v-responsive="['hidden-all', 'md', 'xl', 'lg']"
      />
      <div
        class="leftDiv"
        v-responsive="['hidden-all', 'lg', 'xl']"
        v-if="!isIpadPro() && !isMobile()"
      >
        <sort-filter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        />
        <div
          v-responsive="['hidden-all', 'lg', 'xl']"
          class="clearFilters clearFilterButtonStyle"
          @click="clearAllSelection()"
        >
          <p>Clear All Selection</p>
        </div>
      </div>
      <div class="m_sortfilter" v-if="isMobile() || isiPad() || isIpadPro()">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>

      <div
        :class="isMobile() || isiPad() ? 'rightDiv-mobile' : 'rightDiv'"
        v-if="currentdisplayisready"
      >
        <b-row>
          <search-results
            v-if="searchString.length > 0"
            :result="cardItemsFiltered.length"
          />
        </b-row>
        <b-row
          v-if="currentdisplayisready"
          cols="4"
          :class="isMobile() || isiPad() ? 'cards-row-mobile' : 'cards-row'"
        >
          <b-col
            v-for="item in currentdisplay"
            :key="item.databaseId"
            :class="
              isMobile()
                ? 'cards-col-mobile'
                : isiPad()
                ? 'cards-col-iPad'
                : 'cards-col'
            "
          >
            <div class="card-comp">
              <SharingCards
                v-if="currentdisplayisready && !isMobile()"
                :post="item"
                @readMore="onClickedRead"
                class="sharing-cards-comp"
              />
              <msharingcorner
                v-if="currentdisplayisready && isMobile()"
                :post="item"
                @readMore="onClickedRead"
                class="sharing-cards-comp"
              />
            </div>
          </b-col>
        </b-row>
        <div id="pagination" class="pagination" v-if="currentdisplayisready">
          <pager
            ref="pager"
            :key="reloadPager"
            v-on:emitCurrentDisplayIdx="updateCurrentDisplay"
            :buttoncount="buttoncount"
            :cardsperpagecount="getPageCount()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortFilter from "../../SortFilter.vue";
import SharingCards from "../cards/SharingCards.vue";
import Msharingcorner from "../../mobile/Msharingcorner.vue";
import SharingCornerRead from "./SharingCornerRead.vue";
import { gQLCaller } from "../../../queries/gqlCaller.js";
import {
  SHARING_CORNER_READ,
  NEWS_QUERY,
} from "../../../queries/gqlQueries.js";
import pager from "../../pagination/Pager.vue";
import Search from "../../../utils/Search.js";
import CardSearch from "../search/CardSearch.vue";
import SearchResults from "../search/SearchResults.vue";
import SortFilterCommon from "../../../utils/SortFilterCommon.js";
import MSortFilter from "../../mobile/MSortFilter.vue";
import { mapGetters, mapMutations } from "vuex";
import Utils from "../../../utils/Utils";

export default {
    components: {
        SortFilter,
        SharingCards,
        SharingCornerRead,
        pager,
        CardSearch,
        SearchResults,
        MSortFilter,
        Msharingcorner
    },
    props: {
        pageTitle: String,
        compProps: String
    },
    data() {
        return {
            currentComponent: "Dashboard",
            sortsFilters: [],
            objSettings: Object,
            showRead: false,
            selectedPost: {},
            reloadPager: 0,
            reloadFilters: 0,
            reloadSearch: 0,
            cardsperpagecount: 9,
            currentdisplayidx: 0,
            currentdisplay: [],
            currentdisplayisready: false,
            buttoncount: 0,
            currentPage: 1,
            filters: [],
            sorts: [],
            sortDefault: "",
            userSelections: Object,
            cardItemsFiltered: [],
            cardItems: [],
            previousFilters: [],
            searchString: "",
            page:"SharingCorner",
        }
    },
    created() {
        this.getData();
    },
    mounted() {
      setTimeout(() => {
          if (this.$route.params.headline) {
              this.scrollToSearch(this.$route.params.headline, this.$route.params.story);
          }
      }, 1500);
    },
    methods: {
      ...mapMutations(['mutateSortSharingCorner','mutateFilterSharingCorner']),
        scrollToSearch(headline, story) {
            let card = this.cardItems.find((card) => card.headline == headline && card.story == story);
            if (card) {
                let cardIndex = this.cardItems.indexOf(card);
                if(cardIndex <= this.getPageCount() - 1) {
                    this.$nextTick(() => {
                        document.querySelectorAll(".sharing-cards-comp")[cardIndex].classList.add("highlight");
                        window.scrollTo({ top: document.querySelectorAll(".cards-col")[cardIndex].offsetTop, behavior: 'smooth' });
                        return;
                    });
                } else {
                    let page = 0;
                    if(cardIndex == this.getPageCount()) {
                        page = Math.ceil(cardIndex / this.getPageCount()) + 1;
                    } else {
                        page = Math.ceil(cardIndex / this.getPageCount())
                    }
                    this.$refs.pager.findSearch(page - 1);
                    let cardIdx = cardIndex % this.getPageCount();
                    this.$nextTick(() => {
                        document.querySelectorAll(".sharing-cards-comp")[cardIdx].classList.add("highlight");
                        window.scrollTo({ top: document.querySelectorAll(".cards-col")[cardIdx].offsetTop, behavior: 'smooth' });
                    });
                }
            }
        },
        isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
        isIpadPro() {
          var ratio = window.devicePixelRatio || 1;
          var screen = {
            width: window.screen.width * ratio,
            height: window.screen.height * ratio,
          };
          return (
            (screen.width === 2048 && screen.height === 2732) ||
            (screen.width === 2732 && screen.height === 2048) ||
            (screen.width === 1536 && screen.height === 2048) ||
            (screen.width === 2048 && screen.height === 1536)
          );
        },
        isiPad() {
          const userAgent = navigator.userAgent.toLowerCase();
          const isTablet =
            /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
              userAgent
            );
          return isTablet || this.isIpadPro();
        },

        getBackBtnImage() {
          return Utils.Images.back;
        },

        backBtnClicked() {
          this.$router.back();
        },
      setState(clearAll) {
        const doResetState = clearAll || false
        if (doResetState || this.getSortSharingCorner=="" ) {
          this.$store.commit("mutateSortSharingCorner", this.sortDefault)
          this.$store.commit("mutateFilterSharingCorner")
        }
      },

    closeReadMore() {
      this.showRead = false;
      setTimeout(() => {
        this.$refs.sortFilter.refresh(this.previousFilters);
      }, 0);
    },

    navToSaved() {
      this.$router.push({ name: "Saved" });
    },

    goHome() {
      this.$emit("goHome");
      this.$root.$emit('disabledBackground');
    },

    reloadPagination() {
      this.reloadPager++;
    },
    getPageCount() {
      return this.isMobile() || this.isiPad() ? 6 : 9;
    },
    clearAllSelection() {
      this.searchString = "";
      this.setState(true);
      this.reloadFilters++;
      this.reloadSearch++;
      this.updateSettings();
    },

    updateCurrentDisplay(newidx) {
      this.currentdisplayidx = newidx;
      if (this.currentdisplaycontentFiltered.length > 0) {
        this.currentdisplay = this.currentdisplaycontentFiltered;
      } else {
        this.currentdisplay = this.currentdisplaycontent;
      }
    },

    searchCards(value) {
      if (value.length == 0) {
        this.clearAllSelection();
      } else {
        this.searchString = value;
        this.updateSettings();
      }
    },

    updateSettings() {
      this.currentdisplayisready = false;
      let SortedFilteredCards = [];
      SortedFilteredCards = SortFilterCommon.sortCards(
        this.cardItems,
        this.getSortSharingCorner
      );

      if (this.getFilterSharingCorner.length > 0) {
        SortedFilteredCards = SortFilterCommon.filterCards(
          SortedFilteredCards,
          this.getSortSharingCorner,
          this.getFilterSharingCorner
        );
      }
      if (this.searchString.length > 0) {
        this.cardItemsFiltered = Search.posts(
          this.searchString,
          SortedFilteredCards
        );
      } else {
        this.cardItemsFiltered = SortedFilteredCards;
      }

      this.currentdisplayidx = 0;
      this.buttoncount = Math.ceil(
        this.cardItemsFiltered.length / this.getPageCount()
      );
      this.currentdisplay = this.currentdisplaycontentFiltered;
      this.currentdisplayisready = true;
      this.reloadPagination();
    },

    onClickedRead(value) {
      this.selectedPost = value;
      this.showRead = true;
    },

    init() {
      this.setFilters();
      this.setSorts();
      this.setState();
      this.buttoncount = Math.ceil(this.cardItems.length / this.getPageCount());
      this.currentdisplay = this.currentdisplaycontent;
      this.currentdisplayisready = true;
      this.reloadPagination();
      this.reloadFilters++;
    },

    getData() {
      gQLCaller(SHARING_CORNER_READ).then((result) => {
        this.cardItems = result.allSharingCorner.nodes;
        this.init();
      });
    },
    setFilters() {
      let filterdata = [];

      this.cardItems.filter((item) => {
        for (let i = 0; i < item.Filter.nodes.length; i++) {
          let f = item.Filter.nodes[i].name;

          if (!filterdata.includes(f)) {
            filterdata.push(f);
          }
        }
      });

      this.filters = filterdata;
    },
    setSorts() {
      this.sorts = [];
      this.sorts.push("Latest Posts"); //,'second option','third option')
      this.sortDefault = this.sorts[0];
    },
  },
  computed: {
    ...mapGetters(["getSortSharingCorner", "getFilterSharingCorner"]),
    currentdisplayrange() {
      return parseInt(this.currentdisplayidx) + parseInt(this.getPageCount());
    },
    currentdisplaycontent() {
      return this.cardItems.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
    currentdisplaycontentFiltered() {
      return this.cardItemsFiltered.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.m_sortfilter {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  z-index: 100;
  margin-left: -7px;
}
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.outerDiv {
  color: #fff;
  margin: 0px auto;
  padding: 5px;
}

.clearFilters p {
  font-weight: 400;
  text-decoration: underline;
  width: auto;
  font-family: "Isidora-SemiBold";
  font-size: 18px;
  margin-left: 30px;
  text-align: left;
}

.mobile_heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  left: 0px;
  background: #ffffff;
  border-bottom: 2px solid #e3e3e3;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  .back-btn {
    width: 36px;
    height: 18px;
  }
  .saved-mobile {
    margin-left: auto;
    margin-right: 10px;
    img {
      width: 14px;
      height: 20px;
    }
  }
  .main_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #14377c;
  }
  .main_fullstop {
    width: 4px;
    height: 4px;
    background: #ee3124;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 2px;
  }
}

.clearFilterButtonStyle:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(24%) sepia(37%) saturate(6249%)
    hue-rotate(351deg) brightness(86%) contrast(97%);
}

.clearFilters {
    padding-left: 40px;
    text-align: left;
    width: 100%;
    color:deepskyblue
}

.msearch {
  margin-top: 40px;
}

.readMoreWrapper {
  top: 0px;
  left: 0px;
}

.ReadContent {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
}

.heading {
  text-align: left;
  padding-left: 70px;
  padding-top: 50px;
}

.heading-desktop {
  text-align: left;
  padding-left: 70px;
  padding-top: 50px;
  margin-bottom: 1rem;
}
.heading-iPad {
  text-align: left;
  padding: 2% 3%;
}

.heading h1 {
  display: inline;
  font-size: 48px;
  font-family: "Isidora-Bold";
  color: #14377c;
}
.heading h2 {
  display: inline;
  font-size: 48px;
  font-family: "Isidora-Bold";
  color: #ee3124;
}
.leftDiv {
  color: #000;
  min-height: 500px;
  width: 25%;
  float: left;
}

.rightDiv-mobile {
  color: #000;
  height: 100%;
  min-height: 500px;
  width: 100%;
  float: right;
}
.rightDiv {
  color: #000;
  height: 100%;
  min-height: 500px;
  width: 75%;
  float: right;
  padding-top: 20px;
}

.pagination {
  display: flex;
  float: bottom;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pageWrap {
  padding-left: 3px;
  width: 100%;
  height: 100%;
}
.cards-col-mobile {
  padding-top: 20px;
  width: 98%;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-col-iPad {
  padding-top: 20px;
  width: 365px;
  margin-right: 12px;
}
.cards-col {
  padding-top: 20px;
  width: 375px;
  margin-right: 20px;
}
.cards-row-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 30px;
  width: 100%;
}
.saved {
  float: right;
  margin-right: 75px;
  margin-top: 12px;
  cursor: pointer;
}
.highlight {
    border: 2px solid orange;
    box-shadow: 0 0 10px orange;
    animation: boxanimate 1s 3;
}

@keyframes boxanimate {
  50% {box-shadow: 10px 10px 27px -12px rgba(0,0,0,0.75);}
}
</style>
