<template>
    <div class="comp">
        <component :is="blockHandler(block)" :block="block"/>
    </div>
</template>

<script>
import HeadingBlock from './blocks/HeadingBlock.vue'
import ParagraphBlock from './blocks/ParagraphBlock.vue'
import ImageBlock from './blocks/ImageBlock.vue'
import TableBlock from './blocks/TableBlock.vue'
import ListBlock from './blocks/ListBlock.vue'
import ButtonBlock from './blocks/ButtonBlock.vue'
import CoverBlock from './blocks/CoverBlock.vue'
import EmbedBlock from './blocks/EmbedBlock.vue'
import MediaTextBlock from './blocks/MediaTextBlock.vue'
import ColumnsBlock from './blocks/ColumnsBlock.vue'
import SpacerBlock from './blocks/SpacerBlock.vue'

export default {
    components: {
        HeadingBlock,
        ParagraphBlock,
        ImageBlock,
        TableBlock,
        ListBlock,
        ButtonBlock,
        CoverBlock,
        EmbedBlock,
        MediaTextBlock,
        ColumnsBlock,
        SpacerBlock
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    mounted() {

    },
    methods: {
        blockHandler(block) {
            switch(block.name) {
                case "core/heading":
                    return HeadingBlock
                case "core/paragraph":
                    return ParagraphBlock
                case "core/image":
                    return ImageBlock
                case "core/table":
                    return TableBlock
                case "core/list":
                    return ListBlock
                case "core/button":
                    return ButtonBlock
                case "core/buttons":
                    return ButtonBlock
                case "genesis-blocks/gb-button":
                    return ButtonBlock
                case "core/cover":
                    return CoverBlock
                case "core/embed":
                    return EmbedBlock
                case "core/media-text":
                    return MediaTextBlock
                case "core/columns":
                    return ColumnsBlock
                case "core/column":
                    return ColumnsBlock
                case "genesis-blocks/gb-column":
                    return ColumnsBlock
                case "genesis-blocks/gb-columns":
                    return ColumnsBlock
                case "core/spacer":
                    return SpacerBlock
                case "genesis-blocks/gb-spacer":
                    return SpacerBlock
            }
        }
    }
}

</script>

<style>

</style>
