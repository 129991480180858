<template>
  <div
    :class="
      isiPad()
        ? isUrgent
          ? 'post-cards-red sharing-iPad-red'
          : 'post-cards sharing-iPad'
        : isMobile()
        ? isUrgent
          ? 'post-cards-red sharing-mobile-red-news'
          : 'post-cards sharing-mobile-news'
        : isUrgent
        ? 'post-cards-red sharing-red'
        : 'post-cards sharing'
    "
  >
    <div class="card-content">
      <div class="card-header">
        <h4>{{ formatRawDate }}</h4>
        <div class="card-icons">
          <img
            v-show="hasAttachments"
            :src="getIcons.savecardIcon"
            alt="fav"
            class="card-icon icon savecard"
          />
          <img
            v-show="isRecent"
            :src="getIcons.flagIcon"
            alt="fav"
            class="card-icon icon flag"
          />
          <img
            v-show="isUrgent"
            :src="getIcons.redstarIcon"
            alt="fav"
            class="card-icon icon redstar"
          />
        </div>
      </div>
      <div class="card-body news">
        <span :class="isMobile() ? 'mheadline' : 'headline'">{{
          headline
        }}</span>
        <p :class="isMobile() ? 'msummary' : 'summary'">{{ cardSummary }}</p>
      </div>
      <div class="footer">
        <a target="_blank" @click="doReadMore()">
          <img :src="getIcons.readmoreIcon" alt="fav" class="read-more-btn" />
        </a>
        <a
          target="_blank"
          v-if="this.getSaveProfile"
          @click="saveThisItem(id)"
          class="save-btn"
        >
          <img
            :src="
              isSaved
                ? getIcons.savecardfilledIcon
                : getIcons.savecardoutlineIcon
            "
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import "@/styles/newsfeed-cards.scss";
import { convertDate } from "@/utils/Dates.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dateObj: new Date(),
      output: "",
      currentDate: "",
      attachmentsObj: Object,
      saveInfoReady: false,
    };
  },
  name: "CardNewsUpdates",
  props: {
    postedDate: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    cardSummary: {
      type: String,
      required: true,
    },
    attachments: {
      type: [Array, String],
      required: true,
    },
    story: {
      type: String,
      required: true,
    },
    isUrgent: {
      type: Boolean,
      required: true,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getSavedGetter", "getSaveProfile", "getDisplaySaved", "getHomePageDetails"]),
    getIcons() {
      return {
        pdfcardIcon: this.getHomePageDetails.pdfcardicon.mediaItemUrl,
        savecardIcon: this.getHomePageDetails.savecardicon.mediaItemUrl,
        savecardfilledIcon: this.getHomePageDetails.savefilledicon.mediaItemUrl,
        savecardoutlineIcon:
          this.getHomePageDetails.saveoutlineicon.mediaItemUrl,
        redstarIcon: this.getHomePageDetails.redstaricon.mediaItemUrl,
        flagIcon: this.getHomePageDetails.flagicon.mediaItemUrl,
        readmoreIcon: this.getHomePageDetails.readmoreicon.mediaItemUrl,
      };
    },
    displaySaved: function () {
      return this.getDisplaySaved;
    },
    isRecent: function () {
      return this.determineIfRecent();
    },
    hasAttachments: function () {
      if (this.attachments == "null") {
        this.attachmentsObj = [];
        return false;
      }
      this.attachmentsObj = this.attachments;
      return true;
    },
    isUrgentPost: function () {
      if (this.isUrgent) return true;
    },
    formatRawDate: function () {
      return convertDate(this.postedDate);
    },
    isSaved: function () {
        for (let i = 0; i < this.getSaveProfile.userPosts.length; i++) {
            if (this.getSaveProfile.userPosts[i].postId == this.id) {
                return true;
            }
        }
        return false;
    },
  },
  methods: {
    ...mapActions(["updateSaveProfile"]),
    isMobile() {
      if (
        /iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
          navigator.userAgent.toLowerCase()
        )
      ) {
        return this.isiPad() ? false : true;
      } else {
        return false;
      }
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    saveThisItem(id) {
        this.updateSaveProfile(id);
    },
    doReadMore() {
      this.$emit("readMore", {
        headline: this.headline,
        cardSummary: this.cardSummary,
        attachments: this.attachments,
        story: this.story,
      });
      window.scrollTo(0, 350);
    },
    determineIfRecent() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      const current = new Date(cYear, cMonth, cDay);

      const posted = new Date(this.postedDate);
      let difference = current.getTime() - posted.getTime();
      let TotalDaysDiff = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDaysDiff < 80;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/newsfeed-cards.scss";
</style>
