import Main from "@/components/Main";
import store from "@/store";

const trainingHubRoutes = [
    {
        path: '/learning-cart',
        meta: {requiresAuth: true},
        beforeEnter() {
            if(store.getters.getLearningCart !== null) {
                window.open(store.getters.getLearningCart);
                return;
            }
            window.open("https://sso.dayforcehcm.com/Savealot");
        }
    },
    {
        path: '/frequently-asked-questions',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'FAQs',
            pageTitle: "Frequently Asked Questions",
        }
    },
    {
        path: '/retail-resources',
        name: 'Retail Resources',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'RetailResources',
            pageTitle: "Retail Resources",
        }
    },
    {
        path: '/sharing-corner',
        name: 'Sharing Corner',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'SharingCorner',
            pageTitle: "Sharing Corner",
        }
    },
]


export default trainingHubRoutes;