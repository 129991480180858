const COUNT_QUERY = `query AllCounts {
  ImportantNewsCount: newsFeedTypes(where: {name: "Important News & Updates"}) {
    nodes {
      count
    }
  }
  DotCommCount: newsFeedTypes(where: {name: "Dot Comm Archives"}) {
    nodes {
      count
    }
  }
  WeeklyCheckoutCount: newsFeedTypes(where: {name: "Weekly Checkout Archives"}) {
  	nodes {
    	count
    }
  }
  RecallsCount: newsFeedTypes(where: {name: "Recalls"}) {
  	nodes {
    	count
    }
  }
  WebinarsCount: newsFeedTypes(where: {name: "Webinars"}) {
  	nodes {
    	count
    }
  }
  RetailResourceCount: trainingHubTypes(where: {name: "Retail Resources"}) {
  	nodes {
    	count
    }
  }
  FAQsCount: trainingHubTypes(where: {name: "FAQs"}) {
  	nodes {
    	count
    }
  }
  SharingCornerCount: trainingHubTypes(where: {name: "Sharing Corner"}) {
  	nodes {
    	count
    }
  }
}
`;
export { COUNT_QUERY };