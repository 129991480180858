<template>
  <div class="outerDiv">
    <div :class="isMobile() ? 'header-m' : 'header'">
      <div :class="isMobile() ? 'selectHeader-m' : 'selectHeader'" @click="navigateHome">
        <img
          :src="this.getPagedetails().salLogo"
          alt="SAL logo"
        />
        <h1>{{ this.getPagedetails().appTitle }}</h1>
      </div>
    </div>
    <div class="separator">
      <hr />
    </div>
    <span class="profileSection">
      <h1 :class="isMobile() ? 'profileLabel-m' : 'profileLabel'">Your Profile</h1>
      <div :class="isMobile() ? 'profileInfo-m' : 'profileInfo'">
        <img :src="imgSrc" alt="Profile Picture" />
        <div class="info">
          <h3>{{ renderUser.displayName }}</h3>
          <h4>{{ renderUser.jobTitle }}</h4>
          <h4>{{ renderUser.officeLocation }}</h4>
        </div>
      </div>
      <div :class="isMobile() ? 'bioSection-m' : 'bioSection'">
        <span class="bio">
          <textarea
            cols="33"
            rows="5"
            id="bio"
            placeholder="Please enter a bio here"
            @click="showSaveBtn"
            v-text="removeHtmlTags(renderBio)"
          ></textarea>
        </span>
        <button
          type="submit"
          v-if="showButtons"
          id="saveBtn"
          class="saveButton"
          @click="wpProfileHandler"
        >
          Save
        </button>
        <button
          type="submit"
          v-if="showButtons"
          id="canclBtn"
          class="cancelButton"
          @click="cancelBtn"
        >
          Cancel
        </button>
      </div>
    </span>
    <div class="cardSeparator">
      <hr />
    </div>
    <div :class="isMobile() ? 'cards-m' : 'cards'">
      <div :class="isMobile() ? 'reset-pass-card-m' : 'reset-pass-card'">
        <ProfileReset />
      </div>
      <div :class="isMobile() ? 'sign-out-card-m' : 'sign-out-card'">
        <profile-sign-out />
      </div>
    </div>
    <Footer class="rp-footer" :pageDetails="getPagedetails()" />
  </div>
</template>

<script>
import ProfileReset from "./module/cards/ProfileReset.vue";
import ProfileSignOut from "./module/cards/ProfileSignOut.vue";
// import  PointOfContacts from "./module/cards/PointOfContacts.vue";
import { mapGetters, mapActions } from "vuex";
import Footer from "./Footer.vue";

export default {
  data() {
    return {
      blobUrl: false,
      shouldUpdate: false,
      postID: "",
      showButtons: false,
    };
  },
  mounted() {
    this.viewWPProfile();
    this.getProfilePicture();
  },
  components: {
    ProfileReset,
    ProfileSignOut,
    // PointOfContacts,
    Footer,
  },
  computed: {
    ...mapGetters(["getProfileInfo", "getSaveProfile", "getHomePageDetails"]),
    imgSrc() {
      if (this.blobUrl) {
        return this.getProfileInfo.profileImg;
      } else {
        return this.getPagedetails().profileIcon;
      }
    },
    renderBio() {
      return this.getSaveProfile.bio;
    },
    renderUser() {
      return this.getProfileInfo.profile;
    },
  },
  methods: {
    ...mapActions([
      "getProfile",
      "createSaveProfile",
      "updateSaveProfile",
      "viewSaveProfile",
    ]),
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    getPagedetails() {
        return  {
                  appTitle: this.getHomePageDetails.applicationTitle,
                  salLogo: this.getHomePageDetails.sallogo.mediaItemUrl,
                  profileIcon: this.getHomePageDetails.profileiconplaceholder.mediaItemUrl,
                  facebookIcon: this.getHomePageDetails.facebookicon.mediaItemUrl,
                  twitterIcon: this.getHomePageDetails.twittericon.mediaItemUrl,
                  linkedinIcon: this.getHomePageDetails.linkedinicon.mediaItemUrl,
                  tiktokIcon: this.getHomePageDetails.tiktokicon.mediaItemUrl,
                  instagramIcon: this.getHomePageDetails.instagramicon.mediaItemUrl,
                  yotubeIcon: this.getHomePageDetails.youtubeicon.mediaItemUrl,
                  privacyPolicy: this.getHomePageDetails.privacyPolicy,
                  termsOfUse: this.getHomePageDetails.termsOfUse,
                  coronaResources: this.getHomePageDetails.coronaResources,
                  contactUs: this.getHomePageDetails.contactUs,
                  ownaSal: this.getHomePageDetails.ownaSal,
                };
    },
    getProfilePicture() {
      if (
        this.getProfileInfo.profileImg != null &&
        this.getProfileInfo.profileImg != ""
      ) {
        if (!(this.getProfileInfo.profileImg instanceof Blob)) {
          this.getProfile();
          setTimeout(() => {
            this.blobUrl = true;
          }, 1000);
        } else {
          this.blobUrl = true;
        }
      } else {
        this.blobUrl = false;
      }
    },
    viewWPProfile() {
      if (!this.getSaveProfile) {
        this.viewSaveProfile(this.getProfileInfo.profile.id);
      }
    },
    wpProfileHandler() {
        try {
            this.updateSaveProfile({ 'id': this.getSaveProfile.userId, 'bio': document.getElementById('bio').value });
        } catch (error) {
            console.log("ERROR - Profile bio was unable to update.");
        }
        this.showButtons = false;
    },
    navigateHome() {
      this.$router.push({ name: "Main" });
    },
    showSaveBtn() {
      this.showButtons = true;
    },
    cancelBtn() {
      this.showButtons = false;
    },
    removeHtmlTags(str) {
      return str === null || str === undefined
        ? ""
        : str.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Isidora-Bold";
  src: url("../assets/fonts/Isidora/Isidora-Bold.otf");
}

@font-face {
  font-family: "Isidora-SemiBold";
  src: url("../assets/fonts/Isidora/Isidora-SemiBold.otf");
}

@font-face {
  font-family: "Isidora Sans Medium";
  src: url("../assets/fonts/IsidoraSans/Isidora Sans Medium.otf");
}

.outerDiv {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  float: left;
  width: 100%;
}

.header {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 100px;
  width: 100%;
  padding-bottom: 20px;
  padding-left: 70px;
}
.header-m {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 100px;
  width: 100%;
  padding-bottom: 20px;
  padding-left: 20px;
}

.selectHeader img {
  display: inline;
  width: 75px;
  height: 75px;
}
.selectHeader-m img {
  display: inline;
  width: 50px;
  height: 50px;
}


.selectHeader h1 {
  display: inline;
  padding-top: 20px;
  font-family: "Isidora-Bold";
  padding-left: 20px;
  width: fit-content;
  word-wrap: normal;
  font-size: 32px;
  color: #14377c;
}
.selectHeader-m h1 {
  display: inline;
  padding-top: 20px;
  font-family: "Isidora-Bold";
  padding-left: 20px;
  width: fit-content;
  word-wrap: normal;
  font-size: 20px;
  color: #14377c;
}

.selectHeader {
  cursor: pointer;
  display: flex;
  height: 100%;
}

.separator hr {
  width: 100%;
  border: none;
  height: 50px;
  margin-top: 0;
  border-bottom: 2px solid #787878;
  box-shadow: 0 15px 15px -20px #333;
  margin: -50px auto 10px;
}

.profileSection {
  display: block;
  height: 100%;
  width: 100%;
  padding-bottom: 0px;
  padding-left: 0px;
}

.bioSection {
  display: inline-block;
  margin-left: 0px;
  float: left;
  margin-top: 20px;
  width: 55%;
  height: auto;
}
.bioSection-m {
  display: inline-block;
  margin-left: 20px;
  float: left;
  margin-top: 20px;
  width: 75%;
  height: auto;
}

.bio textarea {
  width: 100%;
  height: 118px;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  padding: 12px 20px;
  vertical-align: middle;
  margin-top: 10px;
  resize: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px 2px #fff;
  color: #14377c;
  min-width: 200px;
  font-family: "Isidora Sans Medium";
}

.bioSection textarea:focus {
  outline: 1px solid #dfdfdf;
}

.bio {
  width: 100%;
}

.saveButton {
  margin-top: 10px;
  margin-left: 15px;
  width: 125px;
  height: 30px;
  text-align: center;
  background-color: #14377c;
  color: white;
  float: left;
  font-family: "Isidora-SemiBold";
  font-size: 18px;
  border-radius: 20px;
  border: none;
}

.cancelButton {
  margin-top: 10px;
  margin-left: 10px;
  width: 125px;
  height: 30px;
  text-align: center;
  background-color: #14377c;
  color: white;
  float: left;
  font-family: "Isidora-SemiBold";
  font-size: 18px;
  border-radius: 20px;
  border: none;
}

.profileInfo {
  float: left;
  display: inline-block;
  margin-left: 70px;
  margin-top: 30px;
  width: 35%;
  min-width: 300px;
  height: 100%;
}
.profileInfo-m {
  float: left;
  display: inline-block;
  margin-left: 20px;
  margin-top: 30px;
  width: 35%;
  min-width: 300px;
  height: 100%;
}

.profileLabel {
  font-family: "Isidora-SemiBold";
  font-size: 32px;
  color: #14377c;
  width: 211px;
  height: 58px;
  line-height: 58px;
  border-radius: 50px;
  background: #fce300;
  text-align: center;
  margin-left: 70px;
  margin-top: 25px;
}
.profileLabel-m {
  font-family: "Isidora-SemiBold";
  font-size: 24px;
  color: #14377c;
  width: 175px;
  height: 50px;
  line-height: 58px;
  border-radius: 50px;
  background: #fce300;
  text-align: center;
  margin-left: 20px;
  margin-top: 25px;
}

.profileInfo img {
  float: left;
  width: 118px;
  height: auto;
  border-radius: 50%;
  border: 2px solid #14377c;
  margin-right: 15px;
}
.profileInfo-m img {
  float: left;
  width: 118px;
  height: auto;
  border-radius: 50%;
  border: 2px solid #14377c;
  margin-right: 15px;
}

.info {
  display: inline;
  float: left;
  margin-left: 10px;
  text-align: left;
  margin-top: 15px;
  overflow: hidden;
}

.info h3 {
  font-family: "Isidora-Bold";
  font-size: 32px;
  color: #14377c;
  margin-bottom: 0px;
}

.info h4 {
  font-family: "Isidora-SemiBold";
  font-size: 20px;
  color: #ee2e24;
  margin-bottom: 0px;
}

.headline {
  padding: 10px;
  margin-top: 50px;
}

.story {
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 20px;
}

.img {
  float: left;
  padding: 10px;
}

.cardSeparator hr {
  width: 100%;
  border: none;
  height: 50px;
  margin-top: 0px;
  border-bottom: 2px solid #00afd7;
  border-color: #00afd7;
}

.cards {
  width: 100%;
  margin-left: 50px;
}
.cards-m {
  width: 100%;
}

.reset-pass-card {
  display: inline-block;
  width: 375px;
  float: left;
  height: 380px;
  margin-left: 20px;
  margin-top: 40px;
}

.sign-out-card {
  display: inline-block;
  float: left;
  width: 490px;
  height: 100%;
  margin-left: 20px;
  margin-top: 40px;
}

.reset-pass-card-m {
  display: inline-block;
  width: 90%;
  float: left;
  margin-left: 20px;
  margin-top: 40px;
}

.sign-out-card-m {
  display: inline-block;
  float: left;
  width: 90%;
  margin-left: 20px;
  margin-top: 40px;
}

.poc-card {
  display: inline-block;
  float: left;
  width: 490px;
  height: 100%;
  margin-left: 20px;
  margin-top: 40px;
}
.rp-footer {
  width: 100%;
  height: 100%;
  padding-top: 40px;
}
</style>