<template>
    <div class="outer" v-if="showSearching">
        <div class="search">
            <img @click="openInput()" v-if="!showInput" src="../../../assets/images/search.svg" alt="search" class="search-img"/>
        </div>
        <div class="mobileSearch" v-if="showInput">
            <hr class="top-separator"/>
            <input type="text" placeholder="SEARCH" class="search-input">
            <img @click="handleSearch()" src="../../../assets/images/search.svg" alt="search" class="input-icon"/>
            <img @click="goBack()" src="../../../assets/images/back_btn.svg" alt="back" class="back-icon"/>
            <div class="background" v-if="showInput"/>
            <hr class="bot-separator"/>
        </div>
    </div>
</template>

<script>
import { SEARCH_QUERY } from '../../../queries/gqlQueries';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showInput: false,
            showSearching: true,
        }
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    this.handleSearch();
                }
            });
        });
    },
    methods: {
        ...mapActions(['getSearch']),
        openInput() {
            this.showInput = true;
            this.$emit('mobileInput', true);
        },
        handleSearch() {
            let searchValue = document.querySelector('.search-input').value
            if(searchValue) {
                let payload =
                {
                    query: SEARCH_QUERY,
                    vars: {
                        searchTerm: searchValue
                    }
                }
                this.getSearch(payload).then(() => {
                    if(this.$route.name !== 'mAllResults') {
                        this.showSearching = false;
                        this.$router.push({name: 'mAllResults'});
                        document.querySelector('.search-input').value = ''
                        return;
                    } else {
                        this.showSearching = false;
                        document.querySelector('.search-input').value = '';
                        return;
                    }
                });
            }
        },
        goBack() {
            this.showInput = false;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.search {
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

input[type="text"]::placeholder {
    color: #7387B2;
    line-height: 24px;
    padding-right: 20px;
}

.showInput {
    display: block;
    background-color: white;
    height: 100%;
    width: 100%;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1;
}

.search-input:focus {
    outline: none;
    border: none;
    color: $salBlue;
}

.search-input {
    padding-right: 55px;
    box-sizing: border-box;
}

.search-input {
    font-family: "Isidora-SemiBold";
    font-weight: 600px;
    color: $salBlue;
    line-height: 16px;
    font-size: 16px;
    border: none;
    background-color: $salGrey;
    border-radius: 30px;
    padding-left: 20px;
}

.search-input {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 35px;
    margin-left: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-icon {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobileSearch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
}

.back-icon {
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 2;
}

.top-separator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 20px;
    background-color: $salGrey;
    color: $salGrey;
    height: 2px;
    border: none;
    z-index: 2;
    opacity: 1;
}

.bot-separator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 83px;
    background-color: $salGrey;
    color: $salGrey;
    height: 2px;
    border: none;
    z-index: 2;
    opacity: 1;
}



</style>