<template>
    <div class="outerDiv">
        <span class="heading">
            <h1>Calendar</h1>
            <h2>.</h2>
        </span>
        <div class="content">
            <div id="innerDiv">
                <iframe :src="url" id="inner-frame" style="calendar" scrolling="no"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            url: String,
        }
    },
    mounted() {
        this.getLink();
    },
    computed: {
        ...mapGetters([ "getRouteParam" ]),
    },
    methods: {
        ...mapMutations([ "saveRouteParams" ]),
        getLink() {
            if(this.getRouteParam == null) {
                this.saveRouteParams(this.$route.params);
                this.url = this.$route.params.url;
            } else {
                this.url = this.getRouteParam.url;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.outerDiv {
    margin-left: 0px;
    text-align: left;
    margin-top: 60px;
}

.heading {
    padding-left: 70px;
    padding-top: 20px;
}

.heading h1 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #14377C;
    margin-bottom: 0px;
    text-align: left;
}

.heading h2 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0px;
    text-align: left;
}

iframe {
    width: 100%;
    height: 1700px;
    border: none;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
}

.calendar {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
}

#innerDiv
{
width:100%;
height:2000px;
overflow:hidden;
position:relative;
}

#inner-frame
{
position:absolute;
top: 0;
width:100%;
height: 2500px;
}

</style>