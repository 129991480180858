<template>
    <div class="outer">
        <div class="mobileSearching">
            <hr class="top-separator"/>
            <input type="text" placeholder="SEARCH" class="search-input">
            <img @click="handleSearch()" src="../../../assets/images/search.svg" alt="search" class="input-icon"/>
            <img @click="goBack()" src="../../../assets/images/back_btn.svg" alt="back" class="back-icon"/>
            <div class="background-results"/>
            <hr class="bot-separator"/>
        </div>
        <div class="breadcrumbs">
            <h1 @click="goBack()">Retail Partner Connect </h1>
            <h2> / </h2>
            <h2>{{searchParams}}</h2>
        </div>
        <div class="no-results" v-if="empty">
            <h1>No Results Found!</h1>
        </div>
        <div class="results-found" v-if="!empty">
            <h1>{{resultCount}} Results Found</h1>
            <hr/>
        </div>

        <div class="results"  v-for="result in searchResults" :key="result.key">
            <div v-if="result.key != 'FAQs' && result.key != 'WeeklyCheckoutArchives' && result.key != 'Recalls'" @click="navigationHandling(result.key.replace(/([a-z])([A-Z])/g, '$1 $2'), item)" v-for="item in result.nodes">
                <h3>{{result.key.replace(/([a-z])([A-Z])/g, '$1 $2')}}</h3>
                <h2>{{item.headline}}</h2>
                <h2 v-if="result.key.includes('RetailResources')">{{item.pDFTitle}}</h2>
                <h2 v-if="result.key.includes('FAQ')">{{item.question}}</h2>
                <hr/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SEARCH_QUERY } from '../../../queries/gqlQueries';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            searchResults: [],
            searchParams: "",
            empty: true,
            resultCount: 0,
        }
    },
    computed: {
        ...mapGetters(['getSearchResult', 'getSearchParams']),
        getResult() {
            return this.getSearchResult;
        },
    },
    methods: {
        ...mapActions(['getSearch']),
        emptyCheck(){
            this.empty = true;
            Object.entries(this.searchResults).forEach(key => {
                if(key[1].nodes.length > 0){
                    this.empty = false;
                    key[1].key = key[0];
                }
            });
        },
        navigationHandling(key, item) {
            if(key == "Important News And Updates") {
                document.body.style.overflow = "visible";
                this.$router.push({ name: 'Important News and Updates', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Dot Comm Archives") {
                document.body.style.overflow = "visible";
                this.$router.push({ name: 'Dot Comm Archives', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Webinars") {
                document.body.style.overflow = "visible";
                this.$router.push({ name: 'Webinars', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Sharing Corner") {
                document.body.style.overflow = "visible";
                this.$router.push({ name: 'Sharing Corner', params:{ headline: item.headline, story: item.story }});
            }
            if(key == "Retail Resources") {
                document.body.style.overflow = "visible";
                this.$router.push({ name: 'Retail Resources', params:{ item: item }});
            }
        },
        countResults() {
            this.resultCount = 0;
            Object.entries(this.searchResults).forEach(key => {
                if(key[1].key != 'FAQs' && key[1].key != 'WeeklyCheckoutArchives' && key[1].key != 'Recalls') {
                    key[1].nodes.forEach(node => {
                        if(node) {
                            this.resultCount++;
                        }
                    });
                }
            });
        },
        handleSearch() {
            document.body.style.overflow = "visible";
            let searchValue = document.querySelector('.search-input').value
            if(searchValue) {
                let payload =
                {
                    query: SEARCH_QUERY,
                    vars: {
                        searchTerm: searchValue
                    }
                }
                this.getSearch(payload).then(() => {
                    this.searchResults = this.getSearchResult;
                    this.searchParams = searchValue;
                    this.emptyCheck();
                    this.countResults();
                });
            }
        },
        goBack() {
            document.body.style.overflow = "visible";
            this.$router.push({ name: 'Main' });
        },
    },
    mounted() {
        document.body.style.overflow = "visible";
        this.searchResults = this.getSearchResult;
        this.searchParams = this.getSearchParams;
        this.emptyCheck();
        this.countResults();
        document.querySelector('.search-input').addEventListener('keyup', (e) => {
            if (e.keyCode === 13) {
                this.handleSearch();
            }
        });
    },
    watch: {
        getResult() {
            this.searchResults = this.getSearchResult;
            this.searchParams = this.getSearchParams;
            this.emptyCheck();
            this.countResults();
        }

    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.outer {
    margin-left: 23px;
    margin-right: 23px;
}

.search-input:focus {
    outline: none;
    border: none;
    color: $salBlue;
}

.search-input {
    box-sizing: border-box;
}

.search-input {
    font-family: "Isidora-SemiBold";
    font-weight: 600px;
    color: $salBlue;
    line-height: 16px;
    font-size: 16px;
    border: none;
    background-color: $salGrey;
    border-radius: 30px;
    width: 85vw;
    height: 35px;
    padding-left: 20px;
}

.search-input {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 35px;
    margin-left: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-icon {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 40px;
    margin-left: 10px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.mobileSearching {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 85px;
    background-color: white;
}

.back-icon {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 41px;
    margin-left: 13px;
    cursor: pointer;
    z-index: 2;
    width: 25px;
    height: 25px;
}

.top-separator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 20px;
    background-color: $salGrey;
    color: $salGrey;
    height: 2px;
    border: none;
    z-index: 2;
    opacity: 1;
}

.bot-separator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 83px;
    background-color: $salGrey;
    color: $salGrey;
    height: 2px;
    border: none;
    z-index: 2;
    opacity: 1;
}

.search-box {
    position: relative;
    font-family: "Isidora-SemiBold";
    font-weight: 600px;
    color: $salBlue;
    line-height: 24px;
    font-size: 20px;
    border: 2px solid $salBlue;
    border-radius: 30px;
    width: 50vw;
    height: 60px;
    padding-left: 20px;
    padding-right: 60px;
    box-sizing: border-box;
    background: url(../../../assets/images/search.svg) no-repeat right;
    background-size: 25px;
    background-position-x: calc(100% - 30px);

}

.search-box:focus {
    outline: none;
    border: 2px solid $salBlue;
    color: $salBlue;
}

input[type="text"]::placeholder {
    color: #7387B2;
    line-height: 24px;
    padding-right: 20px;
}

.no-results {
    text-align: left;
    h1 {
        color: $salRed;
        font-family: "Isidora-Bold";
        font-size: 18px;
    }
}

.results {
    margin-bottom: 0px;
    margin-top: 0px;
    cursor: pointer;

    h2 {
        color: $salBlue;
        font-family: "Isidora-Bold";
        font-size: 22px;
        margin-top: 0px;
        text-align: left;
    }
    h3 {
        color: $navBlue;
        font-family: "Isidora Sans";
        font-weight: 800;
        font-size: 18px;
        margin-top: 0px;
        text-align: left;
    }
}

.breadcrumbs {
    display: block;
    text-align: left;
    margin-top: 100px;
    padding-bottom: 20px;
    h1 {
        display: inline-block;
        color: $navBlue;
        font-family: "Isidora-Bold";
        font-size: 18px;
        cursor: pointer;
        padding-right: 5px;
    }
    h2 {
        display: inline-block;
        color: $salLightGrey;
        font-family: "Isidora-Bold";
        font-size: 18px;
        padding-left: 5px;
    }
}

.results-found {
    h1 {
        color: $salRed;
        font-family: "Isidora-Bold";
        font-size: 22px;
        margin-top: 0px;
        text-align: left;
    }
}

hr {
    color: $salBlue;
    background-color: $salBlue;
    border: none;
    height: 1px;
    opacity: 1;
}

.background-results {
    height: 85px;
}
</style>