<template>
    <div v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'SpacerBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";



</style>