import Main from "@/components/Main";

const supportServicesRoutes = [
    {
        path: '/marketing',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'Marketing',
            pageTitle: "Marketing",
        }
    },
    {
        path: '/operations',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'Operations',
            pageTitle: "Operations",
        }
    },
    {
        path: '/remodel-resources',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'Remodel',
            pageTitle: "Remodel",
        }
    },
    {
        path: '/hr',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'HR',
            pageTitle: "HR",
        }
    },
]


export default supportServicesRoutes