export async function filterMenu(app) {
    let appDetails = [];

    app.forEach((item) => {
        if (item.appdetails != null) {
            if(item.appdetails.appStartingUrl != null){
                if (!item.appdetails.appStartingUrl.includes("portal.savealot")) {
                    appDetails.push(item.appdetails);
                }
            }
        }
    });

    //remove items that have duplicate appStartingUrl
    const unique = appDetails.filter((thing, index, self) =>
        index === self.findIndex((t) => t.appStartingUrl === thing.appStartingUrl)
    );

    if (appDetails.length > 0) {
        return unique;
    }
}