import Utils from "@/utils/Utils";
import {COMMON_LINKS_QUERY} from "@/queries/gqlQueries";


const getAdditionalLinksData = async (context, payload) =>
{
    context.commit("MENU_LOADING_UPDATE", true);
    let username = context.rootState.userName;
    const request = {
        environment: Utils.Strings.environment,
        userName: username
    };
    await context.dispatch('fetchMenu', request, {root:true})
        .then(() => {
            let threads = 0;
            context.rootState.ProfileApi.menuList.forEach((menu) => {
                const req = {
                    environment: Utils.Strings.environment,
                    businessType: menu.salmenu,
                    userName: request.userName,
                };
                threads = threads + 1;
                context.dispatch('fetchSubMenu', req,  {root:true}).then((res) => {
                    threads = threads - 1;
                    if (threads === 0) {
                        context.dispatch('fetchCommonLinks', COMMON_LINKS_QUERY,  {root:true}).then(() => {
                            context.dispatch('fetchFavoritesList', request.userName,  {root:true})
                                .then(() => {
                                    context.commit("VISIBLE_COMMON_LINKS_CHANGED", context.state.commonLinks)
                                    context.commit("MENU_LOADING_UPDATE", false);
                            });
                        });
                    }
                });
            })
        })
        .catch(err => {
            console.error("this is an error", err);
        })
}
export default {
    getAdditionalLinksData,
}