<template>
    <div class="search">
        <input type="text" placeholder="SEARCH" class="search-input-desk">
        <img @click="handleSearch" src="../../../assets/images/search.svg" alt="search" class="search-icon"/>
    </div>
</template>

<script>
import { SEARCH_QUERY } from '../../../queries/gqlQueries';
import { mapActions } from 'vuex';

export default {
    data() {
        return {

        }
    },
    mounted() {
        document.querySelector('.search-input-desk').addEventListener('keyup', (e) => {
            if (e.keyCode === 13) {
                this.handleSearch();
            }
        })
    },
    methods: {
        ...mapActions(['getSearch']),
        handleSearch() {
            document.querySelector('.search-input-desk').classList.add('search-input-active');
            document.querySelector('.search-icon').classList.add('search-icon-active');
            let searchValue = document.querySelector('.search-input-desk').value
            if(searchValue) {
                let payload =
                {
                    query: SEARCH_QUERY,
                    vars: {
                        searchTerm: searchValue
                    }
                }
                this.getSearch(payload).then(() => {
                    if(this.$route.name !== 'AllResults') {
                        this.$router.push({name: 'AllResults'});
                        return;
                    } else {
                        return;
                    }
                });
            }
        }
    },
    watch: {
        '$route.path': {
            handler: function() {
                if(this.$route.name !== "AllResults") {
                    document.querySelector('.search-input-desk').classList.remove('search-input-active');
                    document.querySelector('.search-icon').classList.remove('search-icon-active');
                    document.querySelector('.search-input-desk').value = '';
                }
            },
            deep: true
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.search {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.search-input-desk {
    font-family: "Isidora-SemiBold";
    font-weight: 600px;
    color: $salBlue;
    line-height: 24px;
    font-size: 20px;
    border: 2px solid $salBlue;
    border-radius: 30px;
    width: 250px;
    height: 60px;
    padding-left: 20px;
}

.search-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    margin-left: 200px;
    cursor: pointer;
}

.search-input-desk:focus {
    outline: none;
    border: 2px solid $salBlue;
    color: $salBlue;
}

.search-input-active:focus {
    outline: none;
    border: 2px solid $salRed;
    color: $salRed;
}

.search-input-desk {
    padding-right: 55px;
    box-sizing: border-box;
}

input[type="text"]::placeholder {
    color: #7387B2;
    line-height: 24px;
    padding-right: 20px;
}

.m-search {
    width: 25px;
    height: 25px;
    position: absolute;
    margin-left: 200px;
    cursor: pointer;
}

.search-input-active {
    border: 2px solid $salRed;
    color: $salRed;
}

.search-icon-active {
    img {
        filter: invert(38%) sepia(100%) saturate(3138%) hue-rotate(341deg) brightness(88%) contrast(113%);
    }
}
</style>