<template>
    <div class="outerDiv">
      <div class="header">
          <div class="selectHeader" @click="navigateHome">
              <img src='../../assets/sal_logo.png' alt="SAL logo">
              <h1>Retail Partner Connect</h1>
          </div>
      </div>
      <div class="separator">
          <hr>
      </div>
    <div class="poc-body">
    <b-row no-gutters class="poc-header">
      <p>Points Of Contact<span>.</span></p>
      <h2 class="go-back" @click="goBack()"> {{goback}} </h2>
    </b-row>
    <b-row class="search">
      <b-input-group class="mt-3">
        <template #append>
          <b-input-group-text
            ><img src="../../assets/images/search.svg" alt="search"
          /></b-input-group-text>
        </template>
        <b-form-input placeholder="Search"></b-form-input>
      </b-input-group>
    </b-row>
    <b-row
      no-gutters
      class="list"
      v-for="(dept, index) in getCommonPocList"
      :key="index"
    >
      <p class="poc-dept">{{ dept.department }}</p>
      <div class="poc-list-container"  no-gutters>
        <b-col
          class="poc-card"
          v-for="(poc, index) in dept.pocList"
          :key="index"
        >
          <b-col class="pic-container">
            <img class="pic" :src="getImageName(poc.contactImage)" alt="Sal" />
          </b-col>
          <div class="details">
            <p class="name">{{ poc.contactName }}</p>
            <p class="role">{{ poc.contactTitle }}</p>
            <p class="email">{{ poc.contactEmail }}</p>
            <p class="contact-number">{{ poc.contactPhone }}</p>
          </div>
        </b-col>
      </div>
    </b-row>
  </div>
  <Footer class="rp-footer"/>
  </div>
</template>
<script>
import { POINT_OF_CONTACT_QUERY } from "../../queries/gqlQueries";
import { mapActions, mapGetters } from "vuex";
import Footer from "../Footer.vue";
import Utils from "../../utils/Utils";
import axios from "axios";

export default {
  data() {
    return {
      goback: Utils.Strings.gobackTitle,
    };
  },
  components: {
    Footer,
  },
  beforeDestroy() {
    this.$store.commit("resetState");
  },
  created() {
    this.fetchPocData();
  },
  computed: {
    ...mapGetters(["getCommonPocList"]),
  },
  methods: {
    ...mapActions(["fetchPointOfContactsListWithParams"]),
    goBack() {
      this.$router.push("/profile");
    },
    navigateHome() {
        this.$router.push({ name: 'Main' });
    },
    getImageName(media) {
        if (media === null) {
            return '';
        }
         return media.mediaItemUrl === null ? "" : media.mediaItemUrl;
    },
    async fetchPocData() {
        const departmentList = [
          "Chief",
          "Fresh Merchandising Team",
          "Produce",
          "Packaged Frozen & Refrigerated Meat",
          "Regional Sales Team",
          "Center Store Merchandising Team",
          "Quality Assurance Team",
          "DSP & Merchandising Services",
          "Merchandising Planning & Data Integrity",
          "Space Planning",
        ];
        const request = { query: POINT_OF_CONTACT_QUERY, params: departmentList };
        await this.fetchPointOfContactsListWithParams(request);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/_shared.scss";

.outerDiv {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    float: left;
    width: 100%;
}

.header {
      display: flex;
      justify-content: left;
      align-items: left;
      height: 100px;
      width: 100%;
      padding-bottom: 20px;
      padding-left: 70px;
}

.selectHeader img {
    display: inline;
    width: 75px;
    height: 75px;
}

.selectHeader h1 {
    display: inline;
    padding-top: 20px;
    font-family: "Isidora-Bold";
    padding-left: 20px;
    width: fit-content;
    word-wrap: normal;
    font-size: 32px;
    color: #14377c;
}

.selectHeader {
    cursor: pointer;
    display: flex;
    height: 100%;
}

.separator hr {
    width: 100%;
    border:none;
    height: 20px;
    height: 50px;
    margin-top: 0;
    border-bottom: 2px solid #787878;
    box-shadow: 0 15px 15px -20px #333;
    margin: -50px auto 10px;
}

.poc-body {
  background-color: white;
  margin: 10px 75px;

  .poc-header {
    color: $salBlue;
    font-weight: 700;
    font-size: 48px;
    font-family: "Isidora-Bold";
    line-height: 58px;
    padding-top: 50px;
    text-align: left;

    span {
      color: $salRed;
      font-weight: 900;
    }
  }
.go-back{
    font-family: 'Isidora-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #00AFD7;
    cursor: pointer;
}

  .search {
    width: 100%;
    margin-bottom: 25px;
    .input-group {
      // border: 0.5px solid $salBlue;
      // border-radius: 20px;
      background-color: white;
    }
    .form-control {
      border: none;
      border: 0.5px solid $salBlue;
      border-radius: 20px;
      border-right: none;
    }
    .input-group-append {
      margin-left: -4px !important;
    }
    .input-group-text {
      border-left: none !important;
      border: 0.5px solid $salBlue;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: white;
    }
  }

  .poc-dept {
    color: $salBlue;
    font-weight: 700;
    font-size: 32px;
    font-family: "Isidora-Bold";
    line-height: 38px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 2rem;
  }

  .poc-card {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: fit-content;

    .pic-container {
      margin-right: 10px;
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;

      .pic {
        height: 120px;
      width: 120px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: stretch;
      align-items: center;
      height: 120px;
      // width: max-content;

      p {
        width: 100%;
        // height: fit-content;
        text-align: left;
        margin-bottom: 0rem;
      }

      .name {
        color: $salBlue;
        font-size: 24px;
        font-weight: 600;
        line-height: 18px;
        margin: 5px auto;
        font-family: "Isidora";
      }

      .role {
        color: $navBlue;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        font-family: "Isidora Sans";
        padding-bottom: 2px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .email, .contact-number {
        font-family: "Isidora Sans";
        color: $salBlue;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    p {
      text-align: left;
    }

    .pic {
      width: 120px;
      height: 120px;
      align-self: center;
      border-radius: 10px;
      border: $salBlueBorder;
    }
  }
}

.rp-footer {
    height: 100%;
    width: 100%;
    padding-top: 20px;
}

@media screen and (min-width: 1800px) {
  .poc-list-container {
    column-count: 4;
  }
}
@media screen and (min-width: 1280px) {
  .poc-list-container {
    column-count: 2;
  }
}

@media screen and (min-width: 1080px) {
  .poc-list-container {
    column-count: 2;
  }
}
</style>
