<template>
    <div v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'ButtonBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

::v-deep a {
    color: $salBlue;
    width: auto;
    height: auto;
    line-height: auto;
    font-family: "Isidora-SemiBold";
    border-color: $salSmoke;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    display: inline-block;
    margin: 10px 0;
    cursor: pointer;
}

</style>