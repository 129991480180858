export default {
  getWebinarsList(state) {
    return state.webinars;
  },
  getTopicsList(state) {
    return state.topics;
  },
  getCommonLinks(state) {
    return state.commonLinks;
  },
  getMarketingPoC(state) {
    return state.pocList;
  },
  getHrPocList(state) {
    return state.hrPocList;
  },
  getWCArchives(state) {
    return state.weeklyCheckoutArchives;
  },
  getCommonPocList(state) {
    return state.commonPOCList;
  },
  getResources(state) {
    return state.dResources;
  },
  getMarketingDetails(state) {
    return state.marketingPageInfo;
  },
  getHrDetails(state) {
    return state.hrPageInfo;
  },
  getNavMenu(state) {
    return state.navMenu;
  },
  getRetailResources(state) {
    return state.retailRes;
  },
  getLearningCart(state) {
    return state.learningCart;
  },
  getRouteParam(state) {
    return state.routeParam;
  },
  getCustomPost(state) {
    return state.customPost;
  },
  getHomePageDetails(state) {
    return state.homePageDetails;
  },
};
