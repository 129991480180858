import { __spreadArray } from "tslib";
import { reactive } from '@vue/composition-api';
import { EventMessageUtils, EventType, InteractionStatus, } from '@azure/msal-browser';
import Vue from 'vue';
/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(arrayA, arrayB) {
    if (arrayA.length !== arrayB.length) {
        return false;
    }
    var comparisonArray = __spreadArray([], arrayB, true);
    return arrayA.every(function (elementA) {
        var elementB = comparisonArray.shift();
        if (!elementA || !elementB) {
            return false;
        }
        return (elementA.homeAccountId === elementB.homeAccountId)
            && (elementA.localAccountId === elementB.localAccountId)
            && (elementA.username === elementB.username);
    });
}
export var msalPlugin = {
    install: function (app, msalInstance) {
        var inProgress = InteractionStatus.Startup;
        var accounts = msalInstance.getAllAccounts();
        var state = reactive({
            instance: msalInstance,
            inProgress: inProgress,
            accounts: accounts,
        });
        Vue.prototype.$msal = state;
        msalInstance.addEventCallback(function (message) {
            switch (message.eventType) {
                case EventType.ACCOUNT_ADDED:
                case EventType.ACCOUNT_REMOVED:
                case EventType.LOGIN_SUCCESS:
                case EventType.SSO_SILENT_SUCCESS:
                case EventType.HANDLE_REDIRECT_END:
                case EventType.LOGIN_FAILURE:
                case EventType.SSO_SILENT_FAILURE:
                case EventType.LOGOUT_END:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_FAILURE:
                    {
                        var currentAccounts = msalInstance.getAllAccounts();
                        if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
                            state.accounts = currentAccounts;
                        }
                    }
                    break;
            }
            var status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
            if (status !== null) {
                state.inProgress = status;
            }
        });
    },
};