<template>
    <div class="container">
        <template>
            <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
                <img
                    class="back-btn"
                    :src="getBackBtnImage()"
                    alt="Back"
                    @click="backBtnClicked()"
                />
                <span class="main_title">{{ getPageDetails.pageTitle }}</span>
                <span class="main_fullstop"></span>
            </div>
      <b-row :class="this.isMobile() || this.isiPad() ? 'mobile-topics' : 'topics' ">
        <carousel
          :perPage="this.isMobile() ? 1 : this.isiPad() ? 2 : 4 "
          paginationColor="#f59790"
          paginationActiveColor="#EE3124"
          :paginationSize="10"
          :paginationPadding="1"
          :navigationEnabled="true"
          :navigation-next-label="
            this.isMobile() || this.isiPad() ? '' : navigationNext
          "
          :navigation-prev-label="
            this.isMobile() || this.isiPad() ? '' : navigationPrev
          "
        >
          <slide
            class="topics-slide"
            v-for="(topic, index) in getTopics"
            :key="index"
          >
            <div
              :class="
                validateTopicCarLink(topic.topicLink)
                  ? 'topic topic-link '
                  : 'topic topic-non-link'
              "
              @click="topicCardClicked(topic.topicLink)"
            >
              <p class="title">{{ topic.topicTitle | str_limit(40) }}</p>
              <img
                v-show="getTopicImage(topic.topicImage)"
                class="topic-img"
                :src="getTopicImage(topic.topicImage)"
                alt="Sal"
              />
              <div :class="getTopicImage(topic.topicImage)
                    ? 'div-p-image'
                    : 'div-p-noimage'">
                <p :class=" getTopicImage(topic.topicImage) === false
                      ? 'desc desc-no-image'
                      : 'desc desc-image'
                  "
                v-html=topic.topicDescription>
                  <!-- {{ removeTagsAndApplyFilter(topic.topicDescription, 16000) }} -->
                </p>
              </div>
            </div>
          </slide>
        </carousel>
      </b-row>
    </template>
    <template>
      <b-row no-gutters :class="this.isMobile() || this.isiPad() ? 'mobile-page-header' : 'page-header'">
        <p class="title">{{ getPageDetails.pageTitle }} <span>.</span></p>
        <p class="desc">
          {{ removeHtmlTags(getPageDetails.pageDescription) }}
        </p>
      </b-row>
    </template>
    <template>
      <b-row
        no-gutters
        :class="this.isMobile() || this.isiPad() ? 'mobile-webinars' : 'webinars'"
        v-if="displaySection(getPageDetails.pageContent, 'webinars')"
      >
        <p class="header">Recent Webinars and Webinar Decks</p>
        <p class="desc">webinars description</p>
        <sal-carousel
          class="sal-carousel"
          :webinars="getWebinars"
          :itemsPerPage="itemsToDisplay"
          :marketingflag="getMarketinFlag"
        />
      </b-row>
    </template>
    <template>
      <b-row
        no-gutters
        :class="this.isMobile() ? 'mobile-poc' : this.isiPad() ? 'tablet-poc' : 'poc'"
        v-if="displaySection(getPageDetails.pageContent, 'pointOfContacts')"
      >
        <poc :pocList="getPocs" :qnEmail="getPageDetails.questionToEmail"></poc>
      </b-row>
    </template>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import Utils from "../../../utils/Utils";
import SalCarousel from "../cards/SalCarousel.vue";
import ChevronRight from "../../../assets/carousel/Right Arrow.svg";
import ChevronLeft from "../../../assets/carousel/Left Arrow.svg";
import Template from "../../Template.vue";
import Poc from "./Poc.vue";

export default {
  name: "Marketing",
  props: {
    text: String,
    getTopics: {
      type: Array,
      default() {
        return [];
      },
    },
    getPageDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    getWebinars: {
      type: Array,
      default() {
        return [];
      },
    },
    getPocs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    SalCarousel,
    carousel: Carousel,
    slide: Slide,
    Template,
    Poc,
  },
  data() {
    return {
      itemsToDisplay: 4,
    };
  },
  computed: {
    getMarketinFlag() {
      var marketingflag = "true";
      return marketingflag;
    },
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img class="slideButtonsTopics" src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img class="slideButtonsTopics" src="${chevronLeft}"/>`;
    },
  },
  methods: {
      getBackBtnImage() {
        return Utils.Images.back;
      },
      backBtnClicked() {
        this.$router.back();
      },
      isMobile() {
        return window.innerWidth <= 750 || window.innerHeight <= 480;
      },
      isiPad() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet =
          /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
          );
        return isTablet;
      },
    readWindowSize() {
      if (window.innerWidth < 890) {
        this.itemsToDisplay = 1;
      } else if (window.innerWidth < 1300) {
        this.itemsToDisplay = 2;
      } else if (window.innerWidth < 1700) {
        this.itemsToDisplay = 3;
      } else if (window.innerWidth > 1750) {
        this.itemsToDisplay = 4;
      }
    },
    displaySection(pageContent, value) {
      return pageContent !== null && pageContent !== undefined
        ? pageContent.includes(value)
        : false;
    },

    removeHtmlTags(str) {
      return str === null || str === undefined
        ? ""
        : str.replace(/<\/?[^>]+(>|$)/g, "");
    },
    removeTagsAndApplyFilter(str, charLimit) {
      let tagsStr = "";
      if (str !== null || str !== undefined) {
        tagsStr = str.replace(/<\/?[^>]+(>|$)/g, "");
        tagsStr = this.limitCharsInStr(tagsStr, charLimit);
      }
      return tagsStr;
    },
    limitCharsInStr(value, size) {
      if (!value) return "";
      value = value.toString();

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + "...";
    },
    getImageName(media) {
      return media.mediaItemUrl === null ? "" : media.mediaItemUrl;
    },
    getTopicImage(topicImage) {
      if (topicImage !== null) {
        return topicImage.mediaItemUrl === null ? "" : topicImage.mediaItemUrl;
      } else {
        return false;
      }
    },
    getWebinarDesc() {
      return Utils.Strings.webinarDesc;
    },
    validateTopicCarLink(topicUri) {
      return topicUri !== null && topicUri.includes("http");
    },
    topicCardClicked(topicUri) {
      if (topicUri !== null && topicUri.includes("http")) {
        window.open(topicUri, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";
// @import "../../../styles/carousel.scss";

.topic-link:hover {
  cursor: pointer;
}

.mobile_heading{
    display:flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;
    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 2px;
        margin-top: 8px;
        border-radius: 2px;
    }
}

.container {
  position: relative;
  display: contents;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;

  .topics {
    padding: 0 40px;
    background-color: rgba(20, 55, 125, 0.1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    .VueCarousel {
      width: 95%;
      height: 420px;
      max-width: 95%;
      margin: 0 60px;
    }

    .topics-slide {
      width: 375px;
      height: 372px;
      flex-basis: auto;

      .topic {
        width: 95%;
        height: 98%;
        border-radius: 12px;
        padding: 5px;
        box-shadow: 1rem;
        background-color: white;
        .title {
          float:left;
          padding-top: 10px;
          font-family: "Isidora-Bold";
          font-size: 24px;
          text-align: left;
          color: $salRed;
          line-height: 29px;
          margin-bottom: 0.5rem;
          overflow: hidden;
          // text-overflow: ellipsis;
          display: inline; // -webkit-box;
          // -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          line-height: normal;
        }
        .desc {
          font-family: "Isidora Sans";
          color: $salLightGrey;
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          margin-bottom: 0rem;
          word-wrap: break-word;
          // overflow-y: auto;
        }
        .desc-image {
          padding-top:5px;
          height: 25%;
          overflow: initial;
        }
        .desc-no-image {
          height: 90%;
        }

        .div-p-image {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          float:  left;
          text-align: left;
        }
        .div-p-noimage {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          float:  left;
          text-align: left;
        }
        

        .topic-img {
          width: 100%;
          height: 55%;
        }
      }
    }
  }

.mobile-topics {
    background-color: rgba(20, 55, 125, 0.1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    .VueCarousel {
          width: 95%;
          height: 420px;
          max-width: 95%;
          margin: 0 60px;
        }

        .topics-slide {
          width: 350px;
          height: 372px;

          .topic {
            width: 95%;
            height: 98%;
            margin-left: 7px;
            border-radius: 12px;
            padding: 5px;
            box-shadow: 1rem;
            background-color: white;
            .title {
              float:left;
              padding-top: 10px;
              font-family: "Isidora-Bold";
              font-size: 20px;
              text-align: left;
              color: $salRed;
              line-height: 29px;
              margin-bottom: 0.5rem;
              overflow: hidden;
              // text-overflow: ellipsis;
              display: inline; // -webkit-box;
              // -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              line-height: normal;
            }
            .desc {
              font-family: "Isidora Sans";
              color: $salLightGrey;
              font-size: 16px;
              font-weight: 500;
              text-align: left;
              margin-bottom: 0rem;
              word-wrap: break-word;
              // overflow-y: auto;
            }
            .desc-image {
              padding-top:5px;
              height: 25%;
              overflow: initial;
            }
            .desc-no-image {
              height: 90%;
            }

            .div-p-image {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              float:  left;
              text-align: left;
            }
            .div-p-noimage {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 10;
              -webkit-box-orient: vertical;
              float:  left;
              text-align: left;
            }


            .topic-img {
              width: 100%;
              height: 55%;
            }
          }
        }
      }

  .page-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 75px;

    .title {
      text-align: left;
      color: $salBlue;
      font-weight: 700;
      font-size: 48px;
      font-family: "Isidora-Bold";
      line-height: 58px;
      margin-bottom: 0rem;
      span {
        color: $salRed;
        font-weight: 900;
      }
    }
    .desc {
      text-align: left;
      font-family: "Isidora Sans";
      font-weight: 500;
      font-size: 18px;
      color: $salLightGrey;
      line-height: 22px;
    }
  }

  .mobile-page-header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      .title {
        text-align: left;
        color: $salBlue;
        font-weight: 700;
        font-size: 32px;
        font-family: "Isidora-Bold";
        line-height: 58px;
        margin-bottom: 0rem;
        span {
          color: $salRed;
          font-weight: 900;
        }
      }
      .desc {
        text-align: left;
        font-family: "Isidora Sans";
        font-weight: 500;
        font-size: 16px;
        color: $salLightGrey;
        line-height: 22px;
      }
  }

    .mobile-webinars {
        .header {
            margin: 5px 10px;
            font-family: "Isidora-Bold";
            font-size: 32px;
            font-weight: 700;
            color: $salBlue;
            text-align: left;
            line-height: 32px;
            padding: 0 15px;
        }

        .desc {
            font-family: "Isidora Sans";
            color: $salLightGrey;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            line-height: 22px;
            padding: 0 30px;
        }

        .sal-carousel {
            padding: 0 10px;
        }

        .web-cards {
            width: 350px !important;
        }
    }

  .webinars {
    background-color: #f8f8f8;

    .header {
      margin: 5px 15px;
      font-family: "Isidora-Bold";
      font-size: 32px;
      font-weight: 700;
      color: $salBlue;
      text-align: left;
      line-height: 32px;
      padding: 0 75px;
    }

    .desc {
      font-family: "Isidora Sans";
      color: $salLightGrey;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      line-height: 22px;
      padding: 0 95px;
    }

    .sal-carousel {
      padding: 0 50px;
    }
  }

  .poc {
    padding: 0 90px;
  }

  .mobile-poc {
    padding: 0 25px;
  }

  .tablet-poc {
    padding: 0 30px;
  }
}
</style>
