var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"clas":"Main-Container"}},[_c('div',{class:_vm.grayedOut ? 'greyout' : ''}),_c('div',{staticClass:"rpc"},[_c('div',{class:_vm.isMobile ? 'm-sal-head' : 'sal-head'},[(
          !_vm.isMobile() ||
          (_vm.isMobile() && _vm.componentName === 'Dashboard') ||
          _vm.isiPad()
        )?_c('Header',{attrs:{"currentCompnt":_vm.componentName,"pageDetails":_vm.pageDetails},on:{"commonlinkspresent":_vm.getCommonLinksShowStatus,"reloadPage":_vm.reload}}):_vm._e(),_c('SalNavBar',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'lg', 'xl']),expression:"['hidden-all', 'lg', 'xl']"}],ref:"salNavBar",class:_vm.visible ? 'common-links-collapsed' : null,attrs:{"pageDetails":_vm.pageDetails},on:{"getMenuTitle":_vm.extractMenuTitle,"getComponent":_vm.getAdditionalComponent}})],1),_c('div',{class:!_vm.showCommonLinks ? 'sal-content' : 'sal-content common-links-show'},[_c('div',{staticClass:"content"},[_c('ContentWrapper',{attrs:{"pageTitle":_vm.pageName,"componentName":_vm.compName,"customUrl":_vm.customUrl,"pageDetails":_vm.pageDetails},on:{"getCurrentComponent":_vm.updatedComponent,"reloadPage":_vm.reload}})],1),(
          !_vm.isMobile() ||
          (_vm.isMobile() && _vm.componentName === 'Dashboard') ||
          _vm.isiPad()
        )?_c('Footer',{attrs:{"pageDetails":_vm.pageDetails}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }