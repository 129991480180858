import {
  CallAPIM,
  CallApimWithGql,
  callAPIMWithParams,
  callMultiAPIM,
} from "@/api/GraphqlAPI";
import {
  CUSTOM_POSTS,
  TRAINING_LEARNING_CART_QUERY,
} from "../../queries/gqlQueries";
import Utils from "@/utils/Utils";
import axios from "axios";

export const fetchNav = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveNavMenu", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchWebinars = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveWebinarsInfo", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchTopics = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveTopics", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchCommonLinks = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveCommonLinks", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchPointOfContactsListWithParams = async ({ commit }, data) => {
  await CallApimWithGql(data)
    .then((response) => {
      commit("saveCommonPocList", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchPageDetails = async ({ commit }, data) => {
  await callAPIMWithParams(data)
    .then((response) => {
      commit("savePageDetails", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchWeeklyCheckoutArchives = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveWeeklyCheckoutArchives", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchDistributionResources = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveResources", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchRetailResources = async ({ commit }, data) => {
  await callMultiAPIM(data, "Retail Resource")
    .then((response) => {
      commit("saveRetailResources", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchLearningCart = async ({ commit, getters }) => {
  if (getters.getLearningCart === "") {
    await CallAPIM(TRAINING_LEARNING_CART_QUERY)
      .then((response) => {
        if (response.trainingHubTypes) {
          commit(
            "saveLearningCart",
            response.trainingHubTypes.nodes[0].children.nodes[0].learningCart
              .nodes[0].learningCartLink
          );
        } else {
          commit("saveLearningCart", null);
          console.error("*** Unable to retrieve URL ***");
        }
      })
      .catch((err) => {
        console.log("API failed - ", err);
      });
  }
};
export const fetchSavedPostsContent = async ({ commit }, data) => {
  await CallApimWithGql(data)
    .then((response) => {
      commit("saveSavedContent", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchCustomPost = async ({ commit }, data) => {
  const payload = {
    query: CUSTOM_POSTS,
    vars: {
      uri: data,
    },
  };
  await CallApimWithGql(payload)
    .then((response) => {
      commit("saveCustomPost", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const fetchHomePageDetails = async ({ commit }, data) => {
  await CallAPIM(data)
    .then((response) => {
      commit("saveHomePageDetails", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};
