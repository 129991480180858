import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import axios from "axios";

async function prepareHeaders() {
    let account = "";
    let myMsal = "";
    myMsal = new PublicClientApplication(msalConfig);
    account = myMsal.getAllAccounts();

    const accessTokenRequest = {
        scopes: [process.env.VUE_APP_SAVE_SCOPE],
        account: account,
    };

    const idToken = await myMsal
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken;
        return accessToken;
    })

    .catch((err) => {
        if (err.name === "InteractionRequiredAuthError") {
            return myMsal
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {});
        }
    });

    const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_SAVE_KEY,
        "Access-Control-Allow-Origin": process.env.VUE_APP_AD_REDIRECT_URI,
        Authorization: "Bearer " + idToken,
    };

    return headers;
}

export const apiPOST = async (data) => {
    const headers = await prepareHeaders();
    var results = await axios.post(process.env.VUE_APP_SAVE_API, data, {headers: headers});
}

export const apiGET = async (data) => {
    const headers = await prepareHeaders();
    const url = process.env.VUE_APP_SAVE_API + '?userId=' + data;
    try {
        var results = await axios.get(url, { headers: headers });
    } catch (error) {
        return null;
    }
    return results;
}

export const apiDELETE = async (data, id) => {
    const headers = await prepareHeaders();
    const url = process.env.VUE_APP_SAVE_API + '?userId=' + data + "&postId=" + id;
    var results = await axios.delete(url, { headers: headers });
    return results;
}