<template>
  <div>
    <router-link to="/profile">
      <button class="profileStyle">
        <img :src="profileicon" />
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    profileicon: String,
  },
};
</script>

<style scoped>
.profileStyle {
  outline: none;
  border: none;
  background-color: transparent;
}

.profileStyle:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(24%) sepia(37%) saturate(6249%)
    hue-rotate(351deg) brightness(86%) contrast(97%);
}
</style>
