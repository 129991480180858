<template>
    <div class="outerDiv">
        <span class="heading">
            <h1>FAQs</h1>
            <h2>.</h2>
        </span>
        <div class="content">
            <ComingSoon/>
        </div>
    </div>
</template>

<script>
import ComingSoon from '../../ComingSoon.vue';

export default {
    components: {
        ComingSoon
    },
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'Isidora-Bold';
    src: url('../../../assets/fonts/Isidora/Isidora-Bold.otf');
}

@font-face {
    font-family: 'Isidora-SemiBold';
    src: url('../../../assets/fonts/Isidora/Isidora-SemiBold.otf');
}

@font-face {
    font-family: 'Isidora Sans Medium';
    src: url('../../../assets/fonts/IsidoraSans/Isidora Sans Medium.otf');
}

.outerDiv {
    margin-left: 0px;
    text-align: left;
    margin-top: 60px;
}

.heading {
    padding-left: 70px;
    padding-top: 20px;
}

.heading h1 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #14377C;
    margin-bottom: 0px;
    text-align: left;
}

.heading h2 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0px;
    text-align: left;
}

.content {
    padding-top: 0px;
    padding-bottom: 70px;

}

</style>