<template>
  <div class="outerDiv">
    <div :class="isMobile() ? 'mobile-heading' : isiPad() ? 'tablet-heading' : 'heading' ">
      <h1>{{ pageContent.pageTitle }}</h1>
      <h2>.</h2>
      <p v-html="pageContent.pageDescription"></p>

    </div>
    <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
            class="back-btn"
            :src="getBackBtnImage()"
            alt="Back"
            @click="backBtnClicked()"
        />
        <span class="main_title">{{ pageContent.pageTitle }}</span>
        <span class="main_fullstop"></span>
    </div>

    <div :class="isiPad() ? 'tablet-modules' : isMobile() ? 'mobile-modules' : 'modules' " v-for="module in pageModules" :key="module">
      <div :class="[module]" v-if="module === 'remodelPhotos'">
        <h3>Remodel Photos</h3>
        <p>{{ pageContent.photoLibraryDescription }}</p>
        <div :class=" isMobile() ? 'mobile-img-carousel' : isiPad() ? 'tablet-img-carousel' : 'remodel-img-carousel'">
          <carousel
            id="remodelPhotosCarousel"
            :perPage="isMobile() ? 1 : isiPad() ? 1 : 2"
            :paginationSize="0"
            :paginationPadding="0"
            :navigationEnabled="true"
            :scrollPerPage="false"
            :navigation-next-label= "isMobile() || isiPad() ? '' : navigationNext"
            :navigation-prev-label= "isMobile() || isiPad() ? '' : navigationPrev"
          >
            <slide v-for="photo in pageImgs" class="slide-img">
              <div class="image-container">
                <img
                  v-bind:src="photo.photoLibrary.mediaItemUrl"
                  alt="Store Location"
                />
                <h5>{{ photo.photoTitle }}</h5>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

      <div :class="[module]" v-else-if="module === 'remodelDocuments'">
        <h3>Remodel Documents</h3>
        <b-row cols="4">
          <b-col class="pdf-cols" v-for="pdf in pagePdfs">
            <div class="pdf-card" @click="openPdf(pdf)">
              <img src="../../../assets/PDF_button.svg" alt="" />
              <span class="pdf-title">
                <p>{{ pdf.pDFTitle }}</p>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>

      <div :class="[module]" v-else-if="module === 'pointOfContacts'">
        <PointOfContacts :pointOfContacts="pageContent.pointOfContacts.nodes" />
      </div>
    </div>
  </div>
</template>

<script>
import { SUPPORT_REMODEL_QUERY } from "../../../queries/gqlQueries.js";
import { gQLCaller } from "../../../queries/gqlCaller.js";
import PointOfContacts from "../../PointOfContacts.vue";
import ChevronRight from "../../../assets/carousel/Right Arrow.svg";
import ChevronLeft from "../../../assets/carousel/Left Arrow.svg";
import Utils from "../../../utils/Utils.js";

export default {
  name: "RemodelServices",
  components: {
    PointOfContacts,
  },
  data() {
    return {
      pageContent: [],
      pageModules: [],
      pagePdfs: [],
      selectedPdf: [],
      pageImgs: [],
      pointOfContacts: [],
      contactImg: [],
    };
  },
  mounted: function () {
    this.getRemodel();
  },
  computed: {
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img class="slideButtons" src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img class="slideButtons" src="${chevronLeft}"/>`;
    },
  },
  methods: {
    openPdf(selected) {
      this.selectedPdf = selected.pDFAttachment[0].mediaItemUrl;
      window.open(this.selectedPdf);
    },
    getBackBtnImage() {
        return Utils.Images.back;
    },
    backBtnClicked() {
        this.$router.back();
    },
    isMobile() {
        return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
        );
        return isTablet
    },
    async getRemodel() {
      gQLCaller(SUPPORT_REMODEL_QUERY).then((result) => {
        this.pageContent =
          result.support_services.nodes[0].children.nodes[0].remodelServicesPages.nodes[0];
        this.pageModules =
          result.support_services.nodes[0].children.nodes[0].remodelServicesPages.nodes[0].pageContent;
        this.pagePdfs =
          result.support_services.nodes[0].children.nodes[0].remodel_documents.nodes;
        this.pageImgs =
          result.support_services.nodes[0].children.nodes[0].remodel_photos.nodes;
        this.pointOfContacts =
          result.support_services.nodes[0].children.nodes[0].remodelServicesPages.nodes[0].pointOfContacts;
        for (let i = 0; i < this.pointOfContacts.nodes.length; i++) {
          if (this.pointOfContacts.nodes[i].contactImage.mediaItemUrl == null) {
            this.pointOfContacts.nodes[
              i
            ].contactImage.mediaItemUrl = require("../../../assets/ProfileDefault.svg");
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/carousel.scss";

// #remodelPhotosCarousel {
//         // .navigation-next-label, .navigation-prev-label
//         background-image: none;
//         border-radius: 50%;
//         height: 100px;
//         outline: rgb(247, 245, 245);

// }
.outerDiv {
  width: 100%;
}

.heading {
    text-align: left;
    padding-left: 70px;
    padding-top: 60px;

    h1 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 48px;
        color: #14377c;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
    h2 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 48px;
        color: #ee3124;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
    p {
        font-family: "Isidora Sans Medium";
        font-size: 18px;
        color: #5c5c5c;
        text-align: left;
        padding-bottom: 50px;
        margin-right: 70px;
    }
}

.mobile-heading {
    text-align: left;
    padding-left: 15px;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 15px;

    h1 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 32px;
        color: #14377c;
        margin-bottom: 0;
    }
    h2 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 32px;
        color: #ee3124;
        margin-bottom: 0;
    }
    p {
        font-family: "Isidora Sans Medium";
        font-size: 16px;
        color: #5c5c5c;
        text-align: left;
    }
}

.tablet-heading {
    text-align: left;
    padding-left: 15px;
    padding-bottom: 20px;
    padding-right: 15px;

    h1 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 32px;
        color: #14377c;
        margin-bottom: 0;
    }
    h2 {
        display: inline;
        font-family: "Isidora-Bold";
        font-size: 32px;
        color: #ee3124;
        margin-bottom: 0;
    }
    p {
        font-family: "Isidora Sans Medium";
        font-size: 18px;
        color: #5c5c5c;
        text-align: left;
    }
}

.heading::v-deep p {
  color: #5c5c5c;
}


.mobile_heading{
    display:flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;
    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 2px;
        margin-top: 8px;
        border-radius: 2px;
    }
}

.mobile-modules {
    padding-left: 0px;
    padding-top: 0px;

    .remodelPhotos {
      text-align: left;
      padding-left: 15px;
      padding-top: 15px;
      background-color: #f8f8f8;
    }

    .remodelPhotos h3 {
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
    }

    .remodelPhotos p {
      font-family: "Isidora Sans Medium";
      font-size: 16px;
      color: #5c5c5c;
      text-align: left;
    }

    .mobile-img-carousel {
      width: 100%;
      height: 100%;
      margin-left: 0px;
      margin-right: 0px;

      .VueCarousel {
        width: 95%;
        max-height: 300px !important;

        .VueCarousel-navigation--disabled {
          opacity: 1 !important;
        }
        .VueCarousel-navigation-prev {
          background-color: white !important;
          border-radius: 20px !important;
          width: 40px;
        }

        .VueCarousel-navigation-next {
          background-color: white !important;
          border-radius: 20px !important;
          width: 40px;
          margin-left: 3px;
          text-align: center;
        }
      }

      .slide-img {
        margin-top: 0px;
        margin-bottom: 0px;

        .image-container {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 0px;
          padding-left: 0px;
          max-width: 100%;
          max-height: 650px;
          min-height: 350px;
        }
        .image-container img {
          display: block;
          max-height: 550px;
          max-width: 100%;
          border: 3px solid #14377c;
          border-radius: 20px;
        }

        .image-container h5 {
          text-align: center;
          margin-top: 5px;
          font-family: "Isidora-Bold";
          font-size: 28px;
          color: #14377c;
        }
      }
    }

    .pdfContainer {
      float: left;
    }

    .pdf-card {
      display: relative;
      min-height: 88px;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      vertical-align: middle;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdf-cols {
      margin-right: 25px;
      margin-bottom: 25px;
      width: 100%;
    }

    .pdf-card img {
      display: inline-block;
      line-height: 88px;
      vertical-align: middle;
      float: left;
      width: auto;
      height: 40%;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 30px;
    }

    .pdf-card:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-title p {
      display: inline-block;
      float: left;
      line-height: 65px;
      max-height: 65px;
      max-width: 70%;
      margin-top: 15px;
      position: absolute;
      color: #14377c;
      font-family: "Isidora-SemiBold";
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .remodelDocuments {
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 50px;
        margin-bottom: 1rem;
        background-color: #fff;

        h3 {
            font-family: "Isidora-Bold";
            font-size: 32px;
            color: #14377c;
        }
    }

    p {
        font-family: "Isidora Sans Medium";
        font-size: 16px;
        color: #5c5c5c;
        text-align: left;
    }

    .pointOfContacts {
      margin-left: 0px;
    }
}

.tablet-modules {
    padding-left: 0px;
    padding-top: 0px;

    .remodelPhotos {
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      background-color: #f8f8f8;
    }

    .remodelPhotos h3 {
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
    }

    .remodelPhotos p {
      font-family: "Isidora Sans Medium";
      font-size: 18px;
      color: #5c5c5c;
      text-align: left;
    }

    .tablet-img-carousel {
      width: 100%;
      height: 100%;
      margin-left: 0px;
      margin-right: 0px;

      .VueCarousel {
        width: 100%;
        max-height: 500px !important;

        .VueCarousel-navigation--disabled {
          opacity: 1 !important;
        }
      }

      .slide-img {
        margin-top: 0px;
        margin-bottom: 0px;

        .image-container {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 0px;
          padding-left: 0px;
          max-width: 100%;
          max-height: 650px;
          min-height: 350px;
        }
        .image-container img {
          display: block;
          max-height: 550px;
          max-width: 100%;
          border: 3px solid #14377c;
          border-radius: 20px;
        }

        .image-container h5 {
          text-align: center;
          margin-top: 5px;
          font-family: "Isidora-Bold";
          font-size: 32px;
          color: #14377c;
        }
      }
    }

    .pdfContainer {
      float: left;
    }

    .pdf-card {
      display: relative;
      min-height: 88px;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      vertical-align: middle;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdf-cols {
      margin-right: 25px;
      margin-bottom: 25px;
      width: 100%;
    }

    .pdf-card img {
      display: inline-block;
      line-height: 88px;
      vertical-align: middle;
      float: left;
      width: auto;
      height: 40%;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 30px;
    }

    .pdf-card:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-title p {
      display: inline-block;
      float: left;
      line-height: 65px;
      max-height: 65px;
      max-width: 70%;
      margin-top: 15px;
      position: absolute;
      color: #14377c;
      font-family: "Isidora-SemiBold";
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .remodelDocuments {
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 50px;
        margin-bottom: 1rem;
        background-color: #fff;

        h3 {
            font-family: "Isidora-Bold";
            font-size: 32px;
            color: #14377c;
        }
    }

    p {
        font-family: "Isidora Sans Medium";
        font-size: 18px;
        color: #5c5c5c;
        text-align: left;
    }

    .pointOfContacts {
      margin-left: 0px;
    }
}
.modules {
    .remodelPhotos {
      text-align: left;
      padding-top: 50px;
      padding-left: 0px;
      background-color: #f8f8f8;
    }

    .remodelPhotos h3 {
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
      margin-bottom: 0px;
      padding-left: 70px;
    }

    .remodelPhotos p {
      font-family: "Isidora Sans Medium";
      font-size: 18px;
      color: #5c5c5c;
      text-align: left;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 70px;
    }

    h3 {
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
      padding-bottom: 10px;
    }

    .remodel-img-carousel {
        width: 96%;
        height: 100%;
        margin-left: 2%;
        margin-right: 2%;

        .VueCarousel {
            width: 95%;
            max-height: 650px;
            min-height: 350px;
            margin: 0 auto;

            .VueCarousel-navigation--disabled {
                opacity: 1 !important;
            }
            .VueCarousel-navigation-prev {
                background-color: white !important;
                border-radius: 20px !important;
                width: 40px;
            }

            .VueCarousel-navigation-next {
                background-color: white !important;
                border-radius: 20px !important;
                width: 40px;
                margin-left: 3px;
                text-align: center;
            }
        }

        .slide-img {
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 10px;
            min-width: 50%;
            max-width: 50%;
            max-height: 650px;
            min-height: 350px;

            .image-container {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 0px;
                padding-left: 0px;
                max-width: 100%;
                max-height: 650px;
                min-height: 350px;
            }
            .image-container img {
                display: block;
                max-height: 550px;
                max-width: 100%;
                border: 3px solid #14377c;
                border-radius: 20px;
            }

            .image-container h5 {
                text-align: center;
                margin-top: 5px;
                font-family: "Isidora-Bold";
                font-size: 32px;
                color: #14377c;
            }
        }
    }

    .pdfContainer {
      float: left;
    }

    .pdf-card {
      display: relative;
      min-height: 88px;
      margin: 0 auto;
      height: 100%;
      width: 375px;
      border-radius: 5px;
      vertical-align: middle;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdf-cols {
      margin-right: 25px;
      margin-bottom: 25px;
      width: 375px;
    }

    .pdf-card img {
      display: inline-block;
      line-height: 88px;
      vertical-align: middle;
      float: left;
      width: auto;
      height: 60%;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 15px;
    }

    .pdf-card:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-title p {
      display: inline-block;
      float: left;
      line-height: 88px;
      max-height: 88px;
      position: absolute;
      color: #14377c;
      font-family: "Isidora-SemiBold";
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 285px;
    }

    .remodelDocuments {
      text-align: left;
      padding-left: 70px;
      margin-top: 50px;
      margin-bottom: 1rem;
      background-color: #fff;
    }

    .pointOfContacts {
      margin-left: 55px;
    }
}


</style>
