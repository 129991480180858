<template>
    <h1 v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'HeadingBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

h1 {
    display: inline;
    font-family: "Isidora-Bold";
    font-size: 48px;
    color: #14377c;
    margin-bottom: 0;
    padding-bottom: 10px;
    text-align: left;
}
</style>