<template>
  <carousel
    :perPage="this.isMobile() ? 1 : this.isiPad() ? 2 : itemsPerPage "
    :paginationSize="0"
    :paginationPadding="0"
    :navigationEnabled="true"
    :scrollPerPage="false"
    :navigation-next-label="
        this.isMobile() || this.isiPad() ? '' : navigationNext
    "
    :navigation-prev-label="
        this.isMobile() || this.isiPad() ? '' : navigationPrev
    "
  >
    <slide
      class="p-2 carousel-slide"
      v-for="(webinar, index) in webinars"
      :key="index"
    >
      <Deck :webinarInfo="webinar"> </Deck>
    </slide>
  </carousel>
</template>
<script>
import Deck from "./Deck.vue";
import ChevronRight from "../../../assets/carousel/Right Arrow.svg";
import ChevronLeft from "../../../assets/carousel/Left Arrow.svg";

export default {
  components: {
    Deck,
  },
  props: {
    webinars: Array,
    itemsPerPage: Number,
    marketingflag: String,
  },
  computed: {
    navigationNext() {
      const chevronRight = ChevronRight;
      if (this.marketingflag == "true")
        return `<img class="slideButtonsMarketing right" src="${chevronRight}"/>`;
      else return `<img class="slideButtons" src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      if (this.marketingflag == "true")
        return `<img class="slideButtonsMarketing left" src="${chevronLeft}"/>`;
      else return `<img class="slideButtons" src="${chevronLeft}"/>`;
    },
  },
  methods: {
      isMobile() {
        return window.innerWidth <= 750 || window.innerHeight <= 480;
      },
      isiPad() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet =
          /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
          );
        return isTablet;
      },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";
// @import "../styles/carousel.scss";
.carousel-body {
  padding-left: 30px;
  padding-right: 80px;
}

.VueCarousel {
  width: 95%;
  //   height: 260px;
  max-width: 95%;
  margin: 0 auto;
}
</style>
