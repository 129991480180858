export default {
  getHistory(state) {
    return state.historyData;
  },
  getHistoryStatus(state) {
    return state.historyStaus;
  },
  getHistoryErrCode(state) {
    return state.historyErrCode;
  },
};
