<template>
    <div class="outerDiv">
        <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
            <img
                class="back-btn"
                :src="getBackBtnImage()"
                alt="Back"
                @click="backBtnClicked()"
            />
            <span class="main_title">Calendar</span>
            <span class="main_fullstop"></span>
        </div>
        <div class="tablet-heading" v-if="!isMobile()">
            <span class="main_title">Calendar</span>
            <h1 class="dot">.</h1>
        </div>
        <div class="content">
            <div :id="isMobile() ? 'mInnerDiv' : 'tInnerDiv' ">
                <iframe :src="url" :id="isMobile() ? 'm-inner-frame' : 't-inner-frame'" style="calendar" scrolling="no"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Utils from "../../../utils/Utils";

export default {
    data() {
        return {
            url: String,
        }
    },
    mounted() {
        this.getLink();
        console.log(this.isMobile());
    },
    computed: {
        ...mapGetters([ "getRouteParam" ]),
    },
    methods: {
        ...mapMutations([ "saveRouteParams" ]),
        getLink() {
            if(this.getRouteParam == null) {
                this.saveRouteParams(this.$route.params);
                this.url = this.$route.params.url;
            } else {
                this.url = this.getRouteParam.url;
            }
        },
        getBackBtnImage() {
          return Utils.Images.back;
        },
        backBtnClicked() {
          this.$router.back();
        },
        isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
    },
}
</script>

<style lang="scss" scoped>
.outerDiv {
    text-align: left;
    margin-top: 10px;
}

.mobile_heading {
    display: flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;

    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Isidora-Bold';
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 1px;
        margin-top: 3px;
        border-radius: 2px;
    }
}

.tablet-heading {
    margin-left: 50px;
    margin-top: 20px;

    .main_title {
        display: inline;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 19px;
        font-family: 'Isidora-Bold';
        color: #14377c;
    }
    .dot {
        display: inline;
        font-family: 'Isidora-Bold';
        font-size: 32px;
        color: #ee3124;
        margin-bottom: 0px;
        text-align: left;
    }

}

iframe {
    width: 100%;
    height: 1000px;
    border: none;
}

.calendar {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
}

.content {
    width: 100%;
}

#mInnerDiv
{
width:100%;
height:850px;
overflow:hidden;
position:relative;
}

#tInnerDiv
{
width:100%;
height:1150px;
overflow:hidden;
position:relative;
}

#m-inner-frame
{
position:absolute;
top: -50px;
width:100%;
height: 850px;
}

#t-inner-frame
{
position:absolute;
top: -75px;
width:100%;
height: 1150px;
}


</style>