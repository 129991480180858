import axios from "axios";
import Utils from "@/utils/Utils";

export const saveHistoryData = (data, headers) =>
  axios.post(
    Utils.URI.appProfile + Utils.API.saveRecentlyused,
    data,
    headers
  );

export const fetchHistory = (userName, headers) =>
  axios.get(
    Utils.URI.appProfile + Utils.API.fetchRecentlyused + userName,
    headers
  );
