import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from "@/state";
Vue.use(Vuex);

const initialState = {
  profileData: new Array(),
  profilePicture: new String(),
  queryCounts: new Array(),
  userName: "",
};

const state = { ...initialState };

const mutations = {
  ADD_PROFILE_DATA: function (state, payload) {
    state.profileData.push(payload);
    state.userName = payload.profile.mail.toLowerCase();
    this.dispatch("menuModule/getAdditionalLinksData", payload);
  },
  RESET_STORE: function (state) {
    Object.assign(state, initialState);
  },
  ADD_COUNT_DATA: function (state, payload) {
    state.queryCounts.push(payload);
  },
};

const getters = {
  profileGetter: (state) => state.profileData,
  userName: (state) => state.userName,
};

import menuModule from "@/state/menu";
import {sortFilterSearch} from "@/state/sortfilter"
import { savedStore } from "@/state/saved";
import { saveProfileStore } from "@/state/userprofile";
import { searchStore } from "@/state/search";
//import favoriteModule from "@/state/favorite";

export default new Vuex.Store({
  modules: { ...modules, menuModule, sortFilterSearch, savedStore, saveProfileStore, searchStore},
  state,
  mutations,
  actions: {},
  getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
