import { getYear, getMonth } from '@/utils/Dates.js'
export default {
    posts,
    retailResources,
    additionalLinks
  };

function posts (value, posts){

    let result = [];
    for (let i = 0; i<posts.length; i++) {

        if(JSON.stringify(posts[i]).toUpperCase().includes(value.toUpperCase())) {
            result.push(posts[i])
        }
    }
    return result 
}

function retailResources (value, arr){
    let categoriesCount = arr.length;

    if(value=="") {
        return arr 
    } 

    for (let i =0; i < categoriesCount; i++) {
        let childrenCount = arr[i].children.length;
        let found = false;

        for(let j=0;j<childrenCount;j++){
            var n = arr[i].children[j].pDFUrl.lastIndexOf('/') 


            if(arr[i].children[j].pDFTitle.toLowerCase().includes(value.toLowerCase()) || 
                arr[i].children[j].pDFUrl.toLowerCase().substring(n+1).includes(value.toLowerCase()))
            {
                found = true;
            }

            if(!found) {
                arr[i].children.splice(j,1)
                j--;
                childrenCount--;
            }
            found=false
        }

        if(childrenCount == 0) {
            arr.splice(i,1)
            i--;
            categoriesCount--;
        }

    }
    return arr  
}

function additionalLinks (value, arr){
    let categoriesCount = arr.length;
    if(value=="") {
        return arr 
    } 

    for (let i =0; i < categoriesCount; i++) {
         let childrenCount = arr[i].appList.length;
         let found = false;

         for(let j=0;j<childrenCount;j++){


            if(arr[i].appList[j].label.toLowerCase().includes(value.toLowerCase()))
            {
                found = true;
            }

            if(!found) {
                arr[i].appList.splice(j,1)
                j--;
                childrenCount--;
            }
            found=false
        }

        if(childrenCount == 0) {
            arr.splice(i,1)
            i--;
            categoriesCount--;
         }

    }
    return arr  
}


