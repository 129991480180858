import { loginRequest, msalConfig, msalInstance } from '../../authConfig';
import { apiPOST, apiGET, apiDELETE } from '../../api/callAPIM';
import { PublicClientApplication } from '@azure/msal-browser';
import { gQLCaller } from "../../queries/gqlCaller";
import { upnParsing } from "./upnParse.js"
import Utils from "../../utils/Utils";
import store from '../../store/index';
import router from '../../router/index';
import { mapGetters } from 'vuex';
import axios from 'axios';

export const getProfile = async ({ commit, dispatch }) => {
    try {
        let account = "";
        let myMsal ="";

        if (msalInstance.getAllAccounts().length > 0) {
            myMsal = msalInstance;
            account = myMsal.getAllAccounts();
        } else {
            myMsal = new PublicClientApplication(msalConfig);
            account = myMsal.getAllAccounts();
        }

        if (account) {
            const accessTokenResponse = await myMsal.acquireTokenSilent({
                scopes: ['User.ReadWrite', 'Profile', 'offline_access'],
                forceRefresh: false,
                account: account
            });
            if (accessTokenResponse) {
                const accessToken = 'Bearer ' + accessTokenResponse.accessToken;
                try {
                    var profileResult = await axios({
                        method: "GET",
                        url: "https://graph.microsoft.com/beta/me",
                        headers:
                        {
                            'Authorization':accessToken
                        },
                    });
                } catch (error) {
                    console.log("Ran into a problem receiving profile data...");
                    var blobUrl = null;
                }
                try {
                    var { data: profilePicture } = await axios({
                        method: "GET",
                        url: 'https://graph.microsoft.com/v1.0/me/photo/$value',
                        headers:
                        {
                            'Authorization':accessToken
                        },
                        responseType: 'blob'
                    });

                    const user =
                        {
                            profile: profileResult.data,
                            profileImg: URL.createObjectURL(profilePicture),
                        }

                    if (user.profile.hasOwnProperty('extension_e6f802ffd11048c0869dd948516f8d67_extensionAttribute15')) {
                        user.profile.mail = user.profile.extension_e6f802ffd11048c0869dd948516f8d67_extensionAttribute15;
                        console.log('Attribute 15: ' + user.profile.mail);
                    } else {
                        console.log('No Extension 15, defaulting to UPN Parser...');
                        const upnResult = upnParsing(user.profile.userPrincipalName);
                        if (upnResult) {
                            user.profile.mail = upnResult;
                            console.log('UPN Result: ' + user.profile.mail);
                        } else {
                            console.log('No UPN result, defaulting to MSAL Account...')
                        }
                    }

                    if (!user.profile.mail || user.profile.mail === "") {
                        let myMsal = msalInstance;
                        let account = myMsal.getAllAccounts();
                        user.profile.mail = account[0].username;
                    }
                    
                    commit("ADD_PROFILE_DATA", user);
                } catch (error) {
                    const user =
                        {
                            profile: profileResult.data,
                            profileImg: null,
                        }

                    if (user.profile.hasOwnProperty('extension_e6f802ffd11048c0869dd948516f8d67_extensionAttribute15')) {
                        user.profile.mail = user.profile.extension_e6f802ffd11048c0869dd948516f8d67_extensionAttribute15;
                        console.log('Attribute 15: ' + user.profile.mail);
                    } else {
                        console.log('No Extension 15, defaulting to UPN Parser...');
                        const upnResult = upnParsing(user.profile.userPrincipalName);
                        if (upnResult) {
                            user.profile.mail = upnResult;
                            console.log('UPN Result: ' + user.profile.mail);
                        } else {
                            console.log('No UPN result, defaulting to MSAL Account...')
                        }
                    }

                    if (!user.profile.mail || user.profile.mail === "") {
                        let myMsal = msalInstance;
                        let account = myMsal.getAllAccounts();
                        user.profile.mail = account[0].username;
                    }
                    
                    commit("ADD_PROFILE_DATA", user);
                    return;
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const createSaveProfile = async ({ commit, dispatch, getters }) => {
    const createVars =
    {
        userId: getters.getProfileInfo.profile.id,
        bio:'',
        userPosts: [],
    }
    try {
        apiPOST(createVars).then((response) => {
            commit("ADD_WP_PROFILE", response.data[0]);
            router.go();
        });
    } catch (error) {
        console.log("ERROR: Unable to create profile." + error);
    }
}

export const updateSaveProfile = async ({ commit, dispatch, getters }, payload) => {
    try {
        if(payload.bio) {
            const updateVars =
            {
                userId: getters.getProfileInfo.profile.id,
                bio: payload.bio,
                userPosts: getters.getSaveProfile.userPosts,
            }
            apiPOST(updateVars).then((response) => {
                commit("ADD_WP_PROFILE", updateVars);
                return;
            })
            .catch((error) => {
                console.log("ERROR: Unable to update profile." + error);
            });
        } else {
            let userPosts = getters.getSaveProfile.userPosts;
            let found = false;
            for (let i = 0; i < userPosts.length; i++) {
                if (userPosts[i].postId == payload) {
                    found = true;
                    break;
                }
            }

            if(!found) {
                const postVar = { postId: payload.toString() }
                const postVars = [postVar].concat(getters.getSaveProfile.userPosts);
                const updateVars =
                {
                    userId: getters.getProfileInfo.profile.id,
                    bio:getters.getSaveProfile.bio,
                    userPosts: postVars
                }
                apiPOST(updateVars).then((response) => {
                    commit("ADD_SAVES", updateVars.userPosts);
                })
            } else {
                const updateVars =
                {
                    userId: getters.getProfileInfo.profile.id,
                    bio:getters.getSaveProfile.bio,
                    userPosts: getters.getSaveProfile.userPosts.filter((item) => item.postId !== payload.toString())

                }
                apiDELETE(getters.getProfileInfo.profile.id, payload).then((response) => {
                    commit("ADD_SAVES", updateVars.userPosts);
                })
            }
        }
    } catch (error) {
        console.log("ERROR: Unable to update profile." + error);
    }
}

export const viewSaveProfile = ({ commit, dispatch, getters }, id) => {
    if(!id){
        console.warn('ERROR: No profile id found...');
    }
    try {
        apiGET(id)
            .then((result) => {
                if(result !== null) {
                    commit("ADD_WP_PROFILE", result.data[0]);
                } else {
                    console.log('no profile found, creating one...');
                    dispatch('createSaveProfile');
                }
        });
    } catch (error) {
        console.warn("ERROR: Unable to view profile." + error);
    }
}

export const signOut = async() => {
    console.log("signing out... ")
    let msalInst = msalInstance;
    msalInst.logoutRedirect();
    msalInst.logoutPopup();
}