import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCompositionAPI from "@vue/composition-api";
import VueRouter from "vue-router";

// import "./registerServiceWorker";
import { BootstrapVue, IconsPlugin, BSidebar } from "bootstrap-vue";
import { BCarousel } from "bootstrap-vue";
Vue.component("b-carousel", BCarousel);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
import FullCalendar from 'vue-full-calendar';
Vue.use(FullCalendar);

import { BEmbed } from "bootstrap-vue";
Vue.component("b-embed", BEmbed);
Vue.component('b-sidebar', BSidebar)

import "./styles/main.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// import all msal plugins for authentication use.
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { CustomNavigationClient } from "./router/NavigationClient";
import { EventType, AuthenticationResult } from "@azure/msal-browser";
import axios from "axios";
import responsive from 'vue-responsive';
Vue.use(responsive);


// Account selection logic is app dependent. Adjust as needed for different use cases.
var accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback(function (event) {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    var payload = event.payload;
    var account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});


import { ApplicationInsights } from '@microsoft/applicationinsights-web'
const appInsights = new ApplicationInsights({ config: {
    connectionString: 'InstrumentationKey=bae60c58-de5c-4a05-a93f-82c9331bc12e;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'
}});
appInsights.loadAppInsights();
appInsights.trackPageView();

// Make Vue Router and Composition API available throughout your project
Vue.use(VueRouter);
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;
Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
// export async function fetchSettings() {
//   const response = await fetch("/api/settings");
//   return await response.json();
// }

// function fetchAppConfig() {
//   return new Promise((resolve) => {
//     let state = store.state;
//     fetchSettings().then((res) => {
//       console.log("settings   ", res);

//       // store.state.config = res;
//       // // store.replaceState(state);
//       // console.log("state   ", store.state);
//       resolve(res);
//     });
//   });
// }

// function createApp() {  
  var app = new Vue({
    router: router,
    store: store,
    data: function () {
      return {
        msalPlugin: msalPlugin,
        msalInstance: msalInstance,
      };
    },
    render: function (h) {
      return h(App);
    },
  });

  router.onReady(function () {
    app.$mount("#app");
  });
// }
// fetchAppConfig().then(createApp());
