<template>
    <div :class="isMobile() ? 'al-container-m' : 'al-container'">
    <b-row class="al-header">
      <p class="title">Links<span>.</span></p>
      <div class="search-container"> 
            <card-search v-on:emitSearchInput="setContent" /> 
            <b-row class="search-results">
            <search-results v-if="searchString.length>0" :result="searchCount" /> 
            </b-row>
        </div> 
    </b-row>
    <div class="al-body">


      <div class="menu-accordion" role="tablist">
        <b-card
          no-body
          class="menu-card"
          v-for="(mObj, index) in content"
          v-if="categoriesShown.includes(mObj.category)"
          :key="index"
        >
          <b-card-header header-tag="header" class="menu-header" role="tab">
            <b-button
              v-b-toggle="`${removeSpaces(mObj.category)}` + index"
              class="menu-btn"
              variant="info"
              @click="menuHeaderClick(mObj.category)"
              v-if="categoriesShown.includes(mObj.category)"
            >
              {{ mObj.category }}
              <img
                class="arrow"
                src="../assets/images/arrow_right.svg"
                alt="Sal"
              />
            </b-button>
          </b-card-header>
          <b-collapse
            :id="removeSpaces(mObj.category) + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="spinner">
              </div>
              <b-list-group-item
                class="link-item"
                v-for="(list, index) in mObj.appList"
                :key="index"
              >
                <img
                  class="favorite-icon"
                  :src="getFavImage(list.isFav)"
                  alt="Sal"
                  @click="favIconClicked(list, mObj.category)"
                />
                <p class="appname" @click="applinkClicked(list)">
                  {{ list.label }}
                </p>
              </b-list-group-item>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Utils from "../utils/Utils";
import Search from "../utils/Search.js";
import CardSearch from "../components/module/search/CardSearch.vue"
import SearchResults from "../components/module/search/SearchResults.vue"

export default {
  data() {
    return {
      currentComponent: "AdditionalLinks",
      appCategories: null,
      additionalLinksData: null,
      currentCollapseId: "productsandprocurement",
      isLoading: false,
      categoriesShown: [],
      showFavIcon: true,
      favoriteList: [],
      content: [],
      searchString: "",
      initialState: [],
    };
  },
  components: {
      CardSearch,
      Search,
      SearchResults
    },
  mounted() {
    this.getProfile();
    this.collectCategories();
    this.favoriteList = this.getFavorites.map((fav) => fav.appName);
    this.content = this.getAdditionalLinks
    this.initialState = this.getAdditionalLinks
  },
  computed: {
    ...mapGetters([
      "getMenuList",
      "getAdditionalLinks",
      "userName",
      "getFavorites",
      "getFavStatus",
      "getFavErrCode",
    ]),
    searchCount () {
            let c = 0;
            if(this.searchString.length>0) {
                 for (let i = 0; i<this.content.length; i++) {
                    // set the results length 
                    c += this.content[i].appList.length; 
                }
            }
            return c; 
        },
  },
  watch: {
    addLinks: function (n, o) {
      this.collectCategories();
    },
  },
  methods: {
    ...mapActions([
      "saveHistory",
      "fetchFavoritesList",
      "addFavorites",
      "deleteFavorite",
    ]),
    ...mapActions(["getProfile"]),

    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    setContent(value) {    
            this.searchString = value; 
            this.content = JSON.parse ( JSON.stringify ( this.initialState) )  
            if(value!="") {      
                this.content = Search.additionalLinks(value, this.content )
            } 
    }, 
    collectCategories() {
      const validItems = this.getAdditionalLinks;
      validItems.forEach((item) => {
        this.categoriesShown.push(item.category);
      });
    },
    removeSpaces(header) {
      return header.replace(/\s/g, "").toLowerCase();
    },
    async menuHeaderClick(appCategory) {
      this.currentCollapseId = appCategory;
    },
    checkAppUri(uri) {
      return uri === "" || uri === null || uri.includes("null") ? false : uri;
    },
    applinkClicked(appDetails) {
      if (
        appDetails.appStartingUrl === "" ||
        appDetails.appStartingUrl === null ||
        appDetails.appStartingUrl.includes("null")
      ) {
        this.$router.push("/noaccess");
      } else {
        const params = {
          menuLabel: appDetails.label,
          url: appDetails.appStartingUrl,
          breadcrumb: "",
          addWrapper: false,
        };
        const request = {
          userName: this.userName,
          environment: Utils.Strings.environment,
          userHistory: [params],
        };
        this.saveHistory(request);
        window.open(appDetails.appStartingUrl, "_blank");
      }
    },
    getFavImage(status) {
      return status
        ? Utils.Images.favAddLinksEnable
        : Utils.Images.favAddLinksDisable;
    },
    favIconClicked(list, appCat) {
      if (!list.isFav) {
        if (list.label != null && list.appStartingUrl != null) {
          const request = {
            userName: this.userName,
            environment: Utils.Strings.environment,
            userFavorites: [
              {
                menuLabel: list.label,
                url: list.appStartingUrl,
                breadcrumb: "",
                addWrapper: "",
              },
            ],
          };
          this.addFavorites(request).then(() => {
            this.$store.commit("updateFavStatus", {
              appName: list.label,
              appCategory: appCat,
              favStatus: true,
            });
          });
          this.fetchFavoritesList(this.userName);
        }
      } else {
        const request = {
          userName: this.userName,
          label: list.label,
          breadcrumb: "",
          url: list.appStartingUrl,
        };
        this.deleteFavorite(request).then(() => {
          this.$store.commit("updateFavStatus", {
            appName: list.label,
            appCategory: appCat,
            favStatus: false,
          });
        });
        this.fetchFavoritesList(this.userName);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/_shared.scss";

.search-container {
  padding-left: 5px;
  padding-right: 45px;
}
.search-results {
  padding-left: 20px;
}

.al-container-m {
  display: flex;
  flex-direction: column;

  .al-header {
    padding-left: 15px;
    text-align: left;
    p {
      margin-bottom: 0rem;
    }
    .title {
      color: $salBlue;
      font-weight: 700;
      font-size: 48px;
      font-family: "Isidora-Bold";
      line-height: 58px;

      span {
        color: $salRed;
        font-weight: 900;
      }
    }
    .desc {
      font-family: "Isidora Sans";
      font-weight: 500;
      font-size: 18px;
      color: $salLightGrey;
      line-height: 22px;
    }
  }
  .al-search {
    width: 100%;
    .input-group {
      // border: 0.5px solid $salBlue;
      // border-radius: 20px;
      background-color: white;
    }
    .form-control {
      border: none;
      border: 0.5px solid $salBlue;
      border-radius: 20px;
      border-right: none;
    }
    .input-group-append {
      margin-left: -4px !important;
    }
    .input-group-text {
      border-left: none !important;
      border: 0.5px solid $salBlue;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: white;
    }
  }
  .al-body {
    padding-left: 15px !important;
    position: relative;
    display: flex;
    max-height: 3500px;
    flex-direction: column !important;
    font-family: "Isidora";
    columns: 2;
    flex-wrap: wrap;
    margin: 10px 0;
    padding-left: 50px;
    background-color: white;
    .menu-accordion {
      display: flex;
      flex-direction: column !important;
      max-height: fit-content;

      .collapsed > .when-open,
      .not-collapsed > .when-closed {
        display: none;
      }

      .menu-card {
        margin-top: 20px;
        border: none;
        .spinner {
          text-align: center;
        }
        .card-body {
          border-left-width: 0;
          border-right-width: 0;
          border-top-width: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          width: 100%;
          margin: 20px auto;
          position: relative;
          display: flex;
          flex-direction: column !important;
          columns: 2;
          flex-wrap: wrap;
          max-height: 2500px;
        }

        .menu-header {
          height: 60px;
          background-color: white !important;
          .btn.menu-btn.btn-info.not-collapsed {
            border: none !important;
          }
          .btn.menu-btn.btn-info.not-collapsed:focus {
            border: none !important;
          }

          .menu-btn {
            .btn-info:focus {
              border: none !important;
            }
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: none;

            height: 40px;
            padding-bottom: 5px;
            color: $salRed;
            font-family: "Isidora";
            font-weight: 700;
            font-size: 18px;
            text-align: left;
            background-color: white;
          }
        }
        .appname:hover {
          cursor: pointer;
        }
        .appname {
          text-decoration: none;
          margin-bottom: -2px;
          padding-left: 3px;
        }
        .favorite-icon {
          cursor: pointer;
        }
        .link-item {
          color: $salBlue;
          font-family: "Isidora";
          font-weight: 600;
          font-size: 14px;
          text-align: left;
          max-width: fit-content;
          border-bottom: $salBlueBorder;
        }
        .list-group-item {
          padding: 5px 5px 0px 0px;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: $salBlueBorder;
          border-radius: 0;
          display: flex;
          align-items: center;
        }
      }
    }
    .link-group {


      .link-item {
        color: $salBlue;
        font-weight: 600;
        font-size: 24px;
        text-align: left;
      }
      margin-right: 20px;
      margin-top: 20px;


    }
  }
}

.al-container {
  display: flex;
  flex-direction: column;

  .al-header {
    padding-left: 75px;
    text-align: left;
    p {
      margin-bottom: 0rem;
    }
    .title {
      color: $salBlue;
      font-weight: 700;
      font-size: 48px;
      font-family: "Isidora-Bold";
      line-height: 58px;

      span {
        color: $salRed;
        font-weight: 900;
      }
    }
    .desc {
      font-family: "Isidora Sans";
      font-weight: 500;
      font-size: 18px;
      color: $salLightGrey;
      line-height: 22px;
    }
  }
  .al-search {
    width: 100%;
    .input-group {
      // border: 0.5px solid $salBlue;
      // border-radius: 20px;
      background-color: white;
    }
    .form-control {
      border: none;
      border: 0.5px solid $salBlue;
      border-radius: 20px;
      border-right: none;
    }
    .input-group-append {
      margin-left: -4px !important;
    }
    .input-group-text {
      border-left: none !important;
      border: 0.5px solid $salBlue;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: white;
    }
  }
  .al-body {
    position: relative;
    display: flex;
    max-height: 3500px;
    flex-direction: column !important;
    font-family: "Isidora";
    columns: 2;
    flex-wrap: wrap;
    margin: 10px 0;
    padding-left: 50px;
    background-color: white;
    .menu-accordion {
      display: flex;
      flex-direction: column !important;
      max-height: fit-content;

      .collapsed > .when-open,
      .not-collapsed > .when-closed {
        display: none;
      }

      .menu-card {
        margin-top: 20px;
        border: none;
        .spinner {
          text-align: center;
        }
        .card-body {
          border-left-width: 0;
          border-right-width: 0;
          border-top-width: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          width: 100%;
          margin: 20px auto;
          position: relative;
          display: flex;
          flex-direction: column !important;
          columns: 2;
          flex-wrap: wrap;
          max-height: 2500px;
        }

        .menu-header {
          height: 60px;
          background-color: white !important;
          .btn.menu-btn.btn-info.not-collapsed {
            border: none !important;
          }
          .btn.menu-btn.btn-info.not-collapsed:focus {
            border: none !important;
          }

          .menu-btn {
            .btn-info:focus {
              border: none !important;
            }
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: none;

            height: 40px;
            padding-bottom: 5px;
            color: $salRed;
            font-family: "Isidora";
            font-weight: 700;
            font-size: 32px;
            text-align: left;
            background-color: white;
          }
        }
        .appname:hover {
          cursor: pointer;
        }
        .appname {
          text-decoration: none;
          margin-bottom: -2px;
          padding-left: 3px;
        }
        .favorite-icon {
          cursor: pointer;
        }
        .link-item {
          color: $salBlue;
          font-family: "Isidora";
          font-weight: 600;
          font-size: 24px;
          text-align: left;
          max-width: fit-content;
          border-bottom: $salBlueBorder;
        }
        .list-group-item {
          padding: 5px 5px 0px 0px;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: $salBlueBorder;
          border-radius: 0;
          display: flex;
          align-items: center;
        }
      }
    }
    .link-group {


      .link-item {
        color: $salBlue;
        font-weight: 600;
        font-size: 24px;
        text-align: left;
      }
      margin-right: 20px;
      margin-top: 20px;


    }
  }
}

@media screen and (min-width: $small) and (max-width: $medium) {
  .al-container {
    flex-wrap: nowrap;
    max-height: fit-content;
  }
}
</style>
