import {
  AdditionalLinksMenu,
  AdditionalLinksSubMenu,
} from "@/api/AdditionalLinks";

export const fetchMenu = async ({ commit }, data) => {
  return AdditionalLinksMenu(data)
    .then((response) => {
        if (!response.data) {
            response.data = []
        }
        commit("saveMenu", response.data);
      return response.data
    })
    .catch((err) => {
      console.error("API failed - ", err);
      commit("saveMenu", []);
    })
      .finally();
};

export const fetchSubMenu = async ({ commit }, data) => {
  return AdditionalLinksSubMenu(data)
    .then((response) => {
      commit("saveSubMenu", {
        res: response.data,
        appCategory: data.businessType,
      });

      // return {
      //     res: response.data,
      //     appCategory: data.businessType,
      // };
    })
    .catch((err) => {
      console.error("API failed - ", err);
    });
};
