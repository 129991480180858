<template>
    <table v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'TableBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    mounted() {
        this.block.saveContent = this.block.saveContent.replace(/<figure/g, '<div').replace(/<\/figure>/g, '</div>');
    }
}

</script>

<style lang="scss" scoped>
table {
    border: 1px solid #14377C;
    background-color: #CBCBCB;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-family: "Isidora Sans";
    ::v-deep th, td {
        border: 1px solid #14377C;
        padding: 5px 5px;
    }
    ::v-deep td {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        border-right: 1px solid #14377C;
    }
    ::v-deep tr:nth-child(even) {
        background-color: #F2F2F2;
    }
    ::v-deep tr:nth-child(odd) {
        background-color: #FFFFFF;
    }
    ::v-deep tr:first-child {
        font-weight: bold;
        font-size: 24px;
    }
    a {
        color: #14377C;
        text-decoration: underline;
    }
}




table {
    margin-bottom: 20px;
}

</style>