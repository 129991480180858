<template>
  <div class="wc-container">
    <b-row class="page-title">Weekly Checkout Archives<span>.</span></b-row>
    <b-row class="wc-body" no-gutters>
      <b-col class="calendar">
        <div class="header">
          <img
            class="side-arrows down-arr"
            src="../../../assets/images/left-arr.svg"
            alt="Sal"
            @click="leftArrowClicked()"
          />
          <p>{{ selectedYear }}</p>
          <img
            class="side-arrows up-arr"
            src="../../../assets/images/right-arr.svg"
            alt="Sal"
            @click="rightArrowClicked()"
          />
        </div>
        <div v-if="noDataMessage">No Data Available</div>
        <div v-else class="month-accordion" role="tablist">
          <b-card
            no-body
            class="month-card"
            v-for="(month, index) in newmonth"
            :key="index"
          >
            <b-card-header header-tag="header" class="month-header" role="tab">
              <b-button
                v-b-toggle="'accordion' + index"
                class="month-btn"
                @click="getDataByMonth(month,index)"
              >
                <img class="arrow" v-if="!month.flag" :src="getIconForDropdown()" alt="Sal" />
                <img class="arrow" v-if="month.flag" :src="getIconForDropdownNew()" alt="Sal" />

                {{ month.name }}
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'accordion' + index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-list-group-item
                  class="link-item"
                  v-for="(wcObj, index) in monthsData"
                  :key="index"
                  target="_blank"
                  :active="getActiveStatus(wcObj.data[0].docLink)"
                  @click="updatePDFLink(wcObj.data[0].docLink)"
                >
                  {{ wcObj.data[0].docTitle }}
                </b-list-group-item>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
      <b-col class="pdf-view">
        <iframe class="pdf" :src="documentPath"></iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { mapGetters, mapActions } from "vuex";
import { NEWS_WEEKLY_CHECKOUT_QUERY } from "../../../queries/gqlQueries.js";
import Utils from "../../../utils/Utils.js";

export default {
  name: "WeeklyCheckoutArchives",
  data() {
    return {
      newmonth: [{
        name:"January",
        flag:false
        },
        {
        name:"February",
        flag:false
        },
        {
        name:"March",
        flag:false
        },
        {
        name:"April",
        flag:false
        },
        {
        name:"May",
        flag:false
        },
         {
        name:"June",
        flag:false
        },
        {
        name:"July",
        flag:false
        },
         {
        name:"August",
        flag:false
        },
        {
        name:"September",
        flag:false
        },
        {
        name:"October",
        flag:false
        },
        {
        name:"November",
        flag:false
        },
       {
        name:"December",
        flag:false
        }
      ],
      monthsData: [],
      documentPath: "",
      selectedYear: "2022",
      selectedMonth: "",
      toggle: false,
      numPages: 1,
      noDataMessage: false,
      currentPage: 0,
      pageCount: 0,
      src: "",
    };
  },
  components: {
    pdf,
  },
  created() {
    this.fetchWeeklyCheckoutArchives(NEWS_WEEKLY_CHECKOUT_QUERY).then(() => {
      this.loadInitalData();
      this.src = pdf.createLoadingTask(this.documentPath);
    });
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      this.toggle = isJustShown;
    });
  },
  computed: {
    ...mapGetters(["getWCArchives"]),
  },
  methods: {
    ...mapActions(["fetchWeeklyCheckoutArchives"]),
    getMonths() {
      const month = [{
        name:"January",
        flag:false
        },
        {
        name:"February",
        flag:false
        },
        {
        name:"March",
        flag:false
        },
        {
        name:"May",
        flag:false
        },
         {
        name:"June",
        flag:false
        },
        {
        name:"July",
        flag:false
        },
         {
        name:"August",
        flag:false
        },
        {
        name:"September",
        flag:false
        },
        {
        name:"October",
        flag:false
        },
        {
        name:"November",
        flag:false
        },
       {
        name:"December",
        flag:false
        }
      ];
      return month;
    },
    loadInitalData() {
      if (this.getWCArchives.length > 0) {
        this.selectedYear = this.getWCArchives[0].year;
        this.newmonth.forEach((month) => {
          this.getDataByMonth(month.name);
          if (this.documentPath === "" || this.documentPath === null) {
            this.monthsData.forEach((wcObj) => {
              if (
                wcObj.data[0].docLink !== null ||
                wcObj.data[0].docLink !== ""
              ) {
                this.documentPath = wcObj.data[0].docLink.mediaItemUrl;
                return;
              }
            });
          } else {
            return;
          }
        });
      }

      var loadingTask = pdf.createLoadingTask(this.documentPath);
      loadingTask.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    getDataByMonth(month,index) {   
       this.newmonth[index].flag = !this.newmonth[index].flag;
       if(this.newmonth[index].flag){
       this.newmonth.forEach((obj,i) => {
           if(i != index){
            obj.flag=false;
           }
       })}
      const cYearData = this.getWCArchives.filter(
        (archive) => archive.year === this.selectedYear
      );
      this.monthsData = cYearData[0].data.filter((obj) => obj.month === month.name);
    },
    updatePDFLink(link) {
      this.documentPath = link.mediaItemUrl;
      var loadingTask = pdf.createLoadingTask(this.documentPath);
      loadingTask.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
      this.src = loadingTask;
    },
    getIconForDropdown() {
        return Utils.Images.downarr;
    },
    getIconForDropdownNew() {
        return Utils.Images.toparr;
    },
    leftArrowClicked() {
      this.selectedYear--;
      const cYearData = this.getWCArchives.filter(
        (archive) => archive.year === this.selectedYear
      );
      this.noDataMessage = cYearData.length === 0;
    },
    rightArrowClicked() {
      this.selectedYear++;
      const cYearData = this.getWCArchives.filter(
        (archive) => archive.year === this.selectedYear
      );
      this.noDataMessage = cYearData.length === 0;
    },
    getActiveStatus(selectedLink) {
      return selectedLink.mediaItemUrl === this.documentPath;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    overflow: hidden;
}

.card {
    border: .5px solid RGB(20, 55, 125, .5);
    border-right: none;
    border-left: none;
    border-top: none;
    --bs-card-border-width: 0px;
    --bs-card-border-color: RGB(20, 55, 125, .5);
    overflow: hidden;
}

.card:first-child {
    border-top: .5px solid RGB(20, 55, 125, .5);
}

.card:last-child {
    border-bottom: none;
}

.wc-container {
  background-color: white;

  .page-title {
    display: block;
    padding-left: 80px;
    padding-top: 60px;
    text-align: left;
    background-color: white;
    color: $salBlue;
    font-weight: 700;
    font-size: 48px;
    font-family: "Isidora-Bold";
    line-height: 58px;
    span {
      color: $salRed;
      font-weight: 900;
    }
  }
  .wc-body {
    padding-left: 65px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;

    .calendar {
      max-width: 25%;
      min-width: 15%;
      overflow: hidden;
      border-radius: 20px;
      border: none;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      margin: 10px;
      background-color: white;

      .header {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: flex-start;
        width: 150px;
        height: 40px;
        margin-left: 20px;
        margin-top: 15px;
        .side-arrows:hover {
          cursor: pointer;
        }
        p {
          padding: 5px;
          text-align: center;
          font-family: "Isidora-SemiBold";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: $salRed;
        }
      }

      .month-accordion {
        display: flex;
        flex-direction: column !important;
        max-height: fit-content;
        border-radius: 20px;

        margin-top: 10px;
        background-color: white;

        .collapsed > .when-open,
        .not-collapsed > .when-closed {
          display: none;
        }

        .month-card {
          background-color: white !important;

          .card-body {
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            margin: 20px auto;
            position: relative;
            display: flex;
            flex-direction: column !important;
            columns: 2;
            flex-wrap: wrap;
            max-height: 2500px;
          }

          .month-header {
            height: 60px;
          }

          .list-group-item {
            padding: 5px 5px 0px 0px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            font-family: "Isidora Sans";
            font-weight: 500;
            font-size: 18px;
            color:#14377D;
          }
          .list-group-item:hover {
            cursor: pointer;
          }
          .list-group-item.active {
            cursor: pointer;
            color: red;
          }
        }
      }
    }
    .pdf-view {
      box-shadow: 1px;
      border-radius: 20px;
      border: none;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      margin: 10px;
      background-color: white;
      width: 70%;

      .pdf {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.arrow {
  margin-right: 20px !important;
}
.btn-secondary {
  border-color: transparent !important;
  background-color: white !important;
  color: $salBlue !important;
  padding-left: 20px !important;
  font-family: "Isidora-SemiBold" !important;
  font-size: 24px !important;
  line-height: 30px !important;
  height: 40px !important;
  width: 300px !important;
  text-align: left !important;
}
.btn-secondary:visited,
.btn-secondary:focus,
.btn-secondary:focus-visible {
  border-color: transparent !important;
  box-shadow: transparent !important;
}
.card {
    border-radius: 0px !important;
    --bs-card-border-color: rgba(20, 55, 125, 0.5) !important;
    --bs-card-border-width: .5px !important;
    border-bottom: none !important;

    &:not(:first-child) {
        border-top: none !important;
    }

    .card-body {
        margin-top: 10px;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        position: relative;
        display: flex;
        flex-direction: column !important;
        columns: 2;
        flex-wrap: wrap;
        max-height: 2500px !important;
    }
}

.card-header {
    background-color: white !important;
}
</style>
