<template>
  <div
    :class="!isMobile() ? 'post-cards sharing' : 'post-cards sharing-mobile'"
  >
    <div class="card-content">
      <div class="card-header">
        <span class="user-info">
          <img
            src="../../../assets/ProfileDefault.svg"
            alt="Author profile picture"
          />
          <span class="author-info">
            <h1>{{ post.authorName }}, {{ post.authorTitle }}</h1>
            <h2>{{ post.authorLocation }}</h2>
          </span>
        </span>
      </div>
      <div class="card-body share">
        <h1>{{ post.headline }}</h1>
        <h2>{{ formatRawDate(post.postedDate) }}</h2>
        <p class="card-summary" v-html="post.story"></p>
      </div>
      <div class="footer">
        <a target="_blank" @click="readMorePost(post)">
          <img src="../../../assets/ReadMore.svg" style="cursor: pointer" />
        </a>

          <a target="_blank" v-if="this.getSaveProfile" @click="saveThisItem(post.databaseId)" class="save-btn-share">
              <img
                  :src="isSaved ? require('../../../assets/SaveFilled.svg') : require('../../../assets/Save.svg')"
              />
          </a>
      </div>
    </div>
  </div>
</template>

<script>
import { convertDate } from "../../../utils/Dates.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getSaveProfile"]),
    isSaved: function () {
        if (this.getSaveProfile.userPosts.length > 0) {
            for (let i = 0; i < this.getSaveProfile.userPosts.length; i++) {
                if (this.getSaveProfile.userPosts[i].postId == this.post.databaseId) {
                    return true;
                }
            }
        }
        return false;
    },
  },
  methods: {
    ...mapActions(["updateSaveProfile"]),
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 1536 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet || this.isIpadPro();
    },
    saveThisItem(id) {
        this.updateSaveProfile(id);
    },
    readMorePost(post) {
      this.$emit("readMore", post);
      window.scrollTo(0, 325);
    },
    formatRawDate(date) {
      return convertDate(date);
    },
  },
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/newsfeed-cards.scss";
</style>
