<template>
    <div class="sortFilterBox" v-responsive="['hidden-all', 'lg', 'xl']"> 

        <div id="sortbox"> 
            <h2 id="sorttitle" class="sorthead">Sort</h2>
            <ul v-for="sort in sortOptions" :key="sort" :class="sortSelected.includes(sort) ? 'sort-selected' : 'ul'">
                    <img
                        class="larger"
                        :src="getRadioImage(sortSelected.includes(sort))"
                        alt="Sal"
                        @click="sortInputSelected(sort)"
                        name="sort" 
                        :value="sort"
                      /> {{ sort }}
                    </ul>

        </div> 
        <div id="filterbox"> 
            <h2 id="filtertitle" class="filterhead">Filter</h2>
            <ul v-for="filter in filterOptions" :key="filter" :class="filterSelected.includes(filter) ? 'sort-selected' : 'ul'">
                <img
                    class="larger"
                    :src="getCheckboxImage(isSelected(filter))"
                    alt="Sal"
                    @click="filterInputSelected(filter)"
                    name="filterCB" 
                    :value="filter"
                    /> {{ filter }}
                </ul>
        </div>
    </div>   
</template>

<script>
  import Utils from "../utils/Utils";
  import { mapGetters } from 'vuex';
    export default {
        props: {
            sortOptions: {
                type: Array,
                required: true
            },
            filterOptions: {
                type: Array,
                required: true
            }, 
            sortDefault: {
                type: [String,Number],
                required: true 
            },
            page: {
                type: String,
                required: true 
            }
        },
        data () {
            return {
                userFiltered: [], 
                userSorted: [],
                selected: 'first',
                filterSelected : [],                
                sortSelected : [],
            }
        },
        methods: {
            isSelected(filter) {
                for (let i=0; i<this.filterSelected.length; i++) {
                    if(this.filterSelected[i]==filter) {
                        return true
                    }
                }
                return false
            },

            refresh(filter) {
                this.emitSettings(); 
            },
            emitSettings() {
                this.$emit('emitUserSettings', 
                    `{"Sort":` + JSON.stringify(this.sortSelected) 
                    + `,"Filter":` + JSON.stringify(this.filterSelected)+`}`); 
            },
            sortInputSelected(sort){
                if(this.sortSelected.length > 0){
                    this.sortSelected = [];
                     this.sortSelected.push(sort);
                } else {
                    this.sortSelected.push(sort);
                }
                this.$store.commit("mutateSort"+this.page, this.sortSelected[0])
                window.scrollTo(0, 0);
                this.emitSettings(); 
            },
            filterInputSelected(filter){
                if(!this.filterSelected.includes(filter)){
                    this.filterSelected.push(filter);
                } else {
                    const remove = this.filterSelected.indexOf(filter);
                    this.filterSelected.splice(remove, 1);
                }
                if(this.filterSelected.length==0) {
                    this.$store.commit("mutateFilter"+this.page)
                } else {
                    this.$store.commit("mutateFilter"+this.page,this.filterSelected)
                }
                this.userFiltered =  this.filterSelected;
                this.emitSettings(); 
                window.scrollTo(0, 350);
            },
            getCheckboxImage(status) {
                return status ? Utils.Images.filterCheckboxSelected : Utils.Images.filterCheckboxEmpty;
            },
            getRadioImage(status) {
                return status ? Utils.Images.sortRadioButtonSelected : Utils.Images.sortRadioButtonEmpty;
            }
        },
        computed: {
            ...mapGetters([
                'getSortNewsUpdates',
                'getFilterNewsUpdates',
                'getSortDotComm',
                'getFilterDotComm',
                'getSortWebinars',
                'getFilterWebinars',
                'getSortSharingCorner',
                'getFilterSharingCorner',
                ]),
            
        },
        created() {
            this.sortSelected = []; 
            this.filterSelected = []; 

            switch(this.page) {
                case "NewsUpdates": 
                    this.sortSelected.push(this.getSortNewsUpdates);
                    
                    for(let i=0; i < this.getFilterNewsUpdates.length; i++){
                        this.filterSelected.push(this.getFilterNewsUpdates[i]);
                    }
                    if(this.filterSelected.length>0) {
                        this.emitSettings(); 
                    }
                    break;
                case "DotComm":
                    this.sortSelected.push(this.getSortDotComm);
                    for(let i=0; i < this.getFilterDotComm.length; i++){
                        this.filterSelected.push(this.getFilterDotComm[i]);
                    }
                    if(this.filterSelected.length>0) {
                        this.emitSettings(); 
                    }

                    break;
                case "Webinars":
                    this.sortSelected.push(this.getSortWebinars);
                    for(let i=0; i < this.getFilterWebinars.length; i++){
                        this.filterSelected.push(this.getFilterWebinars[i]);
                    }
                    if(this.filterSelected.length>0) {
                        this.emitSettings(); 
                    }

                    break;
                case "SharingCorner":
                    this.sortSelected.push(this.getSortSharingCorner);
                    for(let i=0; i < this.getFilterSharingCorner.length; i++){
                        this.filterSelected.push(this.getFilterSharingCorner[i]);
                    }
                    if(this.filterSelected.length>0) {
                        this.emitSettings(); 
                    }

                    break;
            }
            
        }
};


</script>
<style scoped>
@font-face {
    font-family: 'Isidora-SemiBold';
    src: url('../assets/fonts/Isidora/Isidora-SemiBold.otf');
}
ul {

    vertical-align: middle;
    display: flex;
    align-items: center;
    font-family: 'Isidora-SemiBold';
    font-size: 20px;
    line-height: 2em;
    color: #7387B2;
    text-decoration: none;
    background-color:white;
    text-indent: 20px;
    list-style-position: outside;
    list-style-type: square;
    padding: 0;
    margin: 0;
}
.sort-selected{
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-family: 'Isidora-SemiBold';
    font-size: 20px;
    line-height: 2em;   
    text-decoration: none;
    background-color:white;
    text-indent: 20px;
    list-style-position: outside;
    list-style-type: square;
    padding: 0;
    margin: 0;
    color: #14377C ;
   
 }
.larger {
    width: 36px;
    height: 36px;
    border-radius: 40px;
}

#sortbox {
    float: center;
}
#filterbox {
    float: center;
}
ul,section {
  text-align: left;
}
.sortFilterBox {
    background-color: white;
    padding-left: 65px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sorthead, .filterhead {
    background-color: red;
    color: white;
    text-align: center;
    font-size: 20px;
    border-radius: 30px;
    height: 35px;
    font-family: 'Isidora-SemiBold';
    vertical-align: top;
}

.sorthead {
    width: 65px;
}

.filterhead {
    width: 75px;
}

.filterhead {
    margin-top: 30px;
}

.sorthead, .filterhead h2 {
    line-height: 35px;
}

.filterhead, .sorthead h2 {
    line-height: 35px;
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}
</style>