const MENU_LOADING_UPDATE = (state, isLoading) => {
    state.menuLoading = isLoading
}

const VISIBLE_COMMON_LINKS_CHANGED = (state, links) => {
    state.visibleCommonLinks = links
}

const COMMON_MENU_LINKS_LOADED = (state, links) => {
    state.commonLinks = links;
}

const FAVORITE_MENU_LINKS_LOADED = (state,links) => {
    state.favoriteLinks = links
}

export default {
    MENU_LOADING_UPDATE,
    VISIBLE_COMMON_LINKS_CHANGED,
    COMMON_MENU_LINKS_LOADED,
    FAVORITE_MENU_LINKS_LOADED
}