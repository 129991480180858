<template>
  <b-row class="rpc-footer">
    <b-row class="social-media">
      <a class="icon instagram" :href="getIcons.instagramUrl" target="_blank">
        <img :src="getIcons.instagram" alt="Sal" />
      </a>
      <a class="icon twitter" :href="getIcons.twitterUrl" target="_blank">
        <img :src="getIcons.twitter" alt="Sal" />
      </a>
      <a class="icon linkedin" :href="getIcons.linkedinUrl" target="_blank">
        <img :src="getIcons.linkedin" alt="Sal" />
      </a>
      <a class="icon facebook" :href="getIcons.facebookUrl" target="_blank">
        <img :src="getIcons.facebook" alt="Sal" />
      </a>
      <a class="icon tiktok" :href="getIcons.tiktokUrl" target="_blank">
        <img :src="getIcons.tiktok" alt="Sal" />
      </a>
      <a class="icon youtube" :href="getIcons.youtubeuUrl" target="_blank">
        <img :src="getIcons.youtube" alt="Sal" />
      </a>
    </b-row>
    <b-row class="footer-content">
      <b-row class="inside">
        <b-col class="links-1">
          <b-row
            ><a class="f-link" :href="getIcons.privacyPolicy" target="_blank">
              Privacy Policy</a
            ></b-row
          >
          <b-row
            ><a class="f-link" :href="getIcons.termsOfUse" target="_blank">
              Terms of Use</a 
            ></b-row
          >
          <b-row>
            <a v-if="contactUsIsEmail" class="f-link" :href="`mailto:` + getIcons.contactUs"> Contact Us</a>
            <a v-else class="f-link" :href="getIcons.contactUs" target="_blank"> Contact Us</a> 
          </b-row>
        </b-col>
        <b-col class="links-2">
          <b-row
            ><a class="f-link" :href="getIcons.coronaResources" target="_blank">
              Coronavirus Resources</a
            ></b-row
          >
          <b-row
            ><a class="f-link" :href="getIcons.ownaSal" target="_blank">
              Own a Save A Lot</a
            ></b-row
          >
        </b-col>
        <b-col class="links-3"> </b-col>
        <b-col class="links-4"> </b-col>
      </b-row>
    </b-row>
  </b-row>
</template>
<script>
export default {
  props: {
    pageDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contactUsIsEmail: false,
    };
  },
  watch: {
      pageDetails(newValue) {
            if(newValue.contactUs.includes('@')) {
              this.contactUsIsEmail = true; 
            }
        }
    }, 
  computed: {
    getIcons() {
      return {
        tiktok: this.pageDetails.tiktokIcon,
        tiktokUrl: this.pageDetails.tiktokUrl,
        instagram: this.pageDetails.instagramIcon,
        instagramUrl: this.pageDetails.instagramUrl,
        linkedin: this.pageDetails.linkedinIcon,
        linkedinUrl: this.pageDetails.linkedinUrl,
        facebook: this.pageDetails.facebookIcon,
        facebookUrl: this.pageDetails.facebookUrl,
        twitter: this.pageDetails.twitterIcon,
        twitterUrl: this.pageDetails.twitterUrl,
        youtube: this.pageDetails.yotubeIcon,
        youtubeuUrl: this.pageDetails.youtubeuUrl, 
        privacyPolicy: this.pageDetails.privacyPolicy,
        termsOfUse: this.pageDetails.termsOfUse,
        coronaResources: this.pageDetails.coronaResources,
        contactUs: this.pageDetails.contactUs,
        ownaSal: this.pageDetails.ownaSal,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_shared.scss";

.rpc-footer {
  width: 100%;
  height: auto;
  margin-left: 12px;

  .social-media {
    height: auto;
    background-color: $salBlue;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;

    .icon {
      width: 40px;
      height: 40px;
      margin-right: 25px;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;

      @media (max-width: 576px) {
        width: 20px;
        height: 30px;
        margin-right: 9px;
        cursor: pointer;
      }
      img {
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 20px;
        @media (max-width: 576px) {
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 10px;
        }
      }
    }
  }

  .footer-content {
    width: 100%;
    height: auto;
    .inside {
      padding: 40px;
      @media (max-width: 576px) {
        padding: 20px 40px 40px 40px;
      }
    }
    .links-1 {
      flex: 10%;
      flex-direction: column;
    }
    .links-2 {
      flex: 13%;
      flex-direction: column;
    }
    .links-3 {
      flex: 13%;
      flex-direction: column;
    }
    .links-3 > p {
      text-align: left;
      padding-left: 15px;
    }
    .links-4 {
      flex: 40%;
      flex-direction: column;
    }
    .f-link {
      text-decoration: none;
      color: $salBlue;
      text-align: left;
      font-family: "Isidora";
      font-weight: 500;
      font-size: 18px;
      padding-left: 5px;
      color: $salBlue;
      cursor: pointer;
      width: max-content;
    }
    .f-link:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
