import Main from "@/components/Main";

const distributionRoutes = [
    {
        path: '/distribution-resources',
        component: Main,
        meta: { requiresAuth: true },
        props: {
            componentName: 'Resource',
            pageTitle: "Distribution Resources",
        }
    },
    {
        path: '/distribution-center-information',
        component: Main,
        meta: { requiresAuth: true },
        props: {
            componentName: 'CenterInformation',
            pageTitle: "Distribution Center Information",
        }
    },]

export default distributionRoutes