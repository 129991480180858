var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer"},[_c('div',{staticClass:"readMore"},[(_vm.showReadMore)?_c('read-more',{staticClass:"readContent",attrs:{"readMoreObj":_vm.readMoreObj,"breadcrumb":"Dashboard","dashboard":true},on:{"close":function($event){_vm.showReadMore = false},"readMore":_vm.readMore}}):_vm._e()],1),(!_vm.showReadMore)?_c('div',{staticClass:"dash-content"},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'lg', 'xl']),expression:"['hidden-all', 'lg', 'xl']"}],staticClass:"heading-dash"},[_c('h1',[_vm._v(_vm._s(_vm.getHeaders.carouselHeader))]),_c('h1',{staticClass:"red-dot"},[_vm._v(".")])]),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'md']),expression:"['hidden-all', 'md']"}],staticClass:"heading-dash"},[_c('h2',[_vm._v(_vm._s(_vm.getHeaders.carouselHeader))]),_c('h2',{staticClass:"red-dot"},[_vm._v(".")])]),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'sm', 'xs']),expression:"['hidden-all', 'sm', 'xs']"}],staticClass:"heading-dash mobile"},[_c('h3',[_vm._v(_vm._s(_vm.getHeaders.carouselHeader))]),_c('h3',{staticClass:"red-dot"},[_vm._v(".")])]),(_vm.ready)?_c('div',{class:_vm.isMobile() ? 'carousel-wrapper-mobile' : 'carousel-wrapper'},[_c('carousel',{staticClass:"carousel",attrs:{"perPage":_vm.getItemsPerPage(),"paginationColor":"gray","paginationActiveColor":"#14377C","paginationSize":20,"paginationEnabled":false,"paginationPadding":5,"navigationEnabled":true,"navigation-next-label":this.isMobile() || this.isiPad() ? '' : _vm.navigationNext,"navigation-prev-label":this.isMobile() || this.isiPad() ? '' : _vm.navigationPrev}},_vm._l((_vm.allCards),function(item){return _c('slide',{key:item.name,class:_vm.isiPad()
              ? 'cards-slide iPad'
              : _vm.isMobile()
              ? 'cards-slide mobile'
              : 'cards-slide'},[_c('div',{class:_vm.isiPad()
                ? 'card-container iPad'
                : _vm.isMobile()
                ? 'card-container mobile'
                : 'card-container'},[(Object.keys(item)[9] == 'postAttachments')?_c('card-news-updates',{attrs:{"postedDate":item.postedDate,"headline":item.headline,"cardSummary":item.cardSummary,"attachments":_vm.getAttachments(item),"story":item.story,"isUrgent":item.urgentPost,"id":item.databaseId},on:{"readMore":_vm.readMore}}):_vm._e(),(Object.keys(item)[9] == 'pDFAttachments')?_c('CardDotComm',{attrs:{"postedDate":item.postedDate,"headline":item.headline,"cardSummary":item.cardSummary,"attachments":_vm.getAttachments(item),"story":item.story,"isUrgent":item.urgentPost,"id":item.databaseId},on:{"readMore":_vm.readMore}}):_vm._e()],1)])}),1)],1):_vm._e(),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'lg', 'xl']),expression:"['hidden-all', 'lg', 'xl']"}],staticClass:"heading-dash"},[_c('h1',{staticStyle:{"padding-top":"15px"}},[_vm._v(_vm._s(_vm.getHeaders.poerBItitle))]),_c('h1',{staticClass:"red-dot",staticStyle:{"padding-top":"15px"}},[_vm._v(".")])]),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'md']),expression:"['hidden-all', 'md']"}],staticClass:"heading-dash"},[_c('h2',{staticStyle:{"padding-top":"15px"}},[_vm._v(_vm._s(_vm.getHeaders.poerBItitle))]),_c('h2',{staticClass:"red-dot",staticStyle:{"padding-top":"15px"}},[_vm._v(".")])]),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(['hidden-all', 'sm', 'xs']),expression:"['hidden-all', 'sm', 'xs']"}],staticClass:"heading-dash mobile"},[_c('h3',{staticStyle:{"padding-top":"15px"}},[_vm._v(_vm._s(_vm.getHeaders.poerBItitle))]),_c('h3',{staticClass:"red-dot",staticStyle:{"padding-top":"15px"}},[_vm._v(".")])]),_c('b-row',{staticClass:"power-bi"},[_c('div',{staticClass:"bidash"},[_c('iframe',{class:_vm.phoneDash ? 'phone-dash' : 'dash',attrs:{"src":_vm.iframeUrl}})])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }