<template>
    <div v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'MediaTextBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    mounted() {
        this.block.saveContent = this.block.saveContent.replace(/<figure/g, '<div').replace(/<\/figure>/g, '</div>');
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

::v-deep .wp-block-media-text {
    overflow: hidden;
    padding-bottom: 25px;
    .wp-block-media-text__content {
        width: 50%;
        float: left;
        text-align: left;
        vertical-align: top;
        padding-left: 20px;
        p {
            font-family: "Isidora Sans";
            color: $salLightGrey;
            text-align: left;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .wp-block-media-text__media {
        width: 50%;
        img {
            float: left;
            max-width: 100%;
            max-height: 100%;
            border-radius: 10px;
        }
    }
}

::v-deep img {
    max-width: 50%;
    height: auto;
}

</style>