<template>
    <div class="outerDiv">
        <div class="heading" v-if="!showRead">
            <h1>{{this.getSavedPage.pageTitle}}</h1>
            <h2>.</h2>
            <div class="filter-bar">
                <div class="filters" v-for="filter in filters" :key="filter.name">
                    <button @click="filterSelected(filter.name)" :class="filter.active ? 'selected-btn' : 'inactive'">{{filter.name}}</button>
                </div>
            </div>
        </div>
        <div class="separator" v-if="!showRead">
            <p class="separator-text">{{this.getSavedPage.pageDescription}}</p>
            <hr class="separator-bar">
        </div>
        <div v-if="!noCards">
            <b-row cols="4" class="cards-row" v-for="item in filters" :key="item.name" v-if="item.active && !showRead && !loading">
              <p class="no-cards" v-if="!showRead && !item.cards[0].length > 0">No saved items in {{item.name}}.</p>
                <b-col class="cards-col" v-for="card in item.cards[0].slice(displayIdx, nextDisplayIdx)" :key="card.databaseId">
                    <component
                        :is="currentComponent"
                        :postedDate="card.postedDate"
                        :headline="card.headline"
                        :cardSummary="card.cardSummary"
                        :attachments="getAttachments(card)"
                        :story="card.story"
                        :isUrgent="card.urgentPost"
                        :post="card"
                        :webPosts="card"
                        :id ="card.databaseId"
                        @readMore="readMoreClick(item.name, $event)"
                     />
                </b-col>
            </b-row>
        </div>
        <div class="card-check" v-if="noCards && item.active" v-for="item in filters" :key="item.name">
            <p class="no-cards">No saved items in {{item.name}}.</p>
        </div>
        <div class="pagination" v-if="!loading && !showRead">
            <Pager
                v-on:emitCurrentDisplayIdx="updateCurrentDisplay"
                :buttoncount=buttonCount
                :cardsperpagecount=cardsPerPage
                :key="reloadPager"
            />
        </div>
        <div class="read-more-content" v-if="showRead">
            <component
                :is="currentReadMore"
                :readMoreObj="readMoreObj"
                :post="readMoreObj"
                :breadcrumb="`Saved Posts`"
                @close="close()"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { GET_SAVED_POSTS } from "../../../queries/gqlQueries";
import NewsUpdates  from "../cards/NewsUpdates";
import DotComm  from "../cards/DotComm";
import CardWebinar from "../cards/CardWebinar";
import SharingCards from "../cards/SharingCards";
import ReadMore from "../cards/ReadMore.vue"
import ReadMoreDotComm from "../cards/ReadMoreDotComm.vue"
import SharingCornerRead from '../traininghub/SharingCornerRead.vue';
import Pager from '../../pagination/Pager.vue';

export default {
    name: "Saved",
    components: {
        NewsUpdates,
        DotComm,
        CardWebinar,
        SharingCards,
        ReadMore,
        ReadMoreDotComm,
        SharingCornerRead,
        Pager
    },
    data() {
        return {
            filters:
            [
                {
                'name':'Important News and Updates',
                'active': true,
                'cards': []
                },
                {
                'name':'Dot Comm',
                'active': false,
                'cards': []
                },
                {
                'name':'Webinars',
                'active': false,
                'cards': []
                },
                {
                'name':'Sharing Corner',
                'active': false,
                'cards': []
                }
            ],
            currentComponent: 'NewsUpdates',
            readMoreObj: Object,
            showRead: false,
            buttonCount: 0,
            cardsPerPage: 12,
            loading: true,
            displayIdx: 0,
            nextDisplayIdx: 9,
            reloadPager: 0,
            noCards: false,
        }
    },
    mounted() {
        this.getCards();
        if(!this.getSavedPage.pageTitle) {
            this.fetchSavePageInfo();
        }
    },
    methods: {
        ...mapActions(['fetchSavedPostsContent', 'fetchSavePageInfo']),
        assignCards() {
            const content = this.getSavedContent;
            for (var key in content) {
                const element = content[key];
                this.filters.forEach(filter => {
                    if (key.toLowerCase().includes(filter.name.replace(/\s/g, '').toLowerCase())){
                        filter.cards.push(element.nodes);
                    }
                });
            }
            this.loading = false;
        },
        filterSelected(filterName) {
            this.filters.forEach(filter => {
                if(filter.name === filterName) {
                    filter.active = true;
                } else {
                    filter.active = false;
                }
            });
        },
        getCards() {
            var key = this.getSaveProfile.userPosts.map(post => {
                return post.postId
            });
            key = key.filter(function (el) {
                return el != ""
            });
            var keyObj = JSON.stringify({ "in": key });
            if(key.length > 0) {
                this.fetchSavedPostsContent({query: GET_SAVED_POSTS, vars:keyObj}).then(() => {
                    this.assignCards();
                });
            } else {
                this.noCards = true;
                this.loading = false;
            }
        },
        readMoreClick(comp, readContent) {
            if(comp === "Important News and Updates") {
                this.currentReadMore = "ReadMore";
            } else if(comp === "Dot Comm") {
                this.currentReadMore = "ReadMoreDotComm";
            } else if(comp === "Sharing Corner") {
                this.currentReadMore = "SharingCornerRead";
            }
            this.readMoreObj = readContent;
            this.showRead = true;
        },
        close() {
            this.showRead = false;
        },
        getAttachments(item){
            var key = Object.keys(item).find(key => key.toLowerCase().includes("attachment"));
            var attachments = item[key];
            if(attachments) {
                return attachments;
            }
                return "null"
        },
        updateCurrentDisplay(newidx) {
           this.displayIdx = newidx;
           this.nextDisplayIdx = newidx + this.cardsPerPage;
        },
        reloadPagination() {
          this.reloadPager++
        },
    },
    computed: {
        ...mapGetters(['getSavedContent', 'getSaveProfile', 'getSavedPage']),
        filterHandling() {
            this.filters.forEach(filter => {
                if (filter.active === true) {
                    return filter.name;
                }
            });
        }
    },
    watch: {
        filters: {
            handler: function (val) {
                if(!this.noCards) {
                    this.filters.forEach(filter => {
                        if (filter.name === "Important News and Updates" && filter.active === true) {
                           this.currentComponent = 'NewsUpdates';
                           this.buttonCount = Math.ceil(filter.cards[0].length / this.cardsPerPage);
                        }
                        if (filter.name === "Dot Comm" && filter.active === true) {
                            this.currentComponent = 'DotComm';
                            this.buttonCount = Math.ceil(filter.cards[0].length / this.cardsPerPage);
                        }
                        if (filter.name === "Webinars" && filter.active === true) {
                            this.currentComponent = 'CardWebinar';
                            this.buttonCount = Math.ceil(filter.cards[0].length / this.cardsPerPage);
                        }
                        if (filter.name === "Sharing Corner" && filter.active === true) {
                            this.currentComponent = 'SharingCards';
                            this.buttonCount = Math.ceil(filter.cards[0].length / this.cardsPerPage);
                        }
                    });
                    this.reloadPagination();
                    this.displayIdx = 0;
                    this.nextDisplayIdx = this.cardsPerPage;
                }
            },
            deep: true
        }
    },
}


</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

.outerDiv {
    height: 100%;
    line-height: 100%;
}

.heading {
    text-align: left;
    padding-left: 70px;
    padding-top: 50px;
}

.heading h1 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #14377C;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.heading h2 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.filters{
    display: inline;
    float: left;
    margin: 0 10px;

    button:hover {
        background-color: $salBlue;
        color: white;
    }
}

.selected-btn {
    height: auto;
    color: white;
    background-color: $salBlue;
    border: 1px solid $salBlue;
    font-family: 'Isidora Sans';
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    line-height: 24px;
    padding: 10px;

}

.inactive {
    height: auto;
    color: $salBlue;
    background-color: white;
    border: 1px solid $salBlue;
    font-family: 'Isidora Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 10px;
    padding: 10px;
}

.filter-bar{
    display: inline-block;
    margin-left: -10px;
    padding-bottom: 20px;
    width: 100%;
    height: auto;
}

.filters {
    display: inline;
    float: left;
    margin-bottom: 10px;
}

.cards-col {
    padding-top: 20px;
    width: 375px;
    margin-right: 20px;
}

.cards-row {
    display: flex;
    padding-left: 70px;
    padding-bottom: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-right: 55px;
    width: 100%;
}

.no-cards {
    font-family: 'Isidora-SemiBold';
    font-size: 24px;
    color: $salLightGrey;
    width: 100%;
    height: 100%;
    text-align: left;
    margin-top: 20px;
}

p {
    line-height: 1;
}

.pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.separator-text {
    font-family: 'Isidora Sans';
    font-size: 20px;
    font-weight: 600;
    color: $salLightGrey;
    width: 100%;
    text-align: left;
    padding-left: 70px;
    padding-bottom: 20px;
    padding-right: 70px;
}

.separator-bar {
    color: $navBlue;
    background-color: $navBlue;
    height: 1px;
    border: none;
}

.card-check {
    width: 100%;
    margin-left: 70px;
}

@media (min-width: 350px) and (max-width: 699px) {
    .outerDiv {
        .heading {
            padding-left: 20px;
        }
        .separator-text {
            padding-left: 20px;
        }
    }
}
</style>
