<template>
    <b-container class="contactsContainer">
      <h3>Points of Contact</h3>
      <b-row cols="4">
          <b-col class="col-sm-4 mb-4 text-center" v-for="(contacts, index) in pointOfContacts">
              <div class="contacts-card">
                    <img :src="contacts.contactImage.mediaItemUrl" alt="Point of Contact Image">
                  <div class="contacts-info">
                      <h5>{{contacts.contactName}}</h5>
                      <h6>{{contacts.contactTitle}}</h6>
                      <p>{{contacts.contactPhone}}</p>
                  </div>
              </div>
          </b-col>
      </b-row>
    </b-container>
</template>

<script>
    export default {
        props: {
            pointOfContacts: {
                type: Array,
                required: true
            },
        },
    }
</script>

<style scoped>
@font-face {
    font-family: 'Isidora-Bold';
    src: url('../assets/fonts/Isidora/Isidora-Bold.otf');
}

@font-face {
    font-family: 'Isidora-SemiBold';
    src: url('../assets/fonts/Isidora/Isidora-SemiBold.otf');
}

@font-face {
    font-family: 'Isidora Sans Medium';
    src: url('../assets/fonts/IsidoraSans/Isidora Sans Medium.otf');
}

.contactsContainer {
    float: left;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: #fff;
    min-width: 1500px;
}

h3 {
    font-family: 'Isidora-Bold';
    font-size: 26px;
    color: #14377C;
    padding-bottom: 10px;
}

.contacts-card {
    height: 175px;
    border-radius: 5px;
    margin-right: 15px;
}

.contacts-info {
    padding-left: 20px;
    display: inline;
    vertical-align: middle;
}

.contacts-info h5 {
    font-family: 'Isidora-SemiBold';
    font-size: 24px;
    color: #14377C;
    text-align: left;
    margin-bottom: 0px;
}

.contacts-info h6 {
    font-family: 'Isidora-SemiBold';
    font-size: 20px;
    color: #00afd7;
    text-align: left;
    margin-bottom: 0px;
}

.contacts-info p {
    font-family: 'Isidora Sans Medium';
    font-size: 18px;
    color: #14377C;
    text-align: left;
}

.contacts-card img {
    display: inline;
    width: 120px;
    height: 120px;
    border-radius: 10px;
    float: left;
    border: 1px solid #14377c;
    margin-right: 10px;
}
</style>