<template>
    <div class="outerDiv">
        <div :class="isMobile() ? 'heading-m' : 'heading'">
            <h1>{{readMoreObj.headline}}</h1>
            <h2>.</h2><br /> 
            <ul :class="isMobile() ? 'breadcrumb-m' : 'breadcrumb'">
                <li @click="goHome"><a>Home</a></li>
                <li @click="close"><a>{{ breadcrumb }}</a></li>
                <li>{{ readMoreObj.headline }}</li>
            </ul>
        </div>
            <div :class="isMobile() ? 'remodelDocuments-m' : 'remodelDocuments'" name="pdfs" v-if="haveAttachments">
            <b-row cols="4">
                <b-col class="pdf-col" v-for="attachment in attachments" :key="attachment.title">
                    <div :class="isMobile() ? 'pdf-card-m download' : 'pdf-card download'" @click="openPdf(attachment.mediaItemUrl)">
                        <img src="../../../assets/PDF_button.svg" alt="">
                        <p> {{ attachment.title }} </p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div :class="isMobile() ? 'story-m' : 'story'">
            <p v-html="readMoreObj.story"></p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.pdftitle  {
  overflow: hidden;
  padding-top: 10px; 
}
.download {
    background-color: white;
}
p { word-break: break-all }
.pdfContainer {
    float: left;
    
}
.pdf-card:hover {
    background-color: #14377c;

    p {
        color: white;
    }
}
.pdf-card {
    display: relative;
    border: 3px solid #e5e5e5;
    min-height: 85px;
    height: 100%;
    width: 375px;
    border-radius: 5px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 10px;
}
.pdf-card-m {
    display: block; 
    margin: 0 auto;
    margin-left: 10px;
    height: 100%;
    width: 90%;
    border-radius: 10px;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.pdf-card img {
    display: relative;
    vertical-align: middle;
    float: left;
    width: auto;
    height: 60%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
}
.pdf-card-m img {
    display: inline;
    vertical-align: middle;
    float: left;
    width: 20%;
    height: 40%;
    margin-top: 10%;
}
.remodelDocuments {
    height: 100%;
    text-align: left;
    padding-left: 70px;
    margin-top: 50px;
    margin-bottom: 1rem;
    background-color: #fff;
}
.remodelDocuments-m {
    height: 100%;
    text-align: left;
    margin: 0 auto;
    margin-left: 10px;
    background-color: #fff;
    vertical-align: middle;
}
.pdf-card p {
    color: #14377c;
    font-family: 'Isidora-SemiBold';
    font-size: 20px;
    display: inline-block;
    text-align: left;
    line-height: 88px;
    max-height: 88px;
    overflow: hidden;
    width: 75%;
}
.pdf-card-m p {
    margin-top: 14%;
    white-space: nowrap;
    display: inline-block;
    float: left;
    text-align: left;
    line-height: 28px;
    min-height: 55px;
    max-height: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
    color: #14377c;
    font-family: "Isidora-SemiBold";
    font-size: 20px;
}

.pdf-col {
    margin-right: 25px;
    margin-bottom: 25px;
    width: 375px;
}

.story p {
    font-family: 'Isidora Sans Medium';
    font-size: 20px;
    margin-top: 15px;
    text-align: left;
    padding-left: 70px;
}
.story-m p {
  text-align: left;
  font-family: "Isidora Sans Medium";
  font-size: 14px;
  color: #5c5c5c;
  margin-right: 10%;
  margin-top: 20px;
}

.story-m::v-deep table  {
  width: 300px;
}
.story-m::v-deep p  {
  word-break: normal; 
}
.story::v-deep p  {
  word-break: normal; 
}
.story-m::v-deep p img  {
    width: 50%;
    height: 50%;
}


.story {
    height: 100%;
}
.story-m {
    height: 100%;
}
.close {
  cursor: pointer;
  position: absolute;
  right: 70px;
  top: 32px;
  width: 50px;
  height: 50px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 5px;
  content: ' ';
  height: 50px;
  width: 4px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.heading {
    text-align: left;
    padding-left: 70px;
    padding-top: 50px;
}

.heading h1 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #14377C;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.heading h2 {
    display: inline;
    font-family: 'Isidora-Bold';
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.heading-m {
  text-align: left;
  padding-left: 4%;
  padding-right: 5%;
}

.heading-m h1 {
  display: inline;
  font-size: 24px;
  font-family: "Isidora-Bold";
  color: #14377c;
  padding-right: 1%; 
  padding-left: 1%; 
}

.heading-m h2 {
  display: inline;
  font-size: 32px;
  font-family: "Isidora-Bold";
  color: #ee3124;
}


.crumb  {
    display: inline;
    font-family: 'Isidora-Bold';
    color:  skyblue;
    margin-bottom: 10;
    padding-bottom: 10px;
}
.crumbName  {
    display: inline;
    font-family: 'Isidora-Bold';
    color: white;
    margin-bottom: 10;
    padding-bottom: 10px;
}
.heading p {
    font-family: 'Isidora Sans Medium';
    font-size: 18px;
    color: #5C5C5C;
    text-align: left;
    padding-bottom: 50px;
}

.crumb  {
    display: inline;
    font-family: 'Isidora-Bold';
    color:  skyblue;
    margin-bottom: 10;
    padding-bottom: 10px;
}
.crumbName  {
    display: inline;
    font-family: 'Isidora-Bold';
    color: white;
    margin-bottom: 10;
    padding-bottom: 10px;
}
.heading p {
    font-family: 'Isidora Sans Medium';
    font-size: 18px;
    color: #5C5C5C;
    text-align: left;
    padding-bottom: 50px;
}
.heading {
    text-align: left;
    padding-left: 70px;
    padding-top: 50px;
}

ul.breadcrumb {
    font-family: 'Isidora-Bold';
  padding: 10px 16px;
  list-style: none;
  background-color: white
}
ul.breadcrumb-m {
  font-family: 'Isidora-Bold';
  padding: 10px 16px;
  list-style: none;
  background-color: white;
  margin-left: 1%;
  margin-right: 10%;
  text-align: left;
}
ul.breadcrumb li {
  display: inline;
  font-size: 28px;
}
ul.breadcrumb-m li {
  display: inline;
  font-size: 18px;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb-m li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a {
  cursor: pointer;
  color:  skyblue;
  text-decoration: none;
}
ul.breadcrumb-m li a {
  cursor: pointer;
  color:  skyblue;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #01447e;
}
ul.breadcrumb-m li a:hover {
  color: #01447e;
}

li {
    word-wrap: break-word;
}
</style>
<script>


export default {
    data() {
        return {
            attachments: Object
        }
    },
    props: {
        readMoreObj: {
                type: Object,
                required: true
            },
        breadcrumb: {
            type: String,
            required: true
        }
    },
    methods: {
        isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
        close() {
            this.$emit('close');
            document.querySelector('.sal-content').scrollTo(0, 0);
        },
        openPdf(mediaItemUrl) {
            window.open(mediaItemUrl);
        },
        goHome() {
            this.$router.push("/");
            document.querySelector('.sal-content').scrollTo(0, 0);
            this.$root.$emit('disabledBackground');
        }
    },
    computed: {
        haveAttachments () {
            if(this.readMoreObj.attachments.length > 0) {
                this.attachments = Object.assign({}, this.readMoreObj.attachments)
                return true; 
            }

            return false; 
        }
    },
}
</script>

