<template>
  <div :class=" isMobile()? 'm-outer' : isiPad() ? 't-outer' : 'outerDiv' ">
    <div :class="isMobile() ? 'm-header' : isiPad() ? 't-header' : 'header' ">
      <h1>Retail Resources</h1>
      <h2>.</h2>
      <p>Here you can find all the documents on Retail Resources</p>
    </div>
    <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
            class="back-btn"
            :src="getBackBtnImage()"
            alt="Back"
            @click="backBtnClicked()"
        />
        <span class="main_title">Retail Resources</span>
        <span class="main_fullstop"></span>
    </div>

    <div class="search-container">
      <card-search v-on:emitSearchInput="setContent" />
      <b-row class="search-results">
        <search-results v-if="searchString.length > 0" :result="searchCount" />
      </b-row>
    </div>

    <div class="spinner" v-if="isLoading">
      <b-spinner></b-spinner>
      <div>Loading...</div>
    </div>
    <div v-else class="treeView">
      <TreeView
        ref="tree"
        :pdfContent="content"
        />
    </div>
  </div>
</template>

<script>
import { TRAINING_SOP_QUERY } from "../../../queries/gqlQueries";
import TreeView from "../../TreeView.vue";
import { mapGetters, mapActions } from "vuex";
import CardSearch from "../search/CardSearch.vue";
import Search from "../../../utils/Search.js";
import SearchResults from "../search/SearchResults.vue";
import Utils from "../../../utils/Utils.js";

export default {
  data() {
    return {
      isLoading: true,
      content: [],
      searchString: "",
      initialState: [],
    };
  },
  components: {
    TreeView,
    CardSearch,
    SearchResults,
  },
  created() {
    // if (this.getRetailResources.length === 0) {
    this.getSops();
    // }
  },
  watch: {
    getRetailResources() {
      this.content = this.getRetailResources;
    },
    searchString() {
      if (this.searchString == "") {
        this.content = this.getRetailResources;
      }
    },
    isLoading() {
        if(this.isLoading == false) {
            if(this.$route.params.item) {
                setTimeout(() => {
                    if (this.$route.params.item.pDFTitle) {
                        this.findSearch(this.$route.params.item);
                    }
                }, 1000);
            }
        }
    },
  },
  computed: {
    ...mapGetters(["getRetailResources"]),
    searchCount() {
      let c = 0;
      if (this.searchString.length > 0) {
        for (let i = 0; i < this.content.length; i++) {
          // set the results length
          c += this.content[i].children.length;
        }
      }
      return c;
    },
  },
  methods: {
    ...mapActions(["fetchRetailResources"]),
    getBackBtnImage() {
        return Utils.Images.back;
    },
    backBtnClicked() {
        this.$router.back();
    },
    isMobile() {
        return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
        );
        return isTablet;
    },
    findSearch(item) {
        for (let i = 0; i < this.content.length; i++) {
            for (let j = 0; j < this.content[i].children.length; j++) {
                if (this.content[i].children[j].pDFTitle == item.pDFTitle) {
                    let searchItem = this.content[i].children[j];
                    if (searchItem) {
                        let index = this.content.findIndex(x => x.title == this.content[i].title);
                        this.$refs.tree.handleSearchNav(item, this.content[i], index);
                    }
                }
            }
        }
    },
    getSops() {
      this.isLoading = true;
      this.fetchRetailResources(TRAINING_SOP_QUERY).then((result) => {
        this.content = this.getRetailResources;
        this.initialState = this.getRetailResources;
        this.isLoading = false;
      });
    },
    setContent(value) {
      this.searchString = value;
      this.content = JSON.parse(JSON.stringify(this.initialState));
      if (value != "") {
        this.content = Search.retailResources(value, this.content);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Isidora-Bold";
  src: url("../../../assets/fonts/Isidora/Isidora-Bold.otf");
}

@font-face {
  font-family: "Isidora-SemiBold";
  src: url("../../../assets/fonts/Isidora/Isidora-SemiBold.otf");
}

@font-face {
  font-family: "Isidora Sans Medium";
  src: url("../../../assets/fonts/IsidoraSans/Isidora Sans Medium.otf");
}

.mobile_heading{
    display:flex;
    flex-direction: row;
    height: 40px;
    left: 0px;
    background: #ffffff;
    border-bottom: 2px solid #e3e3e3;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;
    .back-btn {
        width: 36px;
        height: 18px;
    }
    .main_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #14377c;
    }
    .main_fullstop {
        width: 4px;
        height: 4px;
        background: #ee3124;
        margin-left: 5px;
        margin-top: 8px;
        border-radius: 2px;
    }
}

.search-container {
  padding-left: 0px;
  padding-right: 0px;
}
.search-results {
  padding-left: 15px;
}

.outerDiv {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 30px;
  align-items: left;
}

.m-outer {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  align-items: left;
}

.t-outer {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  align-items: left;
}

.header {
    text-align: left;

    h1 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 48px;
      color: #14377c;
      margin-bottom: 0px;
      text-align: left;
    }

    h2 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 48px;
      color: #ee3124;
      margin-bottom: 0px;
      text-align: left;
    }

    p {

      font-family: "Isidora Sans Medium";
      font-size: 18px;
      color: #5c5c5c;
      margin-bottom: 25px;
      text-align: left;
    }
}

.m-header {
    text-align: left;
    margin-top: 20px;

    h1 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
      margin-bottom: 0px;
      text-align: left;
    }

    h2 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #ee3124;
      margin-bottom: 0px;
      text-align: left;
    }

    p {
      font-family: "Isidora Sans Medium";
      font-size: 16px;
      color: #5c5c5c;
      margin-bottom: 25px;
      text-align: left;
    }
}


.t-header {
    text-align: left;
    margin-top: 20px;

    h1 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #14377c;
      margin-bottom: 0px;
      text-align: left;
    }

    h2 {
      display: inline;
      font-family: "Isidora-Bold";
      font-size: 32px;
      color: #ee3124;
      margin-bottom: 0px;
      text-align: left;
    }

    p {
      font-family: "Isidora Sans Medium";
      font-size: 18px;
      color: #5c5c5c;
      margin-bottom: 25px;
      text-align: left;
    }
}

.treeView {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
  //   margin-right: auto;
}
.search {
  float: left;
}
</style>
