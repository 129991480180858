<template>
    <div class="outerDiv">
        <div :class="isMobile() ? 'm-header' : isiPad() ? 't-header' : 'heading' ">
            <h1>{{post.headline}}</h1>
            <h2>.</h2>
        </div>
        <ul :class="isMobile() ? 'm-breadcrumb' : isiPad() ? 't-breadcrumb' : 'breadcrumb' ">
            <li @click="goHome"><a>Home</a></li>
            <li @click="close"><a>Sharing Corner</a></li>
            <li class="currentPostCrumb">{{ post.headline }}</li>
        </ul>
        <div :class="isMobile() ? 'm-content' : isiPad() ? 't-content' : 'pageContent' ">
            <div class="pdf-container">
                <b-row cols="4">
                    <b-col class="pdf-cols" v-for="pdf in pdfArray">
                        <div class="pdfs" @click="open(pdf.pdf)">
                            <img src="../../../assets/PDF_button.svg" alt="PDF Button"/>
                            <p> {{ pdf.pdfTitle }} </p>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="imgs" v-for="img in imgArray">
                <img class="single-img" :src="img" alt="post image" v-if="singleImg"></img>
            </div>
            <div class="imgs-carousel" v-if="multiImg">
                <carousel
                id="photo-carousel"
                class="carousel"
                :perPage="isMobile() || isiPad() ? 1 : 2"
                :paginationSize="0"
                :paginationPadding="0"
                :navigationEnabled="true"
                :scrollPerPage="false"
                :navigation-next-label="isMobile() || isiPad() ? '' : navigationNext"
                :navigation-prev-label="isMobile() || isiPad() ? '' : navigationPrev"
                >
                    <slide v-for="photo in imgArray">
                        <div class="image-container">
                            <img v-bind:src="photo" alt="Store Location">
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="post-content">
                <p v-html="post.story"></p>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronRight from '../../../assets/carousel/Right Arrow.svg';
import ChevronLeft from '../../../assets/carousel/Left Arrow.svg';

export default {
    name: "Post",
    props: {
        post: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            singleImg: false,
            multiImg: false,
            multiPDF: false,
            pdfArray: [],
            imgArray: [],
            imgCount: 0,
            pdfCount: 0,
            pdfTitle: [],
        }
    },
    methods: {
        isMobile() {
          return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
        isiPad() {
          const userAgent = navigator.userAgent.toLowerCase();
          const isTablet =
              /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
                  userAgent
              );
          return isTablet;
        },
        goHome() {
            this.$router.push("/");
            document.querySelector('.sal-content').scrollTo(0, 0);
            this.$root.$emit('disabledBackground');
        },
        close() {
            this.$emit('close');
            document.querySelector('.sal-content').scrollTo(0, 0);
        },
        open(pdfUrl) {
            window.open(pdfUrl);
        },
        embedImgCheck(story) {
            if (story.includes("<img")) {
                let imgArray = story.split("<img");
                imgArray.shift();
                imgArray.forEach(img => {
                    let src = img.split("src=")[1];
                    src = src.split(" ")[0];
                    src = src.replace(/"/g, "");
                    this.imgArray.push(src);
                    this.imgCount++;
                });

                story = story.replace(/<img[^>]*>/g, "");
                this.post.story = story;
            }
        },
    },
    mounted() {
        this.embedImgCheck(this.post.story);

        if (this.post.attachmentLink.length >= 1) {
            for (let i = 0; i < this.post.attachmentLink.length; i++) {
                if (this.post.attachmentLink[i].includes(".pdf")) {
                    this.pdfCount++;
                    this.pdfArray.push({'pdf': this.post.attachmentLink[i], 'pdfTitle': this.post.attachmentLink[i].split("/").pop()});
                } else {
                if (this.post.attachmentLink[i].includes(".jpg") || this.post.attachmentLink[i].includes(".png")) {
                    this.imgCount++;
                    this.imgArray.push(this.post.attachmentLink[i]);
                    }
                }
            }
        }
        if (this.imgCount === 1) {
            this.singleImg = true;
        } else {
        if (this.imgCount > 1) {
            this.multiImg = true;
            }
        }
    },
    computed: {
        navigationNext() {
            const chevronRight = ChevronRight;
            return `<img class="slideButtons" src="${chevronRight}"/>`;
            },
        navigationPrev() {
            const chevronLeft = ChevronLeft;
            return `<img class="slideButtons" src="${chevronLeft}"/>`;
        },
    },
}

</script>

<style lang="scss" scoped>
@import "../../../styles/carousel.scss";

.outerDiv {
    width: 100%;
}

.heading {
    text-align: left;
    padding-left: 70px;

    h1
    {
        display: inline;
        font-size: 48px;
        font-family: 'Isidora-Bold';
        color: #14377C;
    }

    h2
    {
        display: inline;
        font-size: 48px;
        font-family: 'Isidora-Bold';
        color: #ee3124;
    }
}

.m-header {
    text-align: left;
    padding-left: 0px;
    padding-right: 20px;

    h1
    {
        display: inline;
        font-size: 32px;
        font-family: 'Isidora-Bold';
        color: #14377C;
    }

    h2
    {
        display: inline;
        font-size: 32px;
        font-family: 'Isidora-Bold';
        color: #ee3124;
    }
}

.t-header {
    text-align: left;
    padding-left: 0px;
    padding-right: 20px;

    h1
    {
        display: inline;
        font-size: 32px;
        font-family: 'Isidora-Bold';
        color: #14377C;
    }

    h2
    {
        display: inline;
        font-size: 32px;
        font-family: 'Isidora-Bold';
        color: #ee3124;
    }
}

.currentPostCrumb {
    color: #5c5c5c;
}

ul.breadcrumb {
    font-family: 'Isidora-SemiBold';
    padding: 10px 16px;
    list-style: none;
    background-color: white;
    margin-left: 55px;

    li {
        display: inline;
        font-size: 28px;
    }
    li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    li a {
        color:  #00afd7;
        text-decoration: none;
    }
     li a:hover {
        color: #01447e;
        cursor: pointer;
    }
}

ul.m-breadcrumb {
    font-family: 'Isidora-SemiBold';
    list-style: none;
    background-color: white;
    text-align: left;
    padding-left: 0px !important;
    padding-right: 20px !important;

    li {
        display: inline;
        font-size: 18px;
    }
    li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    li a {
        color:  #00afd7;
        text-decoration: none;
    }
     li a:hover {
        color: #01447e;
        cursor: pointer;
    }
}

ul.t-breadcrumb {
    font-family: 'Isidora-SemiBold';
    list-style: none;
    background-color: white;
    text-align: left;
    padding-left: 0px !important;
    padding-right: 20px !important;

    li {
        display: inline;
        font-size: 18px;
    }
    li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    li a {
        color:  #00afd7;
        text-decoration: none;
    }
     li a:hover {
        color: #01447e;
        cursor: pointer;
    }
}

.pageContent {

    .pageContent::v-deep p {
        color: #5c5c5c;
    }

    .post-content p {
        font-family: 'Isidora Sans Medium';
        font-size: 18px;
        color: #5c5c5c;
        margin-left: 70px;
        margin-right: 55px;
        margin-top: 20px;
        text-align: left;
        word-wrap: break-word;
    }

    .post-content::v-deep p {
        color: #5c5c5c;
    }

    .pdfs {
        display: block;
        min-height: 88px;
        margin: 0 auto;
        margin-left: 70px;
        height: 88px;
        width: 100%;
        border-radius: 10px;
        vertical-align: middle;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdfs:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-cols {
         margin-right: 25px;
         margin-bottom: 25px;
         width: 375px;
    }

    .pdfs img {
        display: inline;
        vertical-align: middle;
        float: left;
        width: 20%;
        height: 60px;
        margin-left: 10px;
        margin-top: 13px;
    }

    .pdfs p {
        display: inline-block;
        float: left;
        text-align: left;
        line-height: 88px;
        max-height: 88px;
        overflow: hidden;
        width: 75%;
        color: #14377c;
        font-family: 'Isidora-SemiBold';
        font-size: 20px;
    }

    .single-img {
        display: block;
        margin-left: 70px;
        max-height: 500px;
        max-width: 500px;
        align-self: center;
        border: 2px solid #14377D;
        border-radius: 10px;
    }

    .imgs-carousel {
        height: auto;
        max-width: 1920px;
        min-width: 500px;
        margin-left: 20px;
    }

    .carousel img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 550px;
        max-width: 775px;
        border: 2px solid #14377c;
        border-radius: 20px;
    }

    .image-container {
        padding: 10px;
        height: auto;
        max-width: 775px;
        margin-left: 5%;
    }
}

.m-content {
    padding-right: 20px;
    width: 100%;

    .pageContent::v-deep p {
        color: #5c5c5c;
    }

    .post-content p {
        font-family: 'Isidora Sans Medium';
        font-size: 16px;
        color: #5c5c5c;
        margin-left: 0px;
        margin-right: 55px;
        margin-top: 20px;
        text-align: left;
        word-wrap: break-word;
    }

    .post-content::v-deep p {
        color: #5c5c5c;
    }

    .pdf-container {
        padding-right: 20px;
    }

    .pdfs {
        display: block;
        min-height: 88px;
        height: 60px;
        width: 100%;
        border-radius: 10px;
        vertical-align: middle;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdfs:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-cols {
         margin-right: 20px;
         margin-bottom: 25px;
         width: 100%;
    }

    .pdfs img {
        display: inline;
        vertical-align: middle;
        float: left;
        width: 20%;
        height: 60px;
        margin-left: 10px;
        margin-top: 13px;
    }

    .pdfs p {
        display: inline-block;
        float: left;
        text-align: left;
        line-height: 88px;
        max-height: 88px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 75%;
        color: #14377c;
        font-family: 'Isidora-SemiBold';
        font-size: 16px;
    }

    .imgs {
        padding-right: 20px;
    }

    .single-img {
        display: block;
        width: 100%;
        max-width: 100%;
        align-self: center;
        border: 2px solid #14377D;
        border-radius: 10px;
    }

    .imgs-carousel {
        height: auto;
        margin-right: 15px;
    }

    .carousel img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 550px;
        max-width: 775px;
        border: 2px solid #14377c;
        border-radius: 20px;
    }

    .image-container {
        height: auto;
        max-width: 775px;
    }
}

.t-content {
    padding-right: 20px;
    width: 100%;

    .pageContent::v-deep p {
        color: #5c5c5c;
    }

    .post-content p {
        font-family: 'Isidora Sans Medium';
        font-size: 18px;
        color: #5c5c5c;
        margin-left: 0px;
        margin-right: 55px;
        margin-top: 20px;
        text-align: left;
        word-wrap: break-word;
    }

    .post-content::v-deep p {
        color: #5c5c5c;
    }

    .pdf-container {
        padding-right: 20px;
    }

    .pdfs {
        display: block;
        min-height: 88px;
        height: 60px;
        width: 100%;
        border-radius: 10px;
        vertical-align: middle;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .pdfs:hover {
      background-color: #14377c;
      p {
        color: white;
      }
    }

    .pdf-cols {
         margin-right: 20px;
         margin-bottom: 25px;
         width: 100%;
    }

    .pdfs img {
        display: inline;
        vertical-align: middle;
        float: left;
        width: 20%;
        height: 60px;
        margin-left: 10px;
        margin-top: 13px;
    }

    .pdfs p {
        display: inline-block;
        float: left;
        text-align: left;
        line-height: 88px;
        max-height: 88px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 75%;
        color: #14377c;
        font-family: 'Isidora-SemiBold';
        font-size: 18px;
    }

    .imgs {
        padding-right: 20px;
    }

    .single-img {
        display: block;
        width: 100%;
        max-width: 100%;
        align-self: center;
        border: 2px solid #14377D;
        border-radius: 10px;
    }

    .imgs-carousel {
        height: auto;
        margin-right: 15px;
    }

    .carousel img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 550px;
        max-width: 775px;
        border: 2px solid #14377c;
        border-radius: 20px;
    }

    .image-container {
        height: auto;
        max-width: 775px;
    }
}

</style>