import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../components/Main.vue";
import Profile from "../components/Profile.vue";
import PocList from "../components/profile/PocList.vue";
import { registerGuard } from "./Guard";
import newsfeedsRoutes from "./newsfeedsRoutes";
import supportServicesRoutes from "@/router/supportServicesRoutes";
import calendarRoutes from "@/router/calendarRoutes";
import distributionRoutes from "@/router/distributionRoutes";
import trainingHubRoutes from "@/router/trainingHubRoutes";
import Saved from "../components/module/misc/SavedRead";
import NoAccess from "../components/profile/NoAccessRouting.vue";
import McommonLinks from "../components/mobile/McommonLinks.vue";
import AllResults from "../components/module/search/AllResults.vue";
import mAllResults from "../components/module/search/mAllResults.vue";

Vue.use(VueRouter);
const routes = [
  ...newsfeedsRoutes,
  ...supportServicesRoutes,
  ...calendarRoutes,
  ...distributionRoutes,
  ...trainingHubRoutes,
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/links",
    component: Main,
    meta: { requiresAuth: true },
    props: {
      componentName: "AdditionalLinks",
      pageTitle: "Additional Links",
    },
  },
  {
    path: "/common-links",
    component: McommonLinks,
    meta: { requiresAuth: true },
    props: {
      componentName: "McommonLinks",
      pageTitle: "Common Links",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/help",
    component: Main,
    meta: { requiresAuth: true },
    props: {
      componentName: "GetHelp",
      pageTitle: "Get Help",
    },
  },
  {
    name: "CustomPost",
    path: "/:path",
    component: Main,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: "Saved",
    path: "/saved",
    component: Main,
    meta: { requiresAuth: true },
    props: {
      componentName: "Saved",
      pageTitle: "Saved Posts",
    },
  },
  {
    name: "AllResults",
    path: "/search-results",
    component: Main,
    meta: { requiresAuth: true },
    props: {
        componentName: "AllResults",
        pageTitle: "Search Results",
    }
  },
  {
    name: "mAllResults",
    path: "/search-results",
    component: mAllResults,
    meta: { requiresAuth: true },
    props: {
        componentName: "mAllResults",
        pageTitle: "Search",
    }
  },
  {
    path: "/noaccess",
    component: Main,
    meta: { requiresAuth: true },
    props: {
      componentName: "NoAccessRouting",
      pageTitle: "Access Denied",
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: PocList,
    meta: { requiresAuth: true },
    props: {
      componentName: "PocList",
      pageTitle: "Points Of Contact",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  routes: routes,
});

registerGuard(router);
export default router;
