import { addFavorite, fetchFavorites, deleteFavorites } from "@/api/Favorite";

export const fetchFavoritesList = async ({ commit }, request) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  };
  await fetchFavorites(request, headers)
    .then((res) => {
      commit("setFavoritesData", { response: res, userEmail: request });
    })
    .catch((err) => {
      console.log("Fev Api Call Failed", err);
      commit("setEmptyFavoritesData", 500);
    });
};
export const addFavorites = async ({ commit }, request) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  await addFavorite(request, headers)
    .then(() => {
      commit("objectSavedAsFavorite", request);
      //commit("updateFavStatus", { path: data.breadcrumb, isFavorite: true });
    })
    .catch((err) => {
      console.log("Api Call Failed", err);
    });
};
export const deleteFavorite = async ({ commit }, request) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  };
  await deleteFavorites(request, headers)
    .then((res) => {
      commit("setEmptyFavoritesData", {
        response: res.data,
        appName: request.label,
      });
      // commit("updateFavStatus", {
      //   path: data.deletedObj.breadcrumb,
      //   isFavorite: false,
      // });
    })
    .catch((err) => {
      console.log("Delete Api Call Failed", err);
      commit("setDeleteFavoriteErrorStatus", {
        deletedObj: data.deletedObj,
      });
    });
};
