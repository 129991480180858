<template>
  <div
    :class="
      isMobile()
        ? 'search-container-mobile'
        : isiPad()
        ? 'search-container-ipad'
        : 'search-container'
    "
    id="search-container"
  >
    <input
      v-model="inputValue"
      id="searchInput"
      type="search"
      placeholder="Search"
      class="search-field"
      @input="onEntry"
    />
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    onEntry() {
      this.emitSettings();
    },
    onSearch() {
      this.emitSettings();
    },
    emitSettings() {
      this.$emit("emitSearchInput", this.inputValue);
    },
    isMobile() {
      if (
        /iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
          navigator.userAgent.toLowerCase()
        )
      ) {
        return this.isiPad() ? false : true;
      } else {
        return false;
      }
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
  },
  mounted() {
    document.getElementById("searchInput").focus();
  },
};
</script>
<style lang="scss" scoped>
.search-container {
  padding: 0 65px;
  width: 100%;
}
.search-container-mobile {
  padding: 0 3%;
  width: 100%;
}
.search-container-ipad {
  width: 100%;
  padding-left: 2%;
  padding-right: 9%;
  margin-top: 10px;
}
.search-field {
  width: 100%;
  height: 33px;
  padding: 0px 15px;
  border: none;
  border-radius: 15px;
  outline: 1px solid #14377d;
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: block;
}
input,
input::placeholder {
  font-family: "Isidora Sans" !important;
  font-size: 18px !important;
}

.search-button {
  background: transparent;
  border: none;
  outline: none;
  margin-left: -45px;
}

.search-button img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

input,
input::-webkit-input-placeholder {
  font-size: 24px;
  line-height: 28.8px;
  font-family: Isidora;
  font-weight: 600;
  color: #7387b2;
}
</style>
