export const sortFilterSearch = {
    state: {
        sortNewsUpdates: "",
        sortDotComm: "",
        sortWebinars: "", 
        sortSharingCorner: "",

        filterNewsUpdates: [],
        filterDotComm: [],
        filterWebinars: [], 
        filterSharingCorner: [],
    },
    mutations: {
        mutateSortNewsUpdates(state, value) {
            try{
                if(value != null) {
                    state.sortNewsUpdates = value; 
                }
            } catch(e){
                console.log("Error in mutateSortNewsUpdates:", e)
            }
        },
        mutateFilterNewsUpdates(state, value) {
            try{
                state.filterNewsUpdates = []
                if(value != null) {
                    state.filterNewsUpdates.push(...value);
                  } 
            } catch(e){
                console.log("Error in mutateSortNewsUpdates:", e)
            }
        },

        mutateSortDotComm(state, value) {
            try{
                if(value != null) {
                    state.sortDotComm = value; 
                }
            } catch(e){
                console.log("Error in mutateSortDotComm:", e)
            }
        },
        mutateFilterDotComm(state, value) {
            try{
                state.filterDotComm = []
                if(value != null) {
                    state.filterDotComm.push(...value);
                  } 
            } catch(e){
                console.log("Error in mutateFilterDotComm:", e)
            }
        },

        mutateSortWebinars(state, value) {
            try{
                if(value != null) {
                    state.sortWebinars = value; 
                }
            } catch(e){
                console.log("Error in mutateSortWebinars:", e)
            }
        },
        mutateFilterWebinars(state, value) {
            try{
                state.filterWebinars = []
                if(value != null) {
                    state.filterWebinars.push(...value);
                  } 
            } catch(e){
                console.log("Error in mutateFilterWebinars:", e)
            }
        },

        mutateSortSharingCorner(state, value) {
            try{
                if(value != null) {
                    state.sortSharingCorner = value; 
                }
            } catch(e){
                console.log("Error in mutateSortSharingCorner:", e)
            }
        },
        mutateFilterSharingCorner(state, value) {
            try{
                state.filterSharingCorner = []
                if(value != null) {
                    state.filterSharingCorner.push(...value);
                  } 
            } catch(e){
                console.log("Error in mutateFilterSharingCorner:", e)
            }
        },
    }, 
    
    getters: {
        getSortNewsUpdates: state => state.sortNewsUpdates,
        getSortDotComm: state => state.sortDotComm, 
        getSortWebinars: state => state.sortWebinars,
        getSortSharingCorner: state => state.sortSharingCorner,
        getFilterNewsUpdates: state => state.filterNewsUpdates,
        getFilterDotComm: state => state.filterDotComm, 
        getFilterWebinars: state => state.filterWebinars,
        getFilterSharingCorner: state => state.filterSharingCorner,
    }
}