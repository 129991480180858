import store from "@/store";

export default {
  URI: {
    wpengine: process.env.VUE_APP_WPENGINE_URI,
    menu: process.env.VUE_APP_ADDITIONAL_LINKS_URI,
    submenu: process.env.VUE_APP_ADDITIONAL_LINKS_SUBMENU_URI,
    appProfile: process.env.VUE_APP_APP_PROFILE_URI,
  },

  API: {
    fetchFavorite: "/favorite/fetch/",
    addFavorite: "/favorite/add/",
    deleteFavorite: "/favorite/delete",
    fetchRecentlyused: "/history/fetch/",
    saveRecentlyused: "/history/save"
  },

  Strings: {
    gobackTitle: "< Go Back to Profile",
    environment: "Extranet",
    Favs: "Favorites",
    AllApps: "All Applications",
    RecentVstd: "Recently Visited",
    commonLinks: "Common Links",
    noFavorites: "No Favorites Available",
    noHistory: "No History Available",
    fetchFavoriteErrMsg: "Error while fetching favorites...",
    deleteFavoriteErrMsg:
      "Error while removing favorite...Please try again later",
    webinarDesc:
      "You can access recent webinars and webinar decks below. You can also find these webinars on a dedicated page of webinars of this portal. Download the PPT/PDF file from the button below.",
  },
  Images: {
    favEnable: require("../assets/images/heart_fill.svg"),
    favDisable: require("../assets/images/heart.svg"),
    rightarr: require("../assets/images/right-arr.svg"),
    leftarr: require("../assets/images/left-arr.svg"),
    toparr: require("../assets/images/top-arr.svg"),
    downarr: require("../assets/images/down-arr.svg"),
    favAddLinksEnable: require("../assets/images/heart_filled.svg"),
    favAddLinksDisable: require("../assets/images/heart_outlined.svg"),
    filterCheckboxSelected: require("../assets/images/Checkbox-Filled.svg"),
    filterCheckboxEmpty: require("../assets/images/Checkbox-Empty.svg"),
    sortRadioButtonSelected: require("../assets/images/Radio-Filled.svg"),
    sortRadioButtonEmpty: require("../assets/images/Radio-Empty.svg"),
    polygon_down: require("../assets/images/Polygon_down.svg"),
    polygon_up: require("../assets/images/Polygon_up.svg"),
    profile_placeholder: require("../assets/images/user_placeholder.svg"),
    back: require("../assets/images/back_btn.svg"),
    m_gethelp: require("../assets/images/mobile/m_gethelp.svg"),
    m_add_link: require("../assets/images/mobile/m_add_link.svg"),
  },
};
