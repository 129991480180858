import { render, staticRenderFns } from "./NewsAndUpdates.vue?vue&type=template&id=76fbf271&scoped=true&"
import script from "./NewsAndUpdates.vue?vue&type=script&lang=js&"
export * from "./NewsAndUpdates.vue?vue&type=script&lang=js&"
import style0 from "./NewsAndUpdates.vue?vue&type=style&index=0&id=76fbf271&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76fbf271",
  null
  
)

export default component.exports