<template>
    <div v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'ColumnsBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    data() {
        return {
            url: ""
        }
    },
    mounted() {
        this.checkEmbed();
        if (this.block.saveContent.includes('wp-block-cover')) {
            this.handleWrap();
        }
    },
    methods: {
        checkEmbed() {
            const content = this.block.saveContent;
            if(content && content.includes("vimeo")) {
                var url = content.replace(/<[^>]*>?/gm, '').replace("https://vimeo.com", "https://player.vimeo.com/video");
                this.url = url.substring(url.indexOf("https")).replace(/\/(?=[^\/]*$)/, "?h=");
                const wrapper = document.getElementsByClassName("wp-block-embed__wrapper");
                if(wrapper.length > 0) {
                    wrapper[0].innerHTML = `<iframe src="${this.url}" frameborder="0" scrolling="no"></iframe>`;
                }
            }
        },
        handleWrap() {
            if (this.block.saveContent.includes('wp-block-cover')) {
                if (this.block.saveContent.includes('img')) {
                    setTimeout(() => {
                        const img = this.$el.querySelector('.wp-block-cover__image-background');
                        const span = this.$el.querySelector('.wp-block-cover__background');
                        span.appendChild(img);
                    }, 10);
                } else {
                    this.$el.querySelector('.wp-block-cover__background').classList.add('solid-block');
                }
            }
        }
    },
    watch: {
        '$route'() {
            this.handleWrap();
        }
    }
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

::v-deep .wp-block-columns {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}
::v-deep .wp-block-column {
    padding-right: 10px;
}

::v-deep p {
    font-family: "Isidora Sans";
    color: $salLightGrey;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

::v-deep img {
    float: left;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

::v-deep table {
    border: 1px solid #14377C;
    background-color: #CBCBCB;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-family: "Isidora Sans";
    th, td {
        border: 1px solid #AAAAAA;
        padding: 5px 5px;
    }
    tr:nth-child(even) {
        background-color: #F2F2F2;
    }
    tr:nth-child(odd) {
        background-color: #FFFFFF;
    }
    tr:first-child {
        font-weight: bold;
        font-size: 24px;
    }
    td:first-child {
        padding-left: 15px;
    }
    a {
        color: #14377C;
        text-decoration: underline;
    }
}

::v-deep wp-block-embed__wrapper {
    width: 100%;
    height: 100vh;
    border: none;
    overflow: hidden;
}

::v-deep iframe {
    width: 100%;
    height: 50vh;
    border: none;
    overflow: hidden;
    background-color: #000;
}


::v-deep .wp-block-cover {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;

    p {
        font-family: "Isidora-SemiBold";
        color: $salWhiteSmoke;
        text-align: left;
        font-weight: 500;
        font-size:  4vw;
        text-align: center;
    }
    .wp-block-cover__inner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .wp-block-cover__background {
        background-color: rgba(0,0,0,1);
        border-radius: 10px;
        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 50%;
        }
    }
    .solid-block {
        background-color: $salBlue;
        border-radius: 10px;
        width: 100%;
        height: 300px;
    }
}

</style>