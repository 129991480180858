// import * as additionalLinksActions from "./additionallinks/actions";
// import additionalLinksGetters from "./additionallinks/getters";
// import additionalLinksMutations from "./additionallinks/mutations";

// import * as wpActions from "./wordpresscontent/actions";
// import wpGetters from "./wordpresscontent/getters";
// import wpMutations from "./wordpresscontent/mutations";

// import * as favActions from "./favorite/actions";
// import favGetters from "./favorite/getters";
// import favMutations from "./favorite/mutations";

// import { pmutations, pgetters } from "./profile/profile";

// const state = {
//   profileData: [],
//   profilePicture: [],
//   menuList: [],
//   additionalLinks: [],
//   webinars: [],
//   topics: [],
//   commonLinks: [],
//   favoritesData: [],
//   favErrCode: "",
//   favStatus: "",
// };
// const actions = {
//   ...additionalLinksActions,
//   ...wpActions,
//   ...favActions,
// };
// const getters = {
//   ...additionalLinksGetters,
//   ...wpGetters,
//   ...favGetters,
//   ...pgetters,
// };
// const mutations = {
//   ...additionalLinksMutations,
//   ...wpMutations,
//   ...favMutations,
//   ...pmutations,
// };

// export default {
//   state,
//   actions,
//   mutations,
//   getters,
// };

import ProfileApi from "./profileapi";

export default {
  ProfileApi,
};
