export const NAVIGATION_QUERY = `query {
    navigationItems(first: 100, where: {orderby: {field: DATE, order: ASC}}) {
      nodes {
        menuItem
        postLink
        terms(first: 1) {
          nodes {
            name
          }
        }
      }
    }
}`;

export const NEWS_QUERY = `query ImportantNewsAndUpdates {
  newsFeedTypes(where: {name: "Newsfeed"}) {
    nodes {
      header: name
      children(where: {name: "Important News & Updates"}) {
        nodes {
          sub_header: name
          importantNewsAndUpdates(first: 100) {
            nodes {
              headline
              story
              cardSummary
              databaseId
              urgentPost
              urgentUntil
              postedDate
              effectiveDate
              expirationDate
              postAttachments {
                title
                mediaItemUrl
              }
              Filter: newsFiltering {
                nodes {
                  name
                }
              }
            }
            pageInfo {
              endCursor
            }
          }
        }
      }
    }
  }
}`;

export const NEWS_DOT_COMM_QUERY = `query DotCommArchives {
   newsFeedTypes(where: {name: "Newsfeed"}) {
     nodes {
       header: name
       children(where: {name: "Dot Comm Archives"}) {
         nodes {
           sub_header: name
           dotCommArchives(first: 100) {
             nodes {
               headline
               story
               cardSummary
               databaseId
               urgentPost
               urgentUntil
               postedDate
               effectiveDate
               expirationDate
               pDFAttachments {
                 title
                 mediaItemUrl
               }
             }
             pageInfo {
               endCursor
             }
           }
         }
       }
     }
   }
 }`;

export const NEWS_WEEKLY_CHECKOUT_QUERY = `query WeeklyCheckoutArchives {
   newsFeedTypes(where: {name: "Newsfeed"}) {
     nodes {
       header: name
       children(where: {name: "Weekly Checkout Archives"}) {
         nodes {
           sub_header: name
           weeklyCheckoutArchives(first: 100) {
             nodes {
               pDFTitle
               postedDate
               pDFAttachment {
                 mediaItemUrl
               }
             }
              pageInfo {
                endCursor
              }
           }
         }
       }
     }
   }
 }`;

export const NEWS_RECALL_QUERY = `query Recalls {
   newsFeedTypes(where: {name: "Newsfeed"}) {
     nodes {
       header: name
       children(where: {name: "Recalls"}) {
         nodes {
           sub_header: name
           recalls(first: 100) {
             nodes {
               category: recallCategories {
                 nodes {
                   name
                 }
               }
               pDFTitle
               postedDate
               effectiveDate
               expirationDate
               pDFAttachment {
                 mediaItemUrl
               }
             }
           pageInfo {
             endCursor
           }
           }
         }
       }
     }
   }
 }`;

export const NEWS_WEBINAR_QUERY = `query Webinars {
    newsFeedTypes(where: {name: "Newsfeed"}) {
       nodes {
           header: name
           children(where: {name: "Webinars"}) {
             nodes {
               sub_header: name
               webinars(first: 100) {
                 nodes {
                   headline
                   story
                   databaseId
                   cardSummary
                   webinarLink
                   webinarDate
                   postedDate
                   effectiveDate
                   expirationDate
                   pDFAttachment {
                    mediaItemUrl
                  }
                   thumbnailImage {
                     mediaItemUrl
                   }
                   Filter: webinarsFiltering {
                    nodes {
                      name
                    }
                  }
                 }
                pageInfo {
                  endCursor
                }
               }
             }
           }
         }
       }
     }`;

export const HR_NEWS_WEBINAR_QUERY = `query Webinars {
  newsFeedTypes(where: {name: "Newsfeed"}) {
     nodes {
         header: name
         children(where: {name: "Webinars"}) {
           nodes {
             sub_header: name
             webinars(first: 100) {
               nodes {
                 headline
                 story
                 databaseId
                 cardSummary
                 webinarLink
                 webinarDate
                 postedDate
                 effectiveDate
                 expirationDate
                 pDFAttachment {
                  mediaItemUrl
                }
                 thumbnailImage {
                   mediaItemUrl
                 }
                 Filter: webinarsFiltering {
                  nodes {
                    name
                  }
                }
               }
              pageInfo {
                endCursor
              }
             }
           }
         }
       }
     }
   }`;

export const SUPPORT_SERVICES_QUERY = `query Support_Services {
   support_services: supportServicesTypes(where: {name: "Support Services"}) {
     nodes {
       header: name
       children {
         nodes {
           sub_header: name
           pages: supportServicesPages {
             nodes {
               pageTitle
               pageDescription
               pageContent
               pointOfContacts {
                 nodes {
                   contactName
                   contactTitle
                   contactPhone
                   contactEmail
                   contactImage {
                     mediaItemUrl
                   }
                 }
               }
             }
           }
           updates: supportServiceUpdates(first: 500) {
             nodes {
               updateHeadline
               updateStory
               updateLink
               effectiveDate
               expirationDate
             }
           }
         }
       }
     }
   }
 }`;

export const SUPPORT_REMODEL_QUERY = `query Remodel_Services {
  support_services: supportServicesTypes(where: {name: "Support Services"}) {
    nodes {
      header: name
      children(where: {name: "Remodel Services"}) {
        nodes {
          sub_header: name
          remodelServicesPages {
            nodes {
              pageTitle
              pageDescription
              photoLibraryDescription
              pageContent
              pointOfContacts {
                nodes {
                  contactName
                  contactTitle
                  contactPhone
                  contactEmail
                  contactImage {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          remodel_photos: remodelPhotoLibraries(first: 500) {
            nodes {
              photoTitle
              photoLibrary {
                mediaItemUrl
              }
            }
          }
          remodel_documents: remodelDocumentLibraries(first: 500) {
            nodes {
              pDFTitle
              pDFAttachment {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
}`;

export const SUPPORT_SERVICES_PAGE_DETAILS = `query ($department: String) {
  supportServicesPages(first: 100, where: {title: $department}) {
    nodes {
      ...SupportServicesPageFields
      pageContent
      pageTitle
      pageDescription
      questionToEmail
    }
  }
}

fragment SupportServicesPageFields on SupportServicesPage {
  pageTitle
}`;

export const CALENDAR_QUERY = `query calendar {
   calendar: calendarTypes(where: {name: "Calendar"}) {
     nodes {
       header: name
       children {
         nodes {
           sub_header: name
           calendar: operationalCalendar(first: 500) {
             nodes {
               eventTitle
               eventDescription
               eventLink
               eventDate
               eventTime
             }
           }
         }
       }
     }
   }
 }`;

export const DISTRIBUTION_RESOURCE_QUERY = `query Distribution_Resource {
   distributionTypes(where: {name: "Distribution"}) {
     nodes {
       header: name
       children(where: {name: "Distribution Resource"}) {
         nodes {
           sub_header: name
           distributionResourcePage {
             nodes {
               pageTitle
               pageDescription
             }
           }
           distributionResource(first: 500) {
             nodes {
               pDFTitle
               pDFAttachment {
                 mediaItemUrl
               }
             }
           }
         }
       }
     }
   }
 }`;

export const DISTRIBUTION_CENTER_QUERY = `query Distribution_Centers {
   distributionTypes(where: {name: "Distribution"}) {
     nodes {
       header: name
       children(where: {name: "Distribution Center Information"}) {
         nodes {
           sub_header: name
           distributionCenters(first: 500) {
             nodes {
               distributionCenterTitle
               distributionCenterAddress
               distributionCenterPhone
               distributionCenterEmail
             }
           }
         }
       }
     }
   }
 }`;

export const TRAINING_SOP_QUERY = `query Training_SOPs($after:String) {
  trainingHubTypes(where: {name: "Training Hub"}) {
    nodes {
      header: name
      children(where: {name: "Retail Resources"}) {
        nodes {
          sub_header: name
          standardOperatingProcedures(first: 100, after: $after) {
            nodes {
              category: sOPCategories {
                nodes {
                  name
                }
              }
              pDFTitle
              pDFAttachment {
                mediaItemUrl
              }
            }
            pageInfo {
              endCursor
            }
          }
        }
      }
    }
  }
}`;

export const TRAINING_LEARNING_CART_QUERY = `query Training_Learning_Cart {
   trainingHubTypes(where: {name: "Training Hub"}) {
     nodes {
       header: name
       children(where: {name: "Learning Cart"}) {
         nodes {
           sub_header: name
           learningCart {
             nodes {
               learningCartLink
             }
           }
         }
       }
     }
   }
 }`;

export const TRAINING_FAQS_QUERY = `query Training_FAQs {
    trainingHubTypes(where: {name: "Training Hub"}) {
      nodes {
        header: name
        children(where: {name: "FAQs"}) {
          nodes {
            sub_header: name
            frequentlyAskedQuestions(first: 500) {
              nodes {
                category: fAQsCategories {
                  nodes {
                    name
                  }
                }
              question
              questionDescription
              }
            }
          }
        }
      }
    }
}`;

export const COMMON_LINKS_QUERY = `query {
  allCommonLinks (first: 100) {
    nodes {
      ...CommonLinksFields
    }
  }
}

fragment CommonLinksFields on CommonLinks {
  apps
  commonLinksTypes {
    nodes {
      name
    }
  }
}`;

export const PROFILE_CREATE = `mutation queryCreateBio($userID:String!, $savedPosts:String!) {
   createProfileBio( input: {
    userID: $userID,
    savedPosts: $savedPosts,
    status: PUBLISH })
    {
    profileBio {
      id
     }
    }
 }`;

export const PROFILE_INFO = `query UserInformation($userID:String!) {
  profileBios(where: {title:$userID}) {
    nodes {
      userID
      bio
      id
      title
    }
  }
}`;

export const PROFILE_UPDATE = `mutation queryUpdateData($id:ID!, $bio:String!) {
    updateProfileBio(input: {
        id: $id,
        bio: $bio,
        status: PUBLISH
    })
    {
    profileBio {
        status
        id
        userID
        bio
        }
    }
}`;

export const SHARING_CORNER_READ = `query Sharing_Corner_Read {
   allSharingCorner(first: 100) {
     nodes {
       headline
       story
       databaseId
       postedDate
       attachmentLink
       authorName
       authorTitle
       authorLocation
       Filter: sharingCornerCategories {
         nodes {
           name
         }
       }
     }
     pageInfo {
       endCursor
     }
   }
 }`;

export const SHARING_CORNER_POST = `mutation queryCreateSharingCorner($authorLocation:String!, $authorName:String!, $authorTitle:String!, $headline:String!, $story:String!, $postedDate:String!, $attachmentLink:[String], $categories:String!) {
    createSharingCorner(
        input: {
            authorLocation: $authorLocation,
            authorName: $authorName,
            authorTitle: $authorTitle,
            headline: $headline,
            story: $story,
            postedDate: $postedDate,
            attachmentLink: $attachmentLink,
            sharingCornerCategories: {
                nodes: {name: $categories}
            }
        }
    ) {
    clientMutationId
    }
}`;

export const MARKETING_TOPICS_QUERY = `query Topics {
    topicsFiltering(where: {name: "Marketing"}) {
        nodes {
                topics {
                    nodes {
                    topicTitle
                    topicDescription
                    topicLink
                    topicImage {
                        mediaItemUrl
                    }
                }
            }
        }
    }
}`;

export const HR_TOPICS_QUERY = `query Topics {
    topicsFiltering(where: {name: "HR"}) {
        nodes {
            topics {
                nodes {
                    topicTitle
                    topicDescription
                    topicLink
                    topicImage {
                        mediaItemUrl
                    }
                }
            }
        }
    }
}`;

export const POINT_OF_CONTACT_QUERY = `query PointofContacts($department: [String!]) {
    pointsOfContactDepartment(where: {name: $department}) {
        nodes {
            name
            pointOfContacts {
                nodes {
                    contactName
                    contactPhone
                    contactTitle
                    contactEmail
                    orderby
                    contactImage {
                        mediaItemUrl
                        mediaItemId
                        mediaType
                    }
                }
            }
        }
    }
}`;

export const HOME_PAGE_QUERY = `query homePage($name: [String] = ["Important News & Updates", "Dot Comm Archives"]) {
    newsFeedTypes(where: {name: "Newsfeed"}) {
        nodes {
            header: name
            children(where: {name: $name}) {
                nodes {
                    sub_header: name
                    dotCommArchives(first: 100) {
                        nodes {
                            headline
                            databaseId
                            story
                            cardSummary
                            urgentPost
                            urgentUntil
                            postedDate
                            effectiveDate
                            expirationDate
                            pDFAttachments {
                                title
                                mediaItemUrl
                            }
                        }
                    }
                    importantNewsAndUpdates(first: 100) {
                        nodes {
                            headline
                            databaseId
                            story
                            cardSummary
                            urgentPost
                            urgentUntil
                            postedDate
                            effectiveDate
                            expirationDate
                            postAttachments {
                            title
                            mediaItemUrl
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export const GET_SAVED = `query UserInformation($userID: String!) {
    profileBios(where: {title: $userID}, first: 1) {
        nodes {
            userID
            id
            savedPosts
        }
    }
}`;

export const POC_LIST_DOWNLOAD = `query pointsOfContact{
    contactsSheet {
        nodes {
            pointOfContact {
                mediaItemUrl
                title
            }
        }
    }
}`;

export const NAV_BAR_MENU = `query {
  allNavmenu {
    nodes {
      menulable
      orderby
    }
  }
}
`;

export const GET_SAVED_POSTS = `query ReadSavedPosts($in:[ID]) {
importantNewsAndUpdates(first: 100, where: {in: $in}) {
 nodes {
   headline
   story
   cardSummary
   databaseId
   urgentPost
   urgentUntil
   postedDate
   effectiveDate
   expirationDate
   postAttachments {
     title
     mediaItemUrl
   }
   Filter: newsFiltering {
     nodes {
       name
     }
   }
 }
}
dotCommArchives(first: 100, where: {in: $in}) {
 nodes {
   headline
   story
   cardSummary
   databaseId
   urgentPost
   urgentUntil
   postedDate
   effectiveDate
   expirationDate
   pDFAttachments {
     title
     mediaItemUrl
   }
 }
}
webinars(first: 100, where: {in: $in}) {
nodes {
  headline
  story
  databaseId
  cardSummary
  webinarLink
  webinarDate
  postedDate
  effectiveDate
  expirationDate
  pDFAttachment {
   mediaItemUrl
 }
  thumbnailImage {
    mediaItemUrl
  }
  Filter: webinarsFiltering {
   nodes {
     name
   }
 }
}
}
allSharingCorner(first: 100, where: {in: $in}) {
 nodes {
   headline
   story
   databaseId
   postedDate
   attachmentLink
   authorName
   authorTitle
   authorLocation
   Filter: sharingCornerCategories {
     nodes {
       name
     }
   }
 }
}
}`;

export const GET_SAVED_PAGE = `query {
    savedContents(first: 1) {
        nodes {
            pageTitle
            pageDescription
        }
    }
}`;

const CUSTOM_POSTS = `query CustomPosts($uri: ID!) {
    post(id: $uri, idType: URI) {
      title
      blocks {
        name
        ... on CoreParagraphBlock {
          saveContent
          order
        }
        ... on CoreImageBlock {
          saveContent
          order
        }
        ... on CoreColumnsBlock {
          saveContent
          order
        }
        ... on CoreHeadingBlock {
          saveContent
          order
        }
        ... on CoreListBlock {
          saveContent
          order
        }
        ... on CoreTableBlock {
          saveContent
          order
        }
        ... on CoreMediaTextBlock {
          saveContent
          order
        }
        ... on CoreCoverBlock {
          saveContent
          order
        }
        ... on CoreEmbedBlock {
          saveContent
          order
        }
        ... on CoreButtonsBlock {
          saveContent
          order
        }
        ... on GenesisBlocksGbButtonBlock {
          saveContent
          order
        }
        ... on CoreSpacerBlock {
          saveContent
          order
        }
        ... on GenesisBlocksGbSpacerBlock {
          saveContent
          order
        }
        ... on GenesisBlocksGbColumnBlock {
          saveContent
          order
        }
        ... on GenesisBlocksGbColumnsBlock {
          saveContent
          order
        }
        ... on CoreColumnBlock {
          saveContent
          order
        }
        ... on CoreButtonBlock {
          saveContent
          order
        }
      }
    }
}`;

export const HOME_PAGE_DETAILS = `query {
  allHomePageDetails {
    nodes {
      applicationTitle
      sallogo {
       mediaItemUrl
      }
      commonlinksTitle
      commonlinksicon {
        mediaItemUrl
      }
      carouselheader
      powerBITitle
      profileiconplaceholder {
        mediaItemUrl
      }
      additionallinksicon {
        mediaItemUrl
      }
      facebookicon {
        mediaItemUrl
      }
      facebookurl
      twittericon {
        mediaItemUrl
      }
      twitterurl
      tiktokicon {
        mediaItemUrl
      }
      tiktokurl
      linkedinicon {
        mediaItemUrl
      }
      linkedinurl
      youtubeicon {
        mediaItemUrl
      }
      youtubeurl
      instagramicon {
        mediaItemUrl
      }
      instagramurl
      dropdownicon {
        mediaItemUrl
      }
      gethelpicon {
        mediaItemUrl
      }
      gethelpurl
      hamburgmenuicon {
        mediaItemUrl
      }
      redstaricon {
        mediaItemUrl
      }
      savecardicon {
        mediaItemUrl
      }
      pdfcardicon {
        mediaItemUrl
      }
      savefilledicon {
        mediaItemUrl
      }
      saveoutlineicon {
        mediaItemUrl
      }
      flagicon {
        mediaItemUrl
      }
      readmoreicon {
        mediaItemUrl
      }
      privacyPolicy
      termsOfUse
      coronaResources
      contactUs
      ownaSal
    }
  }
}`;

export const SEARCH_QUERY = `query searchQuery($searchTerm: String) {
  ImportantNewsAndUpdates: importantNewsAndUpdates(
    where: {search: $searchTerm}
    first: 100
  ) {
    nodes {
      headline
      story
      cardSummary
      databaseId
      urgentPost
      urgentUntil
      postedDate
      effectiveDate
      expirationDate
      postAttachments {
        title
        mediaItemUrl
      }
    }
  }
  DotCommArchives: dotCommArchives(where: {search: $searchTerm}, first: 100) {
    nodes {
      headline
      story
      cardSummary
      databaseId
      urgentPost
      urgentUntil
      postedDate
      effectiveDate
      expirationDate
      pDFAttachments {
        title
        mediaItemUrl
      }
    }
  }
  WeeklyCheckoutArchives: weeklyCheckoutArchives(where: {search: $searchTerm}, first: 100) {
    nodes {
      pDFTitle
      postedDate
      pDFAttachment {
        mediaItemUrl
      }
    }
  }
  Recalls: recalls(where: {search: $searchTerm}, first: 100) {
    nodes {
      category: recallCategories {
        nodes {
          name
        }
      }
      pDFTitle
      postedDate
      effectiveDate
      expirationDate
      pDFAttachment {
        mediaItemUrl
      }
    }
  }
  Webinars: webinars(where: {search: $searchTerm}, first: 100) {
    nodes {
      headline
      story
      databaseId
      cardSummary
      webinarLink
      webinarDate
      postedDate
      effectiveDate
      expirationDate
      pDFAttachment {
        mediaItemUrl
      }
      thumbnailImage {
        mediaItemUrl
      }
    }
  }
  DistributionCenters: distributionCenters(where: {search: $searchTerm}, first: 100) {
    nodes {
      distributionCenterTitle
      distributionCenterAddress
      distributionCenterPhone
      distributionCenterEmail
    }
  }
  RetailResources: standardOperatingProcedures(where: {search: $searchTerm}, first: 100) {
    nodes {
      category: sOPCategories {
        nodes {
          name
        }
      }
      pDFTitle
      pDFAttachment {
        mediaItemUrl
      }
    }
  }
  FAQs: frequentlyAskedQuestions(where: {search: $searchTerm}, first: 100) {
    nodes {
      category: fAQsCategories {
        nodes {
          name
        }
      }
      question
      questionDescription
    }
  }
  SharingCorner: allSharingCorner(where: {search: $searchTerm}, first: 100) {
    nodes {
      headline
      story
      databaseId
      postedDate
      attachmentLink
      authorName
      authorTitle
      authorLocation
    }
  }
}`;