<template>
    <div v-html="block.saveContent" class="list"/>
</template>

<script>
export default {
  name: 'ListBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../../../../assets/_shared.scss";

::v-deep ul {
    text-align: left;
    list-style: none;
}

::v-deep li::before {
    content: "◦";
    color: $salBlue;
    font-weight: bold;
    font-size: 200%;
    line-height: 10px;
    display: inline-block;
    height: 18px;
    width: auto;
    padding-right: 10px;
    margin-left: -1em;
    vertical-align: middle;
}

::v-deep li {
    font-family: "Isidora Sans";
    color: $salLightGrey;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}


</style>