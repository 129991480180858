export default {
  getFavorites(state) {
    return state.favoritesData;
  },
  getFavStatus(state) {
    return state.favStatus;
  },
  getFavErrCode(state) {
    return state.favErrCode;
  },
};
