export default {
  saveNavMenu(state, response) {
    let navMenu = {};
    response.navigationItems.nodes.forEach((item) => {
      let key = item.terms.nodes[0].name;
      let value = {
        menuItem: item.menuItem,
        postLink: item.postLink,
      };

      if (navMenu[key]) {
        navMenu[key].push(value);
      } else {
        navMenu[key] = [value];
      }
    });
    state.navMenu = navMenu;
  },
  saveWebinarsInfo(state, data) {
    state.webinars =
      data.newsFeedTypes.nodes[0].children.nodes[0].webinars.nodes;
  },
  saveCustomPost(state, data) {
    state.customPost = data.post;
  },
  saveSavedContent(state, data) {
    state.savedContent = data;
  },
  saveRouteParams(state, data) {
    state.routeParam = data;
  },
  saveLearningCart(state, url) {
    state.learningCart = url;
  },
  saveTopics(state, data) {
    state.topics = data.topicsFiltering.nodes[0].topics.nodes;
  },
  saveCommonLinks(state, data) {
    const cList = data.allCommonLinks.nodes;
    const mList = state.additionalLinks;
    //LOGIC DESCRIPTION:
    //1. GATHER COMMON LINKS NAMES AND CATEGORY FROM WPENGINE
    //2. GATHER MENU ITEMS FROM MENU SERVICE
    //3. FILTER MENU ITEMS, LOOP THROUGH, IF MENU SERVICE CATEGORY DOES NOT MATCH A COMMON LINK CATEGORY, REMOVE IT
    //4. LOOP THROUGH THE FILTERED MENU ITEMS AND FILTER THAT BASED ON IF A MENU SERVICE ITEM NAME MATCHES A COMMON LINK APP NAME
    //5. IF IT DOES, RETURN THE APPNAME, APPLINK, AND FAVENBLD BOOLEAN VALUE.
    //6. IF IT DOES NOT, ASSIGN BLANK URL VALUE
    //7. FILTER OUT ITEMS WITH BLANK URL VALUE
    var newCL = cList.map((commonlink) => {
      const busineeType = commonlink.commonLinksTypes.nodes[0];
      const filterParentMenu = mList.filter(
        (menu) => menu.category === busineeType.name
      );
      if (filterParentMenu.length > 0) {
        const filterApp = filterParentMenu[0].appList.filter(
          (obj) => obj.label === commonlink.apps
        );
        const category = filterParentMenu[0].category; // ------------------- DEBUGGING -------------------
        if (filterApp.length > 0) {
          return {
            appName: commonlink.apps,
            appLink: filterApp[0].appStartingUrl,
            favEnbld: false,
          };
        } else {
          return {
            WP_APP_NAME: commonlink.apps,
            MENU_APP_NAME: "Unable to determine menu service name",
            appLink: "",
            favEnbld: false,
            menu_category: category,
            wp_category: busineeType.name,
          };
        }
      } else {
        return {
          WP_APP_NAME: commonlink.apps,
          MENU_APP_NAME: "Unable to determine menu service name",
          appLink: "",
          favEnbld: false,
          menu_category: "Unable to determine category",
          wp_category: busineeType.name,
        };
      }
    });

    const filteredCL = newCL.filter((item) => {
      return item.appLink !== "";
    });

    const blankCL = newCL.filter((item) => {
      // ------------------- DEBUGGING -------------------
      return item.appLink === ""; // ------------------- DEBUGGING -------------------
    }); // ------------------- DEBUGGING -------------------

    // console.log('Unable to match some Common Link items!'); // ------------------- DEBUGGING -------------------
    // console.table(blankCL, ['MENU_APP_NAME', 'menu_category', 'WP_APP_NAME' , 'wp_category']); // ------------------- DEBUGGING -------------------

    newCL = filteredCL;
    state.commonLinks = newCL;

    this.commit("menuModule/COMMON_MENU_LINKS_LOADED", newCL, { root: true });
    this.commit("menuModule/VISIBLE_COMMON_LINKS_CHANGED", newCL, {
      root: true,
    });
  },

  savePageDetails(state, response) {
    const detailNodes = response.data.data.supportServicesPages.nodes;

    detailNodes.forEach((node) => {
      if (node.pageTitle === "HR") {
        state.hrPageInfo = node;
      }

      if (node.pageTitle === "Marketing") {
        state.marketingPageInfo = node;
      }
    });
  },

  saveCommonPocList(state, data) {
    const pocByDpt = data.pointsOfContactDepartment.nodes;
    if (pocByDpt.length > 0) {
      const hrDepts = pocByDpt.filter((dept) => dept.name === "HR");
      state.hrPocList = hrDepts[0].pointOfContacts.nodes.sort(
        (a, b) => a.orderby - b.orderby
      );

      const mrkDepts = pocByDpt.filter((dept) => dept.name === "Marketing");
      state.pocList = mrkDepts[0].pointOfContacts.nodes.sort(
        (a, b) => a.orderby - b.orderby
      );

      const depts = pocByDpt.map((dept) => {
        return {
          department: dept.name,
          pocList: dept.pointOfContacts.nodes.sort(
            (a, b) => a.orderby - b.orderby
          ),
        };
      });

      state.commonPOCList = depts;
    }
  },

  resetState(state) {
    state.commonPOCList = [];
  },

  saveWeeklyCheckoutArchives(state, data) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const cWCData =
      data.newsFeedTypes.nodes[0].children.nodes[0].weeklyCheckoutArchives
        .nodes;

    const yrsObj = cWCData.map((wcObj) => {
      const pDate = new Date(wcObj.postedDate);
      const pYear = pDate.getFullYear();
      return pYear;
    });
    const sortByYears = new Set(yrsObj);

    const optWC = cWCData.map((wcObj) => {
      const pDate = new Date(wcObj.postedDate);
      const pMonth = pDate.toLocaleString("en-US", { month: "long" });
      const pYear = pDate.getFullYear();

      const spcMonthList = months.map((month) => {
        if (month === pMonth) {
          return {
            monthName: pMonth,
            year: pYear,
            docDetails: {
              date: wcObj.postedDate,
              docTitle: wcObj.pDFTitle,
              docLink: wcObj.pDFAttachment,
            },
          };
        }
        return;
      });

      return spcMonthList.filter((data) => data !== undefined);
    });

    let wholeObj = [];
    const yearObjects = sortByYears.forEach((year) => {
      let monthsObj = [];

      const bij1 = optWC.forEach((obj) => {
        if (obj[0].year === year) {
          if (monthsObj.length > 0) {
            const checkExists = monthsObj.filter(
              (item) =>
                item.month === obj[0].monthName && obj[0].year === item.year
            );
            if (checkExists.length > 0) {
              const mergeData = [...monthsObj.data, [obj[0].docDetails]];
              monthsObj.push({
                month: obj[0].monthName,
                data: mergeData,
              });
            } else {
              monthsObj.push({
                month: obj[0].monthName,
                data: [obj[0].docDetails],
              });
            }
          } else {
            monthsObj.push({
              month: obj[0].monthName,
              data: [obj[0].docDetails],
            });
          }
        }
      });

      wholeObj.push({
        year: year,
        data: monthsObj,
      });
    });

    state.weeklyCheckoutArchives = wholeObj;
  },
  saveResources(state, data) {
    const response = data.distributionTypes.nodes[0].children.nodes[0];
    state.dResources = {
      resources: response.distributionResource.nodes,
      pageTitle: response.distributionResourcePage.nodes[0].pageTitle,
      pageDesc: response.distributionResourcePage.nodes[0].pageDescription,
    };
  },
  saveRetailResources(state, data) {
    const rawData =
      data.trainingHubTypes.nodes[0].children.nodes[0]
        .standardOperatingProcedures.nodes;

    let reduceRes = rawData.reduce((acc, current) => {
      let prev = acc.find((doc) => doc[current.category.nodes[0].name]);
      if (!prev) {
        prev = {
          title: current.category.nodes[0].name,
          [current.category.nodes[0].name]: [],
          icon: " + ",
        };
        acc.push(prev);
      }
      prev[current.category.nodes[0].name].push({
        pDFTitle: current.pDFTitle,
        pDFUrl: current.pDFAttachment.mediaItemUrl,
      });

      return acc;
    }, []);

    reduceRes = reduceRes.map(function (elem) {
      return {
        title: elem.title,
        children: elem[elem.title],
        icon: " + ",
      };
    });
    state.retailRes = reduceRes;
  },
  saveHomePageDetails(state, data) {
    state.homePageDetails = data.allHomePageDetails.nodes[0];
  },
};
