import Utils from "@/utils/Utils";

export default {
  objectSavedInHistory(state) {
    state.historyStaus = "";
    state.historyErrCode = "200";
  },
  setHistoryData(state, data) {
    if (data.response.status === 200 || data.response.status === 204) {
      state.historyErrCode = "200";
      state.historyStaus = Utils.Strings.noHistory;
      const historyData =
        data.response.data !== "" ? data.response.data.userHistory : [];
      const optimizedList = historyData.map((historyObj) => {
        return {
          appName: historyObj.menuLabel,
          appLink: historyObj.url,
        };
      });
      state.historyData = optimizedList;
    } else {
      state.historyStaus = constants.strings.noHistory;
      state.historyData = [];
      state.historyErrCode = data.response.status;
    }
  },
  setEmptyHistoryData(state) {
    state.historyData = [];
    state.historyErrCode = "200";
    state.historyStaus = Utils.Strings.noHistory;
  },
  setDeleteHistoryStatus(state) {
    state.historyStaus = Utils.Strings.noHistory;
    state.historyData = [];
    state.historyErrCode = "200";
  },
  setDeleteHistoryErrorStatus(state) {
    state.historyStaus = Utils.Strings.noHistory;
    state.historyErrCode = "500";
  },
  resetHistoryErrorCode(state) {
    state.historyErrCode = "";
    state.historyStaus = "";
  },
};
