<template>
  <div class="outerDiv">
    <div :class="isMobile() ? 'card-m' : 'card'">
    <div class="card-header"> Reset Password  </div>
    <div class="card-body" >
            
      <p class="card-text">If you have an IT-related issues, please call this number</p>

      <p class="card-title">866-787-5510 </p> 
      <!-- <p class="card-text"> or </p>
      <p class="card-text"> Create a ticket from below for assitance.</p> 
      <a href="https://salprod.service-now.com/sp?id=index" target="_blank" class="button-style"><b>Create a Ticket</b></a> -->
    <br/><br/>
    </div>
  </div> 
    

  </div>
 </template>
 <script>
  export default {
    methods: {
        isMobile() {
            return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
    }
  }
 </script>
 <style lang="scss" scoped>

.outerDiv {
    width: 100%; 
}

.card {
    padding: 0 !important;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 375px;
    height: 380px;
    .card-header {
        background-color: #dce1eb !important;
        height: 50px;
        padding-left: 15px !important;
        padding-bottom: 5px;
        padding-top: 10px;
        font-family: 'Isidora-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 34.8px;
        color: #14377D;
        align-items: center;
        display: flex;
        border: none;
    }
    .card-body {
        padding: 15px !important;
        font-family: Isidora Sans SemiBold;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
    }
      .card-title{
        font-family: 'Isidora';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #14377C;
    }

    .card-text{
      font-family: 'Isidora Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      //padding-top: 15px;
    }
}

.card-m {
    padding: 0 !important;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    height: 250px;
    .card-header {
        background-color: #dce1eb !important;
        height: 50px;
        padding-left: 15px !important;
        padding-bottom: 5px;
        padding-top: 10px;
        font-family: 'Isidora-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 34.8px;
        color: #14377D;
        align-items: center;
        display: flex;
        border: none;
    }
    .card-body {
        padding: 15px !important;
        font-family: Isidora Sans SemiBold;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
    }
      .card-title{
        font-family: 'Isidora';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #14377C;
    }

    .card-text{
      font-family: 'Isidora Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      //padding-top: 15px;
    }
}



/* CSS */
.button-style {
  background-color: #fff;
  border: 1px solid #14377D;
  border-radius: 10px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #14377D;
  cursor: pointer;
  display: flex;
  font-family: "Isidora-SemiBold";
  font-size: 18px;
  //line-height: 29px;
  position: relative;
  text-align: center;
  text-decoration: solid;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 175px;
  padding: 6px 20px 5px;
  gap: 5px;


}

.button-style:hover {
  background-color: #14377D;
  color: white;
}

/*.button-style:focus {
  border-color: #14377D;
  //box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  color: white;
  outline: 0;
} */
/*.button-style:visited  {
  border-color: white;
  //box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  color:#14377D;
  outline: 0;
} */
  </style>