export const upnParsing = (upn) => {
    let upnCheck = upn.includes("#EXT#");
    if (upnCheck) {
        console.log('Account is a guest, splitting EXT...');
        let upnSplit = upn.split("#EXT#");
        let upnSplit2 = upnSplit[0].split("@");
        let upnSplit3 = upnSplit2[0].split("_");
        let upnSplit4 = upnSplit3[0] + "@" + upnSplit3[1];
        return upnSplit4;
    } else {
        console.log('Account is SAL, serving UPN...');
        return upn;
    }
}