import axios from 'axios';
import store from '../../store/index';
import { GET_SAVED_PAGE } from '../../queries/gqlQueries';
import { gQLCaller } from "../../queries/gqlCaller";
import { CallApimWithGql } from "../../api/GraphqlAPI";
import Utils from '../../utils/Utils.js';

export const fetchSavePageInfo = async ({ commit }) => {
    gQLCaller(GET_SAVED_PAGE, 0).then((response) => {
        commit('MUTATE_SAVE_PAGE', response.savedContents.nodes[0]);
    });
}

export const fetchSavedPostsContent = async ({ commit }, data) => {
  await CallApimWithGql(data)
    .then((response) => {
      commit("saveSavedContent", response);
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};