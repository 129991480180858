<template>
  <div class="content-body">
    <!-- <div class="content">{{ pageTitle }} - Content Goes Here</div> -->

    <template>
      <!-- <Dashboard default /> -->
      <!-- {{ dynamicComponent }}
      <component :is="dynamicComponent" :compProps="pageTitle"></component> -->
      <!-- Loading component ... {{ componentName }}  -->
      <!-- <component v-bind:is="NewsAndUpdates" :compProps="NewsAndUpdates"></component> -->
      <component
        class="wrapper"
        v-bind:is="getComponentName"
        :compProps="getComponentName"
        :customUrl="customUrl"
        :pageTitle="pageTitle"
        v-on:navHome="navHome"
      ></component>
    </template>
  </div>
</template>
<script>
import Dashboard from "./Dashboard.vue";
import AdditionalLinks from "./AdditionalLinks.vue";
import NewsAndUpdates from "./module/newsfeed/NewsAndUpdates.vue";
import DotComm from "./module/newsfeed/DotComm.vue";
import WeeklyCheckoutArchives from "./module/newsfeed/WeeklyCheckoutArchives.vue";
import Recalls from "./module/newsfeed/Recalls.vue";
import Webinars from "./module/newsfeed/Webinars.vue";

import Marketing from "./module/supportservices/Marketing.vue";
import Operations from "./module/supportservices/Operations.vue";
import HR from "./module/supportservices/HR.vue";
import Remodel from "./module/supportservices/Remodel.vue";

import OperationalCalendar from "./module/calendar/OperationalCalendar.vue";
import mOperationalCalendar from "./module/calendar/mOperationalCalendar.vue";
import OrderReleaseDates from "./module/calendar/OrderReleaseDates.vue";
import SeasonalPrograms from "./module/calendar/SeasonalPrograms.vue";

import Resource from "./module/distribution/Resource.vue";
import CenterInformation from "./module/distribution/CenterInformation.vue";

import RetailResources from "./module/traininghub/RetailResources.vue";
import SharingCorner from "./module/traininghub/SharingCorner.vue";
import SharingCornerCreate from "./module/traininghub/SharingCornerCreate.vue";
import SharingCornerRead from "./module/traininghub/SharingCornerRead.vue";
import FAQs from "./module/traininghub/FAQs.vue";

import GetHelp from "./module/help/GetHelp.vue";
import PocList from "./profile/PocList.vue";

import Saved from "./module/misc/SavedRead.vue";
import NoAccessRouting from "./profile/NoAccessRouting.vue";
import CustomPost from "./module/custom/CustomPost.vue";
import AllResults from "./module/search/AllResults.vue";
import mAllResults from "./module/search/mAllResults.vue";
import mCustomPost from "./module/custom/mCustomPost.vue";

export default {
  components: {
    Dashboard,
    AdditionalLinks,
    NewsAndUpdates,
    DotComm,
    WeeklyCheckoutArchives,
    Recalls,
    Webinars,
    Marketing,
    Operations,
    HR,
    Remodel,
    OperationalCalendar,
    mOperationalCalendar,
    OrderReleaseDates,
    SeasonalPrograms,
    Resource,
    CenterInformation,
    RetailResources,
    SharingCorner,
    FAQs,
    SharingCornerCreate,
    SharingCornerRead,
    GetHelp,
    Saved,
    PocList,
    NoAccessRouting,
    CustomPost,
    AllResults,
    mAllResults,
    mCustomPost,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    componentName: {
      type: String,
      required: true,
    },
    customUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentComponent: "",
      isAdditionalLinksComponent: true,
      savedPosts: [],
    };
  },
  computed: {
    getComponentName() {
      if (this.isAdditionalLinksComponent === true) {
        if (this.componentName === "Learning Cart") {
          return this.currentComponent;
        } else {
          this.currentComponent = this.componentName;
          return this.componentName;
        }
      } else {
        this.currentComponent = "AdditionalLinks";
        return "AdditionalLinks";
      }
    },
  },
  watch: {
    componentName(newComp) {
      this.isAdditionalLinksComponent = newComp !== "AdditionalLinks";
    },
  },
  methods: {
    gotoAdditionalLinks() {
      this.isAdditionalLinksComponent = false;
      this.$emit("getCurrentComponent", this.getComponentName);
    },
    navHome() {
        this.$emit("reloadPage", "Dashboard");
        this.$root.$emit("one", "Dashboard");
    },
  },
};
</script>
<style lang="scss">
.content {
  padding: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  .content-body {
    width: 100%;
    .chevron-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .chevron {
        :hover {
          cursor: pointer;
        }
        align-self: flex-start;
        margin: 10px auto 5px 1px;
      }
    }
    .wrapper {
      padding: 10px 0px;
    }
  }
}
</style>
