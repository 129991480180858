import { saveHistoryData, fetchHistory } from "@/api/RecentlyUsed";
import Utils from "@/utils/Utils";

export const fetchHistoryList = async ({ commit }, request) => {
  
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  };
   console.log("fetch history api is calling");
  await fetchHistory(request, headers)
    .then((res) => {
      commit("setHistoryData", { response: res, email: request.userName });
    })
    .catch((err) => {
      console.log("headers", err);
      console.log("Fetch history Api Call Failed");
      commit("setEmptyHistoryData", err);
    });
};
export const saveHistory = async ({ commit }, request) => {
 const headers = {
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  };
  await saveHistoryData(request, headers)
    .then((res) => {
      commit("objectSavedInHistory", res.data);
    })
    .catch((err) => {
      console.log("headers", err);
      console.log("Api Call Failed");
    });
};
