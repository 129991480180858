<template>
  <div>
    <b-navbar class="navbar" type="dark">
      <b-navbar-nav>
        <b-nav-item-dropdown text="News Feed" right>
          <b-dropdown-item
            class="nav-item"
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=21')
            "
            >Important News and Updates</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            class="nav-item"
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=23')
            "
            >Dot Comm Archives</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            class="nav-item"
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=24')
            "
            >Weekly Checkout Archives</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            class="nav-item"
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=25')
            "
            >Recalls</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            class="nav-item"
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=26')
            "
            >Webinars</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Support Services" right>
          <b-dropdown-item
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=27')
            "
            >Marketing</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=28')
            "
            >Operations</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            @click="
              updateProps('https://salrpconnect.wpengine.com/?page_id=29')
            "
            >HR</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Calendar" right>
          <b-dropdown-item href="#">Seasonal Programs</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#">Operational Calendar</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#"
            >Pre-booked Order Release Dates</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Distribution" right>
          <b-dropdown-item href="#">Distribution Resource</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#"
            >Distribution Center Information</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Training Hub" right>
          <b-dropdown-item href="#">SOPs</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#">Learning Cart</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#">Sharing Corner</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#">FAQs</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <div class="gethelp-nav">
        <a class="gethelp">Get Help</a>
      </div>
    </b-navbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wordpressUrl: String
    };
  },
  mounted() {},
  methods: {
    updateProps(url) {
      this.wordpressUrl = url;
      this.$emit("getWrdprssUrl", url);
    }
  }
};
</script>
<style>
.navbar {
  background-color: #00afd7;
}
.nav-item {
  color: white;
}
.navbar-dark .navbar-nav .nav-link {
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal;
  font-size: 15px;
  color: white;
  width: 130px;
  text-align: right !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-style: normal;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: white;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-divider {
  background-color: white;
}
.gethelp-nav {
  margin-left: auto;
  margin-right: 10px;
}
.gethelp-nav .gethelp:hover {
  cursor: pointer;
}
.gethelp-nav .gethelp {
  background-color: white;
  width: auto !important;
  height: 40px !important;
  text-align: center !important;
  border-radius: 20px;
  color: #14377c !important;
  font-weight: bold;
  padding: 0 5px;
  padding-bottom: 3px;
  font-size: 16px;
  text-decoration: none;
}
</style>
