import { __awaiter, __extends, __generator } from "tslib";
import { NavigationClient } from '@azure/msal-browser';
/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
var CustomNavigationClient = /** @class */ (function (_super) {
    __extends(CustomNavigationClient, _super);
    function CustomNavigationClient(router) {
        var _this = _super.call(this) || this;
        Object.defineProperty(_this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.router = router;
        return _this;
    }
    /**
     * Navigates to other pages within the same web application
     * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
     * @param url
     * @param options
     */
    Object.defineProperty(CustomNavigationClient.prototype, "navigateInternal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (url, options) {
            return __awaiter(this, void 0, void 0, function () {
                var relativePath;
                return __generator(this, function (_a) {
                    relativePath = url.replace(window.location.origin, '');
                    if (options.noHistory) {
                        this.router.replace(relativePath);
                    }
                    else {
                        this.router.push(relativePath);
                    }
                    return [2 /*return*/, false];
                });
            });
        }
    });
    return CustomNavigationClient;
}(NavigationClient));
export { CustomNavigationClient };
