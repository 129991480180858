import axios from "axios";
import { gQLCaller } from "@/queries/gqlCaller";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig, msalInstance } from "../authConfig";
import Utils from "../utils/Utils";
import store from "../store/index.js";

export const CallAPIM = async (data) => {
  return await gQLCaller(data, 100)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const CallApimWithGql = async (data) => {
  return await gQLCaller(data.query, 100, data.vars)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("API failed - ", err);
    });
};

export const callAPIMWithParams = async (request) => {
  return  await axios({
    method: "POST",
    url: Utils.URI.wpengine,
    auth: {
       username: "svc_wpe_rpconnect_dev",
       password: "Vytq 2Ypo 5LPa bqkZ Tflw JBmt"
     },
     data: {
         query:request.query,
         variables: {
             department:request.params,
         }
     }
 });
}

export const callMultiAPIM = async (request, name) => {
    let account = "";
    let myMsal = "";

    if (msalInstance.getAllAccounts().length > 0) {
        myMsal = msalInstance;
        account = myMsal.getAllAccounts();
    } else {
        myMsal = new PublicClientApplication(msalConfig);
        account = myMsal.getAllAccounts();
    }

    const accessTokenRequest = {
        scopes: loginRequest.tokenRequest,
        account: account,
    };
    const idToken = await myMsal
        .acquireTokenSilent(accessTokenRequest)
            .then(function (accessTokenResponse) {
                let accessToken = accessTokenResponse.accessToken;
                return accessToken;
            });

    const headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.VUE_APP_API_SUBSCRIPTION_KEY,
    "Access-Control-Allow-Origin": process.env.VUE_APP_AD_REDIRECT_URI,
    Authorization: "Bearer " + idToken,
    };

    const postCounts = store.state.queryCounts[0];
    var postCountsTotal = 0;

    for (let i = 0; i < postCounts.length; i++) {
        if(postCounts[i].name.includes(name.replace(/\s/g, ""))){
            postCountsTotal += postCounts[i].count;
        }
    }

    let queriesNeeded = Math.ceil(postCountsTotal / 100);
    let endCursor = '';
    let data = [];

    for(let i = 0; i < queriesNeeded; i++){
        var results = await axios.post(
            process.env.VUE_APP_WP_API_URI,
            { query: request,
                variables: {
                    after: endCursor,
                }
            },
            { headers },
        );
        endCursor = results.data.data.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.endCursor;
        data.push(results.data.data);
    }

    //combine the objects in the data array into one object
    data = data.reduce((acc, cur) => {
        return {
            trainingHubTypes: {
                nodes: [
                    {
                        children: {
                            nodes: [
                                {
                                    standardOperatingProcedures: {
                                        nodes: [
                                            ...acc.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.nodes,
                                            ...cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.nodes,
                                        ],
                                        pageInfo: {
                                            endCursor: cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.endCursor,
                                            hasNextPage: cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.hasNextPage,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
    }
    );

    return data;
}

