import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig, msalInstance } from "../authConfig";
import store from "../store/index.js";
import Utils from "../utils/Utils.js";
import axios from "axios";

export async function gQLCaller(query, page, vars) {
  let account = "";
  let myMsal = "";

  if (msalInstance.getAllAccounts().length > 0) {
    myMsal = msalInstance;
    account = myMsal.getAllAccounts();
  } else {
    myMsal = new PublicClientApplication(msalConfig);
    account = myMsal.getAllAccounts();
  }

  const accessTokenRequest = {
    scopes: loginRequest.tokenRequest,
    account: account,
  };
  const idToken = await myMsal
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      return accessToken;
    })
    .catch((err) => {
      if (err.name === "InteractionRequiredAuthError") {
        return myMsal
          .acquireTokenSilent(accessTokenRequest)
          .then((response) => {});
      }
    });

  const headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.VUE_APP_API_SUBSCRIPTION_KEY,
    "Access-Control-Allow-Origin": process.env.VUE_APP_AD_REDIRECT_URI,
    Authorization: "Bearer " + idToken,
  };

    let thisQueryCount = [];

    if(page === undefined || page === 0){
        if(vars){
            var results = await axios({
                method: "POST",
                url: process.env.VUE_APP_WP_API_URI,
                headers: headers,
                data: {
                    query: query,
                    variables: vars
                }
            });
            var data = results.data.data;
            return data;
        } else {
            var results = await axios.post(
                process.env.VUE_APP_WP_API_URI,
                { query: query },
                { headers }
            );
            var data = results.data.data;
            return data;
        }
    }

    try {
        const postCounts = store.state.queryCounts;
            for (let i = 0; i < postCounts[0].length; i++) {
                if (postCounts[0][i].name == page) {
                    thisQueryCount.push(postCounts[0][i].count);
                }
            }

            if( thisQueryCount[0] > 100 ) {
                let queriesNeeded = Math.ceil(thisQueryCount[0] / 100);
                let endCursor = '';
                let data = [];

                for(let i = 0; i < queriesNeeded; i++){
                    var results = await axios.post(
                        process.env.VUE_APP_WP_API_URI,
                        { query: query,
                            variables: {
                                after: endCursor,
                            }
                        },
                        { headers },
                    );
                    endCursor = results.data.data.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.endCursor;
                    data.push(results.data.data);
                }

                //combine the objects in the data array into one object
                data = data.reduce((acc, cur) => {
                    return {
                        trainingHubTypes: {
                            nodes: [
                                {
                                    children: {
                                        nodes: [
                                            {
                                                standardOperatingProcedures: {
                                                    nodes: [
                                                        ...acc.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.nodes,
                                                        ...cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.nodes,
                                                    ],
                                                    pageInfo: {
                                                        endCursor: cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.endCursor,
                                                        hasNextPage: cur.trainingHubTypes.nodes[0].children.nodes[0].standardOperatingProcedures.pageInfo.hasNextPage,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    };
                }
                );
                return data;
            } else {
                if(vars){
                    var results = await axios({
                        method: "POST",
                        url: process.env.VUE_APP_WP_API_URI,
                        headers: headers,
                        data: {
                            query: query,
                            variables: vars
                        }
                    });
                } else {
                    var results = await axios.post(
                        process.env.VUE_APP_WP_API_URI,
                        { query: query },
                        { headers }
                    );
                }
                var data = results.data.data;
                return data;
            }
        } catch (error) {
            if(vars) {
                var results = await axios({
                    method: "POST",
                    url: process.env.VUE_APP_WP_API_URI,
                    headers: headers,
                    data: {
                        query: query,
                        variables: vars
                    }
                });
            } else {
                var results = await axios.post(
                    process.env.VUE_APP_WP_API_URI,
                    { query: query },
                    { headers }
                );
            }
            var data = results.data.data;
            return data;
        }
}
