<template>
  <div class="distribution-body">
    <span class="heading">
      <h1>{{ getResources.pageTitle }}</h1>
      <h2>.</h2>
    </span>
    <p class="caption">{{ removeTags(getResources.pageDesc) }}</p>
    <b-row class="content" no-gutters cols="2">
      <!-- <ComingSoon/> -->
            <b-col
                class="rsrc"
                v-for="(rsrce, index) in getResources.resources"
                :key="index"
            >
                <a @click.prevent="openPDF(rsrce.pDFAttachment)"> {{ rsrce.pDFTitle }} </a>
            </b-col>
    </b-row>
  </div>
</template>

<script>
import ComingSoon from "../../ComingSoon.vue";
import { mapGetters, mapActions } from "vuex";

import { DISTRIBUTION_RESOURCE_QUERY } from "../../../queries/gqlQueries";
export default {
  components: {
    ComingSoon,
  },
  data() {
    return {
      dResource: "testttt",
    };
  },
  created() {
    this.fetchResources();
  },
  computed: {
    ...mapGetters(["getResources"]),
  },
  methods: {
    ...mapActions(["fetchDistributionResources"]),
    fetchResources() {
      this.fetchDistributionResources(DISTRIBUTION_RESOURCE_QUERY).then(
        (res) => {
          console.log(" *** getResources *** ", this.getResources);
        }
      );
    },
    openPDF(pdfDocs) {
      if(pdfDocs !== null && pdfDocs.length > 0) {
        window.open(pdfDocs[0].mediaItemUrl, "_blank")
      }
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

@font-face {
  font-family: "Isidora-Bold";
  src: url("../../../assets/fonts/Isidora/Isidora-Bold.otf");
}

@font-face {
  font-family: "Isidora-SemiBold";
  src: url("../../../assets/fonts/Isidora/Isidora-SemiBold.otf");
}

@font-face {
  font-family: "Isidora Sans Medium";
  src: url("../../../assets/fonts/IsidoraSans/Isidora Sans Medium.otf");
}

.distribution-body {
  margin-left: 0px;
  text-align: left;
  margin-top: 60px;

  .heading {
    padding-left: 70px;
    padding-top: 20px;
  }

  .caption {
    padding-left: 70px;
    padding-top: 15px;
    font-size: 18px;
    font-family: "Isidora Sans";
    line-height: 22px;
    font-weight: 500;
    color: $salLightGrey;
  }

  .heading h1 {
    display: inline;
    font-family: "Isidora-Bold";
    font-size: 48px;
    color: #14377c;
    margin-bottom: 0px;
    text-align: left;
  }

  .heading h2 {
    display: inline;
    font-family: "Isidora-Bold";
    font-size: 48px;
    color: #ee3124;
    margin-bottom: 0px;
    text-align: left;
  }

  .content {
    padding: 0 70px 70px 70px;
    text-align: left;
    .rsrc {
        border-bottom: $salBlueBorder;
        text-align: left;
        margin: 5px auto 0 10px;
        height: 40px;
        display: flex;
        justify-items: center;
        align-items: end;
        max-width: 48%;

        a {
            text-decoration: none;
            color: $salBlue;
            text-align: left;
            vertical-align: bottom;
            margin-left: -10px;
            font-size: 24px;
            font-family: "Isidora";
            line-height: 29px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        a:hover {
          cursor: pointer;
          color: red;
        }
    }
  }
}
</style>
