<template>
  <div class="outer">
    <div class="readMore">
      <read-more
        class="readContent"
        @close="showReadMore = false"
        v-if="showReadMore"
        @readMore="readMore"
        :readMoreObj="readMoreObj"
        :breadcrumb="`Dashboard`"
        :dashboard="true"
      />
    </div>
    <div class="dash-content" v-if="!showReadMore">
      <div class="heading-dash" v-responsive="['hidden-all', 'lg', 'xl']">
        <h1>{{ getHeaders.carouselHeader }}</h1>
        <h1 class="red-dot">.</h1>
      </div>
      <div class="heading-dash" v-responsive="['hidden-all', 'md']">
        <h2>{{ getHeaders.carouselHeader }}</h2>
        <h2 class="red-dot">.</h2>
      </div>
      <div
        class="heading-dash mobile"
        v-responsive="['hidden-all', 'sm', 'xs']"
      >
        <h3>{{ getHeaders.carouselHeader }}</h3>
        <h3 class="red-dot">.</h3>
      </div>

      <div v-if="ready" :class="isMobile() ? 'carousel-wrapper-mobile' : 'carousel-wrapper'">
        <carousel
          class="carousel"
          :perPage="getItemsPerPage()"
          paginationColor="gray"
          paginationActiveColor="#14377C"
          :paginationSize="20"
          :paginationEnabled="false"
          :paginationPadding="5"
          :navigationEnabled="true"
          :navigation-next-label="
            this.isMobile() || this.isiPad() ? '' : navigationNext
          "
          :navigation-prev-label="
            this.isMobile() || this.isiPad() ? '' : navigationPrev
          "
        >
          <slide
            v-for="item in allCards"
            :key="item.name"
            :class="
              isiPad()
                ? 'cards-slide iPad'
                : isMobile()
                ? 'cards-slide mobile'
                : 'cards-slide'
            "
          >
            <div
              :class="
                isiPad()
                  ? 'card-container iPad'
                  : isMobile()
                  ? 'card-container mobile'
                  : 'card-container'
              "
            >
              <card-news-updates
                v-if="Object.keys(item)[9] == 'postAttachments'"
                @readMore="readMore"
                :postedDate="item.postedDate"
                :headline="item.headline"
                :cardSummary="item.cardSummary"
                :attachments="getAttachments(item)"
                :story="item.story"
                :isUrgent="item.urgentPost"
                :id="item.databaseId"
              />
              <CardDotComm
                v-if="Object.keys(item)[9] == 'pDFAttachments'"
                @readMore="readMore"
                :postedDate="item.postedDate"
                :headline="item.headline"
                :cardSummary="item.cardSummary"
                :attachments="getAttachments(item)"
                :story="item.story"
                :isUrgent="item.urgentPost"
                :id="item.databaseId"
              />
            </div>
          </slide>
        </carousel>
      </div>
      <div class="heading-dash" v-responsive="['hidden-all', 'lg', 'xl']">
        <h1 style="padding-top: 15px">{{ getHeaders.poerBItitle }}</h1>
        <h1 style="padding-top: 15px" class="red-dot">.</h1>
      </div>
      <div class="heading-dash" v-responsive="['hidden-all', 'md']">
        <h2 style="padding-top: 15px">{{ getHeaders.poerBItitle }}</h2>
        <h2 style="padding-top: 15px" class="red-dot">.</h2>
      </div>
      <div
        class="heading-dash mobile"
        v-responsive="['hidden-all', 'sm', 'xs']"
      >
        <h3 style="padding-top: 15px">{{ getHeaders.poerBItitle }}</h3>
        <h3 style="padding-top: 15px" class="red-dot">.</h3>
      </div>
      <b-row class="power-bi">
        <div class="bidash">
          <iframe :src="iframeUrl" :class="phoneDash ? 'phone-dash' : 'dash'">
          </iframe>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import ReadMore from "@/components/module/cards/ReadMore.vue";
import { Carousel, Slide } from "vue-carousel";
import { HOME_PAGE_QUERY } from "../queries/gqlQueries.js";
import { gQLCaller } from "../queries/gqlCaller.js";
import CardDotComm from "../components/module/cards/DotComm.vue";
import CardNewsUpdates from "../components/module/cards/NewsUpdates.vue";
import ChevronRight from "../assets/carousel/Right Arrow.svg";
import ChevronLeft from "../assets/carousel/Left Arrow.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    carousel: Carousel,
    slide: Slide,
    ReadMore,
    CardDotComm,
    CardNewsUpdates,
  },
  data() {
    return {
      showReadMore: false,
      allCards: [],
      slide: 0,
      sliding: null,
      itemsPerPage: 4,
      iframeUrl: "",
      phoneDash: false,
      ready: false, 
    };
  },
  watch: {
    userSavedPosts: function() {
      this.stateReady; 
    },
  },
  computed: {
    ...mapGetters(["getHomePageDetails"]),
    ...mapGetters({
      userSavedPosts: 'getSaveProfile'
    }),
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img class="slideButtons" src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img class="slideButtons" src="${chevronLeft}"/>`;
    },
    getHeaders() {
      return {
        carouselHeader: this.getHomePageDetails.carouselheader,
        poerBItitle: this.getHomePageDetails.powerBITitle,
      };
    },
    stateReady() {
      if(this.userSavedPosts.userPosts !== undefined)  {
        this.ready = true; 
      } 
    }
  },
  mounted() {
    console.log("isMobile   ---- ", this.isMobile());
    console.log("isiPad  ----- ", this.isiPad());
    console.log("isPortrait ----- ", this.isPortrait());
    console.log(
      "isTablet || this.isIpadPro() ----- ",
      this.isiPad() || this.isIpadPro()
    );

    this.readWindowSize();
    window.addEventListener("resize", this.readWindowSize);
    this.stateReady; 
  },
  methods: {
    getItemsPerPage() {
      const isLaptop = window.innerWidth > 1300 && window.innerWidth < 1700;
      return this.isMobile() ? 1 : isLaptop ? 3 : this.isiPad() ? 2 : 4;
    },
    readWindowSize() {
      if (window.innerWidth < 890) {
        // this.itemsPerPage = 1;
        this.iframeUrl =
          "https://app.powerbi.com/reportEmbed?reportId=e107d929-d06a-43c7-8477-1e6e6fb28a40&autoAuth=true&ctid=64391119-a32d-4c70-aa4c-daebed404c71";
        this.phoneDash = true;
      } else if (window.innerWidth < 1300) {
        // this.itemsPerPage = 2;
        this.iframeUrl =
          "https://app.powerbi.com/reportEmbed?reportId=0c6daa8d-71eb-45c7-bf39-95f5e85a93ca&appId=a5748e3d-d999-4276-8ae7-ce00a0f8c69b&autoAuth=true&ctid=64391119-a32d-4c70-aa4c-daebed404c71";
        this.phoneDash = false;
      } else if (window.innerWidth < 1700) {
        // this.itemsPerPage = 3;
        this.iframeUrl =
          "https://app.powerbi.com/reportEmbed?reportId=0c6daa8d-71eb-45c7-bf39-95f5e85a93ca&appId=a5748e3d-d999-4276-8ae7-ce00a0f8c69b&autoAuth=true&ctid=64391119-a32d-4c70-aa4c-daebed404c71";
        this.phoneDash = false;
      } else if (window.innerWidth > 1750) {
        // this.itemsPerPage = 4;
        this.iframeUrl =
          "https://app.powerbi.com/reportEmbed?reportId=0c6daa8d-71eb-45c7-bf39-95f5e85a93ca&appId=a5748e3d-d999-4276-8ae7-ce00a0f8c69b&autoAuth=true&ctid=64391119-a32d-4c70-aa4c-daebed404c71";
        this.phoneDash = false;
      }
    },
    isPortrait() {
      return window.innerHeight > window.innerWidth;
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 1536 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    getAttachments(item) {
      if (Object.keys(item)[9] === "postAttachments") {
        if (item.postAttachments !== null) {
          return item.postAttachments;
        } else {
          return "null";
        }
      } else {
        if (item.pDFAttachments !== null) {
          return item.pDFAttachments;
        } else {
          return "null";
        }
      }
    },
    readMore(readMore) {
      this.readMoreObj = readMore;
      this.showReadMore = true;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    async getData() {
      gQLCaller(HOME_PAGE_QUERY).then((result) => {
        var dotComms =
          result.newsFeedTypes.nodes[0].children.nodes[0].dotCommArchives.nodes;
        var newsUpdates =
          result.newsFeedTypes.nodes[0].children.nodes[1]
            .importantNewsAndUpdates.nodes;
        this.allCards = dotComms.concat(newsUpdates);
        this.allCards.forEach((item, index) => {
          if (item.urgentPost) {
            if (item.urgentUntil !== null) {
              if (new Date(item.urgentUntil) < new Date()) {
                item.urgentPost = false;
              }
            }
          }
        });
        this.allCards.sort(function (a, b) {
          if (a.urgentPost && !b.urgentPost) {
            return -1;
          } else if (!a.urgentPost && b.urgentPost) {
            return 1;
          } else {
            return new Date(b.postedDate) - new Date(a.postedDate);
          }
        });
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "../assets/_shared.scss";
@import "../styles/carousel.scss";

.sharing-mobile-red-news {
  height: 396px !important;
}
.sharing-mobile-news {
  height: 396px !important;
}
.heading-dash {
  text-align: left;
  margin-left: 70px;
  margin-top: 50px;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  color: #14377c;
  font-family: "Isidora-Bold";
  font-size: 5vw;
}
.heading-dash.mobile {
  margin-left: 25px;
  margin-top: 0px;
}

.heading-dash .red-dot {
  color: #ee3124;
}

.heading-dash p {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}
</style>

<style lang="scss" scoped>
@import "../assets/_shared.scss";

.VueCarousel::v-deep .VueCarousel-wrapper {
  height: 409px;
  padding-top: 5px;
}

.VueCarousel::v-deep
  .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  margin-left: 5px !important;
  opacity: 1;
}

.VueCarousel::v-deep
  .VueCarousel-navigation-button.VueCarousel-navigation-next {
  margin-right: 5px !important;
}

.bidash {
  padding-top: 20px;
}

.carousel-wrapper {
  padding: 0 5%;
  padding-top: 20px;
  margin: 0 auto;
  height: 100%;
  align-items: left;

  .mobile {
    width: 255px;
    margin: 0 5px;
  }

  .iPad {
    margin: 0 10px;
    width: 375px;
  }
}
.carousel-wrapper-mobile {
  padding-top: 20px;
  // margin: 0 auto;
  height: 100%;
  align-items: left;
  width: 100%;

  .mobile {
    width: 90%;
    // margin: 0 5px;
  }
  .VueCarousel::v-deep .VueCarousel-wrapper {
    width: 100%;
    height: 409px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .VueCarousel::v-deep .VueCarousel-inner {
    width: 90% !important;
  }
}

.carousel h5 {
  text-align: center;
  margin-top: 5px;
  font-family: "Isidora-Bold";
  font-size: 32px;
  color: #14377c;
}

.container-title {
  text-align: left;
  padding-left: 1rem;
  font-size: 25px;
  font-weight: bold;
  color: $salBlue;
}

.container-title .performance {
  display: flex;
  justify-content: flex-start;
}

.retail-summary {
  padding: 15px;
  height: 70px;
  background-color: whitesmoke;
  border-radius: 8px;
  color: red;
}

.readMore {
  top: 0;
  left: 0;
}

.readContent {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
}

.power-bi {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.dash {
  height: 1000px;
  width: 100vw;
  max-width: 100%;
  border: none;
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
  float: left;
}

.phone-dash {
  height: 1000px;
  width: 100vw;
  border: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}

@media (min-width: 551px) and (max-width: 890px) {
  .phone-dash {
    height: 1000px;
    width: 100%;
    border: none;
    overflow: hidden;
  }

  .heading-dash {
    text-align: left;
    margin-left: 25px;
    margin-top: 50px;
    max-height: 45px;
    display: flex;
    flex-direction: row;
    color: #14377c;
    font-family: "Isidora-Bold";
    font-size: 5vw;
  }
}

@media (max-width: 550px) {
  .phone-dash {
    height: 500px;
    width: 100%;
    border: none;
    overflow: hidden;
  }
}
</style>
