<template>
  <div>
    <hrmarketing
      :getTopics="getTopicsList"
      :getPageDetails="getHrDetails"
      :getWebinars="getRecentWebinars"
      :getPocs="getHrPocList"
    >
    </hrmarketing>
  </div>
</template>
<script lang="js">
import { mapGetters, mapActions } from "vuex";
import {
  HR_TOPICS_QUERY,
  NEWS_WEBINAR_QUERY,
  SUPPORT_SERVICES_PAGE_DETAILS,
  POINT_OF_CONTACT_QUERY,
} from "../../../queries/gqlQueries";

import HrMarketing from "./HrMarketing.vue";

export default {
  name: "HR",
  components: {
    hrmarketing: HrMarketing,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "getHrDetails",
      "getWebinarsList",
      "getTopicsList",
      "getHrPocList",
    ]),
    getRecentWebinars() {
      var webinars = this.getWebinarsList;
      var filteredWebinars = webinars.filter((item) =>
        item.Filter.nodes.some((node) => node.name === "HR")
      );
      return filteredWebinars;
    },
  },
  created() {
    this.getTopicsData();
    this.fetchWebinarsAndDecks();
    this.fetchPoC();
    this.fetchPageInfo();
  },
  methods: {
    ...mapActions([
      "fetchWebinars",
      "fetchTopics",
      "fetchPointOfContactsListWithParams",
      "fetchPageDetails",
    ]),
    getTopicsData() {
      this.fetchTopics(HR_TOPICS_QUERY).then( res => {});
    },
    fetchWebinarsAndDecks() {
      this.fetchWebinars(NEWS_WEBINAR_QUERY);
    },
    fetchPoC() {
      this.fetchPointOfContactsListWithParams({
        query: POINT_OF_CONTACT_QUERY,
        param: "HR",
      });
    },
    fetchPageInfo() {
      this.fetchPageDetails({
        query: SUPPORT_SERVICES_PAGE_DETAILS,
        param: "HR",
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
