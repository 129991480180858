import Main from "@/components/Main";

const calendarRoutes = [
    {
        path: '/operational-calendar',
        name: 'Operational Calendar',
        component: Main,
        meta: {requiresAuth: true},
        props: {
          post: (route) => ({
            url: route.params.url,
          }),
          componentName: "OperationalCalendar",
          pageTitle: "Operational Calendar",
        },
    },
    {
        path: '/operational-calendar',
        name: 'Mobile Operational Calendar',
        component: Main,
        meta: {requiresAuth: true},
        props: {
          post: (route) => ({
            url: route.params.url,
          }),
          componentName: "mOperationalCalendar",
          pageTitle: "Operational Calendar",
        },
    },
]

export default calendarRoutes