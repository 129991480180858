import Utils from "../../utils/Utils";
export default {
  objectSavedAsFavorite(state, data) {
    state.favStatus = status.statusDesc;
    state.breadcrumb = data.path;
  },
  setFavoritesData(state, res) {
    if (res.response.status === 200 && res.response.data === "") {
      state.favStatus = Utils.Strings.noFavorites;
      state.favErrCode = "200";
    } else if (res.response.status === 200 || res.response.status === 204) {
      state.favErrCode = res.response.status === 204 ? "204" : "200";
      state.favStatus =
        res.response.status === 204 ? Utils.Strings.noFavorites : "";
      let currentCLData = state.commonLinks;

      const modifiedFavs = res.response.data.userFavorites.map((favItem) => {
        const objAtIndex = currentCLData.findIndex(
          (obj) => obj.appName === favItem.menuLabel
        );
        if (objAtIndex >= 0) {
          currentCLData[objAtIndex].favEnbld = true;
        }

        return {
          appName: favItem.menuLabel,
          appLink: favItem.url,
          favEnbld: true,
        };
      });
      state.commonLinks = currentCLData;
      state.favoritesData = modifiedFavs;
    } else if (Object.keys(state.favoritesData).length === 0) {
      state.favStatus = Utils.Strings.noFavorites;
      state.favErrCode = "200";
    } else {
      state.favErrCode = "500";
      state.favStatus = Utils.Strings.fetchFavoriteErrMsg;
    }
  },
  setEmptyFavoritesData(state, res) {
    const data = res.response;
    const statuscode = res === 500 ? 500 : data.statusCode;
    if (statuscode === "200 OK" || res === 500) {
      state.favStatus =
        state.favoritesData.length > 0 ? "" : Utils.Strings.noFavorites;
      state.favErrCode = "200";
      let currentCLData = state.commonLinks;
      const objAtIndex = currentCLData.findIndex(
        (obj) => obj.appName === res.appName
      );
      if (objAtIndex >= 0) {
        currentCLData[objAtIndex].favEnbld = false;
      }
    } else {
      state.favStatus = Utils.Strings.deleteFavoriteErrMsg;
      state.favErrCode = "500";
    }
  },
  setDeleteFavoriteStatus(state, menuLabel) {
    const i = state.favoritesData
      .map((item) => item.menuLabel)
      .indexOf(menuLabel);
    state.favoritesData.splice(i, 1);
    state.favErrCode = "200";
  },
  setDeleteFavoriteErrorStatus(state, data) {
    state.favStatus = Utils.Strings.deleteFavoriteErrMsg;
    state.favoritesData.push(data.deletedObj);
    state.favErrCode = "500";
  },
};
