import { __assign, __awaiter, __generator } from "tslib";
import { InteractionType, } from '@azure/msal-browser';
import { msalInstance, loginRequest } from '../authConfig';
export function registerGuard(router) {
    var _this = this;
    router.beforeEach(function (to, from, next) { return __awaiter(_this, void 0, void 0, function () {
        var request, shouldProceed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!to.matched.some(function (route) { return route.meta.requiresAuth; })) return [3 /*break*/, 2];
                    request = __assign(__assign({}, loginRequest), { redirectStartPage: to.fullPath });
                    return [4 /*yield*/, isAuthenticated(msalInstance, InteractionType.Redirect, request)];
                case 1:
                    shouldProceed = _a.sent();
                    if (!shouldProceed) {
                        next(false);
                    }
                    _a.label = 2;
                case 2:
                    next();
                    return [2 /*return*/];
            }
        });
    }); });
}
export function isAuthenticated(instance, interactionType, loginRequest) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
            return [2 /*return*/, instance.handleRedirectPromise().then(function () {
                    var accounts = instance.getAllAccounts();
                    if (accounts.length > 0) {
                        return true;
                    }
                    // User is not signed in and attempting to access protected route. Sign them in.
                    if (interactionType === InteractionType.Popup) {
                        return instance.loginPopup(loginRequest).then(function () { return true; }).catch(function () { return false; });
                    }
                    if (interactionType === InteractionType.Redirect) {
                        return instance.loginRedirect(loginRequest).then(function () { return true; }).catch(function () { return false; });
                    }
                    return false;
                }).catch(function () { return false; })];
        });
    });
}


