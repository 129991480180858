import Main from "@/components/Main";

const newsfeedsRoutes = [

    {
        path: '/important-news-and-updates',
        component: Main,
        name: 'Important News and Updates',
        meta: {requiresAuth: true},
        props: {
            componentName: 'NewsAndUpdates',
            pageTitle: "Important News And Updates",
        }
    },
    {
        path: '/dot-comm-archives',
        name: 'Dot Comm Archives',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'DotComm',
            pageTitle: "Dot Comm Archives",
        }
    },
    {
        path: '/weekly-checkout-archives',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'WeeklyCheckoutArchives',
            pageTitle: "Weekly Checkout Archives",
        }
    },
    {
        path: '/recalls',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'Recalls',
            pageTitle: "Recalls",
        }
    },
    {
        path: '/webinars',
        name: 'Webinars',
        component: Main,
        meta: {requiresAuth: true},
        props: {
            componentName: 'Webinars',
            pageTitle: "Webinars",
        }
    },
]


export default newsfeedsRoutes