<template>
    <div class="outerDiv">
        <div :class="isMobile() ? 'card-m' : 'card'">
            <div class="card-header">
                Sign Out
            </div>
            <div class="card-body">
                <p class="card-text">Click to sign out.</p>
                <div>
                    <a target="_blank" class="button-style-view" @click="signOutBtn"> Sign Out </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions(['signOut']),
        signOutBtn() {
            this.signOut()
        },
        isMobile() {
            return window.innerWidth <= 750 || window.innerHeight <= 480;
        },
    }
}
</script>

<style lang="scss" scoped>
.outerDiv {
    width: 100%; 
}

.card {
    padding: 0 !important;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 375px;
    height: 380px;
    .card-header {
        background-color: #dce1eb !important;
        height: 50px;
        padding-left: 15px !important;
        padding-bottom: 5px;
        padding-top: 10px;
        font-family: 'Isidora-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 34.8px;
        color: #14377D;
        align-items: center;
        display: flex;
        border: none;
    }
    .card-body {
        padding: 15px !important;
        font-family: Isidora Sans SemiBold;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        border: none;
    }
      .card-title{
        font-family: 'Isidora';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #14377C;
    }

    .card-text{
      font-family: 'Isidora Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
}
.card-m {
    padding: 0 !important;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    height: 250px;
    .card-header {
        background-color: #dce1eb !important;
        height: 50px;
        padding-left: 15px !important;
        padding-bottom: 5px;
        padding-top: 10px;
        font-family: 'Isidora-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 34.8px;
        color: #14377D;
        align-items: center;
        display: flex;
        border: none;
    }
    .card-body {
        padding: 15px !important;
        font-family: Isidora Sans SemiBold;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        border: none;
    }
      .card-title{
        font-family: 'Isidora';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #14377C;
    }

    .card-text{
      font-family: 'Isidora Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
}
/* CSS */
.button-style-view {
  background-color: #fff;
  border: 1px solid #14377D;
  border-radius: 10px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #14377D;
  cursor: pointer;
  font-family: "Isidora-SemiBold";
  font-size: 20px;
  text-align: center;
  text-decoration: solid;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 175px;
  text-align: center;
  padding: 6px 20px 5px;
  gap: 5px;
}

.button-style-view:hover {
  background-color: #14377D;
  color: white;
}
.button-style-download:hover {
  background-color: #14377D;
  color: white;
}

  </style>