<template>
  <div class="outerDiv">
    <div class="readMoreWrapper">
      <read-more-dot-comm
        class="ReadContent"
        @close="closeReadMore()"
        v-if="showReadMore"
        @readMore="readMore"
        :readMoreObj="readMoreObj"
        :breadcrumb="`Dot Comm Archive`"
      />
    </div>
    <div class="pageWrap" v-if="!showReadMore">
      <div
        :class="isiPad() ? 'heading heading-iPad' : 'heading heading-desktop '"
        v-responsive="['hidden-all', 'md', 'lg', 'xl']"
      >
        <h1>Dot Comm Archive</h1>
        <h2>.</h2>

        <a @click="navToSaved()" class="saved">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="mobile_heading" v-responsive="['hidden-all', 'sm', 'xs']">
        <img
          class="back-btn"
          :src="getBackBtnImage()"
          alt="Back"
          @click="backBtnClicked()"
        />
        <span class="main_title">Dot Comm Archive</span>
        <span class="main_fullstop"></span>
        <a @click="navToSaved()" class="saved-mobile">
          <img src="../../../assets/SaveFilled.svg" />
        </a>
      </div>
      <div class="msearch" v-if="isMobile()">
        <card-search
          :key="reloadSearch"
          v-on:emitSearchInput="searchCards"
          v-responsive="['hidden-all', 'xs', 'sm']"
        />
      </div>
      <card-search
        :key="reloadSearch"
        v-on:emitSearchInput="searchCards"
        v-responsive="['hidden-all', 'md', 'xl', 'lg']"
      />
      <div
        class="leftDiv"
        v-responsive="['hidden-all', 'lg', 'xl']"
        v-if="!isIpadPro()"
      >
        <sort-filter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        />
        <div
          v-responsive="['hidden-all', 'lg', 'xl']"
          class="clearFilters"
          @click="clearAllSelection()"
        >
          <p>Clear All Selection</p>
        </div>
      </div>
      <div class="m_sortfilter" v-if="isMobile() || isiPad() || isIpadPro()">
        <MSortFilter
          ref="sortFilter"
          :key="reloadFilters"
          v-on:emitUserSettings="updateSettings"
          :sortOptions="sorts"
          :filterOptions="filters"
          :sortDefault="sortDefault"
          :page="page"
        ></MSortFilter>
      </div>
      <div :class="isMobile() || isiPad() ? 'rightDiv-mobile' : 'rightDiv'">
        <b-row>
          <search-results
            v-if="searchString.length > 0"
            :result="cardItemsFiltered.length"
          />
        </b-row>
        <b-row
          v-if="currentdisplayisready"
          :class="isMobile() || isiPad() ? 'cards-row-mobile' : 'cards-row'"
        >
          <div
            v-for="item in currentdisplay"
            :key="item.databaseId"
            :class="
              isMobile()
                ? 'cards-col-mobile'
                : isiPad()
                ? 'cards-col-iPad'
                : 'cards-col'
            "
          >
            <card-dot-comm
              class="leftPad"
              @readMore="readMore"
              v-if="currentdisplayisready && !isMobile()"
              :postedDate="item.postedDate"
              :headline="item.headline"
              :cardSummary="item.cardSummary"
              :attachments="getAttachments(item)"
              :story="item.story"
              :isUrgent="item.urgentPost"
              :id="item.databaseId"
            />
            <mcard
              class="leftPad"
              @readMore="readMore"
              v-if="currentdisplayisready && isMobile()"
              :postedDate="item.postedDate"
              :headline="item.headline"
              :cardSummary="item.cardSummary"
              :attachments="getAttachments(item)"
              :story="item.story"
              :isUrgent="item.urgentPost"
              :id="item.databaseId"
            />
          </div>
        </b-row>
        <div id="pagination" v-if="currentdisplayisready">
          <pager
            ref="pager"
            :key="reloadPager"
            v-on:emitCurrentDisplayIdx="updateCurrentDisplay"
            :buttoncount="buttoncount"
            :cardsperpagecount="getPageCount()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReadMoreDotComm from "../cards/ReadMoreDotComm.vue";
import SortFilter from "../../SortFilter.vue";
import CardSearch from "../search/CardSearch.vue";
import SearchResults from "../search/SearchResults.vue";
import SortFilterCommon from "../../../utils/SortFilterCommon.js";
import Pager from "../../pagination/Pager.vue";
import CardDotComm from "../cards/DotComm.vue";
import Mcard from "../../mobile/Mcard.vue";
import { NEWS_DOT_COMM_QUERY } from "../../../queries/gqlQueries.js";
import { gQLCaller } from "../../../queries/gqlCaller";
import Search from "../../../utils/Search.js";
import { mapGetters, mapMutations } from "vuex";
import MSortFilter from "../../mobile/MSortFilter.vue";
import Utils from "../../../utils/Utils";

export default {
  components: {
    SortFilter,
    Pager,
    CardDotComm,
    ReadMoreDotComm,
    CardSearch,
    SearchResults,
    MSortFilter,
    Mcard,
  },
  props: {
    pageTitle: String,
    compProps: String,
  },
  data() {
    return {
      showReadMore: false,
      reloadPager: 0,
      reloadFilters: 0,
      reloadSearch: 0,
      currentdisplayidx: 0,
      currentdisplay: [],
      currentdisplayisready: false,
      buttoncount: 0,
      currentPage: 1,
      filters: [],
      sorts: [],
      sortDefault: "",
      userSelections: Object,
      cardItemsFiltered: [],
      cardItems: [],
      readMoreObj: {},
      searchString: "",
      previousFilters: [],
      page: "DotComm",
    };
  },
  mounted() {
    setTimeout(() => {
        if (this.$route.params.headline) {
            this.scrollToSearch(this.$route.params.headline, this.$route.params.story);
        }
    }, 1500);
  },
  methods: {
    ...mapMutations(["mutateSortDotComm", "mutateFilterDotComm"]),
    scrollToSearch(headline, story) {
        let card = this.cardItems.find((card) => card.headline == headline && card.story == story);
        if (card) {
            let cardIndex = this.cardItems.indexOf(card);
            if(cardIndex <= this.getPageCount() - 1) {
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIndex].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".cards-col")[cardIndex].offsetTop, behavior: 'smooth' });
                    return;
                });
            } else {
                let page = 0;
                if(cardIndex == this.getPageCount()) {
                    page = Math.ceil(cardIndex / this.getPageCount()) + 1;
                } else {
                    page = Math.ceil(cardIndex / this.getPageCount())
                }
                this.$refs.pager.findSearch(page - 1);
                let cardIdx = cardIndex % this.getPageCount();
                this.$nextTick(() => {
                    document.querySelectorAll(".leftPad")[cardIdx].classList.add("highlight");
                    window.scrollTo({ top: document.querySelectorAll(".cards-col")[cardIdx].offsetTop, behavior: 'smooth' });
                });
            }
        }
    },
    isMobile() {
      return window.innerWidth <= 750 || window.innerHeight <= 480;
    },
    isIpadPro() {
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      return (
        (screen.width === 2048 && screen.height === 2732) ||
        (screen.width === 2732 && screen.height === 2048) ||
        (screen.width === 1536 && screen.height === 2048) ||
        (screen.width === 2048 && screen.height === 1536)
      );
    },
    isiPad() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet || this.isIpadPro();
    },
    getPageCount() {
      return this.isMobile() || this.isiPad() ? 6 : 9;
    },
    setState(clearAll) {
      const doResetState = clearAll || false;
      if (doResetState || this.getSortDotComm == "") {
        this.$store.commit("mutateSortDotComm", this.sortDefault);
        this.$store.commit("mutateFilterDotComm");
      }
    },

    closeReadMore() {
      this.showReadMore = false;
      setTimeout(() => {
        this.$refs.sortFilter.refresh(this.previousFilters);
      }, 0);
    },

    navToSaved() {
      this.$router.push({ name: "Saved" });
    },

    readMore(readMore) {
      this.readMoreObj = readMore;
      this.showReadMore = true;
      document.querySelector(".sal-content").scrollTo(0, 0);
    },
    getAttachments(item) {
      if (item.pDFAttachments === null) {
        return "null";
      }
      return item.pDFAttachments;
    },

    reloadPagination() {
      this.reloadPager++;
    },

    clearAllSelection() {
      this.searchString = "";
      this.setState(true);
      this.reloadFilters++;
      this.reloadSearch++;
      this.init()
    },

    updateCurrentDisplay(newidx) {
      this.currentdisplayidx = newidx;
      if (this.currentdisplaycontentFiltered.length > 0) {
        this.currentdisplay = this.currentdisplaycontentFiltered;
      } else {
        this.currentdisplay = this.currentdisplaycontent;
      }
    },

    searchCards(value) {
      if (value.length == 0) {
        this.clearAllSelection();
      } else {
        this.searchString = value;
        this.updateSettings();
      }
    },

    updateSettings() {
      this.currentdisplayisready = false;
      let SortedFilteredCards = [];
      SortedFilteredCards = SortFilterCommon.sortCards(
        this.cardItems,
        this.getSortDotComm
      );

      if (this.getFilterDotComm.length > 0) {
        SortedFilteredCards = SortFilterCommon.filterCards(
          SortedFilteredCards,
          this.getSortDotComm,
          this.getFilterDotComm
        );
      }
      if (this.searchString.length > 0) {
        this.cardItemsFiltered = Search.posts(
          this.searchString,
          SortedFilteredCards
        );
      } else {
        this.cardItemsFiltered = SortedFilteredCards;
      }

      this.currentdisplayidx = 0;
      this.buttoncount = Math.ceil(
        this.cardItemsFiltered.length / this.getPageCount()
      );
      this.currentdisplay = this.currentdisplaycontentFiltered;
      this.currentdisplayisready = true;
      this.reloadPagination();
    },

    init() {
      this.setFilters();
      this.setSorts();
      this.buttoncount = Math.ceil(this.cardItems.length / this.getPageCount());
      this.currentdisplay = this.currentdisplaycontent;
      this.currentdisplayisready = true;
      this.reloadPagination();
      this.reloadFilters++;
    },

    async getData() {
      gQLCaller(NEWS_DOT_COMM_QUERY).then((result) => {
        this.cardItems =
          result.newsFeedTypes.nodes[0].children.nodes[0].dotCommArchives.nodes.sort(
            function custom_sort(a, b) {
              return (
                new Date(b.postedDate).getTime() -
                new Date(a.postedDate).getTime()
              );
            }
          );
        this.cardItems.forEach((item) => {
          if (item.urgentUntil) {
            if (new Date(item.urgentUntil) < new Date()) {
              item.urgentPost = false;
            }
          }
        });
        this.cardItems.sort(function (a, b) {
          return b.urgentPost - a.urgentPost;
        });

        this.clearAllSelection();
      });
    },

    setFilters() {
      let filterdata = [];
      filterdata.push(
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      );
      this.filters = filterdata;
    },

    setSorts() {
      this.sorts = [];
      const s = []
      this.cardItems.forEach((item) => {
        const distinctYear = new Date(item.postedDate).getFullYear()

          if (!s.includes(distinctYear)) {
            s.push(distinctYear);
          }
        });

        this.sorts = s.sort(function(a, b) {
          return b - a;
        });
    },

    getBackBtnImage() {
      return Utils.Images.back;
    },

    backBtnClicked() {
      this.$router.back();
    },
  },

  computed: {
    ...mapGetters(["getSortDotComm", "getFilterDotComm"]),
    currentdisplayrange() {
      return parseInt(this.currentdisplayidx) + parseInt(this.getPageCount());
    },
    currentdisplaycontent() {
      return this.cardItems.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
    currentdisplaycontentFiltered() {
      return this.cardItemsFiltered.slice(
        this.currentdisplayidx,
        this.currentdisplayrange
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../../assets/_shared.scss";

.m_sortfilter {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  margin-left: -7px;
}
.readMoreWrapper {
  top: 0px;
  left: 0;
}
.ReadContent {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
}
.mobile_heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  left: 0px;
  background: #ffffff;
  border-bottom: 2px solid #e3e3e3;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  .back-btn {
    width: 36px;
    height: 18px;
  }
  .saved-mobile {
    margin-left: auto;
    margin-right: 10px;
    img {
      width: 14px;
      height: 20px;
    }
  }
  .main_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #14377c;
  }
  .main_fullstop {
    width: 4px;
    height: 4px;
    background: #ee3124;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 2px;
  }
}
.msearch {
  margin-top: 40px;
}

.heading-desktop {
  text-align: left;
  padding-left: 70px;
  padding-top: 50px;
  margin-bottom: 1rem;
}
.heading-iPad {
  text-align: left;
  padding: 2% 3%;
}
.heading h1 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #14377c;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.heading h2 {
  display: inline;
  font-family: "Isidora-Bold";
  font-size: 48px;
  color: #ee3124;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.clearFilters {
  padding-left: 70px;
  text-align: left;
  width: 300px;
  color: deepskyblue;
  font-size: 18px;
  text-decoration: underline;
  font-family: "Isidora Sans";
  font-weight: 500;
}
.clearFilters:hover {
  cursor: pointer;
}
p:hover {
  color: rgb(23, 23, 179);
  font-weight: 500;
}
.pageSelected {
  background-color: rgb(21, 11, 167);
}
.rightDiv {
  color: #000;
  height: 100%;
  min-height: 500px;
  width: 75%;
  float: right;
}
.rightDiv-mobile {
  color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.leftDiv {
  color: #000;
  height: 1000px;
  width: 25%;
  float: left;
}
.pageWrap {
  padding-left: 3px;
}
h1 {
  font-size: 2.5em;
  text-align: left;
  color: darkblue;
}
.cards-col {
  padding-top: 20px;
  width: 375px;
  margin-right: 20px;
}
.cards-col-iPad {
  padding-top: 12px;
  width: 48%;
}
.cards-col-mobile {
  padding-top: 12px;
  width: 98%;
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 55px;
  width: 100%;
}

.cards-row-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  columns: 2;
}

.saved {
  float: right;
  margin-right: 75px;
  margin-top: 12px;
  cursor: pointer;
}

.highlight {
    border: 2px solid orange;
    box-shadow: 0 0 10px orange;
    animation: boxanimate 1s 3;
}

@keyframes boxanimate {
  50% {box-shadow: 10px 10px 27px -12px rgba(0,0,0,0.75);}
}
</style>
