<template>
  <div :class="isMobile() || isiPad() ? 'container-mobile' : 'container'">
    <ul class="pagination" id="pager" />
    <li
      class="prevNext"
      v-if="!selectedButtonIdx == 0"
      v-on:click="loadNextCards"
    >
      << Prev
    </li>
    <li
      :class="selectedButtonIdx === page - 1 ? 'selected' : 'pagination'"
      v-for="(page, index) in pages"
      v-on:click="loadNextCards"
    >
      {{ page }}
    </li>
    <li
      class="prevNext"
      v-if="selectedButtonIdx < buttoncount - 1"
      v-on:click="loadNextCards"
    >
      Next >>
    </li>
  </div>
</template>
<script>
export default {
  mounted() {
    for (var i = 1; i <= this.buttoncount; i++) {
      if (i <= 5) {
        this.pages.push(i);
      }
    }
  },
  props: {
    buttoncount: {
      required: true,
      type: Number,
    },
    cardsperpagecount: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pages: [],
      selectedButtonIdx: 0,
    };
  },
  computed: {},
  methods: {
    findSearch(idx) {
      this.selectedButtonIdx = idx;
      this.$emit('emitCurrentDisplayIdx', this.selectedButtonIdx * this.cardsperpagecount);
    },
    isMobile() {
      if (
        /iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
          navigator.userAgent.toLowerCase()
        )
      ) {
        return this.isiPad() ? false : true;
      } else {
        return false;
      }
    },
    isiPad() {
      if (
        /ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
          navigator.userAgent.toLowerCase()
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    loadNextCards(e) {
      window.scrollTo(0, 350);
      if (e.target.innerText == "Next >>") {
        this.selectedButtonIdx++;
        this.$emit(
          "emitCurrentDisplayIdx",
          this.selectedButtonIdx * this.cardsperpagecount
        );
        if (this.buttoncount > 5) {
          if (this.pages[4] < this.buttoncount) {
            if (
              this.selectedButtonIdx > 2 &&
              this.selectedButtonIdx < this.buttoncount
            ) {
              if (this.selectedButtonIdx + 2 < this.buttoncount) {
                this.pages.shift();
                this.pages.push(this.selectedButtonIdx + 3);
              }
              return;
            }
          }
        }
        return;
      } else if (e.target.innerText == "<< Prev ") {
        this.selectedButtonIdx--;
        this.$emit(
          "emitCurrentDisplayIdx",
          this.selectedButtonIdx * this.cardsperpagecount
        );
        if (this.buttoncount > 5) {
          if (
            this.selectedButtonIdx >= 2 &&
            this.selectedButtonIdx < this.buttoncount - 3
          ) {
            this.pages.pop();
            this.pages.unshift(this.selectedButtonIdx - 1);
            return;
          }
        }
        return;
      } else {
        this.selectedButtonIdx = e.target.innerText - 1;
        this.$emit(
          "emitCurrentDisplayIdx",
          this.selectedButtonIdx * this.cardsperpagecount
        );
        if (this.buttoncount > 5) {
          if (this.pages[4] < this.buttoncount) {
            if (
              this.selectedButtonIdx >= 2 &&
              this.selectedButtonIdx < this.buttoncount - 2
            ) {
              var diff = this.selectedButtonIdx - 2;
              for (var i = 0; i < this.pages.length; i++) {
                this.pages[i] = this.pages[i] + diff;
              }
            } else if (this.selectedButtonIdx == this.buttoncount - 2) {
              var diff = this.buttoncount - 5;
              for (var i = 0; i < this.pages.length; i++) {
                this.pages[i] = this.pages[i] + diff;
              }
            }
          }
          if (this.pages[0] > 1) {
            if (this.selectedButtonIdx == 1) {
              for (var i = 0; i < this.pages.length; i++) {
                this.pages[i] = this.pages[i] - 1;
              }
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="css" scoped>
.selected {
  color: #14377d;
  text-decoration: underline;
  font-size: 24px;
  font-family: "Isidora-SemiBold";
  list-style-type: none;
  display: inline;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pagination {
  color: #00afd7;
  font-size: 24px;
  font-family: "Isidora-SemiBold";
  list-style-type: none;
  display: inline;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.prevNext {
  color: #00afd7;
  font-size: 24px;
  font-family: "Isidora-SemiBold";
  list-style-type: none;
  display: inline;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 25%;
}
.container-mobile {
  padding-block: 25px;
}
</style>
