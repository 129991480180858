<template>
    <div class="img-div" v-html="block.saveContent"/>
</template>

<script>
export default {
  name: 'ImageBlock',
    props: {
        block: {
        type: Object,
        required: true
        }
    },
    mounted() {

    }
}

</script>

<style lang="scss" scoped>
.img-div {
    height: 100%;
}

::v-deep img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>